import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const postEventAnalysisPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4, 4),
      boxSizing: "border-box",
    },
    promoSimulationForm: {
      marginTop: theme.spacing(4),
    },
    promoKPI: {
      padding: theme.spacing(4, 4),
      backgroundColor: "white",
      width: "white",
      minHeight: 400,
    },
  })
);
export const kpiSimulationTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      //  minHeight: "366px;",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(2, 2),
      backgroundColor: "white",
    },
    labelData: {
      width: "10%",
      padding: "10px 20px",
      borderBottom: "1px solid #bbb",
    },
    chartData: {
      width: "20%",
      padding: "10px 20px",
      borderBottom: "1px solid #bbb",
    },
    tableHeaderCell: {
      borderLeft: "0.2px solid black",
      borderTop: "0.2px solid black",
      borderRight: "0.2px solid black",
      backgroundColor: "rgba(244, 244, 244, 1)",
    },
    tableCell: {
      borderLeft: "0.2px solid black",
      borderTop: "0.2px solid black",
      borderRight: "0.2px solid black",
      padding: 20,
      fontSize: "20px",
      width: 149,
      backgroundColor: "white",
      height: 50,
      minWidth: 40,
    },
    tableCellMechnaincDisplay: {
      borderLeft: "0.2px solid black",
      borderTop: "0.2px solid black",
      borderRight: "0.2px solid black",
      padding: "12 12 12 12",
      width: 149,
      backgroundColor: "white",
      height: 50,
      // minWidth: 40,
    },
    specialSelectItem: {
      backgroundColor: "white",
      width: 200,
      height: 32,
      padding: 2,
      alignItems: "left",
    },
    formContainer: {
      display: "flex",
      alignItems: "baseline",
      gap: 12,
    },
    textControl: {
      width: 65,
      backgroundColor: "#FFFFFF",
    },
    customToolTip: {
      background: "white",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "black",
      marginLeft: "-1em",
      padding: "0.5em",
    },
  })
);
