import { CalendarConstraint, ConstraintSet } from "utils/types";
import { ConstraintsManagementActionTypes } from "./constraintsManagementAction";

type State = {
  pending: boolean;
  fulfilled: boolean;
  rejected: boolean;
  data: ConstraintSet[];
  constraintsForm: CalendarConstraint[];
  commons: ConstraintSet[];
  brands: ConstraintSet[];
  products: ConstraintSet[];
};

const INITIAL_STATE: State = {
  pending: false,
  fulfilled: false,
  rejected: false,
  data: [],
  constraintsForm: [],
  commons: [],
  brands: [],
  products: [],
};

function ConstraintsManagementReducer(
  state: State = INITIAL_STATE,
  action: any
) {
  switch (action.type) {
    case ConstraintsManagementActionTypes.CONSTRAINTS_UPDATE:
      return {
        ...state,
        commons: action.data.constraintsCommons,
        brands: action.data.constraintsBrands,
        products: action.data.constraintsProducts,
      };
    case ConstraintsManagementActionTypes.CONSTRAINTS_WIPE:
      return { ...state, commons: [], brands: [], products: [] };
    case ConstraintsManagementActionTypes.COMMON_CONSTRAINTS_UPDATE:
      return { ...state, commons: action.data };
    case ConstraintsManagementActionTypes.COMMON_CONSTRAINTS_WIPE:
      return { ...state, commons: [] };
    case ConstraintsManagementActionTypes.BRAND_CONSTRAINTS_UPDATE:
      return { ...state, brands: action.data };
    case ConstraintsManagementActionTypes.BRAND_CONSTRAINTS_WIPE:
      return { ...state, brands: [] };
    case ConstraintsManagementActionTypes.PRODUCT_CONSTRAINTS_UPDATE:
      return { ...state, products: action.data };
    case ConstraintsManagementActionTypes.PRODUCT_CONSTRAINTS_WIPE:
      return { ...state, products: [] };
    case ConstraintsManagementActionTypes.EDIT_FORM_UPDATE:
      return { ...state, constraintsForm: action.data };
    case ConstraintsManagementActionTypes.EDIT_FORM_WIPE:
      return { ...state, constraintsForm: [] };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_PENDING:
      return {
        ...state,
        pending: true,
        fulfilled: false,
        rejected: false,
        data: [],
      };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_REJECTED:
      return {
        ...state,
        pending: false,
        fulfilled: false,
        rejected: true,
        data: [],
      };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_FULFILLED:
      return {
        ...state,
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_EDIT_PENDING:
      return { ...state, data: { ...action.payload } };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_EDIT_FULFILLED:
      return { ...state, data: { ...action.payload } };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_EDIT_REJECTED:
      return { ...state, data: { ...action.payload } };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_DELETE_FULFILLED:
      const copyArray = state.data.filter((x) => x.id != action.data);
      return { ...state, data: copyArray };
    case ConstraintsManagementActionTypes.CONSTRAINT_SET_COPY_FULFILLED:
      const itemIndex = state.data.findIndex((x) => x.id === action.data);
      const item = state.data[itemIndex];
      const newArray = [...state.data];
      newArray.splice(itemIndex, 0, item);
      return { ...state, data: newArray };
    case ConstraintsManagementActionTypes.CONSTRAINT_SETS_FETCH:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default ConstraintsManagementReducer;
