import React, { Fragment } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography, TableRow, Paper, Grid, Tab } from "@mui/material";
import { Kpis } from "../../../../../utils/types";
import { calendarKpiTableStyle } from "./calendarKpiTableStyle";
import { formatNumber } from "../../../../../utils/mapper";

export enum PROMO_KPI_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

type PromoKPIProps = {
  className?: string;
  size?: PROMO_KPI_SIZE;
  kpis: Kpis;
  previousData?: any;
};
const CalendarKpiTable = (props: PromoKPIProps) => {
  const { className, size = PROMO_KPI_SIZE.SMALL, kpis, previousData } = props;
  const classes = calendarKpiTableStyle();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item laptop_lg={12}>
          <Typography fontWeight={"bold"}>KPIs</Typography>
        </Grid>
        <Grid item mobile={12} tablet={11} laptop={11} laptop_lg={12} className={classes.kpiTable}>
          <Table stickyHeader size="medium" padding="none">
            <TableRow>
              <TableCell style={{ border: "none" }} />
              <TableCell
                rowSpan={2}
                className={classes.tableCell}
                align="center"
                style={{
                  backgroundColor: " #F4F4F4",
                }}
              >
                <Typography align="center" fontSize={14}>
                  Volume uplift
                </Typography>
              </TableCell>
              <TableCell
                rowSpan={2}
                className={classes.tableCell}
                align="center"
                style={{
                  backgroundColor: " #F4F4F4",
                }}
              >
                <Typography fontSize={14} align="center">
                  ROI
                </Typography>{" "}
              </TableCell>
              <TableCell
                colSpan={3}
                className={classes.tableCell}
                align="center"
                style={{
                  backgroundColor: " #F4F4F4",
                }}
              >
                <Typography fontSize={14} align="left" px={2}>
                  Internal view
                </Typography>{" "}
              </TableCell>
              <TableCell
                colSpan={3}
                className={classes.tableCell}
                align="center"
                style={{
                  backgroundColor: " #F4F4F4",
                  borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                }}
              >
                <Typography fontSize={14} align="left" px={2}>
                  Customer view
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell className={classes.tableCell}>
                <Typography fontSize={14} align="center">
                  Volume
                </Typography>{" "}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography fontSize={14} align="center" sx={{ lineHeight: 1.1 }}>
                  CPG Profit
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography fontSize={14} align="center" sx={{ lineHeight: 1.1 }}>
                  Promo spend
                </Typography>{" "}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography fontSize={14} align="center" sx={{ lineHeight: 1.1 }}>
                  Retail sales
                </Typography>{" "}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Typography fontSize={14} sx={{ lineHeight: 1.1 }} align="center">
                  Customer front profit
                </Typography>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="center"
                style={{
                  borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                }}
              >
                <Typography fontSize={14} sx={{ lineHeight: 1.1 }} align="center">
                  Retail total profit
                </Typography>
              </TableCell>
            </TableRow>

            {kpis && !previousData && (
              <TableRow className={classes.tableRow} sx={{ lineHeight: 1.1 }}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography fontSize={13} sx={{ lineHeight: 1.1 }}>
                    Initial promotion
                  </Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{kpis?.volume_uplift}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{kpis?.roi}</Typography>
                </TableCell>

                <TableCell
                  colSpan={1}
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.volume)}</Typography>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.cpg_profit)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.promo_spend)}</Typography>
                </TableCell>

                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.retail_sales)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}> {formatNumber(kpis?.customer_trade_margin)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                    borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.customer_trade_profit)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {kpis && previousData && (
              <TableRow className={classes.tableRow}>
                <TableCell align="center" className={classes.tableCell}>
                  <Typography fontSize={13} sx={{ lineHeight: 1.1 }}>
                    Initial promotion
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{previousData?.volume_uplift}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{previousData?.roi}</Typography>
                </TableCell>

                <TableCell colSpan={1} className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{formatNumber(previousData?.volume)}</Typography>
                </TableCell>
                <TableCell colSpan={1} className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{formatNumber(previousData?.cpg_profit)}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{formatNumber(previousData?.promo_spend)}</Typography>
                </TableCell>

                <TableCell className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}>{formatNumber(previousData?.retail_sales)}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  <Typography className={classes.textStyle}> {formatNumber(previousData?.customer_trade_margin)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    //     borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                    borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(previousData?.customer_trade_profit)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {previousData && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography fontSize={13} sx={{ lineHeight: 1.1 }}>
                    New promotion
                  </Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{kpis?.volume_uplift}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.roi)}</Typography>
                </TableCell>

                <TableCell
                  colSpan={1}
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.volume)}</Typography>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.cpg_profit)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.promo_spend)}</Typography>
                </TableCell>

                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.retail_sales)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}> {formatNumber(kpis?.customer_trade_margin)}</Typography>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                  style={{
                    borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                    borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                  }}
                >
                  <Typography className={classes.textStyle}>{formatNumber(kpis?.customer_trade_profit)}</Typography>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default CalendarKpiTable;
