import { ProductData, RetailerData } from "../types";
import ALL_CALENNDARS from "../data/all_calendars.json";
import productsData from "../../../utils/data/products.json";
import retailersData from "../../../utils/data/retailers.json";

export type CommonState = {
  referenceCalendars: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: any[]; //  Calendar[];
  };
  products: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: ProductData[];
  };
  retailers: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: RetailerData[];
  };
};

export const initialState: CommonState = {
  referenceCalendars: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: [...ALL_CALENNDARS],
  },
  products: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: [],
  },
  retailers: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: [],
  },
};

function commonReducer(state = initialState, action: string) {
  return state;
}

export default commonReducer;
