import { EventParamsStateType } from "./eventParamsReducer";

export enum EventParamsActionTypes {
  EVENT_UPDATE = "EVENT_UPDATE",
}

export function updateEventParams(data: EventParamsStateType) {
  return async function (dispatch: any, getState: any) {
    dispatch({
      type: EventParamsActionTypes.EVENT_UPDATE,
      data: data,
    });
  };
}
