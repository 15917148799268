import { CalendarCreationFormActionTypes } from "./calendarCreationFormActions";

type CalendarCreationStateType = {
  financialsConstraints: {
    calendars: any[];
    brands: any[];
    products: any[];
  };
  promoMixConstraints: {
    calendars: any[];
    brands: any[];
  };
  promotionsConstraints: {
    products: any[];
  };
  dataIsSet: boolean;
};
export const initialState: CalendarCreationStateType = {
  financialsConstraints: {
    calendars: [],
    brands: [],
    products: [],
  },
  promoMixConstraints: {
    calendars: [],
    brands: [],
  },
  promotionsConstraints: {
    products: [],
  },
  dataIsSet: false,
};

function CalendarCreationFormReducer(state = initialState, action: any) {
  switch (action.type) {
    case CalendarCreationFormActionTypes.SEND_FINANCIALS_CONSTRAINTS:
      let dataIsSet = true;

      if (
        action.payload.brands.length <= 0 &&
        action.payload.products.length <= 0 &&
        action.payload.calendars.length <= 0
      ) {
        dataIsSet = false;
      }

      return {
        ...state,
        dataIsSet,
        financialsConstraints: action.payload,
      };
    case CalendarCreationFormActionTypes.SEND_PROMO_MIX_CONSTRAINTS:
      let dataIsSetC = true;

      if (
        action.payload.brands.length <= 0 &&
        action.payload.calendars.length <= 0
      ) {
        dataIsSetC = false;
      }

      return {
        ...state,
        dataIsSet: dataIsSetC,
        promoMixConstraints: action.payload,
      };
    case CalendarCreationFormActionTypes.SEND_PROMOTION_CONSTRAINTS:
      let dataIsSetF = true;
      if (
        action.payload.products.length <= 0 /*&&
        action.payload.calendars.length <= 0*/
      ) {
        dataIsSetF = false;
      }

      return {
        ...state,
        dataIsSet: dataIsSetF,
        promotionsConstraints: action.payload,
      };
    case CalendarCreationFormActionTypes.CLEAR_FINANCIALS_CONSTRAINTS:
      return {
        ...state,
        financialsConstraints: [],
      };
    case CalendarCreationFormActionTypes.CLEAR_PROMO_MIX_CONSTRAINTS:
      return {
        ...state,
        promoMixConstraints: [],
      };
    case CalendarCreationFormActionTypes.CLEAR_PROMOTION_CONSTRAINTS:
      return {
        ...state,
        promotionsConstraints: [],
      };
    default:
      return state;
  }
}

export default CalendarCreationFormReducer;
