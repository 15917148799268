import { makeStyles, withStyles, createStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2),
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "4px",
      boxShadow:
        "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(63 63 68 / 15%) 0px 1px 2px 0px",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      width: "100%",
    },
    label: {
      fontSize: 15,
      //fontWeight: 900,
    },
    heading: {
      fontSize: "18px",
      color: theme.palette.primary.main,
      margin: 0,
      textAlign: "left",
    },
    dataContainer: {
      display: "flex",

      alignItems: "center",
    },
    icon: {
      width: "50px",
      height: "auto",
    },
    data: {
      fontSize: "25px",
      fontWeight: "bold",
    },
  })
);
