import { addDays, format, parseISO, setHours } from "date-fns";
import { eachDayOfInterval, eachWeekOfInterval } from "date-fns/esm";
import { enUS } from "date-fns/locale";
import { useEffect, useState } from "react";

type IProps = {
  timelineRange: any;
  stickyRef: any;
  topPos: number;
};

const Timeline: React.FC<IProps> = ({ timelineRange, stickyRef, topPos }) => {
  const startTime = new Date().toISOString();
  const [timelineHours, setTimeline] = useState<any>(
    eachWeekOfInterval({
      start: addDays(timelineRange.start, 0),
      end: addDays(timelineRange.end, 1),
    }).filter((_, index) => index % 2 === 0)
  );
  return (
    <div
      ref={stickyRef}
      className={`flex items-center h-16 relative z-[99] bg-white shadow-lg`}
      style={{
        top: `${topPos}px`,
        transition: "top 0.1s ease-out",
      }}
    >
      <div className="flex bg-gray-50 gap-x-[76.5px] px-[2.063rem] h-2 ">
        {timelineHours.map((date, index) => {
          return (
            <div
              className="relative h-2 w-[1px] bg-stone-500"
              key={date.toString()}
            >
              {date.getMonth() !==
                timelineHours[Math.max(index - 1, 0)].getMonth() && (
                <span className="text-xs font-semibold text-black-100 absolute -top-5 -left-4 w-16">
                  {format(date, "MMM yy", {
                    locale: enUS,
                  })
                    .split(" ")
                    .map((word, index) =>
                      index === 0
                        ? word.charAt(0).toLocaleUpperCase() + word.slice(1)
                        : word
                    )
                    .join(" ")}
                </span>
              )}
              {(index === 0 || index === timelineHours.length - 1) &&
                timelineRange && (
                  <span
                    className={`${
                      topPos === 0
                        ? "text-xs font-semibold text-red-400 absolute -top-10 -left-6 w-16"
                        : "text-xs font-semibold text-red-400 absolute -top-5 -left-6 w-16"
                    }`}
                  >
                    {format(
                      index === 0 ? timelineRange.start : timelineRange.end,
                      "dd MMM yy",
                      {
                        locale: enUS,
                      }
                    )
                      .split(" ")
                      .map((word, index) =>
                        index === 0
                          ? word.charAt(0).toLocaleUpperCase() + word.slice(1)
                          : word
                      )
                      .join(" ")}
                  </span>
                )}
              {index % 2 === 0 && (
                <time
                  dateTime={date.toISOString()}
                  className="text-xs absolute top-3 -left-[5.5px] text-black-100"
                >
                  {format(date, "dd")}
                </time>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
