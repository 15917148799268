import { PROMO_SIMULATION_FORM_SIZE } from "./PromoSimulationForm";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

type PromoSimulationFormStylesProps = {
  size: PROMO_SIMULATION_FORM_SIZE;
};

export const promoSimulationFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      paddingTop: 25,
      display: "flex",
      alignItems: "center",
      gap: 12,
    },

    formInternalContainer2: {
      // padding: "8px 0px",
      display: "flex",
      alignItems: "flex-end",
      gap: 8,
    },
    titleItemContainer: {
      display: "flex",
      gap: 9,
      justifyContent: "center",
    },
    formContainer2: {
      padding: "8px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      alignContent: "center",
      width: "90%",
    },
    selectItem: {
      backgroundColor: "white",
      height: 32,
      alignItems: "left",
      width: "auto",
    },
    specialSelectItem: {
      backgroundColor: "white",
      minWidth: 140,
      height: 32,
      alignItems: "left",
    },
    specialSmallSelectItem: {
      backgroundColor: "white",
      minWidth: 75,
      height: 32,
      alignItems: "left",
    },
    itemTitle: {
      margin: 2,
    },
    item: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      padding: 12,
    },
    dateControl: {
      //width: "120px",
      // height: "10px",
      backgroundColor: "white",
      //width: "13em",

      // height: "2em",
    },
    textControl: {
      width: 65,
      height: 28,
      //    backgroundColor: "#FFFFFF",
      // textSize: 3,
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    primaryButton: {
      borderRadius: "4px",
      color: "#FFFFFF",
      height: 33,
      width: 220,
    },
    primaryButtonContainer: {
      marginTop: 25,
    },
    verticalLine: {
      border: "0.1px solid #8D8D8D",
      transform: "rotate(90deg)",
      width: "42px",
      // marginTop: "30px",
    },
  })
);
