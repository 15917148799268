import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const promoKPIStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "24px",
      marginTop: "24px",
    },
    card: {
      background: "#F4F4F4",
      minWidth: "166px",
      height: "100px",
      borderRadius: "5px",
      boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.15)",
      filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15))",
      flexGrow: 1,
      margin: "0 12px 0 12px",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#6e6e6e",
      },
    },
    cardContent: {
      padding: "12px!important",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#F4F4F4",
    },
    promoKPI: {
      padding: theme.spacing(4, 4),
      backgroundColor: "white",
      width: "white",
      minHeight: 400,
    },
  })
);
