import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { CardColumn, CardWrapper, PaperWrapper, StatusChip } from "./constraintsManagementStyles";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useState } from "react";
import { copyConstraintSet, deleteConstraintSet, fetchConstraintSets } from "Promo/utils/redux/constraintsManagement/constraintsManagementAction";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "utils/hooks/useEffectOnce";
import { mapToConstraintSetRow } from "Promo/utils/mapper";
import { ConstraintsSetsTable } from "Promo/components/constraintsSetsTable/constraintsSetsTable";

import CloseIcon from "@mui/icons-material/Close";
import { AlertDialog } from "Promo/components/alertDialog/alertDialog";

export type ConstraintSetRow = {
  id: string;
  isFavorite: boolean;
  isTemplate: boolean;
  name: string;
  lastUpdated: Date;
  author: string;
  quarter: string;
  customer: string;
  category: any;
  calendarsUsingIt: any[];
};

function CalendarCard({ calendar }: any) {
  return (
    <CardWrapper>
      <CardColumn flex={1}>
        <Typography variant="h5">{calendar.name}</Typography>
        <Typography variant="body3">
          {calendar.quarter}, {calendar.retailer}, {calendar.category}
        </Typography>
      </CardColumn>

      <CardColumn flex={1}>
        <Typography variant="body3">Status</Typography>
        <StatusChip>{calendar.system_status}</StatusChip>
      </CardColumn>

      <Box flex={2} display="flex" justifyContent={"space-between"}>
        <CardColumn>
          <Typography variant="body3">Created on {calendar.createdAt}</Typography>
          <Typography variant="body3">By -</Typography>
        </CardColumn>

        <CardColumn>
          <Typography variant="body3">Volume on deal</Typography>
          <Typography variant="body3">-</Typography>
        </CardColumn>

        <CardColumn>
          <Typography variant="body3">Overall Promo depth</Typography>
          <Typography variant="body3">-</Typography>
        </CardColumn>

        <CardColumn>
          <Typography variant="body3">ROI</Typography>
          <Typography variant="body3">-</Typography>
        </CardColumn>
      </Box>
    </CardWrapper>
  );
}

export function ConstraintsManagement() {
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    content: "",
    onTrue: null,
    onFalse: null,
  });
  const [filterText, setFilterText] = useState("");
  const [selectedConstraintSet, setSelectedConstraintSet] = useState<ConstraintSetRow>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { list } = useAppSelector((state) => {
    return {
      constraintsSets: state.reducer.ConstraintsManagementReducer,
      list: state.reducer.ConstraintsManagementReducer.data.length > 0 ? mapToConstraintSetRow(state.reducer.ConstraintsManagementReducer.data.filter((x) => x.is_template)).filter((constraint) => constraint.name.toLowerCase().includes(filterText.toLowerCase())) : [],
    };
  });

  function handleOnDelete(id: string, name: string) {
    openDialog(
      `Are you sure you to delete the ${name} constraint set`,
      () => {
        dispatch(deleteConstraintSet(id));
        setDialogConfig((state) => ({ ...state, open: false }));
      },
      () => setDialogConfig((state) => ({ ...state, open: false }))
    );
  }

  function handleOnCopy(id: string) {
    dispatch(copyConstraintSet(id));
  }

  function handleOnEdit(id: string) {
    navigate(`/promo/recommendation/constraints-sets/${id}/edit`);
  }

  function handleOnChipClick(id: string) {
    setSelectedConstraintSet(list[list.findIndex((x) => x.id === id)]);
  }

  function handleCloseButton() {
    setSelectedConstraintSet(undefined);
  }

  function openDialog(content, onTrue, onFalse) {
    setDialogConfig({
      open: true,
      content: content,
      onTrue: onTrue,
      onFalse: onFalse,
    });
  }

  useEffectOnce(() => {
    dispatch(fetchConstraintSets());
  });

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <AlertDialog status={dialogConfig.open} content={dialogConfig.content} handleResponse={dialogConfig.onTrue} handleClickClose={dialogConfig.onFalse} />

      <Grid>
        <Typography variant="h3">Constraints sets manager</Typography>
      </Grid>
      <Grid mt={3}>
        <Typography variant="body2">Sort by</Typography>
        <TextField variant="outlined" size="small" onChange={(e) => setFilterText(e.target.value)} />
      </Grid>
      <Grid mt={2}>
        {!selectedConstraintSet && (
          <PaperWrapper>
            <Typography variant="h3">All sets</Typography>

            <Grid mt={4} mb={4}>
              {!list && <Typography variant="h1">You don't have any constraint set yet</Typography>}
              {list && <ConstraintsSetsTable rows={list} onCopy={(id) => handleOnCopy(id)} onEdit={(id) => handleOnEdit(id)} onDelete={(id, name) => handleOnDelete(id, name)} onChipClick={(id) => handleOnChipClick(id)} />}
            </Grid>
          </PaperWrapper>
        )}

        {selectedConstraintSet && (
          <PaperWrapper>
            <Grid container justifyContent={"space-between"}>
              <Typography variant="h3">
                {selectedConstraintSet.calendarsUsingIt.length} Calendars using "{selectedConstraintSet.name}"
              </Typography>

              <Button variant="text" onClick={handleCloseButton}>
                <CloseIcon />
              </Button>
            </Grid>

            <Grid container flexDirection={"column"} gap={2} mt={4} mb={4}>
              {selectedConstraintSet.calendarsUsingIt.map((calendar) => (
                <CalendarCard calendar={calendar} />
              ))}
            </Grid>
          </PaperWrapper>
        )}
      </Grid>
    </Grid>
  );
}
