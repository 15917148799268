import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PromoKPI, { PROMO_KPI_SIZE } from "../../components/promoKPI/PromoKPI";
import PromoSimulationForm, {
  PROMO_SIMULATION_FORM_SIZE,
  PromoSimulationFormValues,
} from "../../components/promoSimulationForm/PromoSimulationForm";
import { promoSimulation } from "../../utils/mapper";
import { getClientData } from "../../../utils/redux/Global/GlobalActions";
import { Typography, Box, Alert } from "@mui/material";
import { postEventAnalysisPageStyles } from "./eventSimulationPageStyles";
import { PromoEvent } from "./types";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { useEffectOnce } from "../../../utils/hooks/useEffectOnce";
import {
  SimulationInputs,
  ClientData,
  ClientBrand,
  ClientCategories,
} from "../../../utils/types";
import PnLTable from "../../components/consumptionAndRetailerPL/PnLTable";
import {
  SimulationActionTypes,
  fetchAllRefCalendars,
  postEventSimulationAction,
} from "../../utils/redux/simulation/eventSimulationAction";
import LinearProgress from "@mui/material/LinearProgress";
import KPISimulationTable from "./KPISimulationTable";
import AlternativePeriodsTable from "./AlternativePeriodsTable";
import PromoIncrementality from "../../components/promoIncrementality/PromoIncrementality";
import { tacticTypes } from "Promo/components/promoSimulationForm/SelectItemMenues";
import { tooltipItemsToShow } from "Promo/utils/constants";

type SimulationProps = {
  selectedMarket: string;
};

const SimulationPage = (props: SimulationProps) => {
  const { selectedMarket } = props;

  const dispatch = useAppDispatch();

  const classes = postEventAnalysisPageStyles();
  const ClientData: ClientData = useAppSelector(
    (state) => state.reducer.GlobalReducer.clientData
  );
  const {
    eventSimulation,
    pending,
    fulfilled,
    rejected,
    id,
    referenceCalendars,
  }: any = useAppSelector((state) => state.reducer.SimulationReducer);

  const [selectedRetailers, setSelectedRetailers] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const [simulatedEvent, setSimulatedEvent] = useState<PromoEvent | null>(null);
  const [consumter, setConsumer] = useState<string>();
  const [selectedRetailer, setSelectedRetailer] = useState<string | null>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [customer, setCustomer] = useState<string>();
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [everydayPriceList, setEverydayPriceList] = useState<any[]>([]);
  const [oldFormValue, setOldValues] = useState<SimulationInputs>();
  const [tacticValues, SetTacticValues] = useState<string>("");
  const [refCal, setRefCal] = useState<string>("");

  function deepEqual(x, y) {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  }

  useEffect(() => {
    if (ClientData && ClientData.retailers) {
      dispatch({ type: SimulationActionTypes.REF_CALENDARS_RESET });
      setSelectedRetailers(ClientData.retailers.map((c) => c.internal_code));
    }
  }, [ClientData]);

  useEffect(() => {
    setSelectedCategory("");
    if (selectedRetailer != null && ClientData) {
      const retailers = ClientData.retailers;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const cats: ClientCategories[] = retailers.filter(
        (v) => v.internal_code === selectedRetailer
      )[0].categories;
      if (cats != null && cats !== undefined) {
        setSelectedCategories(cats.map((c) => c.internal_code));
      }
    }
  }, [selectedRetailer]);

  useEffect(() => {
    setSelectedBrand("");
    if (selectedRetailer != null && ClientData) {
      const retailers = ClientData.retailers;
      const cats: ClientCategories[] = retailers.filter(
        (v) => v.internal_code === selectedRetailer
      )[0].categories;
      const subCategoriesListItem = cats.filter(
        (categoryItem) => categoryItem.internal_code === selectedCategory
      )[0];

      if (
        subCategoriesListItem != null &&
        subCategoriesListItem != undefined &&
        subCategoriesListItem.brands != null
      ) {
        const brandList: any = subCategoriesListItem.brands;
        // subCategoriesListItem.sub_categories.map((cat) => {
        //   cat.brands.forEach((brand) => brandList.push(brand));
        //   return [...cat.brands];
        // });

        if (
          brandList != null &&
          brandList != undefined &&
          brandList.length > 0
        ) {
          const brandSet = new Set<string>(
            brandList.map((brand) => brand.internal_code)
          );
          setSelectedBrands(Array.from(brandSet.values()));
        }
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedProduct("");
    if (selectedRetailer != null && selectedCategory != null && ClientData) {
      const retailers = ClientData.retailers;
      const cats: ClientCategories[] = retailers.filter(
        (v) => v.internal_code === selectedRetailer
      )[0].categories;
      const subCategoriesListItem = cats.filter(
        (categoryItem) => categoryItem.internal_code === selectedCategory
      )[0];

      if (
        subCategoriesListItem != null &&
        subCategoriesListItem != undefined &&
        subCategoriesListItem.brands != null
      ) {
        const brandList: any = subCategoriesListItem.brands;
        // subCategoriesListItem.sub_categories.map((cat) => {
        //   cat.brands.forEach((brand) => brandList.push(brand));
        //   return [...cat.brands];
        // });

        if (
          brandList != null &&
          brandList != undefined &&
          brandList.length > 0
        ) {
          const brandSet = new Set<string>(
            brandList.map((brand) => brand.internal_code)
          );
          setSelectedBrands(Array.from(brandSet.values()));

          if (brandList != null && brandList != undefined) {
            const selectedItemBrands = brandList.filter(
              (brandItem) => brandItem.internal_code === selectedBrand
            )[0];
            if (selectedItemBrands != null) {
              setSelectedProducts(
                selectedItemBrands.products.map((product) => ({
                  product: product.internal_product_code,
                  redemptionRate: product.redemption_rate,
                }))
              );
              setEverydayPriceList(
                selectedItemBrands.products.map((product) => {
                  return {
                    product_code: product.internal_product_code,
                    price: product.retail_standard_price,
                  };
                })
              );
            }
          }
        }
      }
    }
  }, [selectedBrand]);

  const handleTacticValues = (values: SimulationInputs) => {
    if (values.tacticTypes == tacticTypes[0]) {
      SetTacticValues(
        values.tacticTypes + ": " + values.singleBuyValue + values.singleBuyPick
      );
    } else if (values.tacticTypes == tacticTypes[1]) {
      SetTacticValues(
        values.tacticTypes +
          ": " +
          values.multiBuyPick1 +
          " " +
          values.multibuyUnit +
          " units, " +
          values.multiBuyPick2 +
          " " +
          values.multibuyAmount +
          values.multiBuyPick3
      );
    }
  };

  function fetchReferenceCalendars(selectedQuarter: string) {
    dispatch(
      fetchAllRefCalendars(selectedQuarter, selectedRetailer, selectedMarket)
    );
  }

  const onSimulate = (values: SimulationInputs) => {
    if (deepEqual(values, oldFormValue)) return;
    setOldValues(values);
    const formValueToSend = { ...values };
    const retailer = ClientData?.retailers.filter(
      (value) => value.internal_code === formValueToSend.customer
    )[0];
    const cat = retailer?.categories.filter(
      (value) => value.internal_code === formValueToSend.category
    )[0];
    // const subCategory = cat?.sub_categories[0];
    const brand = cat?.brands.filter(
      (value) => value.internal_code === formValueToSend.brand
    )[0];
    const prod = brand?.products.filter(
      (value) => formValueToSend.product === value.internal_code
    )[0];
    if (cat?.internal_code) {
      formValueToSend.category = cat.internal_code;
    }
    if (brand?.internal_code) {
      formValueToSend.brand = brand.internal_code;
    }
    formValueToSend.product = [formValueToSend.product];
    if (refCal != "") {
      formValueToSend.referenceCalendar = refCal;
    }
    formValueToSend.market = selectedMarket;
    handleTacticValues(values);
    dispatch(postEventSimulationAction({ ...formValueToSend }));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h1">Predictive Simulation</Typography>
      <PromoSimulationForm
        size={PROMO_SIMULATION_FORM_SIZE.BIG}
        retailersData={ClientData}
        onSubmit={onSimulate}
        setCustomer={setSelectedRetailer}
        setCategory={setSelectedCategory}
        setBrand={setSelectedBrand}
        selectedRetailers={selectedRetailers}
        selectedCategories={selectedCategories}
        selectedBrands={selectedBrands}
        category={selectedCategory || ""}
        customer={customer || ""}
        brand={selectedBrand || ""}
        selectedProducts={selectedProducts}
        product={selectedProduct || ""}
        setProducts={setSelectedProduct}
        everydayPriceList={everydayPriceList}
        id={id}
        showExport={Boolean(eventSimulation)}
        fetchReferenceCalendars={fetchReferenceCalendars}
        setRefCalendar={setRefCal}
        refCalendars={referenceCalendars?.data}
        refCalendarsLoad={referenceCalendars?.pending}
        refCalendar={refCal}
      />
      <br />
      {eventSimulation && (
        <PromoKPI
          KPIs={eventSimulation.kpiIndicator}
          title={"GEPP Key Indicators"}
        />
      )}

      {eventSimulation && (
        <Box
          component={"div"}
          style={{ display: "flex", gap: "22px", marginBottom: "24px" }}
        >
          <Box style={{ width: "50%" }}>
            <PnLTable
              title="GEPP P&L"
              pnLData={eventSimulation.consumptionPL}
              tooltipToShow={tooltipItemsToShow}
            />
          </Box>
          <Box style={{ width: "50%" }}>
            <PnLTable
              title="Retailer P&L"
              pnLData={eventSimulation.retailerPL}
              tooltipToShow={tooltipItemsToShow}
            />
          </Box>
        </Box>
      )}
      {eventSimulation && (
        <PromoIncrementality
          title="Impact Waterfall"
          promoEvent={simulatedEvent}
          chartEvent={eventSimulation?.event}
        />
      )}
      {eventSimulation && (
        <KPISimulationTable
          simulationTable={eventSimulation.simulationTable}
          startDate={eventSimulation.event.date_start}
          endDate={eventSimulation.event.date_end}
          duration={eventSimulation.event.promo_duration}
          visibility={eventSimulation.event.display}
          depth={eventSimulation.event.promo_depth}
          visibilityDefinition={
            eventSimulation.visibilityLevelRetailerDefinition
          }
        />
      )}
      <Box sx={{ mt: "24px" }}>
        {eventSimulation && (
          <AlternativePeriodsTable
            simulationTable={eventSimulation.alternativePeriodsTable}
            tacticValue={tacticValues}
            startDate={eventSimulation.event.date_start}
            endDate={eventSimulation.event.date_end}
            promoDepth={eventSimulation.event.promo_depth}
            duration={eventSimulation.event.promo_duration}
          />
        )}
      </Box>
      {pending && (
        <>
          <Typography variant="h3">Simulation runtime is 2 minutes</Typography>{" "}
          <LinearProgress />
        </>
      )}
      {rejected && (
        <Alert variant="filled" severity="error">
          Error while fetching data
        </Alert>
      )}
    </div>
  );
};

export default SimulationPage;
