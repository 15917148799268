import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  Menu,
  Box,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { brands, products } from "./SelectValueFields";
import { FormProvider, useForm } from "react-hook-form";
import { Grid as Grid2, Modal } from "@mui/material";
import {
  StyledDialogButton,
  StyledDialogButtonText,
  StyledDialogContentText,
  StyledDialogTitle,
  calendarHeaderStyle,
} from "./CalendarHeaderStyle";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";

import { useTheme } from "@mui/material/styles";
import DescisionSpace from "../../../../components/descisionSpace/DecisionSpace";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { selectReferenceCalendar } from "../calendarComparsionViewEdit/redux/calendarComparsionViewEditActions";
import {
  fetchReferenceCalendarById,
  fetchReferenceCalendars,
  updateCalendarName,
  updateEvent,
} from "../../../../utils/redux/calendarViewEdit/calendarViewEditAction";
import { getClientData } from "../../../../../utils/redux/Global/GlobalActions";
import { useNavigate } from "react-router-dom";
import {
  convertConstraintsData,
  convertToExcel,
  downloadExcel,
} from "Promo/utils/mapper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  ConstraintsManagementActionTypes,
  fetchConstraintSets,
} from "Promo/utils/redux/constraintsManagement/constraintsManagementAction";
import { CalendarCreationActionTypes } from "Promo/utils/redux/calendarCreation/calendarCreationActions";
import { useEffectOnce } from "utils/hooks/useEffectOnce";
import { Constraints } from "./constraints/Constraints";
import { fetchRefCalendar } from "Promo/utils/redux/calendar/calendarOptimizationActions";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlined from "@mui/icons-material/EditOutlined";
import axios from "axios";
import ConstraintsTable from "Promo/components/constraintsTable/constraintsTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "69vh",
  //bgcolor: "background.paper",
  borderRadius: "0.313rem",
  boxShadow: 24,
  typography: "body2",
  backgroundColor: "white",
};

type IProps = {
  calendar: any;
  selectedMarket: string;
};

const CalendarViewEditHeader: React.FC<IProps> = ({
  calendar,
  selectedMarket,
}) => {
  // CODE-SMELL this is bad stop doing this
  const selector: any = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const [categoryValue, setCategoryValue] = useState<any>("");
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [refCalValue, setRefCalValue] = useState<any>("");
  const [referenceCalendarOptions, setReferenceCalendarOptions] = useState<any>(
    []
  );
  const classes = calendarHeaderStyle();
  const [parametersData, setParameterData] = React.useState({});
  const [tabIndex, setTabIndex] = React.useState(0);
  const [paramsFlag, setParamsFlag] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [calendarName, setCalendarName] = useState<string>("");
  const [openEditName, setOpenEditName] = useState<boolean>(false);
  const [editIconOpen, setEditIconOpen] = useState<boolean>(false);
  const [calendarNameEditFail, setCalendarNameEditFail] =
    useState<boolean>(false);

  // const [constraintId, setConstraintId] = useState();
  // const [constraintSet, setConstraintSet] = useState<any>();
  // const [scope_constraints, setScopeConstraints] = useState<any[]>([]);
  // const [constraintName, setConstraintName] = useState<any>("");
  const { list } = useAppSelector((state) => {
    const data = state.reducer.ConstraintsManagementReducer.data;

    if (!Array.isArray(data) || data.length <= 0) {
      return { list: [] };
    }

    return {
      list: data,
    };
  });

  // const [data, setData] = useState<any>({});
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditNameDialog = () => {
    setOpenEditName(!openEditName);
    setCalendarName("");
    setEditIconOpen(false);
  };

  const modalOnSave = () => {
    editIconOpen ? handleNameChange() : saveEvents({ ...selector.saveObject });
    handleEditNameDialog();
  };

  const dispatch = useAppDispatch();

  // constraint sets
  useEffectOnce(() => {
    dispatch({
      type: CalendarCreationActionTypes.SET_CONSTRAINTS_AS_INVALID,
    });

    dispatch(fetchConstraintSets());
  });

  useEffect(() => {
    if (selector.editEventState.fulfilled) navigate("/recommendation");
  }, [selector.editEventState]);

  useEffect(() => {
    if (selector.calendarNameStatus === "FAILURE") {
      setCalendarNameEditFail(true);
    }
  }, [selector.calendarNameStatus]);

  //set constraint id when calendar is not empty
  // useEffect(() => {
  //   if (calendar) {
  //     setConstraintId(calendar.constraint_set_id);
  //     dispatch(fetchRefCalendar(calendar.reference_calendar_id));
  //   }
  // }, [calendar]);

  // set constraint set when constraint id is not empty
  // useEffect(() => {
  //   if (constraintId && list && list.length > 0) {
  //     const calendar_data = list[list.findIndex((x) => x.id === constraintId)];
  //     if(calendar_data && calendar_data.calendar_constraints){
  //       setConstraintSet([
  //         ...list[list.findIndex((x) => x.id === constraintId)]
  //           .calendar_constraints
  //       ]);
  //     }
  //     if(calendar_data && calendar_data.name){
  //       setConstraintName([
  //         ...list[list.findIndex((x) => x.id === constraintId)]
  //           .name
  //       ])
  //     }
  //     if(calendar_data && calendar_data.scope_constraints){
  //       setScopeConstraints([
  //         ...list[list.findIndex((x) => x.id === constraintId)]
  //           .scope_constraints
  //       ]);
  //     }
  //   }
  // }, [constraintId]);

  // useEffect(() => {
  //   dispatch({
  //     type: ConstraintsManagementActionTypes.CONSTRAINTS_WIPE,
  //   });

  //   if (!constraintSet || constraintSet.length <= 0) {
  //     return;
  //   }

  //   const { constraintsCommons, constraintsBrands, constraintsProducts } =
  //     convertConstraintsData(constraintSet);

  //   setData({ constraintsCommons, constraintsBrands, constraintsProducts });

  //   dispatch({
  //     type: ConstraintsManagementActionTypes.CONSTRAINTS_UPDATE,
  //     data: { constraintsCommons, constraintsBrands, constraintsProducts },
  //   });
  //   dispatch(fetchRefCalendar(calendar.reference_calendar_id));
  // }, [constraintSet]);
  //end constraint sets

  useEffect(() => {
    dispatch(fetchReferenceCalendars());
  }, []);

  useEffect(() => {
    if (
      selector.referenceCalendars.length &&
      typeof calendar === "object" &&
      Object.keys(calendar).length > 0
    ) {
      const names = new Set();
      const categories = new Set();
      // get set of reference calendar names and set calendar categories
      selector.referenceCalendars.forEach((element) => {
        const name = element.Calendar.name;
        names.add(name);
        const category = element.Calendar.category;
        categories.add(category);
      });
      setReferenceCalendarOptions(Array.from(names));

      // find id of calendar with same name as first reference calendar and if there is two calendars get first one with same category
      const value_calendar = selector.referenceCalendars.filter(
        (calendar) =>
          calendar.Calendar.name ===
          selector.referenceCalendars[0].Calendar.name
      )[0].Calendar;
      setRefCalValue(value_calendar.name);
      setCategoriesOptions(updateCategories(value_calendar.name));
      setCategoryValue(value_calendar.category);
    }
  }, [selector.referenceCalendars, calendar]);

  // get calendar id based on name and category
  const getCalendarId = (name, category) => {
    if (!name || !category) return;
    // find calendar with same name only
    const calendars = selector.referenceCalendars.filter(
      (calendar) => calendar.Calendar.name === name
    );
    if (calendars.length === 1) {
      setCategoryValue(calendars[0].Calendar.category);
      return calendars[0].Calendar.id;
    }
    // if category is not "empty find calendar based on category
    if (category !== "") {
      const calendar = calendars.filter(
        (calendar) => calendar.Calendar.category === category
      );
      if (calendar.length) {
        return calendar[0].Calendar.id;
      }
    }
    // if category is not in the calendars filtered return first calendar with same name and update category value
    setCategoryValue(calendars[0].Calendar.category);
    return calendars[0].Calendar.id;
  };

  const updateCategories = (name) => {
    //set categories values based of reference calendar that has same value as value
    const categories = new Set();
    selector.referenceCalendars
      .filter((calendar) => calendar.Calendar.name === name)
      .forEach((element) => {
        const category = element.Calendar?.category;
        categories.add(category);
      });
    return Array.from(categories);
  };

  // TODO do we nheed this at all?
  // useEffect(() => {
  //   // set value with id of calendar with same name and category
  //   const id = getCalendarId(refCalValue, categoryValue);
  //   // setValue(id);
  //   // dispatch(fetchRefCalendar(id));
  // }, [refCalValue, categoryValue]);

  // useEffect(() => {
  //   if(value != undefined && value != null) dispatch(fetchReferenceCalendarById(value));
  //   if (client == null) {
  //     dispatch(getClientData());
  //   }
  // }, [value]);

  const paramsForm = useForm<any>({
    mode: "onSubmit",
    defaultValues: {},
    // resolver: yupResolver(CreateScenarioParamsSchema)
  });

  const handleChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  function addParameters(data: any) {
    setParameterData({
      brandPositioning: {
        businessObjectives: data.objectiveRows,
        targetBrand: data.brandRows,
      },
      portfolioPricing: {
        skuPriceIndex: data.skuComparisonRows,
        skuMainInputs: data.skuInputRows,
      },
      incentiveCurve: {
        curveInputs: data.curveInputRows,
      },
    });
    setParamsFlag(true);
  }

  const handleReferenceCalendarOnChange = (e) => {
    dispatch(selectReferenceCalendar(e.target.value));
  };

  const saveEvents = (data) => {
    if (selector.draftCalendar?.id)
      data["previousCalendarId"] = selector.draftCalendar.id;
    data["market"] = selectedMarket;
    data["name"] = calendarName;
    dispatch(updateEvent(calendar.id, data));
  };

  const handleNameChange = () => {
    const payload = { name: calendarName };
    dispatch(updateCalendarName(calendar.id, payload));
  };

  const handleExportClick = () => {
    if (calendar && calendar.system_status === "COMPLETE") {
      axios({
        url: `${process.env.REACT_APP_PROMO_API_URL}/calendar/export/${calendar.id}`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const contentDisposition = response.headers["content-disposition"];
        const filename = `${calendar.name}_all.xlsx`; //contentDisposition.match(/filename=(?<filename>[^,;]+);/)[0];
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  return (
    <div>
      <div></div>
      <div>
        <div className={classes.headerTitles}>
          <div className={classes.leftIcon}>
            <div className={classes.draft}>
              <Typography fontSize={10} fontWeight="bold" align="center">
                {/* DRAFT */}
              </Typography>
            </div>
            {/* <EditSharpIcon /> */}
          </div>

          <div className={classes.leftButtons}>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                id="export-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <FormatAlignCenterIcon
                  style={{ height: "15px", width: "15px" }}
                />
                Export to Excel
              </Button>
              <Menu
                id="export-menu"
                aria-labelledby="export-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    downloadExcel(
                      convertToExcel(calendar.events),
                      calendar.name + "_Promo events Financials"
                    );
                    handleClose();
                  }}
                >
                  <FileDownloadIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography variant="body3">
                    Promo events Financials
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    downloadExcel(
                      convertToExcel(calendar.dailies),
                      calendar.name + "_Product Financials"
                    );
                    handleClose();
                  }}
                >
                  <FileDownloadIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography variant="body3">Product Financials</Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleExportClick();
                    handleClose();
                  }}
                >
                  <FileDownloadIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      paddingRight: "5px",
                    }}
                  />
                  <Typography variant="body3">Calendar Details</Typography>
                </MenuItem>
                {/* <MenuItem onClick={(e)=> {downloadCSV(convertToCSV(ref_calendar.events), ref_calendar.name+"_events"); handleClose()}}>
                <FileDownloadIcon style={{ height: "25px", width: "25px", paddingRight: "5px" }}/>
                Export Reference Events
              </MenuItem>
              <MenuItem onClick={(e)=> {downloadCSV(convertToCSV(ref_calendar.dailies), ref_calendar.name+"_dailies"); handleClose()}}>
                <FileDownloadIcon style={{ height: "25px", width: "25px", paddingRight: "5px" }}/>
                Export Reference Dailies
              </MenuItem>
              <MenuItem onClick={(e)=> {
                        downloadCSV(convertToCSV([ref_calendar]), ref_calendar.name+"_all");
                        handleClose()}}>
                          <FileDownloadIcon style={{ height: "25px", width: "25px", paddingRight: "5px" }}/>
                          Export Reference Calendar Details
              </MenuItem> */}
              </Menu>
            </div>
            <Button
              onClick={() => {
                setCalendarName(selector.calendar.name + " - EDIT");
                setOpenEditName(true);
              }}
              disabled={!selector.changed}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <Grid container direction={"row"}>
        <Typography variant="h2" align="left" fontWeight={"normal"} mr={1}>
          {/* {client?.internal_code},*/}
          {calendar.name ? `${calendar.name}` : "Loading Calendar ..."}
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            setCalendarName(selector.calendar.name);
            setEditIconOpen(true);
            setOpenEditName(true);
          }}
        >
          <EditOutlined />
        </IconButton>
      </Grid>

      <div className={classes.formContainer}>
        {/* <Grid>
          <Typography fontWeight={"bold"} align="left" fontSize={10}>
            Reference Calendar
          </Typography>

          <FormControl>
            <Select
              value={refCalValue}
              className={classes.select}
              displayEmpty={true}
              //multiple={true}
              sx={{ height: 32, bgcolor: "white", paddingY: "4px" }}
              onChange={(event: any) => {
                setRefCalValue(event.target.value);
                setCategoriesOptions(updateCategories(event.target.value));
              }}
            >
              <MenuItem disabled={true}>
                <Typography fontSize={13}>{refCalValue}</Typography>
              </MenuItem>
              {referenceCalendarOptions.length &&
                referenceCalendarOptions.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      <Typography fontSize={13}>{item}</Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid> */}

        {/* <Grid>
          <Typography fontWeight={"bold"} align="left" fontSize={10}>
            Category
          </Typography>

          <FormControl>
            <Select
              className={classes.select}
              value={categoryValue}
              displayEmpty={true}
              sx={{ height: 32, bgcolor: "white", paddingY: "4px" }}
              onChange={(event: any) => setCategoryValue(event.target.value)}
            >
              <MenuItem disabled={true}>
                <Typography fontSize={13}>Category</Typography>
              </MenuItem>
              {categoriesOptions.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography fontSize={13}> {item}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid>
          <Typography fontWeight={"bold"} align="left" fontSize={10}>
            Brand
          </Typography>

          <FormControl>
            <Select
              className={classes.select}
              value={value}
              displayEmpty={true}
              sx={{ height: 32, bgcolor: "white", paddingY: "4px" }}
              onChange={(event: any) => setValue(event.target.value)}
            >
              <MenuItem disabled>
                <Typography fontSize={13}> Brand name </Typography>{" "}
              </MenuItem>
              {brands.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    <Typography fontSize={13}> {item.label}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid>
          <Typography fontWeight={"bold"} align="left" fontSize={10}>
            Product
          </Typography>

          <FormControl>
            <Select
              className={classes.select}
              value={value}
              displayEmpty={true}
              sx={{ height: 32, bgcolor: "white", paddingY: "4px" }}
              onChange={(event: any) => setValue(event.target.value)}
            >
              <MenuItem disabled={true}>
                <Typography fontSize={13}>Product name </Typography>
              </MenuItem>
              {products.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    <Typography fontSize={13}>{item.label}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
      </div>

      <Dialog
        open={openEditName}
        onClose={handleEditNameDialog}
        fullWidth
        maxWidth={"tablet"}
        PaperProps={{
          style: { borderRadius: "10px", padding: "24px" },
        }}
      >
        <StyledDialogTitle>
          {editIconOpen ? "Edit Name" : "Save Calendar"}
        </StyledDialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <StyledDialogContentText>Calendar Name</StyledDialogContentText>
          <TextField
            autoFocus
            margin="dense"
            sx={{ marginBottom: "25px" }}
            id="name"
            size="small"
            variant="outlined"
            fullWidth
            value={calendarName}
            onChange={(event) => {
              setCalendarName(event.target.value);
            }} //whenever the text field change, you save the value in state
          />
        </DialogContent>
        <DialogActions>
          <StyledDialogButton
            onClick={handleEditNameDialog}
            variant="contained"
            startIcon={<CloseIcon />}
            disableRipple
          >
            <StyledDialogButtonText
              sx={{
                color: theme.palette.primary.contrastText,
              }}
            >
              Cancel
            </StyledDialogButtonText>
          </StyledDialogButton>
          <StyledDialogButton
            onClick={modalOnSave}
            variant="contained"
            disabled={calendarName === ""}
            startIcon={<SaveIcon />}
            sx={{
              backgroundColor: `${theme.palette.primary.contrastText} !important`,
              color: theme.palette.secondary.main,
            }}
          >
            <StyledDialogButtonText>Save</StyledDialogButtonText>
          </StyledDialogButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={calendarNameEditFail}
        onClose={() => setCalendarNameEditFail(false)}
        autoHideDuration={5000}
      >
        <Alert severity="error">{`Calendar name edit failed. Please try again later!`}</Alert>
      </Snackbar>
    </div>
  );
};

export default CalendarViewEditHeader;
