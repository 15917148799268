import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Cell,
  LabelList,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { useStyles } from "./aggregationBubbleChartStyle";
import chroma from "chroma-js";
import { PromoSpendNgrossProfit } from "../../../../utils/types";
import { formatNumber } from "../../../../utils/mapper";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

type aggregationBubbleChartProps = {
  cardTitle: string;
  data: PromoSpendNgrossProfit[];
};

const AggregationBubbleChart = (props: aggregationBubbleChartProps) => {
  const { cardTitle, data } = props;
  const chromaScale = chroma.scale(["#EB3A3A", "#FFFFFF", "#39EB10"]);

  const classes = useStyles({});
  const spendData: number[] = data.map((d) => d.spend);
  spendData.sort(function (a, b) {
    return a - b;
  });
  let avgSpendData = (spendData[5] - spendData[0]) / 5;
  const data02 = [{ value: spendData[0], index: 1 }];
  for (let i = 0; i < 4; i++) {
    data02.push({ value: data02[i].value + avgSpendData, index: 1 });
  }
  const getValueFromRange = (roi: number): number => {
    return ((roi - -2.5) * (1 - 0)) / (2.5 - -2.5) + 0;
  };
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "white",
            color: "#333",
            boxShadow: "0 3px 14px rgb(0 0 0 / 40%)",
            padding: "5px",
            textAlign: "left",
            borderRadius: "12px",
          }}
        >
          <p className="label">{`Customer : ${payload?.[0].payload.customer}`}</p>
          <p className="label">{`Brand : ${payload?.[0].payload.brand}`}</p>
          <p className="label">{`ROI : ${formatNumber(
            payload?.[0].payload.roi,
            2
          )}`}</p>
          <p className="label">{`Spend : £${formatNumber(
            payload?.[0].payload.spend,
            0,
            false,
            true
          )}`}</p>
          <p className="label">{`Gross profit : £${formatNumber(
            payload?.[0].payload.gross_profit,
            0,
            false,
            true
          )}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2" fontSize={20} fontWeight={"500"} align="left">
          {cardTitle}
        </Typography>
      </div>
      <div className={classes.dataContainer}>
        <Grid container>
          <Grid item mobile={12} tablet={3} laptop={3} laptop_lg={2}>
            <Box className={classes.valueContainer}>
              <Box
                className={classes.boxBorderBottom}
                sx={{ paddingBottom: "20px" }}
              >
                <Typography variant="body3">ROI</Typography>
                <Box display={"flex"} height={150} gap={0.5}>
                  <Box className={classes.gradientRectangle}> </Box>
                  <Box className={classes.FlexCol}>
                    <Typography variant="body3">+2.5</Typography>
                    <Typography variant="body3">-2.5</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.boxBorderBottom}>
                <Typography variant="body3">Spend (k£)</Typography>
                <Box className={classes.FlexCol}>
                  <ResponsiveContainer width="100%" height={300}>
                    <ScatterChart
                      margin={{
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                    >
                      <XAxis
                        type="category"
                        dataKey="index"
                        tick={false}
                        allowDuplicatedCategory={false}
                        axisLine={false}
                        hide
                      />
                      <YAxis
                        type="category"
                        dataKey="value"
                        allowDuplicatedCategory={false}
                        orientation="right"
                        axisLine={false}
                        tickLine={false}
                        width={200}
                        tickFormatter={(value) =>
                          formatNumber(value, 0, true, true)
                        }
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        range={[100, 1800]}
                      />
                      <Scatter data={data02} fill="#D9D9D9"></Scatter>
                    </ScatterChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item mobile={12} tablet={9} laptop={9} laptop_lg={10}>
            <ResponsiveContainer width="100%" height={700}>
              <ScatterChart
                margin={{
                  top: 10,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <XAxis
                  type="category"
                  dataKey="customer"
                  allowDuplicatedCategory={false}
                />
                <YAxis
                  type="category"
                  dataKey="brand"
                  allowDuplicatedCategory={false}
                  width={150}
                />
                <ZAxis type="number" dataKey="spend" range={[100, 1800]} />
                <Tooltip content={<CustomTooltip />} />
                <Scatter data={data}>
                  <LabelList
                    dataKey="gross_profit"
                    position="top"
                    fill="black"
                    stroke="none"
                    formatter={(value) => formatNumber(value, 0, true, true)}
                  />
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={chromaScale(
                        getValueFromRange(entry.roi)
                      ).toString()}
                      stroke={chroma(
                        chromaScale(getValueFromRange(entry.roi)).toString()
                      ).darken(2)}
                    />
                  ))}
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AggregationBubbleChart;
