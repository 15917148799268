import { CalendarCreationActionTypes } from "./calendarCreationActions";

type CalendarCreationStateType = {
  forms: {
    scope: boolean;
    details: boolean;
    objectives: boolean;
    constraints: boolean;
  };
  snapshot: any;
};
export const initialState: CalendarCreationStateType = {
  forms: {
    scope: true,
    details: true,
    objectives: true,
    constraints: false,
  },
  snapshot: null,
};

function CalendarCreationReducer(state = initialState, action: any) {
  switch (action.type) {
    case CalendarCreationActionTypes.TAKE_FORM_SNAPSHOT:
      return {
        ...state,
        snapshot: action.payload,
      };
    case CalendarCreationActionTypes.CLEAR_FORM_SNAPSHOT:
      return {
        ...state,
        snapshot: null,
      };
    case CalendarCreationActionTypes.SET_SCOPE_AS_VALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          scope: true,
        },
      };
    case CalendarCreationActionTypes.SET_SCOPE_AS_INVALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          scope: false,
        },
      };
    case CalendarCreationActionTypes.SET_DETAILS_AS_VALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          details: true,
        },
      };
    case CalendarCreationActionTypes.SET_DETAILS_AS_INVALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          details: false,
        },
      };
    case CalendarCreationActionTypes.SET_OBJECTIVES_AS_VALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          objectives: true,
        },
      };
    case CalendarCreationActionTypes.SET_OBJECTIVES_AS_INVALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          objectives: false,
        },
      };
    case CalendarCreationActionTypes.SET_CONSTRAINTS_AS_VALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          constraints: true,
        },
      };
    case CalendarCreationActionTypes.SET_CONSTRAINTS_AS_INVALID:
      return {
        ...state,
        forms: {
          ...state.forms,
          constraints: false,
        },
      };
    default:
      return state;
  }
}

export default CalendarCreationReducer;
