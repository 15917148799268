import {
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import RedoIcon from "@mui/icons-material/Redo";
import theme from "utils/theme";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme["spacing"](2),
  },
  "& .MuiDialogActions-root": {
    padding: theme["spacing"](1),
  },
}));

export interface DialogTitleProps {
  id?: string;
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  onNegative: () => void;
  onPositive: (data) => void;
}

export function QuestionDialog({
  open,
  onClose,
  onNegative,
  onPositive,
}: DialogTitleProps) {
  const [input, setInput] = useState("");

  return (
    <div>
      <BootstrapDialog
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
        }}
      >
        <Grid container direction={"column"} gap={3} sx={{ padding: "1.5rem" }}>
          <Box>
            <Typography variant="h3">You've set-up constraints</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: ".7rem" }}>
            <Typography variant="body2">
              Save this constraints set to re-use it later.
            </Typography>
            <TextField
              variant={"outlined"}
              size="small"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              sx={{ width: "350px" }}
            />
            {input.length <= 0 ?? (
              <small style={{ color: "red" }}>
                Can't save it with a blank name
              </small>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
              marginTop: ".5rem",
            }}
          >
            <Button
              sx={{
                border: "1px solid #000",
                borderRadius: "5px",
                padding: ".2rem .5rem",
              }}
              onClick={() => onNegative()}
            >
              <RedoIcon sx={{ marginRight: ".2rem" }} />
              Skip this step and continue
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
                "&:hover": {
                  color: theme.palette.primary.contrastText,
                  background: theme.palette.primary.main,
                },
              }}
              onClick={() => {
                if (input.length > 0) {
                  onPositive(input);
                }
              }}
            >
              <SaveIcon sx={{ marginRight: ".2rem" }} />
              Continue & Save
            </Button>
          </Box>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
