import { memo } from "react";
import { KPIChartColor } from "../../../../../../../utils/theme";
import { ITimelineEventData } from "../../interfaces";
import { calcActivityWidth } from "../../utils/activityWidth";

interface IProps {
  activity: ITimelineEventData;
  variant?: "baseline" | "constantMarginMarketShare" | "increasedMarketShare";
  startTime: string;
}

function Activities({ activity, variant, startTime }: IProps) {
  const reducedActivity = calcActivityWidth(
    startTime,
    activity.start.toISOString(),
    activity.end.toISOString()
  );

  return (
    <div
      className={`flex items-center absolute border-solid border border-gray-400 px-1 rounded-md bg-gray-60 h-8`}
      style={{
        background: "#E6E6E6",
        marginLeft: (reducedActivity.startPoint * 50)/7 + 33 , //50px/7 per day + 33px left margin of the timeline
      }}
    >
      <div
        className="h-5 bg-timeTags-t1 z-10 rounded-md flex items-center px-1 justify-between"
        style={{
          width: (reducedActivity.width * 50)/7, // 50px/7 per day
          background:
            variant == "baseline"
              ? KPIChartColor.baseline
              : variant == "constantMarginMarketShare"
              ? KPIChartColor.inscreasedMark
              : variant == "increasedMarketShare"
              ? KPIChartColor.maxMArgin
              : "",
        }}
      >
        <span className="text-sm text-black">{activity.value}</span>
      </div>
    </div>
  );
}

export default memo(Activities);
