import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ConstraintsTable from "../constraintsTable/constraintsTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "utils/theme";

type ConstraintsAccordianProps = {
  constraintSet: any;
  title: string;
  maximizationGoals?: string;
};

const ConstraintsAccordian = (props: ConstraintsAccordianProps) => {
  return (
    <Accordion
      elevation={1}
      sx={{ background: theme.palette.primary.background_2 }}
    >
      <AccordionSummary
        style={{ flexDirection: "row-reverse" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography variant="h3">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Grid>
            <Box
              sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
              }}
            ></Box>
            <ConstraintsTable
              constraintSet={props.constraintSet}
              maximizationGoals={props.maximizationGoals}
            />
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConstraintsAccordian;
