import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { handleUserCallback } from "utils/redux/Global/GlobalActions";


type AuthProps = {
};

const AuthCallbackPage = (props: AuthProps) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const authRejection =  useAppSelector((state) => state.reducer.GlobalReducer);

  const [searchParams, setSearchParams] = useSearchParams()
  // const [authLopp, setAtuhLoop] = useState(false)

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('session_state')
    if(code && state) {
        dispatch(handleUserCallback(code, state, navigate))
    } else {
        window.location.href = `${process.env.REACT_APP_PROMO_API_URL}/user/auth/login`
    }
  }, [])



    return (
      <div>
      </div>
    );
};

export default AuthCallbackPage;