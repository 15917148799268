import { IconButton, Theme } from "@mui/material";
import { makeStyles, withStyles, createStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 4),
      borderRadius: "4px",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      justifyContent: "space-between",
      gap: 20,
    },

    statusApproved: {
      backgroundColor: "#1DC116",
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",

      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      paddingTop: 1,
      fontSize: 10,
      fontWeight: "bold",
      height: 22,
    },
    statusForValidation: {
      backgroundColor: "#F3A713",
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",

      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: 1,
      fontSize: 10,
      fontWeight: "bold",
      height: 22,
    },
    statusDraft: {
      backgroundColor: "#c4c4c4",
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
      paddingTop: 1,
      fontSize: 10,
      fontWeight: "bold",
      height: 22,
    },
    itemContainer: {
      padding: theme.spacing(1, 1),
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    rightLine: {
      display: "flex",
      borderColor: "#DCDCDC",
      borderLeft: "1px solid",
      height: 40,
      paddingRight: 9,
      marginTop: 8,
    },
    componentContainer: {
      position: "relative",
      marginTop: 9,
      rowGap: 2,
      flexDirection: "column",
      alignItems: "flex-start",
      display: "grid",
      justifyContent: "flex-start",

      padding: 2,
      lineHeight: 1.1,
      //width: "120%",
    },
    topText: {
      fontSize: 14,
    },
    bottomText: {
      fontSize: 12,
      //  minWidth: 190,
    },
    statusComponentContainer: { marginTop: 2 },
    checkBoxComponent: {
      marginTop: 11,
      paddingLeft: 10,
    },
  })
);

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));
