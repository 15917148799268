import React, { useEffect, useState, ChangeEvent } from "react";
import { withFormik, FormikProps, Form } from "formik";
import {
  Button,
  TextField,
  FormControl,
  Select,
  Typography,
  MenuItem,
  Grid,
  Box,
  SelectChangeEvent,
  InputAdornment,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { promoSimulationFormStyles } from "./styles";
import { PromoEvent } from "../../utils/types";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  SimulationInputs,
  CondensedClientData,
  ClientData,
} from "../../../utils/types";
import {
  DisplayItems,
  location,
  tacticTypes,
  singleBuyPicklist,
  multiBuyPicklist1,
  multiBuyPicklist2,
  multiBuyPicklist3,
  visibilityList,
} from "./SelectItemMenues";
import CustomeDatePicker from "../../components/customDatePicker/CustomeDatePicker";
import axios from "axios";
import theme from "utils/theme";

export type PromoSimulationFormValues = {
  customer: string | null;
  category: string | null;
  brand: string | null;
  product: string[] | null;
  startDate: string | null;
  endDate: string | null;
  tacticTypes: string | null;
  display: string | null;
  singleBuyPick: string | null;
  multiBuyPick1: string | null;
  multiBuyPick2: string | null;
  multiBuyPick3: string | null;
  multibuyUnit: number | null;
  multibuyAmount: number | null;
  singleBuyValue: number;
  mechanic: string | null;
  depth: number | null;
  multi: boolean | null;
  quantity: number | null;
  refCal: string | null;
  // display: string | null;
};

export enum PROMO_SIMULATION_FORM_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

type PromoSimulationFormProps = {
  className?: string;
  size?: PROMO_SIMULATION_FORM_SIZE;
  promoEvent?: PromoEvent;
  newForm?: boolean;
  retailersData: ClientData;
  onSubmit: (values: SimulationInputs) => void;
  actionButtonsVisible?: boolean;
  multiSelect?: boolean;
  onApply?: () => void;
  onClear?: () => void;
  setCustomer: (values: string) => void;
  setCategory: (values: string) => void;
  setBrand: (values: string) => void;
  setProducts: (values: string) => void;
  fetchReferenceCalendars: (selectedQuarter: string) => void;
  setRefCalendar: (id: string) => void;
  selectedRetailers: string[];
  selectedCategories: string[];
  selectedBrands: string[];
  selectedProducts: any[];
  refCalendars: any[];
  refCalendarsLoad: boolean;
  refCalendar: string;
  category: string;
  customer: string;
  brand: string;
  product: string;
  everydayPriceList: any[];
  id: string | null | undefined;
  showExport: boolean;
};

const PromoSimulationForm = (
  props: PromoSimulationFormProps & FormikProps<SimulationInputs>
) => {
  const {
    className,
    size = PROMO_SIMULATION_FORM_SIZE.SMALL,
    values,
    setFieldValue,
    retailersData,
    setCustomer,
    setCategory,
    setBrand,
    setProducts,
    setRefCalendar,
    fetchReferenceCalendars,
    selectedRetailers,
    selectedCategories,
    selectedBrands,
    selectedProducts,
    category,
    customer,
    brand,
    product,
    everydayPriceList,
    refCalendars,
    refCalendarsLoad,
    refCalendar,
    id,
    showExport,
  } = props;

  const [activateMultibuy, setActivateMultibuy] = useState(false);
  const [validationObject, setValidationObject] = useState({});
  const classes = promoSimulationFormStyles({ size });
  const [productType, setProductType] = useState<string>("");
  const [redemptionRate, setRedemptionRate] = useState<number | null>(null);

  useEffect(() => {
    setCustomer(null);
    setFieldValue("customer", "");
    setFieldValue("category", "");
    setFieldValue("brand", "");
    setFieldValue("product", "");
    setFieldValue("endDate", null);
    setFieldValue("startDate", null);
    setFieldValue("everydayPrice", 0);
    setFieldValue("singleBuyPick", singleBuyPicklist[0]);
    setFieldValue("singleBuyValue", 0);
    setFieldValue("multiBuyPick1", multiBuyPicklist1[0]);
    setFieldValue("multiBuyPick2", multiBuyPicklist2[0]);
    setFieldValue("multiBuyPick3", multiBuyPicklist3[0]);
    setFieldValue("multibuyUnit", 2);
    setFieldValue("multibuyAmount", 1);
    setFieldValue("display", visibilityList[0]);
    setFieldValue("tacticTypes", tacticTypes[0]);
    let tempValObject = { ...validationObject };
    delete tempValObject["customer"];
    delete tempValObject["category"];
    delete tempValObject["brand"];
    delete tempValObject["product"];
    delete tempValObject["startDate"];
    delete tempValObject["endDate"];
    setFieldValue("refCal", null);

    setProductType("");
    setRefCalendar("");
    setValidationObject({ ...tempValObject });
  }, [retailersData]);

  const handleCustomerChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("customer", event.target.value as string, false);
    delete validationObject["category"];
    delete validationObject["product"];
    delete validationObject["brand"];

    setCustomer(event.target.value as string);
    setValidationObject({
      ...validationObject,
      customer: event.target.value as string,
    });

    // setFieldValue("refCal", null);
    setRefCalendar("");
  };

  useEffect(() => {
    setValidationObject({
      ...validationObject,
      tacticTypes: tacticTypes[0],
      display: visibilityList[0],
      singleBuyPick: singleBuyPicklist[0],
      singleBuyValue: 10,
      multiBuyPick1: multiBuyPicklist1[0],
      multiBuyPick2: multiBuyPicklist2[0],
      multiBuyPick3: multiBuyPicklist3[0],
      multibuyAmount: 1,
      multibuyUnit: 2,
    });
  }, []);

  useEffect(() => {
    if (refCalendars && refCalendars.length > 0)
      setRefCalendar(refCalendars[0].id);
  }, [refCalendars]);

  useEffect(() => {
    if (values.customer)
      fetchReferenceCalendars(`Full Year ${new Date().getFullYear()}`);
  }, [values.customer]);

  const handleRefCalChange = (event: SelectChangeEvent) => {
    setRefCalendar(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("category", event.target.value as string, false);
    delete validationObject["product"];
    delete validationObject["brand"];
    setProductType("");
    setCategory(event.target.value as string);
    setValidationObject({
      ...validationObject,
      category: event.target.value as string,
    });
  };

  const handleBrandChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("brand", event.target.value as string, false);
    delete validationObject["product"];
    setProductType("");
    setBrand(event.target.value as string);
    setValidationObject({
      ...validationObject,
      brand: event.target.value as string,
    });
  };

  const handleVisibilityChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("display", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      display: event.target.value as string,
    });
  };

  const handleTacticTypeChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("tacticTypes", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      tacticTypes: event.target.value as string,
    });
  };
  const handleSingleBuyPickChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("singleBuyPick", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      singleBuyPick: event.target.value as string,
    });
  };

  const handleSingleBuyValueChange = (event: ChangeEvent<any>) => {
    setFieldValue("singleBuyValue", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      singleBuyValue: event.target.value as number,
    });
  };

  const handleMultiBuyPick1Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick1", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick1: event.target.value as string,
    });
  };

  const handleMultiBuyPick2Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick2", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick2: event.target.value as string,
    });
  };

  const handleMultiBuyPick3Change = (event: SelectChangeEvent<any>) => {
    setFieldValue("multiBuyPick3", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      multiBuyPick3: event.target.value as string,
    });
  };

  const handleMultibuyUnitChange = (event: ChangeEvent<any>) => {
    setFieldValue("multibuyUnit", event.target.value as Number, false);
    setValidationObject({
      ...validationObject,
      multibuyUnit: event.target.value as Number,
    });
  };

  const handleMultibuyAmountChange = (event: ChangeEvent<any>) => {
    setFieldValue("multibuyAmount", event.target.value as Number, false);
    setValidationObject({
      ...validationObject,
      multibuyAmount: event.target.value as Number,
    });
  };

  const handleDisplayChange = (event: SelectChangeEvent<any>) => {
    setFieldValue("display", event.target.value as string, false);
    setValidationObject({
      ...validationObject,
      display: event.target.value as string,
    });
  };

  function getPromotedPrice(): string {
    const everydayPrice: number = values.everydayPrice;
    if (values.tacticTypes === "Punto de Precio") {
      if (values.singleBuyPick === "% off") {
        return `${everydayPrice * (1 - values.singleBuyValue / 100)}`;
      } else if (values.singleBuyPick === "$ Off") {
        return `${everydayPrice - values.singleBuyValue}`;
      } else {
        return `${values.singleBuyValue}`;
      }
    } else if (values.tacticTypes === tacticTypes[1]) {
      const Y: number = Number.parseInt(values.multibuyAmount + ""); // need this, otherwise will consider X and Y as string a+b == "ab"
      const X: number = Number.parseInt(values.multibuyUnit + "");

      if (X !== null && Y !== null) {
        if ("Buy" === values.multiBuyPick1) {
          // buy
          if ("Get" === values.multiBuyPick2) {
            if (
              values.multiBuyPick3 === "Units" ||
              values.multiBuyPick3 === "Free"
            ) {
              //GET, unit,
              const result = (everydayPrice * Y) / (X + Y);
              return `${result}`;
            }
          } else if (values.multiBuyPick2 === "Save") {
            // save
            return values.multiBuyPick3 === multiBuyPicklist3[1]
              ? `${(everydayPrice * X - Y) / X}`
              : `${everydayPrice * (1 - Y / 100)}`;
          } else if (values.multiBuyPick2 === multiBuyPicklist2[2]) {
            return values.multiBuyPick3 === multiBuyPicklist3[0]
              ? `${Y / X}`
              : `${(everydayPrice * Y) / X}`;
          }
        } else {
          // Spend
          if (multiBuyPicklist2[0] === values.multiBuyPick2) {
            return `${X / Y}`;
          } else if (multiBuyPicklist2[1] === values.multiBuyPick2) {
            return values.multiBuyPick3 === multiBuyPicklist3[0]
              ? `${everydayPrice * (1 - Y / X)}`
              : `${everydayPrice * (1 - Y / 100)}`;
          }
        }
      }
    }
    return everydayPrice + "";
  }
  useEffect(() => {
    // copy productType in separate function send values in step behind
    if (productType != null) {
      setFieldValue("product", productType, false);
      setRedemptionRate(
        selectedProducts.find((p) => p.product === productType)?.redemptionRate
      );
      if (
        productType.length > 0 &&
        everydayPriceList != null &&
        everydayPriceList.length > 0
      )
        values.everydayPrice = everydayPriceList.filter(
          (price) => price.product_code === productType
        )[0].price;
    }
  }, [productType]);

  const disabledProduct = (productCode: string) => {
    // if no product is selected dont enable product selection
    if (
      productType.length === 0 ||
      everydayPriceList == null ||
      everydayPriceList.length === 0
    )
      return false;
    // else get the product price and compare with selected product price in first time
    const selectedProductPrice = everydayPriceList.filter(
      (price) => price.product_code === productCode
    )[0].price;
    return Math.abs(values.everydayPrice - selectedProductPrice) > 0.1;
  };
  const isValidCombinaison = (
    buySpend: string | null,
    method: string | null,
    units: string | null
  ) => {
    const notValidCombaination = [
      { multiBuyPick1: "Buy", multiBuyPick2: "Save", multiBuyPick3: "Unit" },
      { multiBuyPick1: "Buy", multiBuyPick2: "Save", multiBuyPick3: "Free" },
      { multiBuyPick1: "Buy", multiBuyPick2: "For", multiBuyPick3: "%" },
      { multiBuyPick1: "Buy", multiBuyPick2: "For", multiBuyPick3: "Free" },

      { multiBuyPick1: "Spend", multiBuyPick2: "Save", multiBuyPick3: "Units" },
      { multiBuyPick1: "Spend", multiBuyPick2: "Save", multiBuyPick3: "Free" },

      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "$" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "%" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "Units" },
      { multiBuyPick1: "Spend", multiBuyPick2: "For", multiBuyPick3: "Free" },
    ];
    const combinationsVali = notValidCombaination.filter(
      (item) =>
        item.multiBuyPick1 === buySpend &&
        item.multiBuyPick2 === method &&
        item.multiBuyPick3 === units
    );

    if (combinationsVali != null && combinationsVali.length > 0) return true;
    return false;
  };

  const handleExportClick = (id: string | null | undefined) => {
    if (id) {
      axios({
        url: `${process.env.REACT_APP_PROMO_API_URL}/event-simulation/simulation/export/${id}`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${id.slice(0, 5)}-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const quarters: Array<string> = getQuarters();

  function getQuarters() {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear() - 1;
    const quarter_cur = Math.floor((month - 1) / 3);
    let quarters_list: Array<string> = [];
    quarters_list.push(`Full Year ${year}`);
    for (let i = 1; i <= 4; i++) {
      const q = quarter_cur + i;
      if (q > 4) {
        quarters_list.push(`Q${q - 4} ${year + 1}`);
      } else {
        quarters_list.push(`Q${q} ${year}`);
      }
    }
    return quarters_list;
  }

  return (
    <Form className={className}>
      <Grid container className={classes.formContainer}>
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Retailer
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={customer || values.customer}
              onChange={handleCustomerChange}
              sx={{ minWidth: 130 }}
            >
              {selectedRetailers?.map((customer, index) => {
                return (
                  <MenuItem key={index} value={customer}>
                    <Typography align="left" fontSize={12}>
                      {customer}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Category
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={category || values.category}
              onChange={handleCategoryChange}
              sx={{ minWidth: 130 }}
            >
              {selectedCategories?.map((customer, index) => {
                return (
                  <MenuItem key={index} value={customer}>
                    <Typography align="left" fontSize={12}>
                      {customer}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Brand
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              value={brand || values.brand}
              onChange={handleBrandChange}
              sx={{ minWidth: 120 }}
            >
              {selectedBrands?.map((customer, index) => {
                return (
                  <MenuItem key={index} value={customer}>
                    <Typography align="left" fontSize={12}>
                      {customer}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Product
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              sx={{ minWidth: 130 }}
              value={productType}
              onChange={(event) => setProductType(event.target.value)}
            >
              {selectedProducts.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.product}>
                    <Typography align="left" fontSize={12}>
                      {item.product}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Display
          </Typography>

          <FormControl>
            <Select
              className={classes.specialSelectItem}
              value={values.display}
              onChange={handleVisibilityChange}
              sx={{ minWidth: 80 }}
            >
              {visibilityList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Typography align="left" fontSize={13}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Tooltip
            title={
              <Typography
                fontSize={14}
                color={theme.palette.primary.contrastText}
                lineHeight={1.8}
              >
                Price list or Retailer standard price based on Sell-out data
              </Typography>
            }
            placement="top"
            arrow
          >
            <Grid container alignItems={"center"}>
              <Typography
                fontWeight={"bold"}
                align="left"
                fontSize={13}
                mr={0.3}
              >
                Retail Standard Price
              </Typography>
              <InfoIcon
                fontSize="small"
                sx={{ color: "text.secondary", fontSize: 14 }}
              />
            </Grid>
          </Tooltip>

          <TextField
            sx={{ width: 75 }}
            InputProps={{
              style: {
                fontSize: 12,
                background: "white",
                height: 32,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h8">$</Typography>
                </InputAdornment>
              ),
            }}
            type="text"
            size="small"
            name="everyday_price"
            value={Number(values.everydayPrice.toFixed(2))}
            style={{ minWidth: "65px" }}
            disabled
          />
        </Grid>

        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Promoted Price
          </Typography>
          <TextField
            sx={{ width: 65 }}
            InputProps={{
              style: { fontSize: 12, background: "white", height: 32 },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h8">$</Typography>
                </InputAdornment>
              ),
            }}
            type="text"
            size="small"
            name="promoted_price"
            value={parseFloat(getPromotedPrice()).toFixed(2)}
            style={{ minWidth: "87px" }}
          />
        </Grid>
        <Grid item>
          &nbsp;
          <Box className={classes.verticalLine} sx={{ marginTop: "1px" }}></Box>
        </Grid>
        <Grid item>
          <Typography
            className={classes.itemTitle}
            fontWeight={"bold"}
            align="left"
            fontSize={13}
          >
            Period
          </Typography>

          <CustomeDatePicker
            onClean={() => {
              delete validationObject["startDate"];
              delete validationObject["endDate"];
              setValidationObject({
                ...validationObject,
              });
            }}
            onChange={(date) => {
              if (date) {
                const tzoffset = date[0].getTimezoneOffset() * 60000;
                const endWithoutTimezone = new Date(
                  date[1].valueOf() - tzoffset
                )
                  .toISOString()
                  .slice(0, -1);
                const startWithoutTimezone = new Date(
                  date[0].valueOf() - tzoffset
                )
                  .toISOString()
                  .slice(0, -1);
                setFieldValue("endDate", endWithoutTimezone, false);
                setFieldValue("startDate", startWithoutTimezone, false);

                setValidationObject({
                  ...validationObject,
                  startDate: startWithoutTimezone,
                });
                setValidationObject({
                  ...validationObject,
                  endDate: endWithoutTimezone,
                });
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.formContainer2}
        columnSpacing={3}
      >
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Promo Type
          </Typography>

          <FormControl>
            <Select
              className={classes.specialSelectItem}
              value={values.tacticTypes}
              onChange={handleTacticTypeChange}
              sx={{ width: "142px" }}
            >
              {tacticTypes.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Typography align="left" fontSize={13}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {values.tacticTypes === "Punto de Precio" ? (
          <Grid item className={classes.formInternalContainer2}>
            <TextField
              sx={{ width: 65 }}
              InputProps={{
                style: { fontSize: 12, background: "white", height: 32 },
                inputProps: { min: 1, max: 100 },
              }}
              type="text"
              size="small"
              name="single_buy_value"
              value={values.singleBuyValue}
              onChange={handleSingleBuyValueChange}
            />

            <FormControl sx={{ marginTop: "30px" }}>
              <Select
                className={classes.specialSelectItem}
                sx={{ width: "89px" }}
                value={values.singleBuyPick}
                onChange={handleSingleBuyPickChange}
              >
                {singleBuyPicklist.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          // multi buy
          <Grid item className={classes.formInternalContainer2}>
            <FormControl>
              <Select
                className={classes.specialSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick1}
                onChange={handleMultiBuyPick1Change}
              >
                {multiBuyPicklist1.map((item, index) => {
                  // but spend
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        item,
                        values.multiBuyPick2,
                        values.multiBuyPick3
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                #{" "}
                {values.multiBuyPick1 === multiBuyPicklist1[1] ? "$" : "units"}
              </Typography>
              <TextField
                sx={{ minWidth: 65 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                  inputProps: { min: 1, max: 100 },
                }}
                type="number"
                size="small"
                name="multibuy_unit"
                value={values.multibuyUnit}
                onChange={handleMultibuyUnitChange}
                style={{ width: "48px" }}
              />
            </Box>
            <FormControl>
              <Select
                className={classes.specialSmallSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick2}
                onChange={handleMultiBuyPick2Change}
              >
                {multiBuyPicklist2.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        values.multiBuyPick1,
                        item,
                        values.multiBuyPick3
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                #
              </Typography>
              <TextField
                sx={{ minWidth: 65 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                  inputProps: { min: 1, max: 100, step: "any" },
                }}
                type="number"
                size="small"
                name="multibuy_amount"
                value={values.multibuyAmount}
                onChange={handleMultibuyAmountChange}
                style={{ width: "80px" }}
              />
            </Box>
            <FormControl>
              <Select
                className={classes.specialSmallSelectItem}
                sx={{ width: 10 }}
                value={values.multiBuyPick3}
                onChange={handleMultiBuyPick3Change}
              >
                {multiBuyPicklist3.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      disabled={isValidCombinaison(
                        values.multiBuyPick1,
                        values.multiBuyPick2,
                        item
                      )}
                    >
                      <Typography align="left" fontSize={13}>
                        {item}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Display Type
          </Typography>
          <FormControl>
            <Select className={classes.specialSelectItem} value={values.display} onChange={handleDisplayChange}>
              {DisplayItems.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Typography align="left" fontSize={13}>
                      {item}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Redemtion rate
          </Typography>
          <TextField
            sx={{
              "& .MuiInputBase-adornedEnd": {
                pr: 1,
              },
            }}
            InputProps={{
              style: { fontSize: 12, background: "white", height: 32 },
              inputProps: { min: 1, max: 100 },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="h8">%</Typography>
                </InputAdornment>
              ),
            }}
            type="number"
            size="small"
            name="redemtion_rate"
            value={
              values.tacticTypes === "Punto de Precio"
                ? 100
                : Number.isInteger(redemptionRate)
                ? redemptionRate
                : Number(redemptionRate?.toFixed(1))
            }
            onChange={() => {
              return;
            }}
            disabled
          />
        </Grid>
        <Grid item>
          <Typography fontWeight={"bold"} align="left" fontSize={13}>
            Reference Calendar
          </Typography>
          <FormControl>
            <Select
              className={classes.selectItem}
              sx={{ minWidth: 130 }}
              value={refCalendar}
              onChange={handleRefCalChange}
            >
              {refCalendars &&
                refCalendars.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      <Typography align="left" fontSize={13}>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        {refCalendarsLoad && (
          <Box display={"flex"} alignSelf={"end"} ml={2}>
            <CircularProgress size={30} />
          </Box>
        )}
        <Box className={classes.verticalLine} sx={{ marginTop: "42px" }}></Box>

        <Grid item sx={{ display: "flex", gap: 1, marginTop: 3.5 }}>
          <Box>
            <Button
              type="submit"
              size={"medium"}
              variant="outlined"
              className={classes.primaryButton}
              color="secondary"
              disabled={Object.keys(validationObject).length < 13}
              startIcon={<PlayArrowOutlinedIcon />}
            >
              Run simulation
            </Button>
          </Box>
          {/*<Box>*/}
          {/*<Button type="submit" size={"medium"} variant="outlined" className={classes.primaryButton} color="secondary" */}
          {/*        disabled={!(Boolean(id) && showExport)} startIcon={<IosShareIcon />} onClick={() => { handleExportClick(id) }}*/}
          {/*        sx={{ width: "97px" }}*/}
          {/*>*/}
          {/*  Export*/}
          {/*</Button>*/}
          {/*</Box> */}
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<PromoSimulationFormProps, SimulationInputs>({
  mapPropsToValues: (props) => ({
    customer: "",
    category: "",
    brand: "",
    product: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    mechanic: "",
    display: visibilityList[0],
    tacticTypes: tacticTypes[0],
    singleBuyPick: singleBuyPicklist[0],
    singleBuyValue: 10,
    multiBuyPick1: multiBuyPicklist1[0],
    multiBuyPick2: multiBuyPicklist2[0],
    multiBuyPick3: multiBuyPicklist3[0],
    multibuyUnit: 2,
    multibuyAmount: 1,
    depth: 10,
    multi: false,
    quantity: 10,
    // display: "",
    everydayPrice: 0,
    referenceCalendar: "",
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  displayName: "PromoSimulationForm",
})(PromoSimulationForm);
