import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      display: "flex",
      flexDirection: "column",

      maxWidth: 480,
      height: 380,
    },
  })
);
