import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2, 2),
    backgroundColor: theme.palette.primary.background,
    borderRadius: 4,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  switchDraft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  calendarStatus: {
    padding: theme.spacing(2, 2),
    display: "flex",
    justifyContent: "space-around",
    widht: "90%",
  },
  selectItem: {
    backgroundColor: "white",
    height: 32,
    alignItems: "left",
    width: "auto",
    margin:'0px',
  },
}));
