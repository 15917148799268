import { CalendarViewEditActionTypes } from "./calendarViewEditAction";
import { ViewEditEventCalendarKPi } from "Promo/utils/types";
import { v4 as uuidv4 } from "uuid";

const INITIAL_STATE = {
  changed: false,
  calendar: null as null | any,
  calendarNameStatus: null as null | string,
  calendarDataKpi: null as null | ViewEditEventCalendarKPi,
  previewCalendar: null as null | any,
  previewCalendarKPI: null as null | any,
  draftCalendar: null as null | any,
  draftCalendarKPI: null as null | any,
  referenceCalendars: [],
  referenceCalendar: null as null | any,
  saveObject: {
    add: [],
    remove: [],
    reference_calendar_id: "",
  } as any,
  previewSaveObject: {
    add: [],
    remove: [],
    reference_calendar_id: "",
  } as any,
  previewPending: false,
  previewRejected: false,
  draft: false,
  pending: false,
  rejected: false,
  filterCalendar: false,
  filterResult: null as null | any,
  editEventState: {
    pending: false,
    fulfilled: false,
    rejected: false,
  },
};

// TODO move this to a utils file when able
function updatedKPIsPerNewEvent(event) {
  const volume = Number(Number(event.total_volume).toFixed(2));

  const baselineVolume = Number(Number(event.baseline_volume).toFixed(2));

  const totalPromoSpend = Number(
    Number(event.incremental_rebate_working_spend).toFixed(2)
  );
  const incrementalGrossProfit = Number(
    Number(event.incremental_gross_profit).toFixed(2)
  );

  const totalGrossProfit = Number(Number(event.total_gross_margin).toFixed(2));

  const retailSalesValue = Number(
    Number(event.total_retail_sales_value).toFixed(2)
  );

  const totalCustomerTradeProfit = Number(
    Number(event.total_retailer_total_margin).toFixed(2)
  );
  const totalCustomerRevenue = Number(
    Number(event.total_retailer_revenue).toFixed(2)
  );

  // TODO: CHANGE TO REFERENCE CALENDAR ROUTE
  let simulation = {
    kpis: {
      volume_uplift: Number(Number(volume / baselineVolume).toFixed(2)),
      roi: isFinite(
        Number(Number(incrementalGrossProfit / totalPromoSpend).toFixed(2))
      )
        ? Number(Number(incrementalGrossProfit / totalPromoSpend).toFixed(2))
        : 0,
      volume: Number(Number(volume).toFixed(2)),
      cpg_profit: Number(Number(totalGrossProfit).toFixed(2)),
      promo_spend: Number(Number(totalPromoSpend).toFixed(2)),
      retail_sales: Number(Number(retailSalesValue).toFixed(2)),
      customer_trade_margin: Number(
        Number(totalCustomerTradeProfit / totalCustomerRevenue).toFixed(2)
      ),
      customer_trade_profit: Number(
        Number(totalCustomerTradeProfit).toFixed(2)
      ),
    },
    event,
  };
  return simulation;
}

function CalendarViewEditReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CalendarViewEditActionTypes.SET_SIMULATION_EVENT:
      return { ...state, simulationEvent: { ...action.payload.event } };
    case CalendarViewEditActionTypes.CHANGE:
      return { ...state, changed: action.payload };
    case CalendarViewEditActionTypes.FLAT_EVENTS:
      var calendar = { ...state.calendar };
      if (!Array.isArray(calendar.events)) {
        calendar.events = Object.keys(calendar.events).map((key) => {
          return calendar.events[key];
        });
      }
      return { ...state, calendar };
    case CalendarViewEditActionTypes.CALENDAR_UPDATE:
      return { ...state, microCalendar: { ...action.payload } };
    case CalendarViewEditActionTypes.EDIT_CALENDAR_REQUEST:
      return {
        ...state,
        editEventState: {
          pending: true,
          fulfilled: false,
          rejected: false,
        },
      };
    case CalendarViewEditActionTypes.EDIT_CALENDAR_REQUEST_SUCCESS:
      return {
        ...state,
        editEventState: {
          pending: false,
          fulfilled: true,
          rejected: false,
        },
      };
    case CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST:
      return {
        ...state,
        calendarNameStatus: "PENDING",
      };
    case CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST_SUCCESS:
      const { calendar: calendarDataPayload, ...calendarDataKpiPayload } =
        action.payload;
      return {
        ...state,
        calendarNameStatus: "COMPLETE",
        calendar: { ...calendarDataPayload },
        referenceCalendar: calendarDataPayload.reference_calendar
          ? calendarDataPayload.reference_calendar
          : { ...calendarDataPayload },
        calendarDataKpi: { ...calendarDataKpiPayload },
        saveObject: {
          add: [],
          remove: [],
          reference_calendar_id: "",
        },
        previewSaveObject: {
          add: [],
          remove: [],
          reference_calendar_id: "",
        },
        pending: false,
        rejected: false,
      };
    case CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST_FAILURE:
      return {
        ...state,
        calendarNameStatus: "FAILURE",
      };
    case CalendarViewEditActionTypes.RESET_SIMULATION:
      return { ...state, simulation: null };
    case CalendarViewEditActionTypes.SIMULATE:
      return { ...state, simulation: { ...action.payload } };

    case CalendarViewEditActionTypes.CALENDAR_PREVIEW:
      var saveObject = { ...state.previewSaveObject };
      return {
        ...state,
        previewCalendar: null,
        previewCalendarKPI: null,
        draftCalendar: { ...state.previewCalendar },
        draftCalendarKPI: { ...state.previewCalendarKPI },
        draft: true,
        saveObject,
      };

    case CalendarViewEditActionTypes.PREVIEW_CALENDAR_REQUEST:
      return {
        ...state,
        previewPending: true,
        previewRejected: false,
      };
    case CalendarViewEditActionTypes.PREVIEW_CALENDAR_REJECTED:
      return {
        ...state,
        previewPending: false,
        previewRejected: true,
      };

    case CalendarViewEditActionTypes.FETCH_REFERENCE_CALENDARS:
      return { ...state, referenceCalendars: [...action.payload] };
    case CalendarViewEditActionTypes.FETCH_REFERENCE_CALENDAR_BY_ID:
      return { ...state, referenceCalendar: { ...action.payload } };
    //=====================api call==================================
    case CalendarViewEditActionTypes.SIMULATE_CALENDAR:
      const {
        calendar: previewCalendarData,
        previewCalendarTimeline,
        ...previewCalendarDataKpi
      } = action.payload.data;
      let affected_event;
      let payloadEvents = action.payload.data.calendar.event;
      payloadEvents = Object.keys(payloadEvents).map((key) => {
        return payloadEvents[key];
      });
      // Attribute ids based on event_code
      for (let x = 0; x < payloadEvents.length; x++) {
        const element: any = payloadEvents[x];
        if (String(element.event_code) == action.payload.affected_event_code) {
          affected_event = element;
        }
      }
      let updated_event_output;
      if (affected_event)
        updated_event_output = updatedKPIsPerNewEvent(affected_event);
      return {
        ...state,
        previewPending: false,
        previewRejected: false,
        previewCalendar: previewCalendarData,
        previewCalendarKPI: previewCalendarDataKpi,
        previewCalendarTimeline: action.payload.data.timeline,
        previewSaveObject: { ...action.payload.saveObject },
        changed: true,
        simulation: updated_event_output ? updated_event_output : null,
      };

    case CalendarViewEditActionTypes.UPDATE_CALENDAR_EVENT:
      let tempCalUpdate = JSON.parse(
        JSON.stringify(
          !state.draftCalendarKPI
            ? { ...state.calendarDataKpi }
            : { ...state.draftCalendarKPI }
        )
      );
      tempCalUpdate.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events = tempCalUpdate.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events.filter((event) => event.id !== action.payload.event.id);
      tempCalUpdate.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events.push(action.payload.event);
      return {
        ...state,
        saveObject: action.payload.saveObject,
        draftCalendarKPI: tempCalUpdate,
        draft: true,
        changed: true,
      };
    case CalendarViewEditActionTypes.ADD_CALENDAR_EVENT:
      let tempCalAdd = JSON.parse(
        JSON.stringify(
          !state.draftCalendarKPI
            ? { ...state.calendarDataKpi }
            : { ...state.draftCalendarKPI }
        )
      );
      tempCalAdd.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events.push(action.payload.event);
      return {
        ...state,
        saveObject: action.payload.saveObject,
        draftCalendarKPI: tempCalAdd,
        draft: true,
        changed: true,
      };
    case CalendarViewEditActionTypes.DELETE_CALENDAR_EVENT:
      let tempCalKPI = JSON.parse(
        JSON.stringify(
          !state.draftCalendarKPI
            ? { ...state.calendarDataKpi }
            : { ...state.draftCalendarKPI }
        )
      );
      tempCalKPI.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events = tempCalKPI.timeline.products[
        `${action.payload.product.internal_product_code}`
      ].events.filter((event) => event.id !== action.payload.event);
      return {
        ...state,
        saveObject: action.payload.saveObject,
        draftCalendarKPI: tempCalKPI,
        draft: true,
        changed: true,
      };
    case CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID:
      const { calendar: calendarData, ...calendarDataKpi } = action.payload;
      return {
        ...state,
        calendar: { ...calendarData },
        referenceCalendar: calendarData.reference_calendar
          ? calendarData.reference_calendar
          : { ...calendarData },
        calendarDataKpi: { ...calendarDataKpi },
        saveObject: {
          add: [],
          remove: [],
          reference_calendar_id: "",
        },
        previewSaveObject: {
          add: [],
          remove: [],
          reference_calendar_id: "",
        },
        pending: false,
        rejected: false,
      };
    case CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID_REQUEST:
      return {
        ...state,
        calendar: {},
        calendarDataKpi: null,
        pending: true,
        rejected: false,
      };
    case CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID_REJECTED:
      return {
        ...state,
        calendar: {},
        calendarDataKpi: null,
        pending: false,
        rejected: true,
      };
    case CalendarViewEditActionTypes.FILTER_CALENDAR_SUCCESS:
      return {
        ...state,
        filterCalendar: true,
        filterResult: action.payload,
      };
    case CalendarViewEditActionTypes.FILTER_CALENDAR_RESET:
      return {
        ...state,
        filterCalendar: false,
        filterResult: null,
      };
    default:
      return { ...state };
  }
  return state;
}

export default CalendarViewEditReducer;
