export enum CalendarCreationActionTypes {
  SET_SCOPE_AS_VALID = "SET_SCOPE_AS_VALID",
  SET_SCOPE_AS_INVALID = "SET_SCOPE_AS_INVALID",
  SET_DETAILS_AS_VALID = "SET_DETAILS_AS_VALID",
  SET_DETAILS_AS_INVALID = "SET_DETAILS_AS_INVALID",
  SET_OBJECTIVES_AS_VALID = "SET_OBJECTIVES_AS_VALID",
  SET_OBJECTIVES_AS_INVALID = "SET_OBJECTIVES_AS_INVALID",
  SET_CONSTRAINTS_AS_VALID = "SET_CONSTRAINTS_AS_VALID",
  SET_CONSTRAINTS_AS_INVALID = "SET_CONSTRAINTS_AS_INVALID",

  SEND_FINANCIALS_CONSTRAINTS = "SEND_FINANCIALS_CONSTRAINTS",
  CLEAR_FINANCIALS_CONSTRAINTS = "CLEAR_FINANCIALS_CONSTRAINTS",

  SEND_PROMO_MIX_CONSTRAINTS = "SEND_PROMO_MIX_CONSTRAINTS",
  CLEAR_PROMO_MIX_CONSTRAINTS = "CLEAR_PROMO_MIX_CONSTRAINTS",

  TAKE_FORM_SNAPSHOT = "TAKE_FORM_SNAPSHOT",
  CLEAR_FORM_SNAPSHOT = "CLEAR_FORM_SNAPSHOT",
}
