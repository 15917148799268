import produce, { Draft } from "immer";
import { Reducer } from "redux";

import { CALENDAR_STATUS } from "../../enum";
import { CalendarOptimizationState } from "../../types";

import {
  CalendarOptimizationAction,
  CalendarOptimizationActionTypes,
} from "./calendarOptimizationActions";

export const initialState: CalendarOptimizationState = {
  calendarsData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  AllCalendarData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  createCalendarRequest: {
    pending: false,
    fulfilled: false,
    rejected: false,
  },
  calendarAggregationData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  allRefCalendarsData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  refCalendarData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  selectedRefCalData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  calendarComparisonData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  detailedCalendarData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  addNewCalendarData: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  deleteCalendar: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  uploadCalendar: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
  calendarFilters: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
};

export const calendarOptimizationReducer: Reducer<
  CalendarOptimizationState,
  CalendarOptimizationAction
> = produce((draft: StateDraft, action: CalendarOptimizationAction) => {
  switch (action.type) {
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_PENDING:
      draft.AllCalendarData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      // break;
      draft.calendarsData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: { calendar_data: [], calendar_management_timeline: [] },
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_FULFILLED:
      draft.AllCalendarData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      // break;
      draft.calendarsData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: Object.assign(
          {},
          { calendar_data: action.data.items, calendar_management_timeline: [] }
        ),
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_REJECTED:
      draft.AllCalendarData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      // break;
      draft.detailedCalendarData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_PENDING:
      draft.calendarFilters = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_FULFILLED:
      draft.calendarFilters = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_REJECTED:
      draft.AllCalendarData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.CLEAR_ALL_CALENDARS:
      draft.calendarsData = {
        pending: false,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_FULFILLED:
      draft.calendarComparisonData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_CALENDAR_PENDING:
      draft.detailedCalendarData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_CALENDAR_FULFILLED:
      draft.detailedCalendarData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_CALENDAR_REJECTED:
      draft.calendarsData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: { calendar_data: [], calendar_management_timeline: [] },
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_FULFILLED:
      draft.calendarAggregationData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_PENDING:
      draft.addNewCalendarData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_FULFILLED:
      if (draft.calendarsData.data && draft.calendarsData.data.calendar_data)
        draft.calendarsData.data.calendar_data = [
          ...draft.calendarsData.data.calendar_data,
          action.data,
        ];
      else {
        draft.calendarsData.data!.calendar_data = [action.data];
      }
      draft.addNewCalendarData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_REJECTED:
      draft.addNewCalendarData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.UPDATE_NEW_CALENDAR:
      if (draft.calendarsData.data)
        draft.calendarsData.data.calendar_data =
          draft.calendarsData.data.calendar_data.map((calendar) => {
            if (calendar.id === action.id) {
              return {
                ...calendar,
                status: CALENDAR_STATUS.PROCESSED,
              };
            }
            return calendar;
          });
      break;
    case CalendarOptimizationActionTypes.DELETE_CALENDAR_PENDING:
      draft.calendarsData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: { calendar_data: [], calendar_management_timeline: [] },
      };
      break;
    case CalendarOptimizationActionTypes.DELETE_CALENDAR_FULFILLED:
      draft.calendarsData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.DELETE_CALENDAR_REJECTED:
      draft.calendarsData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.UPLOAD_CALENDAR:
      draft.uploadCalendar = {
        pending: false,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.UPLOAD_CALENDAR_PENDING:
      draft.uploadCalendar = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.UPLOAD_CALENDAR_FULFILLED:
      draft.uploadCalendar = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.UPLOAD_CALENDAR_REJECTED:
      draft.uploadCalendar = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED:
      draft.allRefCalendarsData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_REJECTED:
      draft.selectedRefCalData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_PENDING:
      draft.selectedRefCalData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_FULFILLED:
      draft.selectedRefCalData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_FULFILLED:
      draft.selectedRefCalData = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_REJECTED:
      draft.selectedRefCalData = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_CLEAR:
      draft.selectedRefCalData = {
        pending: false,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_PENDING:
      draft.selectedRefCalData = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      break;
    case CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_FULFILLED:
      draft.createCalendarRequest = {
        pending: false,
        fulfilled: true,
        rejected: false,
      };
      break;
    case CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_PENDING:
      draft.createCalendarRequest = {
        pending: true,
        fulfilled: false,
        rejected: false,
      };
      break;
    case CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_REJECTED:
      draft.createCalendarRequest = {
        pending: false,
        fulfilled: false,
        rejected: true,
      };
      break;
    case CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_RESET:
      draft.createCalendarRequest = {
        pending: false,
        fulfilled: false,
        rejected: false,
      };
      break;
  }
}, initialState);

type StateDraft = Draft<CalendarOptimizationState>;
