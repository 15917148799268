import {
  addDays,
  eachHourOfInterval,
  format,
  getDay,
  getHours,
  parseISO,
  setHours,
} from "date-fns";
import { eachDayOfInterval, eachWeekOfInterval } from "date-fns/esm";
import { enUS } from "date-fns/locale";
import { useEffect, useState } from "react";

function Timeline({ daily, timelineRange }) {
  const startTime = new Date().toISOString();

  const [timelineHours, setTimeline] = useState<any>(
    eachWeekOfInterval({
      start: setHours(parseISO(startTime), 0),
      end: addDays(new Date(), 365 * 2),
    })
  );

  useEffect(() => {
    if (timelineRange) {
      setTimeline(
        eachWeekOfInterval({
          start: addDays(timelineRange.start, 0),
          end: addDays(timelineRange.end, 30),
        })
      );
    }
  }, [timelineRange]);

  return (
    <div className="flex bg-gray-50 min-w-fit gap-x-[51px] px-[2.063rem] h-2">
      {timelineHours.map((date, index) => {
        return (
          <div
            className="relative h-2 w-[1px] bg-stone-500"
            key={date.toString()}
          >
            {date.getMonth() !==
              timelineHours[Math.max(index - 1, 0)].getMonth() && (
              <span className="text-xs font-semibold text-black-100 absolute -top-5 -left-4 w-16">
                {format(date, "MMM yy", {
                  locale: enUS,
                })
                  .split(" ")
                  .map((word, index) =>
                    index === 0
                      ? word.charAt(0).toLocaleUpperCase() + word.slice(1)
                      : word
                  )
                  .join(" ")}
              </span>
            )}
            {index % 2 === 0 && (
              <time
                dateTime={date.toISOString()}
                className="text-xs absolute top-3 -left-[5.5px] text-black-100"
              >
                {format(date, "dd")}
              </time>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Timeline;
