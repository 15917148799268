import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useStyle } from "./promoCardStyle";
import theme from "utils/theme";
const PromoCard = ({ title, sub, icon, buttons, notifications, link }: any) => {
  const classes = useStyle();
  const navigate = useNavigate();

  function nav(link) {
    if (!link.external && link.params != null && link.params != undefined) {
      if (!link.external)
        navigate(link.url, {
          state: {
            tabNumber: link.params,
          },
        });
    } else if (!link.external) navigate(link.url);
    else window.location.href = link.url;
  }
  const commonStyles = {
    bgcolor: "background.paper",
    marginTop: 1.5,
    border: 1,
    minWidth: "auto",
    minHeight: "2rem",
    alignItems: "baseline",
    display: "flex",
    borderTop: "1px solid",
    borderTopColor: "secondary.light",
    borderRight: "1px solid",
    borderRightColor: "secondary.light",
    borderBottom: "1px solid",
    borderBottomColor: "secondary.light",
    borderLeft: "5px solid green",
    borderRadius: "5px",
  };

  return (
    //className="landingPricingCard"
    <Card className={classes.root} sx={{ padding: 0, minHeight: 310 }}>
      <CardContent sx={{ height: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          height="100%"
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            height="auto"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                flexWrap: "nowrap",
                padding: "16px 0px 0px 0px",
                margin: "0px auto",
                minHeight: "100px",
                minWidth: "100px",
              }}
            >
              {icon}
            </Grid>
            <Typography
              sx={{
                fontSize: "22px",
                lineHeight: "27px",
                fontWeight: "600",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "400",
                margin: "10px 30px 20px",
              }}
            >
              {sub}
            </Typography>
            <Grid
              sx={{
                minWidth: "100%",
                paddingLeft: "12px",
                paddingRight: "12px",
                maxHeight: "340px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              {notifications.map((notif, index) => (
                <Box sx={{ ...commonStyles, minWidth: "100%" }}>
                  {notif.hasOwnProperty("icon") && (
                    <notif.icon
                      style={{
                        fill: "secondary.main",
                        height: "60",
                        width: "60",
                        padding: "16px",
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "16px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {notif.content}
                    {notif.hasOwnProperty("value") && (
                      <span style={{ color: "green" }}>
                        {" "}
                        {notif.value}
                        {notif.unit}
                      </span>
                    )}{" "}
                    {notif.afterValue}
                  </Typography>
                  {notif.hasOwnProperty("link") && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "800",
                        lineHeight: "16px",
                        textDecoration: "underline",
                        justifyContent: "flex-end",
                        marginLeft: "auto",
                        marginRight: "10px",
                        color: "primary.main",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                    >
                      {notif.link}
                    </Typography>
                  )}
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid width={"100%"}>
            <Grid
              mt={2}
              sx={{
                marginRight: "8px",
                padding: "0",
                minWidth: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignContent={"flex-end"}
                sx={{ minWidth: "100%" }}
              >
                {buttons.map((btn, index) => (
                  <Button
                    key={index}
                    onClick={() => nav(btn.link)}
                    variant="outlined"
                    startIcon={<btn.icon></btn.icon>}
                    sx={{
                      margin: "5px 8px 0px 0px",
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.secondary.contrastText,
                      borderRadius: "4px",
                      whiteSpace: "nowrap",
                      border: "1px solid",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
                      borderColor: theme.palette.primary.light,
                      height: "34px",
                      width: "42%",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                        opacity: "0.8",
                      },
                    }}
                  >
                    <Typography
                      color="secondary.contrastText"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19px",
                        maxHeight: "32px",
                        alignContent: "center",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                    >
                      {btn.name}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PromoCard;
