import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import * as s from "../ContraintsStyle";
import { styled } from "@mui/system";
import theme from "../../../../../../utils/theme";
import { useEffect, useState } from "react";
import { SlotDatePicker } from "../../../../../components/slotDatePicker/SlotDatePicker";
import { nanoid } from "@reduxjs/toolkit";
import { useAppSelector } from "utils/hooks";
import { Controller } from "react-hook-form";

const Header = styled("p")({
  fontSize: ".8rem",
});

export function Scope({
  errors,
  register,
  setValue,
  getValues,
  control,
  defaultSlots,
  watch,
  resetField,
}: any) {
  const [listOfDates, setListOfDates] = useState(defaultSlots);
  const [allowpriceEstablishment, setAllowpriceEstablishment] = useState(false);

  useEffect(() => {
    setAllowpriceEstablishment(false);
    setValue("priceEstablishment", false);
    setValue("promo_min_per_slot", 0);
    setValue("promo_max_per_slot", 10);
    setListOfDates(defaultSlots);
  }, [
    watch("quarter"),
    watch("category"),
    watch("customer"),
    watch("name"),
    watch("referenceCalendar"),
  ]);

  const { constraintsCommons } = useAppSelector((state) => {
    return {
      constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
    };
  });

  useEffect(() => {
    if (constraintsCommons.length >= 1) {
      // Checks if constraint exists inside the array
      if (
        constraintsCommons.findIndex(
          (x) => x.name === "activate_price_establishment"
        ) !== -1
      ) {
        const ref =
          constraintsCommons[
            constraintsCommons.findIndex(
              (x) => x.name === "activate_price_establishment"
            )
          ];
        if (ref) {
          setAllowpriceEstablishment(!!ref.BINARY);
        }
      }

      if (
        constraintsCommons.findIndex((x) => x.name === "promo_per_slot") !== -1
      ) {
        const ref =
          constraintsCommons[
            constraintsCommons.findIndex((x) => x.name === "promo_per_slot")
          ];
        if (ref) {
          setValue("promo_max_per_slot", ref.MAX);
          setValue("promo_min_per_slot", ref.MIN);
        }
      }
    }
  }, [constraintsCommons]);

  async function addSlot() {
    const new_slot = {
      values: [new Date(), new Date()],
      slotName: `Slot ${listOfDates.length}`,
    };
    setListOfDates([...listOfDates, new_slot]);
  }

  function removeSlot(index) {
    const copyArr = [...listOfDates];
    copyArr.splice(index, 1);

    setListOfDates(copyArr);

    for (let i = 0; i < copyArr.length; i++) {
      setValue(`scopeConstraints.[${i}].date_start`, listOfDates[i].values[0]);
      setValue(`scopeConstraints.[${i}].date_end`, listOfDates[i].values[1]);
      setValue(`scopeConstraints.[${i}].slot_name`, listOfDates[i].slotName);
    }
  }

  return (
    <Grid container direction="column" gap={2}>
      <s.Row>
        {s.SwitchWithLabelWithState(
          "Activate price establishment",
          register("priceEstablishment", {
            onChange: (event) =>
              setAllowpriceEstablishment(!allowpriceEstablishment),
          }),
          allowpriceEstablishment,
          "small"
        )}
      </s.Row>

      <s.Row display={"flex"} gap={2}>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Header>Period</Header>
            <Header sx={{ marginLeft: "86px" }}>Slot name</Header>
            <Header>Delete Slot</Header>
          </Grid>
          {listOfDates.map((slot, index) => {
            const fieldName = `scopeConstraints[${index}]`;

            return (
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                gap={1}
                key={index}
              >
                <SlotDatePicker
                  key={index}
                  defaultValues={slot.values}
                  getValues={(dates: any) => {
                    setValue(`${fieldName}.date_start`, dates[0]);
                    setValue(`${fieldName}.date_end`, dates[1]);
                  }}
                />
                <TextField
                  defaultValue={slot.slotName}
                  key={fieldName}
                  id="outlined-basic"
                  label={`Slot ${index + 1}`}
                  variant="outlined"
                  size="small"
                  {...register(`${fieldName}.slot_name`)}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => removeSlot(index)}
                  sx={{ margin: ".5rem 0" }}
                >
                  X
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </s.Row>
      <p
        style={{
          cursor: "pointer",
          fontSize: "0.9rem",
          fontWeight: 600,
          color: theme["palette"].primary.main,
        }}
        onClick={addSlot}
      >
        + Add another
      </p>

      <s.Row>
        <Typography variant="h4" my={1}>
          Number of promos per slot
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }} gap={2}>
          <FormControl
            sx={{
              maxWidth: "fit-content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
            size="small"
          >
            <s.Label>Min</s.Label>
            <TextField
              id="outlined-basic"
              defaultValue={0}
              name="promo_min_per_slot"
              variant="outlined"
              size="small"
              sx={{ width: "5rem" }}
              {...register("promo_min_per_slot")}
            />
          </FormControl>
          <FormControl
            sx={{
              maxWidth: "fit-content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
            size="small"
          >
            <s.Label>Max</s.Label>
            <TextField
              id="outlined-basic"
              defaultValue={10}
              name="promo_max_per_slot"
              variant="outlined"
              size="small"
              sx={{ width: "5rem" }}
              {...register("promo_max_per_slot")}
            />
          </FormControl>
        </Box>
      </s.Row>
    </Grid>
  );
}
