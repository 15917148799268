import * as s from "../ContraintsStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConstraintsCardRange } from "../../../../../components/constraintsCardRange/ConstraintsCardRange";
import { ConstraintsCardSlider } from "../../../../../components/constraintsCardSlider/ConstraintsCardSlider";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../../../utils/mapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConstraintsCardSelect } from "../../../../../components/constraintsCardSelect/ConstraintsCardSelect";
import { useTheme } from "@mui/styles";
import { CalendarCreationFormActionTypes } from "../../../../../utils/redux/calendarCreationForm/calendarCreationFormActions";
import { promomixProductsConstraints } from "../../../../../utils/constants";
import { ConstraintsCardSelectMulti } from "Promo/components/constraintsCardSelect/ConstraintCardSelectMulti";

type usedStaticConstraintsType = {
  id: Number;
  name: string;
};

type usedBrandsConstraintsType = {
  id: Number;
  brand_id: Number;
  name: string;
};

type usedProductsConstraintsType = {
  id: Number;
  brand_id: Number;
  product_id: Number;
  name: string;
};
const emptyHint = [{ value: 50, label: "" }];

const CONSTRAINT_MAPPPING = {
  "Promote Together": "promote_together",
  "Never Promote Together": "never_promote_together",
};

const typeOptions = [
  { key: 0, name: "Promote Together" },
  { key: 1, name: "Never Promote Together" },
];

export function Promotions({
  errors,
  register,
  setValue,
  watch,
  brands,
  reset,
  onResetComplete,
}: any) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [products, setProducts] = useState<any[]>([]);
  const [productLevelList, setProductLevelList] = useState<any[]>([]);
  const [usedProductsConstraints, setUsedProductsConstraints] = useState<
    usedProductsConstraintsType[]
  >([]);
  const [productAccordian, setProductAccordian] = useState(true);
  const [presetCommonConstraint, setPresetCommonConstraint] = useState<any[]>(
    []
  );

  const { constraintsProducts } = useAppSelector((state) => {
    return {
      constraintsProducts: state.reducer.ConstraintsManagementReducer.products,
    };
  });

  const resetConstraints = () => {
    setProductLevelList([]);
    setUsedProductsConstraints([]);
  };

  useEffect(() => {
    if (reset) {
      resetConstraints();
      onResetComplete();
    }
  }, [reset]);

  useEffect(() => {
    resetConstraints();
  }, [
    watch("quarter"),
    watch("category"),
    watch("customer"),
    watch("referenceCalendar"),
  ]);

  /**
   * @desc Builds the product list from the passed in brandfs object. Will trigger
   */
  useEffect(() => {
    let products: any = [];
    brands.map((brand) => {
      products = products.concat(brand.products);
    });
    products = products.map((product) => {
      return {
        name: product.product_code,
        key: product.product_code,
      };
    });
    setProducts(products);
  }, [brands]);

  useEffect(() => {
    if (constraintsProducts) {
      const productLevelCnstr = constraintsProducts
        .filter(
          (x) =>
            x.name.includes("always_promote_together") ||
            x.name.includes("never_promote_together")
        )
        .filter((x) => x.EQUAL !== undefined);

      setProductLevelList([]);
      setUsedProductsConstraints([]);
      const presetConstraints: any = [];
      if (productLevelCnstr.length >= 1) {
        const tempProdLevelCnstr = [...productLevelCnstr];
        const groupedCnstr: any = [];
        const prodToPreset: any = [];
        productLevelCnstr.forEach((constraint, index) => {
          const productSanitized = constraint.internal_code
            .split("  ")
            .join(" - ");
          const target = groupedCnstr.findIndex(
            (prod) =>
              prod.internal_code === productSanitized &&
              prod.name === constraint.name
          );
          if (target !== -1) {
            groupedCnstr[target].EQUAL.push(constraint.EQUAL);
          } else {
            groupedCnstr.push({
              ...tempProdLevelCnstr[index],
              internal_code: productSanitized,
              type:
                constraint.name === "always_promote_together"
                  ? typeOptions[0].name
                  : typeOptions[1].name,
              EQUAL: [constraint.EQUAL],
            });
          }
        });
        groupedCnstr.forEach((x, index) => {
          if (x.EQUAL.length) {
            prodToPreset.push(x);
            addConstraint("product", x);
          }
        });
        presetConstraints.push(...prodToPreset);
      }
      setPresetCommonConstraint(presetConstraints);
      generateFormBody();
    }
  }, [constraintsProducts]);

  /**
   * @desc Updates the Promotions constraint form data when called
   */
  function generateFormBody() {
    dispatch({
      type: CalendarCreationFormActionTypes.CLEAR_PROMOTION_CONSTRAINTS,
    });

    const requestBody: {
      products: any[];
    } = {
      products: [],
    };

    if (productLevelList.length > 0) {
      productLevelList.forEach((product) => {
        product.constraint.forEach((name, index) => {
          requestBody.products.push({
            name: `products.product-${product.productId}.${
              CONSTRAINT_MAPPPING[`${name}`]
            }`,
            value: product.value,
            percentage: 0,
          });
        });
      });
    }

    dispatch({
      type: CalendarCreationFormActionTypes.SEND_PROMOTION_CONSTRAINTS,
      payload: requestBody,
    });

    return requestBody;
  }

  /**
   * @desc Adds a new constraint to the tabs constraint form
   */
  function addConstraint(type, selectedConstraint?) {
    const id = Math.floor(Math.random() * 10032142);
    setPresetCommonConstraint([]);
    if (type === "product") {
      if (selectedConstraint) {
        setProductLevelList((state) => [
          ...state,
          {
            id: id,
            value: [0],
            constraint: [selectedConstraint.name],
            presetKey: selectedConstraint.name,
            brandId: selectedConstraint.EQUAL,
            productId: selectedConstraint.internal_code,
            type: selectedConstraint.type,
          },
        ]);
      } else {
        setProductLevelList((state) => [
          ...state,
          {
            id: id,
            value: [0],
            constraint: [],
            brandId: 0,
            productId: 0,
            type: "",
          },
        ]);
      }
    }
  }

  /**
   *
   * @pdesc deletes a constraint from the pages constraint form
   */
  function deleteFromList(type, object) {
    if (type === "product") {
      productLevelList.splice(
        productLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedProductsConstraints.splice(
        usedProductsConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setUsedProductsConstraints([...usedProductsConstraints]);
      setProductLevelList([...productLevelList]);
    }
    generateFormBody();
  }

  return (
    <Grid container direction="column" gap={2}>
      <Accordion
        expanded={productAccordian}
        onChange={(event) => setProductAccordian(!productAccordian)}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Product level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {productLevelList &&
            productLevelList.map((constraint) => (
              <ConstraintsCardSelectMulti
                key={constraint.id}
                select1Title={"Product"}
                select1Options={products}
                select2Title={"Type"}
                presetValue={
                  presetCommonConstraint[
                    presetCommonConstraint.findIndex(
                      (x) =>
                        x.name === constraint.presetKey &&
                        x.level === "PRODUCT" &&
                        x.internal_code === constraint.productId
                    )
                  ] || null
                }
                select2Options={typeOptions}
                select3Title={"Product"}
                select3Options={products}
                objectToSave={constraint}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("product", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.light,
            }}
            onClick={() => addConstraint("product")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
