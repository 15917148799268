import {
  Checkbox,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Chip,
  Menu,
  MenuItem,
  Button,
  Modal,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseButton, useStyles } from "./CalendarCardStyle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Calendar, CalendarCardType } from "../../utils/types";
import {
  convertToExcel,
  downloadExcel,
  formatNumber,
} from "../../utils/mapper";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { AlertDialog } from "../../components/alertDialog/alertDialog";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import theme from "utils/theme";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { fetchCalendarById } from "Promo/utils/redux/calendarViewEdit/calendarViewEditAction";
import ConstraintsTable from "../constraintsTable/constraintsTable";

const constraintTableStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "69vh",
  //bgcolor: "background.paper",
  borderRadius: "0.313rem",
  boxShadow: 24,
  typography: "body2",
  backgroundColor: "white",
};
interface CalendarCardProps {
  calendarCardDetails: CalendarCardType | null;
  handleCardClick: any;
  deleteCalendar: Function;
  checkedCard: Function;
  isChecked: boolean;
}

const CalendarCard = (props: CalendarCardProps) => {
  const {
    calendarCardDetails,
    handleCardClick,
    deleteCalendar,
    checkedCard,
    isChecked,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const calendar = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.calendar
  );
  const [open, setOpen] = useState(false);
  const [isHovering, setHovering] = React.useState(false);
  const [isComplete, setIsComplet] = useState(true);
  const [calendarTitle, setCalendarTitle] = useState("Calendar");
  const [openCompletCalendarAlert, setOpenCompletCalendarAlert] =
    useState(false);
  const [openStackTrace, setOpenStackTrace] = useState<boolean>(false);
  const [downloadOnce, setDownloadOnce] = useState<boolean>(false);
  const [downloadType, setDownloadType] = useState<string>("");
  const [constraintDialogOpen, setConstraintDialogOpen] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (calendarCardDetails != null) {
      setIsComplet(calendarCardDetails.system_status === "COMPLETE");
    }
  }, []);

  useEffect(() => {
    if (
      downloadOnce &&
      downloadType.length > 0 &&
      calendar !== null &&
      Object.keys(calendar).length > 0
    ) {
      downloadExcel(
        convertToExcel(
          downloadType === "Product Financials"
            ? calendar.dailies
            : calendar.events
        ),
        calendar.name + `_${downloadType}`
      );
      setDownloadOnce(false);
      setDownloadType("");
    }
  }, [calendar, downloadOnce, downloadType]);

  const handleStackTraceToggle = () => {
    setOpenStackTrace(!openStackTrace);
  };

  const handleCheck = (e, val: any) => {
    checkedCard(val.id);
  };
  const handleDialog = () => {
    setOpen(!open);
  };
  function handleDeletion(calendarId: string) {
    setOpen(false);
    deleteCalendar(calendarId);
  }

  const handleExportClick = (id: string) => {
    if (id) {
      if (isComplete) {
        axios({
          url: `${process.env.REACT_APP_PROMO_API_URL}/calendar/export/${id}`,
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          const contentDisposition = response.headers["content-disposition"];
          const filename = `calendar-${id}.xlsx`; //contentDisposition.match(/filename=(?<filename>[^,;]+);/)[0];
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
      } else {
        if (calendarCardDetails != null)
          setCalendarTitle(calendarCardDetails.title);
        setOpenCompletCalendarAlert(true);
      }
    }
  };

  const handleEditCalendar = () => {
    if (isComplete) handleCardClick();
    else {
      if (calendarCardDetails != null)
        setCalendarTitle(calendarCardDetails.title);
      setOpenCompletCalendarAlert(true);
    }
  };

  const handleClose = () => {
    setOpenCompletCalendarAlert(false);
  };

  const openConstraintDialog = () => {
    setConstraintDialogOpen(true);
  };

  const closeConstraintDialog = () => {
    setConstraintDialogOpen(false);
  };

  const hoverComponent = (calendarId: string) => {
    return (
      <Grid
        item
        container
        justifyContent={"center"}
        columnSpacing={2}
        py={2}
        mobile={2}
      >
        <Grid item style={{ cursor: "pointer" }} onClick={handleDialog}>
          <Tooltip title="Delete">
            <DeleteOutlineOutlinedIcon />
          </Tooltip>
        </Grid>
        <Grid item style={{ cursor: "pointer" }} onClick={openConstraintDialog}>
          <Tooltip title="Constraints">
            <ChecklistIcon />
          </Tooltip>
        </Grid>
        <Grid item style={{ cursor: "pointer" }}>
          <Tooltip title="Export Excel">
            <Button
              onClick={handleMenuClick}
              size="small"
              sx={{ minWidth: 30 }}
              disabled={calendarCardDetails?.system_status !== "COMPLETE"}
            >
              <FileDownloadIcon />
            </Button>
          </Tooltip>
          <Menu
            id="export-menu"
            aria-labelledby="export-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={(e) => {
                dispatch(fetchCalendarById(calendarId));
                setDownloadOnce(true);
                setDownloadType("Promo events Financials");
                handleMenuClose();
              }}
            >
              <FileDownloadIcon
                style={{
                  height: "21px",
                  width: "21px",
                  paddingRight: "5px",
                }}
              />
              <Typography variant="body3">Promo events Financials</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                dispatch(fetchCalendarById(calendarId));
                setDownloadOnce(true);
                setDownloadType("Product Financials");
                handleMenuClose();
              }}
            >
              <FileDownloadIcon
                style={{
                  height: "21px",
                  width: "21px",
                  paddingRight: "5px",
                }}
              />
              <Typography variant="body3">Product Financials</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleExportClick(calendarId);
                handleMenuClose();
              }}
            >
              <FileDownloadIcon
                style={{
                  height: "21px",
                  width: "21px",
                  paddingRight: "5px",
                }}
              />
              <Typography variant="body3">Calendar Details</Typography>
            </MenuItem>
          </Menu>
        </Grid>

        {calendarCardDetails?.status_message !== null &&
        calendarCardDetails?.system_status === "ERROR" ? (
          <Grid
            item
            style={{ cursor: "pointer" }}
            onClick={handleStackTraceToggle}
          >
            <Tooltip title="Error Log">
              <ReportProblemOutlinedIcon />
            </Tooltip>
          </Grid>
        ) : (
          <Grid item style={{ cursor: "pointer" }} onClick={handleEditCalendar}>
            <Tooltip title="Edit">
              <ModeEditOutlineOutlinedIcon />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case "APPROVED":
        return <div className={classes.statusApproved}>{status}</div>;
      case "FORVALIDATION":
        return <div className={classes.statusForValidation}>{status}</div>;
      case "DRAFT":
        return <div className={classes.statusDraft}>{status}</div>;
      default:
        return <div className={classes.statusForValidation}>{status}</div>;
    }
  };

  if (calendarCardDetails != null) {
    return (
      <Grid
        display={"flex"}
        direction="row"
        sx={{
          minHeight: 79,
        }}
      >
        <Grid
          width={20}
          // height={"100%"}
          sx={{
            backgroundColor:
              calendarCardDetails.system_status === "ERROR"
                ? theme.palette.error.main // red
                : calendarCardDetails.system_status === "PENDING"
                ? theme.palette.warning.main // orange
                : theme.palette.primary.background_2,
          }}
          borderRadius={"8px 0 0 8px"}
          my={0}
        ></Grid>
        <Grid
          container
          alignContent="flex-start"
          justifyContent={"space-between"}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          sx={{
            backgroundColor: theme.palette.primary.background_2,
            borderRadius: "0 8px 8px 0",
          }}
        >
          <Grid
            item
            container
            mobile={6.5}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item laptop={1} laptop_lg={1} mr={1}>
              <div className={classes.checkBoxComponent}>
                <Checkbox
                  size={"small"}
                  checked={isChecked}
                  onChange={(e) => handleCheck(e, calendarCardDetails)}
                />
              </div>
            </Grid>

            <Grid
              item
              laptop={calendarCardDetails.system_status === "ERROR" ? 5.5 : 6.5}
              laptop_lg={
                calendarCardDetails.system_status === "ERROR" ? 7.5 : 8.5
              }
              style={{ cursor: "pointer" }}
              pb={1}
              onClick={handleEditCalendar}
            >
              <div className={classes.componentContainer}>
                <Tooltip
                  title={calendarCardDetails?.title}
                  placement="top-start"
                >
                  <div
                    className={classes.topText}
                    style={{ fontWeight: "bold" }}
                  >
                    <Typography fontWeight={600}>
                      {calendarCardDetails?.title.slice(0, 100)}
                      {calendarCardDetails?.title.length >= 100 ? "..." : ""}
                    </Typography>
                  </div>
                </Tooltip>
                <div className={classes.bottomText}>
                  {`${calendarCardDetails.semester} ` +
                    `${calendarCardDetails.customer} `}
                </div>
              </div>
            </Grid>
            {calendarCardDetails.system_status === "ERROR" && (
              <Grid item mobile py={1.5}>
                <Chip
                  label={
                    calendarCardDetails.status_message &&
                    calendarCardDetails.status_message
                      .toLocaleLowerCase()
                      .includes("optimization is infeasible")
                      ? "Infeasible constraints"
                      : "Application error"
                  }
                  variant="outlined"
                  color="error"
                  size="small"
                />
              </Grid>
            )}
            {/*<Grid item laptop={1} laptop_lg={1} mobile={1} tablet={1}></Grid>*/}
            {/*<Grid item laptop={3} laptop_lg={2} py={1}>*/}
            {/*  <div className={classes.statusComponentContainer}>*/}
            {/*    <div className={classes.topText}>Status</div>*/}
            {/*    <div className={classes.bottomText}>{renderStatus(calendarCardDetails.status)}</div>*/}
            {/*  </div>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid item py={1.5}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={classes.rightLine}></div>
              </div>

              <div className={classes.componentContainer}>
                <div className={classes.topText}>Created on</div>
                <div className={classes.bottomText}>
                  {`${new Date(
                    calendarCardDetails?.created_on
                  ).toLocaleTimeString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </div>
                {calendarCardDetails.created_by && (
                  <Grid maxWidth={110} className={classes.bottomText}>
                    By {`${calendarCardDetails.created_by}`}
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
          {/* <Grid item py={1.5}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={classes.rightLine}></div>
              </div>
              <div className={classes.componentContainer}>
                <div className={classes.topText}>Volume on deal</div>
                <div className={classes.bottomText}>{`${formatNumber(
                  calendarCardDetails.volume_on_deal
                )}`}</div>
              </div>
            </div>
          </Grid>
          <Grid item py={1.5}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={classes.rightLine}></div>
              </div>
              <div className={classes.componentContainer}>
                <div className={classes.topText}>Overall Promo depth</div>
                <div className={classes.bottomText}>{`${formatNumber(
                  calendarCardDetails.overall_promo_depth
                )}`}</div>
              </div>
            </div>
          </Grid>
          <Grid item py={1.5}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={classes.rightLine}></div>
              </div>
              <div className={classes.componentContainer}>
                <div className={classes.topText}>ROI</div>
                <div className={classes.bottomText}>{`${formatNumber(
                  calendarCardDetails.roi
                )}`}</div>
              </div>
            </div>
          </Grid> */}
          {hoverComponent(calendarCardDetails.id)}
          <Modal
            //  hideBackdrop={true}
            open={constraintDialogOpen}
            onClose={closeConstraintDialog}
            disableEnforceFocus={true}
          >
            <Box sx={constraintTableStyle}>
              <Grid mt={2}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                  }}
                >
                  <div
                    className="csParamCloseIcon"
                    onClick={closeConstraintDialog}
                  >
                    <CloseIcon />
                  </div>
                </Box>
                {/* <Constraints
                constraintsData={data}
                scopeConstraints={scope_constraints}
              /> */}
                <Grid
                  container
                  alignContent={"flex-start"}
                  height={680}
                  sx={{ overflowY: "auto" }}
                >
                  <ConstraintsTable
                    constraintSet={calendarCardDetails?.constraint_set}
                    calendarName={calendarCardDetails?.title}
                    title="Optimization parameters"
                    maximizationGoals={calendarCardDetails?.maximization_goals}
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Snackbar
            open={openCompletCalendarAlert}
            onClose={handleClose}
            autoHideDuration={5000}
          >
            {calendarCardDetails.system_status === "ERROR" ? (
              <Alert severity="error">{`Calendar errored during execution please contact support`}</Alert>
            ) : (
              <Alert severity="warning">{`Calendar is not yet ready! Optimization runtime is 1-2 hours`}</Alert>
            )}
          </Snackbar>
          <Dialog
            open={openStackTrace}
            onClose={handleStackTraceToggle}
            maxWidth={"tablet"}
            scroll="paper"
          >
            <DialogTitle>ERROR!</DialogTitle>
            <CloseButton
              aria-label="close"
              onClick={handleStackTraceToggle}
              size="small"
            >
              <CloseIcon />
            </CloseButton>
            <DialogContent sx={{ maxHeight: 500 }}>
              <DialogContentText>
                {calendarCardDetails.status_message !== null ? (
                  <Alert severity="error">
                    {calendarCardDetails.status_message}
                  </Alert>
                ) : (
                  <Alert severity="error">{`Calendar errored during execution please contact support`}</Alert>
                )}
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <AlertDialog
            content={"Are you sure you want to delete this calendar?"}
            status={open}
            handleClickClose={handleDialog}
            handleResponse={() => handleDeletion(calendarCardDetails.id)}
          />
        </Grid>
      </Grid>
    );
  } else return <div>Loading...</div>;
};

export default CalendarCard;
