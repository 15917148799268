import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarCreationModel } from "Promo/utils/interfaces";
import { Constraints } from "./constraints/Constraints";
import theme from "utils/theme";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ConstraintsManagementActionTypes,
  fetchConstraintSets,
} from "Promo/utils/redux/constraintsManagement/constraintsManagementAction";
import { convertConstraintsData, mapToRequestBody } from "Promo/utils/mapper";
import { useEffectOnce } from "utils/hooks/useEffectOnce";
import { CalendarCreationActionTypes } from "Promo/utils/redux/calendarCreation/calendarCreationActions";

const FormSchema = yup.object().shape({
  name: yup.string().required("Type a name"),
  // referenceCalendar: yup.string().required("Select a reference calendar"),
  allowMultibuy: yup.boolean(),
  allowUPR: yup.boolean(),
  allowPPR: yup.boolean(),
  allowNewPrice: yup.boolean(),
  multibuy: yup.number().default(0),
  upr: yup.number().default(0),
  ppr: yup.number().default(0),
  newPrice: yup.number().default(0),
  promo_min_per_slot: yup.number(),
  promo_max_per_slot: yup.number(),
  priceEstablishment: yup.boolean().default(false),
});

export function EditConstraintsManagement() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [data, setData] = useState<any>({});

  const calendarFormState = useAppSelector(
    (state) => state.reducer.CalendarCreationFormReducer
  );

  const { constraintSet, constraintName } = useAppSelector((state) => {
    const data = state.reducer.ConstraintsManagementReducer.data;
    if (!Array.isArray(data) || data.length <= 0) {
      return {
        constraintSet: null,
        constraintName: [],
      };
    }
    return {
      constraintSet: data[data.findIndex((x) => x.id === id)],
      constraintName: data[data.findIndex((x) => x.id === id)].name,
    };
  });

  const form = useForm<CalendarCreationModel>({
    mode: "onSubmit",
    resolver: yupResolver(FormSchema),
  });

  const {
    formState,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    resetField,
  } = form;
  const { errors } = formState;

  useEffect(() => {
    dispatch({
      type: ConstraintsManagementActionTypes.CONSTRAINTS_WIPE,
    });

    if (!constraintSet || constraintSet.calendar_constraints.length <= 0) {
      return;
    }

    const { constraintsCommons, constraintsBrands, constraintsProducts } =
      convertConstraintsData(constraintSet.calendar_constraints);

    setData({ constraintsCommons, constraintsBrands, constraintsProducts });

    dispatch({
      type: ConstraintsManagementActionTypes.CONSTRAINTS_UPDATE,
      data: { constraintsCommons, constraintsBrands, constraintsProducts },
    });
  }, [constraintSet]);

  useEffect(() => {
    if (constraintName) {
      setValue("name", constraintName);
    }
  }, [constraintName]);

  function handleGoBackClick() {
    navigate("/promo/recommendation/constraints-sets");
  }

  async function handleFormSubmit(formData: CalendarCreationModel) {
    const constraintsBody = mapToRequestBody(calendarFormState, formData, []);

    const merge = { ...data, ...constraintsBody };
  }

  useEffectOnce(() => {
    dispatch({
      type: CalendarCreationActionTypes.SET_CONSTRAINTS_AS_INVALID,
    });

    if (!constraintSet) {
      dispatch(fetchConstraintSets());
    }
  });

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100%",
        overflowX: "hidden",
        paddingBottom: "40px",
      }}
    >
      {constraintSet && constraintSet.calendar_constraints.length >= 0 && (
        <form id="calendar_form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid>
            <Typography variant="h2" sx={{ padding: 0, margin: 0 }}>
              <Button
                variant="text"
                onClick={handleGoBackClick}
                sx={{
                  marginTop: "-.2rem",
                  maxWidth: "2.2rem",
                  minWidth: "1px !important",
                }}
              >
                <ArrowBackIosIcon sx={{ height: "2rem", margin: "auto 0" }} />
              </Button>{" "}
              Edit "{constraintName}"
            </Typography>
          </Grid>
          <Grid container gap={"2rem"} mt={3}>
            <Grid>
              <Typography variant="body3" display={"block"}>
                Set name
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                sx={{ width: "250px" }}
                {...register("name")}
              />
            </Grid>
          </Grid>
          <Grid mt={2}>
            <Constraints
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              constraintsData={data}
              resetField={resetField}
            />
          </Grid>
        </form>
      )}

      <Grid
        sx={{
          width: "100%",
          height: "40px",
          position: "absolute",
          bottom: 0,
          left: 0,
          padding: "2rem 1rem",
          "& button": { padding: ".5rem" },
        }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap={"1.5rem"}
      >
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.primary.text,
            borderColor: theme.palette.primary.text,
            "&:hover": {
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.text,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="calendar_form"
          variant="outlined"
          sx={{
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
            "&:hover": {
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.main,
            },
          }}
        >
          Continue & Save
        </Button>
      </Grid>
    </Grid>
  );
}
