import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export function AlertDialog({
  content,
  status,
  handleResponse,
  handleClickClose,
}: any) {
  return (
    <Dialog
      open={status}
      onClose={handleClickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm your action"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClickClose()}>No</Button>
        <Button onClick={() => handleResponse(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
