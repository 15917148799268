import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../../utils/theme";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";
import { applyReferenceFormula, formatNumber } from "../../utils/mapper";
import { StyledSlider } from "../inputSlider";

type PropsType = {
  constraint: any;
  marks: any[];
  formatText?: (value) => string;
  getValue: (value: number, refValue: number, percentage: number) => any;
  initialValue?: number;
};

export function ConstraintsCardSlider({
  constraint,
  marks,
  formatText,
  getValue,
}: PropsType) {
  const [value, setValue] = useState(50);
  
  useEffect(() => {
    getValue(
      parseInt(
        applyReferenceFormula(
          "MAX",
          calcSliderValue("MAX", value),
          marks[0].rawValue
        ) + ""
      ),
      marks[0].rawValue,
      Math.floor((value - 50) * 2)
    );
  }, []);

  function calcSliderValue(type, values) {
    return type === "MIN"
      ? (values * 2 - 100) * -1
      : Math.floor((values - 50) * 2);
  }

  function getRangeValue(value) {
    return value === 50 ? 0 : (value - 50) * 2;
  }

  function handleOnCommit() {
    getValue(
      parseInt(
        applyReferenceFormula(
          "MAX",
          calcSliderValue("MAX", value),
          marks[0].rawValue
        ) + ""
      ),
      marks[0].rawValue,
      Math.floor((value - 50) * 2)
    );
  }

  return (
    <InputCard>
      <Typography variant="h4">{constraint.name}</Typography>
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"nowrap"}
        mt={2}
      >
        {constraint.type !== "Max" && (
          <Grid display={"flex"} direction={"column"} mr={1}>
            <Typography variant="body2" mr={.5}>
              Min
              <label style={{ color: (value - 50) < 0 ? theme["palette"].error.main :  theme["palette"].success.main}}>
                {((value - 50) * 2).toFixed(1)}%
              </label>
            </Typography>
            <Typography variant="body3">
              {formatNumber(
                applyReferenceFormula(
                  "MIN",
                  calcSliderValue("MIN", value),
                  marks[0].rawValue
                ),
                0,
              )}
            </Typography>
          </Grid>
        )}
        <StyledSlider
          getAriaLabel={() => "Minimum distance"}
          value={value}
          onChange={(e, v, t) => {
            setValue(e.target!["value"]);
          }}
          onChangeCommitted={handleOnCommit}
          marks={marks}
          disableSwap
          step={0.5}
        />
        {constraint.type === "Max" && (
          <Grid display={"flex"} direction={"column"} ml={"1rem"}>
            <Typography variant="body2">
              Max
              <label
                style={{
                  color:
                    getRangeValue(value) >= 0
                      ? theme["palette"].success.main
                      : theme["palette"].error.main,
                }}
              >
                {getRangeValue(value) >= 0 && "+"}
                {getRangeValue(value)}%
              </label>
            </Typography>
            <Typography variant="body3">
              {formatNumber(
                applyReferenceFormula(
                  "Max",
                  calcSliderValue("MAX", value),
                  marks[0].rawValue
                ),
                0
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </InputCard>
  );
}
