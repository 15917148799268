import React, { useEffect, useState } from "react";

import { Grid, Box, Typography } from "@mui/material";
import CalendarComparisonCard from "../components/comparisonCard/CalendarComparisonCard";

import { KPIChartColor } from "../../../../utils/theme";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { fetchAggregationData } from "../../../utils/redux/calendar/calendarOptimizationActions";
import { mapChartData } from "../../../utils/mapper";
import AggregationBubbleChart from "../components/aggregationBubbleChart/aggregationBubbleChart";
import PnLTable from "../../../components/consumptionAndRetailerPL/PnLTable";
import { ChartDataArrayType } from "../../../utils/types";
import CalendarAggregationHeader from "../components/calendarAggregationHeader/CalendarAggregationHeader";

const CalendarAggregation = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartDataArrayType>({
    cpg_retail_sales_value: [],
    customer_revenue: [],
    cpg_net_revenue: [],
    gross_profit_cpg: [],
    gross_profit_customer: [],
    volumes_cpg: [],
    volumes_customer: [],
  });
  const { data, fulfilled, pending } = useAppSelector((state) => state.reducer.calendarOptimizationReducer.calendarAggregationData);
  const calendars = useAppSelector((state) => state.reducer.calendarOptimizationReducer.AllCalendarData.data);
  
  useEffect(() => {
    if (location.state != null && Array.isArray(location.state.ids)) {
      if (Array.isArray(location.state.ids)) setSelectedIDs(location.state.ids);
    }
  }, [location]);

  useEffect(() => {
    if (selectedIDs != null && Array.isArray(selectedIDs) && selectedIDs.length) {
      if (Array.isArray(selectedIDs))
        dispatch(fetchAggregationData(selectedIDs));
    }
  }, [setSelectedIDs, selectedIDs]);
  
  useEffect(() => {
    const column: any[] = [];

    if (data) {
      let dataCopy = JSON.parse(JSON.stringify(data));
      //resort dataCopy to put refCalValue at the top

      for (const [key, value] of Object.entries(chartData)) {
        chartData[key].length = 0;
      }

      const charts = mapChartData(dataCopy.charts);
      setChartData(charts);
    }
  }, [dispatch, data]);
  
  return (
    <div>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <div>
          <CalendarAggregationHeader
            calendars={calendars?.calendar_data}
            comparedCalendars={data?.calendar_data}
            setSelectedIDs={setSelectedIDs}
          />
        </div>
      </Grid>
      {fulfilled ? (
        <Grid container rowSpacing={2}>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
            <div></div>
          </Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>
          {data && (
            <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
              <Box
                component={"div"}
                style={{ display: "flex", gap: "22px", marginBottom: "24px" }}
              >
                <Box style={{ width: "50%" }}>
                  <PnLTable
                    title="Consumption P&L"
                    pnLData={data.consumptionPL}
                  />
                </Box>
                <Box style={{ width: "50%" }}>
                  <PnLTable title="Retailer P&L" pnLData={data.retailPL} />
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>
          {data && (
            <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
              <AggregationBubbleChart
                cardTitle="Promo spend (k£) and gross profit by brand and customer"
                data={data.promo_spendNGrossProfit.map((data) => {
                  const newData = { ...data };
                  newData.customer = data.customer;
                  return newData;
                })}
              />
            </Grid>
          )}

          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
            <CalendarComparisonCard
              cardTitle="Haleon Retail sales value & Customer Revenue"
              firstChartTitle={"Haleon"}
              firstChartData={chartData.cpg_retail_sales_value}
              secondChartTitle={"Customer"}
              secondChartData={chartData.customer_revenue}
              actionType={"aggregation"}
            />
          </Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
            <CalendarComparisonCard
              cardTitle="Haleon Net revenue"
              firstChartTitle={""}
              firstChartData={chartData.cpg_net_revenue}
              actionType={"aggregation"}
            />
          </Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
            <CalendarComparisonCard
              cardTitle="Gross profit"
              firstChartTitle={"Haleon"}
              firstChartData={chartData.gross_profit_cpg}
              secondChartTitle={"Customer"}
              secondChartData={chartData.gross_profit_customer}
              actionType={"aggregation"}
            />
          </Grid>
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
            <CalendarComparisonCard
              cardTitle="Volumes"
              firstChartTitle={"Haleon"}
              firstChartData={chartData.volumes_cpg}
              secondChartTitle={"Customer"}
              secondChartData={chartData.volumes_customer}
              actionType={"aggregation"}
            />
          </Grid>
          {/*
        <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
          <CalendarComparisonCard
            cardTitle="Weeks on deal"
            firstChartTitle={"Haleon"}
            firstChartData={volumesData.slice(1)}
            secondChartTitle={"Customer"}
            secondChartData={customerVolumesData.slice(1)}
          />
        </Grid>
            */}
          <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CalendarAggregation;
