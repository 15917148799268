import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PromoIncrementalityProps } from "./type";
import { promoIncrementalityStyles } from "./style";
import WaterfallChart from "../waterfallChart/WaterfallChart";
import theme from "../../../utils/theme";
import { useState } from "react";
import { CHART_TYPE } from "../../utils/enum";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { getChartData2 } from "../../utils/mapper";
import { multiBuyPicklist1 } from "../promoSimulationForm/SelectItemMenues";

const PromoIncrementality = (props: PromoIncrementalityProps) => {
  const classes = promoIncrementalityStyles();
  const { title, promoEvent, chartEvent } = props;

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(!expanded);
  };
  const [chartType, setChartType] = useState(CHART_TYPE.SALES_WATERFALL_CPG);
  const onChangeChartType = (chartType: CHART_TYPE) => {
    setChartType(chartType);
  };
  return (
    <div style={{ marginBottom: "24px" }}>
      <Accordion onChange={handleChange("")} defaultExpanded>
        <AccordionSummary
          style={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" mr={1}>
            {title}
          </Typography>
          <Tooltip
            title={
              <Typography
                fontSize={14}
                color={theme.palette.primary.contrastText}
                lineHeight={1.8}
              >
                Uplifts are calculated against baseline calculated by the
                Promo.AI model
              </Typography>
            }
            placement="top"
            arrow
          >
            <Grid display={"flex"} alignItems={"center"}>
              <Typography variant="body3" lineHeight={1.8}>
                (vs. baseline)
              </Typography>
              <InfoIcon
                fontSize="small"
                sx={{ color: "text.secondary", fontSize: 14, mt: 0.3, ml: 0.5 }}
              />
            </Grid>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container laptop_lg={12}>
            <WaterfallChart
              className={classes.kpiDataWidgetSection}
              promoEvents={promoEvent ? [promoEvent] : undefined}
              chartEvent={chartEvent}
              chartType={chartType}
              displayDropdown={false}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PromoIncrementality;
