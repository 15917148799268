import { createStyles, makeStyles } from "@mui/styles";
import { Select, Theme } from "@mui/material";
import { styled, useTheme, CSSObject } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { linearGradientDef } from "@nivo/core";

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 4),
    },
    rootIconTitle: {
      //   color: "#00FFCC",
      color: "linear-gradient(to left top, blue, red)",
    },
    drawerStyling: {
      backgroundColor: "#00FFCC",
    },
    iconButton: {
      marginRight: "12px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      paddingTop: 6,
    },
    itemContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    textButton: {
      width: "100%",
      //    overflow: "hidden",
      whiteSpace: "normal",
      display: "display",
      justifyContent: "flex-end",
      //lineHeight: 2,
      float: "left",
    },
    marketContainer: {
      marginLeft: `calc(78vw - 160px)`,
    },
    marketSelect: {
      color: theme.palette.primary.background,
      background: `${theme.palette.primary.background_2} !important`,
    },
  })
);

const drawerWidth = 320;
export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("mobile")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.primary.contrastText,
  height: 70,

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.primary.contrastText,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: theme.palette.primary.contrastText,

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
