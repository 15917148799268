import { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";

type props = {
  getValues: (dates: Date[]) => any;
  defaultValues: Date[];
};

export function SlotDatePicker({ getValues, defaultValues }: props) {
  const [values, setValues] = useState(() => {
    if(defaultValues) {
      return defaultValues;
    }
    return [new Date(), new Date()];
  });

  useEffect(() => {
    getValues([values[0], values[1]]);
  }, []);

  return (
    <DateRangePicker
      value={[values[0], values[1]]}
      onChange={(dates: any) => {
        setValues([dates[0], dates[1]]);
        getValues([dates[0], dates[1]]);
      }}
    />
  );
}
