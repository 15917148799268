import * as React from "react";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import { Grid, Typography, Box } from "@mui/material";
import { KPIChartColor } from "../../../../../utils/theme";
import { useStyles } from "./ChartKpiStyle";
import { useEffectOnce } from "../../../../../utils/hooks/useEffectOnce";
import { formatNumber } from "../../../../utils/mapper";
import { number } from "yup/lib/locale";
type ChartData = {
  baseline_val: number;
  baseline_val2: number;
  differenceRefCalend: number;
  gap: number;
  inscreased_mark_val: number;
  inscreased_mark_val2: number;
  marginLabel: [number];
  max_margin_val: number;
  max_margin_val2: number;
  name: string;
  total: string;
  up: string;
};
type KpiChartProps = {
  title: string;
  data: ChartData[];
};
export default function KpiChart(props: KpiChartProps) {
  const { title, data } = props;

  const CustomizedLabel = (props) => {
    const { x, y, fill, value, index, offset, viewBox, isDown } = props;
    const { height, width } = viewBox;

    return (
      <foreignObject
        x={x + (isDown ? 50 : 30)}
        y={y - 30}
        width={width + 400}
        height={height > 0 ? height : height * -1}
      >
        <div
          style={{
            display: "flex",
            alignItems: "",
          }}
        >
          <Typography sx={{ paddingRight: 1 }} fontSize={14}>
            {formatNumber(value)}
            {""}
          </Typography>
          {isDown ? (
            <div></div>
          ) : (
            <div>
              <Typography color="red" fontSize={12}>
                {`${data[index].differenceRefCalend}%`}
              </Typography>
            </div>
          )}
        </div>
      </foreignObject>
    );
  };

  const classes = useStyles({});

  const formatTick = function (value) {
    return formatNumber(value);
  };

  const renderLabel = function (values: any) {
    const value = formatNumber(values[0]);
    return `${value}`;
  };

  return (
    <Grid container spacing={1}>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <Typography fontWeight={500} fontSize={14}>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        mobile={12}
        tablet={9}
        laptop={9}
        laptop_lg={9}
        py={2}
        sx={{ minHeight: 120 }}
      >
        <ResponsiveContainer height={275} width={"100%"}>
          <BarChart
            data={data}
            defaultShowTooltip={false}
            margin={{
              top: 22,
              right: 20,
              left: 4,
              bottom: 1,
            }}
          >
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              style={{ fontSize: 12 }}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              allowDataOverflow={false}
              tickFormatter={formatTick}
              width={60}
              height={50}
              style={{ fontSize: 12 }}
            />

            <Bar
              dataKey="baseline_val"
              stackId="1"
              stroke={KPIChartColor.baseline}
              fill={KPIChartColor.baseline}
              name="Baseline"
            />
            <Bar dataKey="gap" stackId="1" fill="transparent" />
            <Bar
              dataKey="baseline_val2"
              stackId="1"
              stroke={KPIChartColor.baseline}
              fill="rgba(255, 255, 255, 1)"
              name="Baseline"
              label={<CustomizedLabel />}
            />
            <Bar
              dataKey="max_margin_val"
              stackId="1"
              stroke={KPIChartColor.maxMArgin}
              fill={KPIChartColor.maxMArgin}
              name="Baseline"
            />

            <Bar dataKey="gap" stackId="1" fill="transparent" />
            <Bar
              dataKey="max_margin_val2"
              stackId="1"
              stroke={KPIChartColor.maxMArgin}
              fill="rgba(255, 255, 255, 1)"
              name="Baseline"
              label={<CustomizedLabel />}
            />
            <Bar
              dataKey="inscreased_mark_val"
              stackId="1"
              stroke={KPIChartColor.inscreasedMark}
              fill={KPIChartColor.inscreasedMark}
              name="Baseline"
            />

            <Bar dataKey="gap" stackId="1" fill="transparent" />
            <Bar
              dataKey="inscreased_mark_val2"
              stackId="1"
              stroke={KPIChartColor.inscreasedMark}
              fill="rgba(255, 255, 255, 1)"
              name="Baseline"
              label={<CustomizedLabel />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}
