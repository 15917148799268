import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CalendarData, KPITableType } from "../../../../utils/types";
import { useAppSelector } from "../../../../../utils/hooks";
import { Calendar } from "../../../../utils/types";
import { useStyles } from "./KpiTableStyle";

interface TableData {
  title: string;
  backgroundColor?: string;
  data?: any[];
}
interface CalendarComparisonKpiTableProps {
  title;
  dataArray: KPITableType[];
}

const CalendarComparisonKpiTable = (props: CalendarComparisonKpiTableProps) => {
  const referenceCalendar = useAppSelector(
    (state) => state.reducer.CalendarComparsionViewEditReducer
  );
  const { calendar } = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer
  );
  const rows = [10, 11];
  const classes = useStyles();
  const { title, dataArray } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h2" fontWeight="normal">
        {title}
      </Typography>
      <Grid container>
        <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
          <TableContainer>
            <Table padding="none">
              <TableHead>
                <TableRow className={classes.tableRootCell}>
                  <TableCell
                    style={{ background: "white", borderColor: "white" }}
                  />
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    sx={{
                      borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                      backgroundColor: " #F4F4F4",
                    }}
                  >
                    <Typography fontSize={14} paddingLeft={2}>
                      CPG
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    colSpan={2}
                    style={{
                      backgroundColor: " #F4F4F4",
                      borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                      borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                    }}
                  >
                    <Typography fontSize={14} textAlign="left" paddingLeft={2}>
                      Customer Impact
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                    }}
                  />
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography fontSize={10} fontWeight="bold">
                      Gross Margin, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography fontSize={10} fontWeight="bold">
                      VSOP, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography fontSize={10} fontWeight="bold">
                      ARP per unit, ($)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      ARP per RSV share, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      Average time on promo per brandline, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      Average time on catalog per brandline, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                    className={classes.tableCellSecondRow}
                    align="center"
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      Average time as seasonal per brandline, (%)
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      Average time as secondary per brandline, (%)
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.8 }}
                    >
                      BeverageCo promo budget, ($)
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      Total profit tool, ($)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    align="center"
                    sx={{ borderBottom: "0.2px solid rgba(82, 82, 82, 1)" }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ lineHeight: 1.5 }}
                    >
                      VSOP, (%)
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.tableCellSecondRow}
                    sx={{
                      borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                      borderRight: "0.2px solid rgba(82, 82, 82, 1)",
                    }}
                    align="center"
                  >
                    <Typography
                      fontSize={10}
                      fontWeight="bold"
                      sx={{ width: 100 }}
                    >
                      ARP per unit, ($)
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {dataArray &&
                dataArray.length > 0 &&
                dataArray.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      style={{ backgroundColor: item.backgroundColor }}
                    >
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        sx={{
                          width: "13%",
                          borderTop: "0.2px solid rgba(82, 82, 82, 1)",
                          borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                        }}
                      >
                        {index === 0 && (
                          <div>
                            <Typography
                              style={{
                                backgroundColor: "#c4c4c4",
                                borderRadius: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "20%",

                                fontSize: 10,
                                fontWeight: "bold",
                                height: 22,
                              }}
                            >
                              Ref
                            </Typography>
                          </div>
                        )}
                        {item.title}
                      </TableCell>

                      {Object.keys(item.data).map((key, cellIndex) => {
                        return (
                          <TableCell
                            key={`${cellIndex}-${index}`}
                            className={classes.tableCell}
                            align="right"
                            sx={{
                              borderRight:
                                Object.keys(item.data).length - 1 === cellIndex
                                  ? "0.2px solid rgba(82, 82, 82, 1)"
                                  : "",
                              borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
                            }}
                          >
                            <Typography
                              fontSize={14}
                              className={classes.textContent}
                            >
                              {item.data[key] || "-"}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default CalendarComparisonKpiTable;
