import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatNumber } from "../../../../../utils/mapper";
import { ITimeline } from "../CalendarComparsionTimeline";
import { KpiRowData } from "../interfaces";
import { defaultKpiRow } from "../mock";

export const KpisTitle = () => {
  return (
    <Grid className="h-16">
      <Grid container className="w-full h-full">
        <Grid
          item
          flex={1}
          container
          alignItems="start"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Net Revenue ($)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="start"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Volume (Unit)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="start"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Profit ($)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="start"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          ARP ($)
        </Grid>
      </Grid>
    </Grid>
  );
};

type KpiItemProps = {
  variant?: "baseline" | "constantMarginMarketShare" | "increasedMarketShare";
  value: any;
};

export const KpiItem: React.FC<KpiItemProps> = ({ variant, value }) => {
  return (
    <Grid
      container
      className="pr-2 h-10 flex items-center justify-center"
      style={{
        background:
          variant == "baseline"
            ? "rgba(204, 210, 208, 0.3)"
            : variant == "constantMarginMarketShare"
            ? "rgba(197, 170, 83, 0.3)"
            : variant == "increasedMarketShare"
            ? "rgba(132, 220, 245, 0.3)"
            : "",
      }}
    >
      {formatNumber(value)}
    </Grid>
  );
};

type KpiColProps = {
  children: JSX.Element | JSX.Element[];
};

export const KpiCol: React.FC<KpiColProps> = ({ children }) => {
  return (
    <Grid
      item
      flex={1}
      className="w-full border-solid border-0 border-r-[1px] border-r-background-60"
    >
      <Grid container className="flex flex-col text-center">
        {/* KPI Item */}
        {children}
      </Grid>
    </Grid>
  );
};

type KpiRowProps = {
  data: any;
  iterator: number;
  timelines: number;
};

export const KpiRow: React.FC<KpiRowProps> = ({
  data,
  iterator,
  timelines,
}) => {
  return (
    data && (
      <Grid container>
        <Grid className="w-full" direction="row" item container>
          <KpiCol>
            <KpiItem
              value={data.netRevenue}
              variant={
                Math.round(iterator % timelines) == 0
                  ? "baseline"
                  : Math.round(iterator % timelines) == 1
                  ? "constantMarginMarketShare"
                  : Math.round(iterator % timelines) == 2
                  ? "increasedMarketShare"
                  : "baseline"
              }
            />
          </KpiCol>
          <KpiCol>
            <KpiItem
              value={data.volume}
              variant={
                Math.round(iterator % timelines) == 0
                  ? "baseline"
                  : Math.round(iterator % timelines) == 1
                  ? "constantMarginMarketShare"
                  : Math.round(iterator % timelines) == 2
                  ? "increasedMarketShare"
                  : "baseline"
              }
            />
          </KpiCol>
          <KpiCol>
            <KpiItem
              value={data.margin}
              variant={
                Math.round(iterator % timelines) == 0
                  ? "baseline"
                  : Math.round(iterator % timelines) == 1
                  ? "constantMarginMarketShare"
                  : Math.round(iterator % timelines) == 2
                  ? "increasedMarketShare"
                  : "baseline"
              }
            />
          </KpiCol>
          <KpiCol>
            <KpiItem
              value={data.arp}
              variant={
                Math.round(iterator % timelines) == 0
                  ? "baseline"
                  : Math.round(iterator % timelines) == 1
                  ? "constantMarginMarketShare"
                  : Math.round(iterator % timelines) == 2
                  ? "increasedMarketShare"
                  : "baseline"
              }
            />
          </KpiCol>
        </Grid>
      </Grid>
    )
  );
};

type KpisProps = {
  timelines: number;
  kpis: any;
};

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1.75rem",
  },
  twoTimelines: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3.5rem",
  },
  threeTimelines: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3.5rem",
  },
}));

export const Kpis: React.FC<KpisProps> = ({ kpis, timelines }) => {
  const classes = useStyles();
  const getSpacing = (i) => {};

  return (
    <Grid className="w-[75rem] max-w-[30%] bg-white border-solid border-l-[1px] border-l-background-50 mt-4">
      <KpisTitle />
      <Grid className="gap-y-6">
        <Grid container className="w-full  h-full relative">
          <Grid
            container
            className="w-full h-full absolute  top-0 left-0 z-9999"
          >
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
          </Grid>
          {/* KPI Class */}
          <Grid container className="pt-8 flex flex-col w-full h-full ">
            {kpis.map((kpi, i) => (
              <Grid
                sx={{
                  width: "100%",
                }}
                className={
                  i % timelines == timelines - 1
                    ? timelines == 3
                      ? classes.threeTimelines
                      : timelines == 2
                      ? classes.twoTimelines
                      : timelines == 1
                      ? classes.oneTimeline
                      : ""
                    : ""
                }
              >
                <KpiRow iterator={i} timelines={timelines} data={kpi} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
