import { makeStyles, createStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2),
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "4px",
      boxShadow:
        "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(63 63 68 / 15%) 0px 1px 2px 0px",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      width: "97%",
    },
    dataContainer: {
      display: "flex",

      alignItems: "center",
    },
    valueContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      gap: 10,
    },
    FlexCol: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
    },
    gradientRectangle: {
      height: "150px",
      width: "11px",
      background: "linear-gradient(#39EB10 0%, #FFFFFF 50%,#EB3A3A 100%)",
      borderRadius: "5px",
    },
    boxBorderBottom: {
      borderBottom: " 1px solid #8D8D8D",
    },
  })
);
