import * as s from "../ContraintsStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConstraintsCardRange } from "../../../../../components/constraintsCardRange/ConstraintsCardRange";
import { ConstraintsCardSlider } from "../../../../../components/constraintsCardSlider/ConstraintsCardSlider";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../../../utils/mapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConstraintsCardSelect } from "../../../../../components/constraintsCardSelect/ConstraintsCardSelect";
import { useTheme } from "@mui/styles";
import { CalendarCreationFormActionTypes } from "../../../../../utils/redux/calendarCreationForm/calendarCreationFormActions";
import {
  promomixProductsConstraints,
  promomixBrandsConstraints,
  promomixCalendarConstraints,
} from "../../../../../utils/constants";

type brandsObjType = {
  id: string;
  level: string;
  name: string;
  type: string;
  value: number;
};

type usedStaticConstraintsType = {
  id: Number;
  name: string;
};

type usedBrandsConstraintsType = {
  id: Number;
  brand_id: Number;
  name: string;
};

type usedProductsConstraintsType = {
  id: Number;
  brand_id: Number;
  product_id: Number;
  name: string;
};
const emptyHint = [{ value: 50, label: "" }];

export function PromoMix({
  errors,
  register,
  setValue,
  watch,
  brands,
  reset,
  onResetComplete,
}: any) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [presetCommonConstraint, setPresetCommonConstraint] = useState<any[]>(
    []
  );
  const [brandsRefValues, setBrandsRefValues] = useState<any>();
  const [productsRefValues, setProductsRefValues] = useState<any>();
  const [calendarLevelList, setCalendarLevelList] = useState<any[]>([]);
  const [brandLevelList, setBrandLevelList] = useState<any[]>([]);
  const [productLevelList, setProductLevelList] = useState<any[]>([]);
  const [staticConstraints, setStaticConstraints] = useState(
    promomixCalendarConstraints
  );
  const [usedStaticConstraints, setUsedStaticConstraints] = useState<
    usedStaticConstraintsType[]
  >([]);
  const [brandsConstraints, setBrandsConstraints] = useState(
    promomixBrandsConstraints
  );
  const [usedBrandsConstraints, setUsedBrandsConstraints] = useState<
    usedBrandsConstraintsType[]
  >([]);

  const [productsConstraints, setProductsConstraints] = useState(
    promomixProductsConstraints
  );
  const [usedProductsConstraints, setUsedProductsConstraints] = useState<
    usedProductsConstraintsType[]
  >([]);

  const referenceCalendar = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.selectedRefCalData
  );

  const [productAccordian, setProductAccordian] = useState(true);
  const [brandAccordian, setBrandAccordian] = useState(true);
  const [calendarAccordian, setCalendarAccordian] = useState(true);

  const { constraintsCommons, constraintsBrands, constraintsProducts } =
    useAppSelector((state) => {
      return {
        constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
        constraintsBrands: state.reducer.ConstraintsManagementReducer.brands,
        constraintsProducts:
          state.reducer.ConstraintsManagementReducer.products,
      };
    });

  const resetConstraints = () => {
    setCalendarLevelList([]);
    setBrandLevelList([]);
    setProductLevelList([]);
    setUsedStaticConstraints([]);
    setUsedBrandsConstraints([]);
    setUsedProductsConstraints([]);
    setStaticConstraints(promomixCalendarConstraints);
    setBrandsConstraints(promomixBrandsConstraints);
    setProductsConstraints(promomixProductsConstraints);
  };

  useEffect(() => {
    if (reset) {
      resetConstraints();
      onResetComplete();
    }
  }, [reset]);

  useEffect(() => {
    resetConstraints();
  }, [
    watch("quarter"),
    watch("category"),
    watch("customer"),
    watch("referenceCalendar"),
  ]);

  useEffect(() => {
    if (referenceCalendar.data) {
      staticConstraints[0].refVal = referenceCalendar.data?.promo_num;
      staticConstraints[0].marks[0].label =
        "Ref " + formatNumber(referenceCalendar.data?.promo_num);
      staticConstraints[0].marks[0].rawValue =
        referenceCalendar.data?.promo_num;

      let tempBrandReferences: { promo_num: any } = { promo_num: [] };

      if (
        referenceCalendar.data?.promo_num_per_brand &&
        Object.keys(referenceCalendar.data?.promo_num_per_brand).length > 0
      ) {
        Object.keys(referenceCalendar.data?.promo_num_per_brand).forEach(
          (key) => {
            tempBrandReferences.promo_num.push({
              name: key,
              value: referenceCalendar.data?.promo_spend_per_brand[key],
            });
          }
        );
      }

      setBrandsRefValues(tempBrandReferences);
      setStaticConstraints(staticConstraints);
    }
  }, [referenceCalendar]);

  useEffect(() => {
    if (constraintsCommons && constraintsBrands && constraintsProducts) {
      const calendarLevelCnstr = constraintsCommons
        .filter(
          (x) =>
            x.name.includes("num_promos") ||
            x.name.includes("consecutive_promo_weeks") ||
            x.name.includes("cooldown_weeks")
        )
        .filter((x) => x.MIN !== undefined);

      const brandLevelCnstr = constraintsBrands
        .filter(
          (x) =>
            x.name.includes("num_promos") ||
            x.name.includes("consecutive_promo_weeks") ||
            x.name.includes("cooldown_weeks")
        )
        .filter((x) => x.MIN !== undefined);

      const productLevelCnstr = constraintsProducts
        .filter(
          (x) =>
            x.name.includes("num_promos") ||
            x.name.includes("consecutive_promo_weeks") ||
            x.name.includes("cooldown_weeks")
        )
        .filter((x) => x.MIN !== undefined);

      setCalendarLevelList([]);
      setUsedStaticConstraints([]);
      setBrandLevelList([]);
      setUsedBrandsConstraints([]);
      setProductLevelList([]);
      setUsedProductsConstraints([]);
      // setStaticConstraints([...financialsCalendarConstraints]);

      const presetConstraints: any = [];
      if (calendarLevelCnstr.length >= 1) {
        calendarLevelCnstr.forEach((x) => {
          if (x.MIN !== undefined) {
            addConstraint(
              "calendar",
              promomixCalendarConstraints.filter(
                (constant) => constant.presetKey === x.name
              )[0]
            );
            const target = staticConstraints.findIndex(
              (constraint) => constraint.presetKey === x.name
            );
            staticConstraints[target].marks[0]["value"] =
              x.name === "num_promos"
                ? [Number(x.MIN), Number(x.MAX)]
                : [Number(x.MIN)];
          }
        });
        presetConstraints.push(...calendarLevelCnstr);
        setStaticConstraints(staticConstraints);
      }

      if (brandLevelCnstr.length >= 1) {
        brandLevelCnstr.forEach((x) => {
          if (x.MIN !== undefined) {
            const tempCnsr = JSON.parse(
              JSON.stringify(
                promomixBrandsConstraints.filter(
                  (constant) => constant.presetKey === x.name
                )[0]
              )
            );
            tempCnsr.brand = x.internal_code;
            addConstraint("brand", tempCnsr);
            const target = brandsConstraints.findIndex(
              (constraint) => constraint.presetKey === x.name
            );
            brandsConstraints[target].marks[0]["value"] =
              x.name === "num_promos"
                ? [Number(x.MIN), Number(x.MAX)]
                : [Number(x.MIN)];
          }
        });
        presetConstraints.push(...brandLevelCnstr);
        setBrandsConstraints(brandsConstraints);
      }

      if (productLevelCnstr.length >= 1) {
        const tempProdLevelCnstr = [...productLevelCnstr];
        const prodToPreset: any = [];
        productLevelCnstr.forEach((x, index) => {
          if (x.MIN !== undefined) {
            const tempCnsr = JSON.parse(
              JSON.stringify(
                promomixProductsConstraints.filter(
                  (constant) => constant.presetKey === x.name
                )[0]
              )
            );
            const productSanitized = x.internal_code.split("  ").join(" - ");
            brands?.forEach((b) => {
              b.products.forEach((p) => {
                if (p.internal_product_code === productSanitized) {
                  tempCnsr.brand = b.internal_code;
                }
              });
            });
            tempCnsr.product = productSanitized;
            prodToPreset.push({
              ...tempProdLevelCnstr[index],
              brand: tempCnsr.brand,
              internal_code: tempCnsr.product,
            });
            addConstraint("product", tempCnsr);
            const target = productsConstraints.findIndex(
              (constraint) => constraint.presetKey === x.name
            );
            productsConstraints[target].marks[0]["value"] =
              x.name === "num_promos"
                ? [Number(x.MIN), Number(x.MAX)]
                : [Number(x.MIN)];
          }
        });
        presetConstraints.push(...prodToPreset);
        setProductsConstraints(productsConstraints);
      }
      setPresetCommonConstraint(presetConstraints);
      generateFormBody();
    }
  }, [constraintsCommons, constraintsCommons, constraintsProducts]);

  // useEffect(() => {
  //   let temp: any = [];
  //   brands.forEach((brand) => {
  //     temp.push({
  //       id: brand.id,
  //       level: "brand",
  //       name: brand.internal_code,
  //       type: "Min",
  //       value: 50,
  //     });
  //   });
  //   setBrandsObj(temp);
  // }, [brands, setBrandsObj]);

  function generateFormBody() {
    dispatch({
      type: CalendarCreationFormActionTypes.CLEAR_PROMO_MIX_CONSTRAINTS,
    });

    const requestBody: {
      calendars: any[];
      brands: any[];
      products: any[];
    } = {
      calendars: [],
      brands: [],
      products: [],
    };

    if (calendarLevelList.length > 0) {
      calendarLevelList.forEach((calendar) => {
        calendar.constraint.forEach((name, index) => {
          requestBody.calendars.push({
            name: name,
            value: calendar.value[index],
            percentages: calendar.percentages
              ? calendar.percentages?.[index]
              : 100,
          });
        });
      });
    }

    if (brandLevelList.length > 0) {
      brandLevelList.forEach((brand) => {
        brand.constraint.forEach((name, index) => {
          requestBody.brands.push({
            name: `brands.brand-${brand.brandId}.${name}`,
            value: brand.value[index],
            percentages: brand.percentages?.[index],
          });
        });
      });
    }

    if (productLevelList.length > 0) {
      productLevelList.forEach((product) => {
        product.constraint.forEach((name, index) => {
          requestBody.products.push({
            name: `products.product-${product.productId}.${name}`,
            value: product.value[index],
            percentage: product.percentages?.[index] || 100,
          });
        });
      });
    }

    dispatch({
      type: CalendarCreationFormActionTypes.SEND_PROMO_MIX_CONSTRAINTS,
      payload: requestBody,
    });

    return requestBody;
  }

  function addConstraint(type, selectedConstraint?) {
    const id = Math.floor(Math.random() * 10032142);
    setPresetCommonConstraint([]);
    if (type === "calendar") {
      const availableConstraints = staticConstraints.filter(
        (constraint) => constraint.isActive === false
      );
      if (
        availableConstraints.length > 0 &&
        calendarLevelList.length < staticConstraints.length
      ) {
        if (selectedConstraint) {
          setCalendarLevelList((state) => [
            ...state,
            {
              id: id,
              value: [0],
              constraint: selectedConstraint.constraints_names,
              presetKey: selectedConstraint.presetKey,
            },
          ]);
        } else {
          setCalendarLevelList((state) => [
            ...state,
            { id: id, value: [0], constraint: [] },
          ]);
        }
      }
    }

    if (type === "brand") {
      if (brandLevelList.length < brands.length * brandsConstraints.length) {
        if (selectedConstraint) {
          setBrandLevelList((state) => [
            ...state,
            {
              id: id,
              value: [0],
              constraint: selectedConstraint.constraints_names,
              presetKey: selectedConstraint.presetKey,
              brandId: selectedConstraint.brand,
            },
          ]);
        } else {
          setBrandLevelList((state) => [
            ...state,
            { id: id, value: [0], constraint: [], brandId: 0 },
          ]);
        }
      }
    }

    if (type === "product") {
      if (selectedConstraint) {
        setProductLevelList((state) => [
          ...state,
          {
            id: id,
            value: [0],
            constraint: selectedConstraint.constraints_names,
            presetKey: selectedConstraint.presetKey,
            brandId: selectedConstraint.brand,
            productId: selectedConstraint.product,
          },
        ]);
      } else {
        setProductLevelList((state) => [
          ...state,
          { id: id, value: [0], constraint: [], brandId: 0, productId: 0 },
        ]);
      }
    }
  }

  function deleteFromList(type, object) {
    if (type === "calendar") {
      calendarLevelList.splice(
        calendarLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedStaticConstraints.splice(
        usedStaticConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setCalendarLevelList([...calendarLevelList]);
      setUsedStaticConstraints([...usedStaticConstraints]);
    }
    if (type === "brand") {
      brandLevelList.splice(
        brandLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedBrandsConstraints.splice(
        usedBrandsConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setBrandLevelList([...brandLevelList]);
      setUsedBrandsConstraints([...usedBrandsConstraints]);
    }
    if (type === "product") {
      productLevelList.splice(
        productLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedProductsConstraints.splice(
        usedProductsConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setUsedProductsConstraints([...usedProductsConstraints]);
      setProductLevelList([...productLevelList]);
    }
    generateFormBody();
  }

  return (
    <Grid container direction="column" gap={2}>
      <Accordion
        expanded={calendarAccordian}
        onChange={(event) => setCalendarAccordian(!calendarAccordian)}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: "100%", padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Calendar level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {calendarLevelList &&
            calendarLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                presetValue={
                  presetCommonConstraint[
                    presetCommonConstraint.findIndex(
                      (x) =>
                        x.name === constraint.presetKey &&
                        x.level === "CALENDAR"
                    )
                  ] || null
                }
                type="calendar"
                constraints={staticConstraints}
                objectToSave={constraint}
                setValue={setValue}
                usedConstraints={usedStaticConstraints}
                UpdateUsedConstraints={setUsedStaticConstraints}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("calendar", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.light,
            }}
            onClick={() => addConstraint("calendar")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={brandAccordian}
        onChange={(event) => setBrandAccordian(!brandAccordian)}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Brand level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {brandLevelList &&
            brandLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                type="brand"
                presetValue={
                  presetCommonConstraint[
                    presetCommonConstraint.findIndex(
                      (x) =>
                        x.name === constraint.presetKey &&
                        x.level === "BRAND" &&
                        x.internal_code === constraint.brandId
                    )
                  ] || null
                }
                constraints={brandsConstraints}
                brands={brands}
                brandsRefValues={brandsRefValues}
                setValue={setValue}
                objectToSave={constraint}
                usedConstraints={usedBrandsConstraints}
                UpdateUsedConstraints={setUsedBrandsConstraints}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("brand", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.light,
            }}
            onClick={() => addConstraint("brand")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={productAccordian}
        onChange={(event) => setProductAccordian(!productAccordian)}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Product level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {productLevelList &&
            productLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                type="product"
                presetValue={
                  presetCommonConstraint[
                    presetCommonConstraint.findIndex(
                      (x) =>
                        x.name === constraint.presetKey &&
                        x.level === "PRODUCT" &&
                        x.internal_code === constraint.productId
                    )
                  ] || null
                }
                constraints={productsConstraints}
                brands={brands}
                productsRefValues={productsRefValues}
                setValue={setValue}
                objectToSave={constraint}
                usedConstraints={usedProductsConstraints}
                UpdateUsedConstraints={setUsedProductsConstraints}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("product", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.light,
            }}
            onClick={() => addConstraint("product")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
