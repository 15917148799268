import { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";
import { formatValue } from "react-currency-input-field";
import CurrencyInput from "react-currency-input-field";
import { StyledSlider } from "../inputSlider";
import { styled } from "@mui/styles";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../Promo/utils/mapper";

type Constraint = {
  name: string | "";
};

type Mark = {
  label: string;
  value: number;
  rawValue: number;
};

type PropsType = {
  constraint?: Constraint | { name: "" };
  marks: Mark[];
  getValues: (values: number[], refValue: number) => any;
  initialValues?: number[];
  currency?: boolean;
  refDecimal: number;
};

const Label = styled("label")({
  fontWeight: "600 !important",
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "14px",
});

const CustomCurrencyInput = styled(CurrencyInput)({
  // background: "blue",
  paddingLeft: "3px",
  height: "20px",
  border: "1px solid black",
  borderRadius: "4px",
  width: "120px",
  minHeight: "40px",
});

export function ConstraintsCardRangeInteger({
  constraint,
  marks,
  getValues,
  initialValues = [],
  currency = false,
  refDecimal,
}: PropsType) {
  const [values, setValues] = useState<number[]>([
    initialValues[0],
    initialValues[1],
  ]);

  useEffect(() => {
    getValues(
      [values[0], values[1]],
      marks && marks.length > 0 ? marks[0].rawValue : 10
    );
  }, []);

  useEffect(() => {
    if (initialValues.length >= 1) {
      setValues([initialValues[0], initialValues[1]]);
      getValues(
        [initialValues[0], initialValues[1]],
        marks && marks.length > 0 ? marks[0].rawValue : 10
      );
    }
  }, []);

  function handleOnCommit(values) {
    getValues(
      [values[0], values[1]],
      marks && marks.length > 0 ? marks[0].rawValue : 10
    );
  }

  function calcSliderPercentage(type, values, refValue) {
    return type === "MIN"
      ? Math.floor(((refValue - values[0]) * 100) / refValue)
      : Math.floor(((values[1] - refValue) * 100) / refValue);
  }

  return (
    <InputCard>
      <Typography variant="h4">{""}</Typography>
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"nowrap"}
        sx={{ marginTop: "-10px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }} gap={3}>
          <Box display={"grid"}>
            <Label style={{ justifySelf: "baseline", paddingLeft: 3 }}>
              Min{"  "}
              {marks[0].rawValue ? (
                <label style={{ color: theme["palette"].error.main }}>
                  {/* -{calcSliderPercentage("MIN", values, marks[0].rawValue)}% */}
                  {((values[0] * 100) / marks[0].rawValue).toFixed(0)}%
                </label>
              ) : null}
            </Label>
            {currency ? (
              <CustomCurrencyInput
                name="promo_min_per_slot"
                placeholder="Enter Value"
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                defaultValue={values[0] || 0}
                onValueChange={(value: any, name) => {
                  let temp = Number.parseFloat(value);
                  setValues([temp, values[1]]);
                  if (
                    values[0] !== null &&
                    values[0] !== undefined &&
                    values[1] !== null &&
                    values[1] !== undefined
                  ) {
                    handleOnCommit([temp, values[1]]);
                  }
                }}
              />
            ) : (
              <TextField
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: 14, paddingLeft: 3 },
                }}
                defaultValue={initialValues[0]}
                value={values[0]}
                name="promo_min_per_slot"
                size="small"
                sx={{ width: "8rem" }}
                onChange={(e: any) => {
                  let temp = Math.ceil(e.target.value);
                  setValues([temp, values[1]]);
                  if (
                    values[0] !== null &&
                    values[0] !== undefined &&
                    values[1] !== null &&
                    values[1] !== undefined
                  ) {
                    handleOnCommit([temp, values[1]]);
                  }
                }}
              />
            )}
          </Box>
          {marks[0].rawValue ? (
            <Box display={"grid"}>
              <Label style={{ justifySelf: "center" }}>Ref</Label>
              <Typography variant="body3">
                {currency ? "$" : null}
                {formatValue({
                  value: marks[0].rawValue.toFixed(refDecimal || 0),
                  groupSeparator: ",",
                  decimalSeparator: ".",
                })}
              </Typography>
            </Box>
          ) : null}
          <Box display={"grid"}>
            <Label style={{ justifySelf: "baseline", paddingLeft: 3 }}>
              Max{"  "}
              {marks[0].rawValue ? (
                <label style={{ color: theme["palette"].success.main }}>
                  {/* +{calcSliderPercentage("MAX", values, marks[0].rawValue)}% */}
                  {((values[1] * 100) / marks[0].rawValue).toFixed(0)}%
                </label>
              ) : null}
            </Label>
            {currency ? (
              <CustomCurrencyInput
                name="promo_min_per_slot"
                placeholder="Enter Value"
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                defaultValue={values[1] || 0}
                onValueChange={(value: any, name) => {
                  let temp = Number.parseFloat(value);
                  setValues([values[0], temp]);
                  if (
                    values[0] !== null &&
                    values[0] !== undefined &&
                    values[1] !== null &&
                    values[1] !== undefined
                  ) {
                    handleOnCommit([values[0], temp]);
                  }
                }}
              />
            ) : (
              <TextField
                id="outlined-basic"
                defaultValue={initialValues[1]}
                value={values[1]}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: 14, paddingLeft: 3 },
                }}
                name="promo_max_per_slot"
                variant="outlined"
                size="small"
                sx={{ width: "8rem" }}
                onChange={(e: any) => {
                  let temp = Math.ceil(e.target.value);
                  setValues([values[0], temp]);
                  if (
                    values[0] !== null &&
                    values[0] !== undefined &&
                    values[1] !== null &&
                    values[1] !== undefined
                  ) {
                    handleOnCommit([values[0], temp]);
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </InputCard>
  );
}
