import { createStyles, makeStyles, styled } from "@mui/styles";
import { TableRow, Theme } from "@mui/material";

export const PnLStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      border: "1px solid #8D8D8D !important",
    },
    tableHeaderCell: {
      background: "#F4F4F4",
      border: "1px solid #8D8D8D !important",
    },
    tableEmptyHeader: {
      borderBottom: "1px solid #8D8D8D !important",
    },
  })
);
