import { Box, FormControlLabel, Slider, Switch, Tab } from "@mui/material";
import { styled } from "@mui/styles";
import { Controller } from "react-hook-form";
import { Grid } from "rsuite";
import theme from "../../../../../utils/theme";

export const SwitchWithLabel = (text, register?, size?, state?) => (
  <FormControlLabel
    control={<Switch size={size} sx={{ whiteSpace: "nowrap" }} {...register} />}
    label={text}
    checked={state}
    sx={{
      "& .MuiFormControlLabel-label": {
        fontWeight: 400,
        fontSize: "1rem",
      },
    }}
  />
);

export const SwitchWithLabelWithState = (
  text,
  register?,
  CheckedValue?,
  size?
) => (
  <FormControlLabel
    control={
      <Switch
        size={size}
        sx={{ whiteSpace: "nowrap" }}
        checked={CheckedValue}
        {...register}
      />
    }
    label={text}
    sx={{
      "& .MuiFormControlLabel-label": {
        fontWeight: 400,
        fontSize: "1rem",
      },
    }}
  />
);

export const Row = styled(Box)({
  width: "100%",
  backgroundColor: theme.palette.primary.background_2,
  padding: `${theme["spacing"](2)} ${theme["spacing"](3)}`,
});

export const Label = styled("label")({
  fontSize: "0.7rem",
  fontWeight: 600,
});

export const RedirectLabel = styled("a")({
  fontSize: "0.9rem",
  fontWeight: 600,
  color: theme["palette"].primary.main,
});

export const StyledSlider = styled(Slider)({
  width: "14rem",
  position: "relative",
  "& .MuiSlider-rail": {
    backgroundColor: "#bebebe",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "white",
    border: "1px solid #828282",
  },
  "& .MuiSlider-track": {
    backgroundColor: "#000",
  },
  "& .MuiSlider-markLabel": {
    fontSize: ".825rem",
  },
  "& .MuiSlider-mark": {
    background:
      "repeating-linear-gradient(to bottom,transparent 0 4px,black 4px 6px) 80%/2px 100% no-repeat",
    height: "27px",
    width: "1px",
  },
});

export const InputCard = styled(Box)({
  width: "fit-content",
  backgroundColor: theme["palette"].primary.background,
  padding: `${theme["spacing"](2)} ${theme["spacing"](2)}`,
});

export const NumberWrapper = styled(Box)({
  backgroundColor: theme.palette.primary["accent"],
  width: "2rem",
  height: "2rem",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderRadius: "0.938rem",
  "& h4": {
    color: theme.palette.primary.dark,
  },
});
