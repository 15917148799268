import axios from "axios";
import { GlobalActionTypes } from "utils/redux/Global/GlobalActions";

const AxiosSetup = (window) => {
    axios.defaults.baseURL = process.env.REACT_APP_PROMO_API_URL
    axios.interceptors.response.use((response) => {
    return response;
    }, (error) => {
    if(error.response.status === 403) {
        window.location.href = `${process.env.REACT_APP_PROMO_API_URL}/user/auth/login`
    }
    return Promise.reject(error);
    }
);
}

export default AxiosSetup