import { RetailerData } from "../../types";
import productsData from "../../../../utils/data/products.json";
import { SimulationActionTypes } from "./eventSimulationAction";
import { EventSimulation } from "../../types";

export type EventSimulationState = {
  pending: boolean;
  fulfilled: boolean;
  rejected: boolean;
  eventSimulation: EventSimulation;
};

export type AsyncState = {
  pending: boolean;
  fulfilled: boolean;
  rejected: boolean;
  data: any;
};

const INITIAL_STATE = {
  pending: false,
  fulfilled: false,
  rejected: false,
  eventSimulation: null as null | EventSimulation,
  referenceCalendars: null as null | AsyncState,
};

function EventSimulationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_PENDING:
      return {
        ...state,
        pending: true,
        rejected: false,
        fulfilled: false,
        eventSimulation: null,
      };
    case SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_FULFILLED:
      return {
        ...state,
        pending: false,
        fulfilled: true,
        rejected: false,
        id: action.id,
        eventSimulation: action.data,
      };
    case SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_REJECTED:
      return {
        ...state,
        pending: false,
        fulfilled: false,
        rejected: true,
        eventSimulation: null,
      };
    case SimulationActionTypes.FETCH_ALL_REF_CALENDARS_PENDING:
      return {
        ...state,
        referenceCalendars: {
          pending: true,
          fulfilled: false,
          rejected: false,
          data: null,
        },
      };
    case SimulationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED:
      return {
        ...state,
        referenceCalendars: {
          pending: false,
          fulfilled: true,
          rejected: false,
          data: action.data,
        },
      };
    case SimulationActionTypes.FETCH_ALL_REF_CALENDARS_REJECTED:
      return {
        ...state,
        referenceCalendars: {
          pending: false,
          fulfilled: false,
          rejected: true,
          data: null,
        },
      };
    case SimulationActionTypes.REF_CALENDARS_RESET:
      return {
        ...state,
        referenceCalendars: {
          pending: false,
          fulfilled: false,
          rejected: false,
          data: null,
        },
      };
    default:
      return { ...state };
  }
  return state;
}

export default EventSimulationReducer;
