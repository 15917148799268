import React, { useState, useEffect } from "react";
import CalendarCard from "../calendarCard/CalendarCard";
import {
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
  Switch,
  Box,
  FormControl,
  Pagination,
  Stack,
} from "@mui/material";
import { useStyles } from "./AllCalendarStyle";
import CalendarManagementTimeline from "../calendarCollapseTable/CalendarManagementTimeline";
import CalendarManagementTimelineAgenda from "../calendarCollapseTable/CalendarCollapseTimelineAgenda";

import {
  CalendarCardType,
  Values,
  SubRowTypes,
  RowType,
  Calendar,
  FetchCalendarFiltersType,
} from "../../utils/types";
import { comparator } from "../../utils/mapper";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { deleteCalendarAction } from "../../utils/redux/calendar/calendarOptimizationActions";
import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import IconButton from "@mui/material/IconButton";
import theme from "utils/theme";
import ImportExport from "@mui/icons-material/ImportExport";

interface AllCalendarProps {
  calendarCardDetails: any[];
  handleCardClick: any;
  calendarTimelineData: RowType[];
  checkedCard: Function;
  listChecked: string[];
  page: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  calendarFilters: FetchCalendarFiltersType;
  setFilters: Function;
  setSortBy: Function;
  sortBy: string;
  setAscending: Function;
  ascending: boolean | null;
  setIsDeleted: Function;
  count?: number;
}

const FILTER_MENU_ITEMS = [
  // { label: "ROI", value: "roi" },
  { label: "Status", value: "status" },
  { label: "Created at", value: "created_on" },
  // { label: "Volume on deal", value: "volume_on_deal" },
  // { label: "Overall Promo depth", value: "overall_promo_depth" },
];

const AllCalendar = (props: AllCalendarProps) => {
  const {
    calendarCardDetails,
    handleCardClick,
    calendarTimelineData,
    checkedCard,
    calendarFilters,
    setFilters,
    setSortBy,
    sortBy,
    setAscending,
    ascending,
    setIsDeleted,
  } = props;
  const [hideDraft, setHideDraft] = useState(false);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [listChechedCard, setListChechedCard] = useState<string[]>([]);
  const [calendarCardStateData, setCalendarCardStateData] =
    React.useState<CalendarCardType[]>();
  const [isHovering, setHovering] = React.useState<[string, boolean]>([
    "",
    false,
  ]);

  const [selectedRetailer, setRetailer] = React.useState<string[]>([]);

  const [selectedQuarter, setSelectedQuarter] = React.useState<string[]>([]);

  const [selectedCreatedBy, setCreateBy] = React.useState<string[]>([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleRetailerChange = (
    event: SelectChangeEvent<typeof selectedRetailer>
  ) => {
    const {
      target: { value },
    } = event;
    setRetailer(typeof value === "string" ? value.split(",") : value);
    setFilters((prevFilter) => ({
      ...prevFilter,
      retailers: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleQuarterChange = (
    event: SelectChangeEvent<typeof selectedQuarter>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedQuarter(typeof value === "string" ? value.split(",") : value);
    setFilters((prevFilter) => ({
      ...prevFilter,
      quarters: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleCreateByChange = (
    event: SelectChangeEvent<typeof selectedCreatedBy>
  ) => {
    const {
      target: { value },
    } = event;
    setCreateBy(typeof value === "string" ? value.split(",") : value);
    setFilters((prevFilter) => ({
      ...prevFilter,
      created_by: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleSortBy = (sortByColumn: string) => {
    if (sortByColumn !== sortBy) {
      setAscending(true);
    } else {
      setAscending(ascending === null ? true : ascending ? false : null);
    }
    setSortBy(sortByColumn);
  };

  useEffect(() => {
    if (calendarCardDetails) {
      // default sort from backend by created date.
      setCalendarCardStateData(calendarCardDetails);
    }
  }, [calendarCardDetails]);

  const handleHideDraft = () => {
    setHideDraft(!hideDraft);
  };
  const handelSelectedCalendar = (list_calendarId: string[]) => {
    if (listChechedCard.includes(list_calendarId[0])) setListChechedCard([]);
    else setListChechedCard([...list_calendarId]);
  };
  const deleteCalendar = (calendarId: string) => {
    dispatch(deleteCalendarAction(calendarId)).then(() => {
      setIsDeleted(true);
    });
  };

  const renderManagementCard = () => {
    if (!calendarCardStateData) return <div>Loading...</div>;

    return calendarCardStateData.length ? (
      calendarCardStateData?.map((details, index) => {
        const isDraft = details["status"] === "DRAFT";
        if (isDraft && hideDraft) return null;
        if (listChechedCard.length > 0 && !listChechedCard.includes(details.id))
          return null;
        return (
          <Grid item mobile={12} key={index} className="mt-1">
            <CalendarCard
              calendarCardDetails={details}
              handleCardClick={(e) => {
                props.handleCardClick(e, details);
              }}
              deleteCalendar={deleteCalendar}
              checkedCard={(calendarId: string) => {
                checkedCard(calendarId);
              }}
              isChecked={props.listChecked.includes(details.id)}
            />
          </Grid>
        );
      })
    ) : (
      <Grid container justifyContent={"center"} my={4} mb={2}>
        <Typography sx={{ color: theme.palette.secondary.dark }}>
          No calendars found!
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root}>
      {/*<Grid item laptop_lg={12} laptop={12}>*/}
      {/*  <div className={classes.headerContainer}>*/}
      {/*    <div>*/}
      {/*      <Typography>All Calendars</Typography>*/}
      {/*    </div>*/}
      {/*    <div className={classes.switchDraft}>*/}
      {/*      <Switch size={"small"} onChange={handleHideDraft} />*/}
      {/*      <Typography fontSize={12} fontWeight="bold">*/}
      {/*        Hide Draft*/}
      {/*      </Typography>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Grid>*/}

      {/*<Grid item container justifyContent="center" mb={2}>*/}
      {/*  <Grid item laptop={2} laptop_lg={2}>*/}
      {/*    <CalendarManagementTimelineAgenda />*/}
      {/*  </Grid>*/}

      {/*  <Grid item laptop={8} laptop_lg={10}>*/}
      {/*    <CalendarManagementTimeline data={calendarTimelineData} hideDraft={hideDraft} handelSelectedCalendar={handelSelectedCalendar} />*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      <Grid item container justifyContent="space-between" mb={1} spacing={2}>
        <Grid
          item
          mobile={12}
          laptop_lg={12}
          laptop={12}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* <div style={{ float: "left" }}>
          <Typography fontSize={13} px={1} fontWeight="bold">
            Sort by
          </Typography>

          <FormControl size={"medium"}>
            <Select value={sortedByValue} displayEmpty={true} sx={{ height: 32, width: "auto" }} onChange={handleChange}>
              <MenuItem value="" disabled>
                <Typography fontSize={11}>Status</Typography>
              </MenuItem>
              {FILTER_MENU_ITEMS.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    <Typography fontSize={11}>{item.label}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div> */}

          <div>
            <Grid
              container
              onClick={() => handleSortBy("retailer")}
              onMouseEnter={() => setHovering(["retailer", true])}
              onMouseLeave={() => setHovering(["", false])}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              gap={0.5}
            >
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                Retailer
              </Typography>
              {isHovering[1] && isHovering[0] === "retailer" && (
                <ImportExport sx={{ fontSize: 14 }} />
              )}
              {sortBy === "retailer" &&
                isHovering[0] !== "retailer" &&
                (ascending === null ? null : ascending ? (
                  <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                ) : (
                  <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                ))}
            </Grid>

            <FormControl sx={{ width: 200 }}>
              <Select
                className={classes.selectItem}
                labelId="retailer-checkbox-label"
                id="retailer-multiple-checkbox"
                multiple
                value={selectedRetailer}
                onChange={handleRetailerChange}
                renderValue={(selected) => (
                  <Typography variant="body3">{selected.join(", ")}</Typography>
                )}
                MenuProps={MenuProps}
              >
                {calendarFilters.retailers.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedRetailer.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      disableTypography
                      sx={{ fontSize: 14 }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div>
            <Grid
              container
              onClick={() => handleSortBy("quarter")}
              onMouseEnter={() => setHovering(["quarter", true])}
              onMouseLeave={() => setHovering(["", false])}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              gap={0.5}
            >
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                Period
              </Typography>
              {isHovering[1] && isHovering[0] === "quarter" && (
                <ImportExport sx={{ fontSize: 14 }} />
              )}
              {sortBy === "quarter" &&
                isHovering[0] !== "quarter" &&
                (ascending === null ? null : ascending ? (
                  <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                ) : (
                  <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                ))}
            </Grid>
            <FormControl sx={{ width: 200 }}>
              <Select
                className={classes.selectItem}
                multiple
                value={selectedQuarter}
                onChange={handleQuarterChange}
                renderValue={(selected) => (
                  <Typography variant="body3">{selected.join(", ")}</Typography>
                )}
                MenuProps={MenuProps}
              >
                {calendarFilters.quarters.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedQuarter.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      disableTypography
                      sx={{ fontSize: 14 }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div>
            <Grid
              container
              onClick={() => handleSortBy("created_by")}
              onMouseEnter={() => setHovering(["created_by", true])}
              onMouseLeave={() => setHovering(["", false])}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              gap={0.5}
            >
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                Created By
              </Typography>
              {isHovering[1] && isHovering[0] === "created_by" && (
                <ImportExport sx={{ fontSize: 14 }} />
              )}
              {sortBy === "created_by" &&
                isHovering[0] !== "created_by" &&
                (ascending === null ? null : ascending ? (
                  <ArrowUpwardIcon sx={{ fontSize: 14 }} />
                ) : (
                  <ArrowDownwardIcon sx={{ fontSize: 14 }} />
                ))}
            </Grid>
            <FormControl sx={{ width: 200 }}>
              <Select
                className={classes.selectItem}
                multiple
                value={selectedCreatedBy}
                onChange={handleCreateByChange}
                renderValue={(selected) => (
                  <Typography variant="body3">{selected.join(", ")}</Typography>
                )}
                MenuProps={MenuProps}
              >
                {calendarFilters.created_by.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedCreatedBy.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      disableTypography
                      sx={{ fontSize: 14 }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Stack>
            <Grid height={32}></Grid>
            <FormControl>
              <IconButton
                onClick={() => handleSortBy("created")}
                onMouseEnter={() => setHovering(["created", true])}
                onMouseLeave={() => setHovering(["", false])}
                sx={{
                  border: "1px solid #e5e7eb",
                  borderRadius: "4px",
                  height: 32,
                }}
                size="small"
              >
                <Typography fontWeight={"bold"} align="center" fontSize={13}>
                  Sort by date
                </Typography>
                {sortBy === "created" ? (
                  ascending === null ? (
                    <ImportExport sx={{ fontSize: 14, ml: 0.5 }} />
                  ) : ascending ? (
                    <ArrowUpwardIcon sx={{ fontSize: 14, ml: 0.5 }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ fontSize: 14, ml: 0.5 }} />
                  )
                ) : (
                  <ImportExport sx={{ fontSize: 14, ml: 0.5 }} />
                )}
              </IconButton>
            </FormControl>
          </Stack>
        </Grid>
        {calendarCardStateData?.length ? (
          <Grid container p={1.5} pb={0}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              width={120}
              gap={1}
            >
              <Box
                component={"span"}
                width={15}
                height={15}
                bgcolor={theme.palette.warning.main}
                borderRadius={0.5}
              ></Box>
              <Typography>Not Ready</Typography>
            </Grid>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              width={120}
              gap={1}
            >
              <Box
                component={"span"}
                width={15}
                height={15}
                bgcolor={theme.palette.error.main}
                borderRadius={0.5}
              ></Box>
              <Typography>Failed</Typography>
            </Grid>
          </Grid>
        ) : null}
        {renderManagementCard()}
        <Grid container justifyContent={"center"} mt={2}>
          <Pagination
            count={props.count}
            page={props.page}
            onChange={props.handleChange}
            shape="rounded"
            size="large"
            showFirstButton
            showLastButton
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllCalendar;
