import { Box, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";

import TrashIcon from "../../img/trash_icon.svg";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../Promo/utils/mapper";

/**
 * Styled Material UI Box for the constraint card
 * TODO: pull out into a common const for all the constraint cards
 */
const BoxWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: "0.8rem",
});

const SelectCustom = ({ children, ...props }) => (
  <Select
    {...props}
    sx={{
      maxWidth: "340px",
      // minWidth: "140px",
      // width: "auto",
    }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }}
  >
    {children}
  </Select>
);

type ConstraintsCardSelectType = {
  select1Title: string;
  select1Options: any[];
  select2Title: string;
  select2Options: any[];
  select3Title: string;
  select3Options: any[];
  objectToSave: any;
  onDelete?: any;
  saveAll?: any;
  presetValue?: any;
};

/**
 * @desc Custom Constrint Card Selection with 2 single selects and a 3rd multi select
 *
 */
export function ConstraintsCardSelectMulti({
  select1Title,
  select1Options,
  select2Title,
  select2Options,
  select3Title,
  select3Options,
  objectToSave,
  onDelete = () => null,
  saveAll = () => null,
  presetValue,
}: ConstraintsCardSelectType) {
  const [formField, setFormField] = useState<any>(null);
  const [select1, setSelect1] = useState<any>("");
  const [select2, setSelect2] = useState<any>("");
  const [select3, setSelect3] = useState<any>("");

  /**
   * @desc Everytime there is a change in the multi select update the form values for the passed in form
   * TODO this function still depends on the knowledge of the outer compoonent aka the constraints form, will break anywhere else
   */
  // useEffect(() => {
  //   setFormField({
  //     names: `products.product-${select1}.${select2}`,
  //     rawValue: multiSelect,
  //   });
  // }, [select2]);

  useEffect(() => {
    if (presetValue) {
      if (presetValue.level === "PRODUCT") {
        setSelect1(presetValue.internal_code);
        setSelect2(presetValue.type);
        setSelect3(presetValue.EQUAL);
        objectToSave.value = presetValue.EQUAL;
        objectToSave.percentages = 0;
        objectToSave.constraint = [presetValue.type];
        objectToSave.productId = presetValue.internal_code;
        saveAll();
      }
    }
  }, [presetValue]);

  /**
   * @desc Handles updating the constraint save object everytime the multislect field is updated
   */
  function handleSelectInputChange(event: any) {
    setSelect3(event.target.value);
    objectToSave.value = event.target.value;
    objectToSave.percentages = 0;
    objectToSave.constraint = [select2];
    objectToSave.productId = select1;
    saveAll();
  }

  return (
    <InputCard
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "nowrap",
        gap: ".5rem",
      }}
    >
      <BoxWrapper>
        {/* First single select component */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ fontWeight: "600" }}>
            {select1Title}
          </Typography>
          <SelectCustom
            size="small"
            defaultValue=""
            value={select1}
            onChange={(e) => setSelect1(e.target.value)}
          >
            {select1Options.map((option, index) => {
              return (
                <MenuItem value={option.name}>
                  <Typography variant="body3">{option.name}</Typography>
                </MenuItem>
              );
            })}
          </SelectCustom>
        </Box>

        {/* Second single select component */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ fontWeight: "600" }}>
            {select2Title}
          </Typography>
          <SelectCustom
            size="small"
            defaultValue=""
            value={select2}
            onChange={(e) => setSelect2(e.target.value)}
          >
            {select2Options.map((option, index) => {
              return (
                <MenuItem value={option.name}>
                  <Typography variant="body3">{option.name}</Typography>
                </MenuItem>
              );
            })}
          </SelectCustom>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ fontWeight: "600" }}>
            {select3Title}
          </Typography>
          <SelectCustom
            size="small"
            defaultValue=""
            value={select3}
            onChange={(e) => handleSelectInputChange(e)}
          >
            {select3Options.map((option, index) => {
              return (
                <MenuItem value={option.name}>
                  <Typography variant="body3">{option.name}</Typography>
                </MenuItem>
              );
            })}
          </SelectCustom>
        </Box>

        {/* Mutli select component */}
        {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ fontWeight: "600" }}>
            {select3Title}
          </Typography>
          <Select
            // className={classes.selectItem}
            sx={{ minWidth: 130 }}
            size="small"
            multiple
            renderValue={(selected) => (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "170px", overflow: "hidden" }}>
                  <Typography align="left" fontSize={12}>
                    {selected[0]}
                  </Typography>
                </Box>
                <Typography align="left" fontSize={12}>
                  {selected.length >= 2 && <Box> +{selected.length - 1}</Box>}
                </Typography>
              </Box>
            )}
            value={multiSelect}
            onChange={(event) => handleMultiSelectInputChange(event)}
          >
            {multiSelectOptions.map((item, index) => {
              return (
                <MenuItem key={item.key} value={item.name}>
                  <Checkbox checked={multiSelect.indexOf(item.name) > -1} />
                  <Typography align="left" fontSize={12}>
                    {item.name}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Box> */}
      </BoxWrapper>

      {/* Deletion Component that uses passed in delete function */}
      <Box sx={{ marginLeft: "auto", margin: "0 1rem" }}>
        <img
          src={TrashIcon}
          alt="Delete constraint"
          onClick={() => onDelete()}
        />
      </Box>
    </InputCard>
  );
}
