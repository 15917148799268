import { EventParamsActionTypes } from "./eventParamsAction";

export type EventParamsStateType = {
  period?: [Date, Date];
  depth?: number;
  unit?: string;
  multiple?: boolean;
  display?: number;
  customer?: string;
  category?: string;
  product?: string;
  quantity?: number;
  brand?: string;
  //=====//
  tacticTypes?: string;
  singleBuyPick?: string;
  multiBuyPick1?: string;
  multiBuyPick2?: string;
  multiBuyPick3?: string;
  multibuyAmount?: number;
  everydayPrice?: number;
  singleBuyValue?: number;
  multibuyUnit?: number;
};

export const EVENT_PARAMS_INITIAL_STATE: EventParamsStateType = {
  period: [new Date(), new Date()],
  depth: 0,
  unit: "",
  multiple: false,
  display: 0,
  //====//
  tacticTypes: "Punto de Precio",
  singleBuyPick: "% off",
  multiBuyPick1: "Buy",
  multibuyAmount: 1,
  multiBuyPick3: "$",
  multiBuyPick2: "Save",
  everydayPrice: 10,
  singleBuyValue: 0,
  multibuyUnit: 0,
};

function EventParamsReducer(state = EVENT_PARAMS_INITIAL_STATE, action: any) {
  switch (action.type) {
    case EventParamsActionTypes.EVENT_UPDATE:
      return { ...state, ...action.data };
    default:
      return { ...state };
  }
  return state;
}

export default EventParamsReducer;
