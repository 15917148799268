import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";
import { StyledSlider } from "../inputSlider";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../Promo/utils/mapper";

type Constraint = {
  name: string | "";
};

type Mark = {
  label: string;
  value: number;
  rawValue: number;
};

type PropsType = {
  constraint?: Constraint | { name: "" };
  marks: Mark[];
  getValues: (values: number[], refValue: number) => any;
  initialValues?: number[];
};

export function ConstraintsCardRange({
  constraint,
  marks,
  getValues,
  initialValues = [],
}: PropsType) {
  const [values, setValues] = useState<number[]>([0, 100]);

  useEffect(() => {
    getValues(
      [(values[0] * 2 - 100) * -1, Math.floor((values[1] - 50) * 2)],
      marks && marks.length > 0 ? marks[0].rawValue : 10
    );
  }, []);

  useEffect(() => {
    if (initialValues.length >= 1) {
      setValues([
        ((initialValues[0] - 100) / 2) * -1,
        initialValues[1] / 2 + 50,
      ]);

      getValues(
        [((initialValues[0] - 100) / 2) * -1, initialValues[1] / 2 + 50],
        marks && marks.length > 0 ? marks[0].rawValue : 10
      );
    }
  }, []);

  function handleOnCommit(event) {
    getValues(
      [calcSliderValue("MIN", values), calcSliderValue("MAX", values)],
      marks && marks.length > 0 ? marks[0].rawValue : 10
    );
  }

  function calcSliderValue(type, values) {
    return type === "MIN"
      ? (values[0] * 2 - 100) * -1
      : Math.floor((values[1] - 50) * 2);
  }

  return (
    <InputCard>
      <Typography variant="h4">
        {(constraint && constraint.name) || ""}
      </Typography>
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"nowrap"}
        mt={2}
      >
        <Grid display={"flex"} direction={"column"}>
          <Typography variant="body2" mr={".8rem"} width={"80px"}>
            Min{" "}
            <label style={{ color: theme["palette"].error.main }}>
              -{calcSliderValue("MIN", values)}%
            </label>
          </Typography>
          <Typography variant="body3">
            {formatNumber(
              applyReferenceFormula(
                "MIN",
                calcSliderValue("MIN", values),
                marks[0].rawValue
              )
            )}
          </Typography>
        </Grid>
        <StyledSlider
          getAriaLabel={() => "Minimum distance"}
          // valueLabelDisplay="auto"
          value={values}
          step={0.5}
          marks={marks}
          //   getAriaValueText={valuetext}
          onChange={(e: any, v, activeThumb) => {
            let temp;
            if (activeThumb === 0) {
              if (e.target.value[0] <= 50) {
                temp = e.target.value[0];
              } else {
                temp = 50;
              }
              setValues([temp, values[1]]);
            } else if (activeThumb === 1) {
              if (e.target.value[1] >= 50) {
                temp = e.target.value[1];
              } else {
                temp = 50;
              }
              setValues([values[0], temp]);
            }
          }}
          onChangeCommitted={handleOnCommit}
          disableSwap
        />
        <Grid display={"flex"} direction={"column"} ml={"1rem"}>
          <Typography variant="body2" width={"90px"}>
            Max{" "}
            <label style={{ color: theme["palette"].success.main }}>
              +{calcSliderValue("MAX", values)}%
            </label>
          </Typography>
          <Typography variant="body3">
            {formatNumber(
              applyReferenceFormula(
                "MAX",
                calcSliderValue("MAX", values),
                marks[0].rawValue
              )
            )}
          </Typography>
        </Grid>
      </Grid>
    </InputCard>
  );
}
