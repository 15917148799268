import { makeStyles } from "@mui/styles";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { KPIChartColor } from "../../../../../../utils/theme";
import { ICalendarComparsionRowData } from "../interfaces";
import Activities from "./activities/Activities";

interface IProps {
  data: any[];
  timelines: number;
  timelineRange: any;
}

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    display: "flex",
    flexDirection: "column",
    gap: "1.75rem",
  },
  twoTimelines: {
    display: "flex",
    flexDirection: "column",
    gap: "3.5rem",
  },
  threeTimelines: {
    display: "flex",
    flexDirection: "column",
    gap: "3.5rem",
  },
}));

function PlotArea({ data, timelines, timelineRange }: IProps) {
  const classes = useStyles();
  const [product, setProduct] = useState<any>([]);
  const [startTime, setStartTime] = useState(new Date().toISOString());

  useEffect(() => {
    if (data.length) {
      let arr: any = [];
      let tarr: any = [];
      data.map((subbrand) => {
        if (subbrand.timeline.data && subbrand.timeline.data.data) {
          for (let i = 0; i < subbrand.timeline.data.data.length; i++) {
            const element = subbrand.timeline.data.data[i];
            arr.push(element);
          }
        }
      });
      setProduct(arr);
      setStartTime(addDays(timelineRange.start, 0).toISOString());
    }
  }, [data]);

  return (
    <div className="h-full bg-white mt-8">
      <div className="flex flex-col gap-y-7 pt-8 pb-4">
        <div
          className={
            timelines == 3
              ? classes.threeTimelines
              : timelines == 2
              ? classes.twoTimelines
              : timelines == 1
              ? classes.oneTimeline
              : ""
          }
        >
          <>
            {product.length &&
              product.map((timelineRow) => (
                <div>
                  {timelineRow.map((map1) =>
                    map1.data.map((event) => (
                      <div
                        style={{
                          background:
                            event[0].type == "baseline"
                              ? KPIChartColor.baseLineSecondary
                              : event[0].type == "constantMarginMarketShare"
                              ? KPIChartColor.inscreasedMarkSecondary
                              : event[0].type == "increasedMarketShare"
                              ? KPIChartColor.maxMarginSecondary
                              : "",
                        }}
                        className={`relative h-10`}
                      >
                        <hr className="h-[1px] w-full border-dashed border-0 border-b border-b-stone-300 absolute top-0 bottom-0 my-auto" />
                        <div className="flex pt-1 px-7 relative">
                          {event.map((map2) => {
                            return map2.events.map((activity, i) =>
                              activity.map((a1) => (
                                <Activities
                                  variant={map2.type}
                                  activity={a1}
                                  startTime={startTime}
                                />
                              ))
                            );
                          })}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ))}
          </>
        </div>
      </div>
    </div>
  );
}

export default PlotArea;
