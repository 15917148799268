import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1),
      backgroundColor: theme.palette.primary.background,
    },
    headerContainer: {
      padding: theme.spacing(0, 0, 2, 0),
      display: "flex",
      justifyContent: "space-between",
    },
    buttonsStyling: {
      display: "flex",
      justifyContent: "space-arround",
    },
    dropDownCreateCalendarMenu: {
      backgroundColor: theme.palette.primary.main,
      background: "container",
      padding: theme.spacing(1, 1, 1, 1),
      marginTop: 8,
      borderRadius: 6,
    },
    listItem: {
      backgroundColor: "white",
      marginTop: 10,
      borderRadius: 4,
    },
  })
);
