import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const constraintsTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    constraintViewTable: {
      padding: "15px",
      overflowX: "auto",
    },
  })
);
