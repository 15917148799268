import { ClientData, CondensedClientData } from "../../types";
import { GlobalActionTypes } from "./GlobalActions";
import RETAILE_MOCK_DATA from "../../data/data.json";

const initialState = {
  drawerOpen: false,
  clientData: null as null | ClientData,
  condensedClientData: null as null | CondensedClientData,
  user: null as null | any,
};

function GlobalReducer(state = initialState, action: any) {
  switch (action.type) {
    case GlobalActionTypes.OPENDRAWER:
      return {
        ...state,
        drawerOpen: true,
      };

    case GlobalActionTypes.CLOSEDRAWER:
      return {
        ...state,
        drawerOpen: false,
      };
    case GlobalActionTypes.INVALIDATE_USER:
      return {
        ...state,
        user: null,
      };
    case GlobalActionTypes.FETCH_USER_CALLBACK_SUCESS:
      return {
        ...state,
        user: action.payload,
      };
    case GlobalActionTypes.FETCH_USER_SUCESS:
      return {
        ...state,
        user: action.payload,
      };
    case GlobalActionTypes.FETCH_RETAIL_DATA_SUCCESS:
      return {
        ...state,
        clientData: action.payload.clientData,
        condensedClientData: action.payload.condensedClientData,
      };
    default:
      return state;
  }
}

export default GlobalReducer;
