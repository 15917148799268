export enum SimulationActionTypes {
  TIMELINE_UPDATE = "TIMELINE_UPDATE",
}

export function updateTimeline(data: any) {
  return async function (dispatch: any, getState: any) {
    dispatch({
      type: SimulationActionTypes.TIMELINE_UPDATE,
      data: data,
    });
  };
}