import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  UseFormRegister,
  UseFormWatch,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import {
  CalendarOptimizationActionTypes,
  fetchRefCalendar,
  fetchAllRefCalendars,
  fetchGeneratedRefCal,
} from "../../../../utils/redux/calendar/calendarOptimizationActions";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import LoopIcon from "@mui/icons-material/Loop";
import { CalendarCreationModel } from "../../../../utils/interfaces";

import "../styles.scss";
import { LoadingDialog } from "Promo/components/loadingDialog/LoadingDialog";

type CalendarDetailsFormProps = {
  errors: any;
  register: UseFormRegister<CalendarCreationModel>;
  getValues: UseFormGetValues<CalendarCreationModel>;
  setValue: UseFormSetValue<CalendarCreationModel>;
  watch: UseFormWatch<CalendarCreationModel>;
  refCalendars: any;
  selectedMarket: string;
};

export function CalendarDetailsForm(props: CalendarDetailsFormProps) {
  const {
    errors,
    register,
    getValues,
    setValue,
    watch,
    refCalendars,
    selectedMarket,
  } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [selectedRefCal, setSelectedRefCal] = useState("");
  const [genCalActive, setGenCalActive] = useState(false);
  const [isScopeFormEmpty, setIsScopeFormEmpty] = useState(false);
  const [refCalendarsOptions, setRefCalendarsOptions] = useState<any[]>();
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);

  // const refCalendars = useAppSelector((state) => state.reducer.calendarOptimizationReducer.allRefCalendarsData);
  const referenceCalendar = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.selectedRefCalData
  );

  useEffect(() => {
    if (refCalendars.data) {
      setRefCalendarsOptions(Object.values(refCalendars.data));
    }
  }, [refCalendars]);

  useEffect(() => {
    if (referenceCalendar.data) {
      setValue("referenceCalendar", referenceCalendar.data.calendar_id);
    }
    setOpen(referenceCalendar.pending);
  }, [referenceCalendar]);

  function handleSelectedRefCalendar(id) {
    if (id === "") {
      setValue("referenceCalendar", "");
      return;
    }
    setFetching(true);
    setSelectedRefCal(id);
    setGenCalActive(false);
    dispatch(fetchRefCalendar(id));
  }

  function handleGenerateCalendar(value) {
    if (referenceCalendar.pending) {
      return;
    }

    if (genCalActive) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_CLEAR,
      });
      setGenCalActive(false);
      return;
    }

    const quarter = getValues("quarter");
    const retailer = getValues("customer");

    if (!quarter || !retailer) {
      setIsScopeFormEmpty(true);
      return;
    }
    setFetching(false);
    setGenCalActive(true);
    setSelectedRefCal("");
    setIsScopeFormEmpty(false);
    dispatch(
      fetchGeneratedRefCal(quarter || "", retailer || "", selectedMarket)
    );
  }

  const quarter = getValues("quarter");
  const retailer = getValues("customer");

  useEffect(() => {
    if (retailer && retailer !== "0") {
      dispatch(fetchAllRefCalendars(quarter, retailer, selectedMarket));
    }
  }, [retailer, quarter]);

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Typography variant="h3" mt={1} mb={0}>
        Calendar details
      </Typography>
      <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Calendar name*</label>
        </Grid>
        <Grid>
          <Input
            name="name"
            className="input input_large"
            size="small"
            {...register("name")}
            sx={{ fontSize: 14 }}
          />
        </Grid>
        <Grid>
          <div>{errors?.name?.message}</div>
        </Grid>
      </Grid>
      {!refCalendarsOptions ? (
        <></>
      ) : (
        <>
          <Grid container direction="row">
            <Grid sx={{ fontWeight: "bold" }}>
              <label>Reference Calendar*</label>
            </Grid>
            {!referenceCalendar.fulfilled && (
              <Grid display={"flex"}>
                <Select
                  defaultValue={""}
                  value={selectedRefCal}
                  className="input input_large select_input"
                  size="small"
                  renderValue={(selected) => (
                    <Typography
                      variant="body3"
                      sx={{ fontWeight: "400 !important" }}
                    >
                      {selected === ""
                        ? "None"
                        : refCalendarsOptions.find(
                            (select) => select.id === selected
                          )?.name}
                    </Typography>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 400,
                      },
                    },
                  }}
                  {...register("referenceCalendar", {
                    onChange: (e) => {
                      handleSelectedRefCalendar(e.target.value);
                    },
                  })}
                >
                  <MenuItem value={""}>
                    {" "}
                    <Typography align="left" fontSize={14}>
                      None
                    </Typography>
                  </MenuItem>
                  {refCalendarsOptions &&
                    refCalendarsOptions.map((item, i) => (
                      <MenuItem key={i} value={item.id}>
                        <Typography align="left" fontSize={14}>
                          {item.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
                {refCalendars.pending && (
                  <CircularProgress sx={{ ml: 0.5 }} size={30} />
                )}
              </Grid>
            )}
            <Grid>
              <div>{errors?.referenceCalendar?.message}</div>
            </Grid>
          </Grid>
          {!referenceCalendar.fulfilled && (
            <Typography sx={{ margin: "0", padding: "0" }} variant="body3">
              Or
            </Typography>
          )}
          <Grid>
            <Button
              className="gradient"
              size="small"
              sx={{
                border: "1px solid black",
                borderRadius: "10px",
                padding: ".5rem 1rem",
                textAlign: "left",
                background: referenceCalendar.rejected
                  ? theme.palette.primary.badChange
                  : "inherit",
              }}
              onClick={handleGenerateCalendar}
            >
              {referenceCalendar.rejected && (
                <>
                  <NoteAddIcon
                    style={{
                      height: "2rem",
                      marginRight: ".5rem",
                    }}
                  />
                  {"Calendar generation failed, please try again."}
                </>
              )}
              {!referenceCalendar.rejected &&
                !referenceCalendar.fulfilled &&
                !referenceCalendar.pending && (
                  <>
                    <NoteAddIcon
                      style={{ height: "2rem", marginRight: ".5rem" }}
                    />
                    {"Add reference calendar from last year"}
                  </>
                )}
              {referenceCalendar.fulfilled && (
                <>
                  <NoteAddIcon
                    style={{ height: "2rem", marginRight: ".5rem" }}
                  />
                  {"Using generated Calendar"}
                </>
              )}
              {referenceCalendar.pending && (
                <>
                  <NoteAddIcon
                    style={{ height: "2rem", marginRight: ".5rem" }}
                  />
                  {"Loading"}
                </>
              )}
            </Button>
          </Grid>
        </>
      )}
      {isScopeFormEmpty && (
        <Typography
          variant="body3"
          sx={{ color: theme.palette.primary.badChange }}
        >
          {
            "Can't add a reference calendar. You need to fill the scope form first."
          }
        </Typography>
      )}

      <LoadingDialog
        id="1ld"
        onClose={() => setOpen(!open)}
        open={open}
        dialogueFlag={fetching}
      />
    </Grid>
  );
}
