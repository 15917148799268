import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Tooltip,
  Grid,
} from "@mui/material";
import { typeKPIs } from "../../utils/types";
import { formatNumber } from "../../utils/mapper";
import theme from "../../../utils/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { keyKpiStyles } from "./KeyKpistyles";

type KeyKpiProps = {
  title: string;
  KPIs: typeKPIs[];
};
const reverseKpiColorList = ["Promo Spend", "$ Per Case"];
const updatePercentageColor = (
  isReverse: boolean,
  updatePercentage: number
) => {
  if (isReverse) {
    return updatePercentage >= 0
      ? theme.palette.error.main
      : theme.palette.success.main;
  } else {
    return updatePercentage >= 0
      ? theme.palette.success.main
      : theme.palette.error.main;
  }
};

const KeyKpi = (props: KeyKpiProps) => {
  const classes = keyKpiStyles();
  const { KPIs, title } = props;

  const Kpi = (kpi: typeKPIs, index: number, secondary: boolean) => {
    return (
      <Tooltip
        title={
          <Typography
            fontSize={14}
            color={theme.palette.primary.contrastText}
            lineHeight={1.8}
          >
            {kpi.definition}
          </Typography>
        }
        placement="top"
        arrow
      >
        <Card key={index} variant="outlined" className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              sx={{ fontSize: secondary ? 14 : 16 }}
              color="text.secondary"
              gutterBottom
              variant={secondary ? "h7" : "h6"}
              textAlign={"center"}
            >
              {kpi.name}
            </Typography>
            <Grid container gap={2} justifyContent={"space-evenly"}>
              <Grid display={"flex"} alignItems={"center"} direction={"column"}>
                <Typography
                  variant={secondary ? "h7" : "h6"}
                  fontWeight={400}
                  mr={1}
                >
                  Optimized calendar
                </Typography>
                <Box display={"flex"}>
                  <Typography
                    variant={secondary ? "h2" : "h1"}
                    component="div"
                    sx={{
                      color:
                        kpi.value >= 0
                          ? "#161616"
                          : reverseKpiColorList.includes(kpi.name)
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {kpi.value < 0 && "-"}
                    {kpi.symbol === "$" ? kpi.symbol : ""}
                    {formatNumber(Math.abs(kpi.value), 2, kpi.symbol, false)}
                    {kpi.symbol === "$" ? "" : kpi.symbol}
                  </Typography>
                  {kpi.updatePercentage != null &&
                    Math.abs(kpi.updatePercentage) > 0 && (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        mt={secondary ? 1.35 : 1.8}
                        ml={1}
                      >
                        <Typography
                          variant={secondary ? "h7" : "h6"}
                          component="div"
                          sx={{
                            color: updatePercentageColor(
                              reverseKpiColorList.includes(kpi.name),
                              kpi.updatePercentage
                            ),
                          }}
                        >
                          {formatNumber(
                            kpi.updatePercentage,
                            undefined,
                            undefined,
                            false
                          )}
                          %
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Grid>
              <Grid display={"flex"} alignItems={"center"} direction={"column"}>
                <Typography
                  variant={secondary ? "h7" : "h6"}
                  fontWeight={400}
                  mr={1}
                >
                  Reference calendar
                </Typography>
                {kpi.fromValue && (
                  <Typography
                    variant={secondary ? "h2" : "h1"}
                    component="div"
                    sx={{
                      color:
                        kpi.fromValue >= 0
                          ? "#161616"
                          : reverseKpiColorList.includes(kpi.name)
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {kpi.fromValue < 0 && "-"}
                    {kpi.symbol === "$" ? kpi.symbol : ""}
                    {formatNumber(
                      Math.abs(kpi.fromValue),
                      2,
                      kpi.symbol,
                      false
                    )}
                    {kpi.symbol === "$" ? "" : kpi.symbol}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary
          style={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid container mobile>
              <Typography variant="h3" mr={1}>
                {title}
              </Typography>
              <Tooltip
                title={
                  <Typography
                    fontSize={14}
                    color={theme.palette.primary.contrastText}
                    lineHeight={1.8}
                  >
                    Incrementals are calculated against selected reference
                    calendar
                  </Typography>
                }
                placement="top"
                arrow
              >
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography variant="body3" lineHeight={1.9}>
                    (vs. reference calendar)
                  </Typography>
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      color: "text.secondary",
                      fontSize: 14,
                      mt: 0.3,
                      ml: 0.5,
                    }}
                  />
                </Grid>
              </Tooltip>
            </Grid>
            <Box component={"div"} display={"flex"} alignItems={"center"}>
              <InfoIcon fontSize="small" sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={14}
                fontWeight={500}
                fontStyle={"italic"}
                color={"text.secondary"}
              >
                Numbers include pull forward and cannibalization results
              </Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            {KPIs.slice(0, 3).map((kpi: typeKPIs, index: number) =>
              Kpi(kpi, index, false)
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {KPIs.slice(3).map((kpi: typeKPIs, index: number) =>
              Kpi(kpi, index, true)
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default KeyKpi;
