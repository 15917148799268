import * as s from "../ContraintsStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConstraintsCardRange } from "../../../../../components/constraintsCardRange/ConstraintsCardRange";
import { ConstraintsCardSlider } from "../../../../../components/constraintsCardSlider/ConstraintsCardSlider";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../utils/hooks";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../../../utils/mapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConstraintsCardSelect } from "../../../../../components/constraintsCardSelect/ConstraintsCardSelect";
import { useTheme } from "@mui/styles";
import { CalendarCreationFormActionTypes } from "../../../../../utils/redux/calendarCreationForm/calendarCreationFormActions";
import {
  promomixBrandsConstraints,
  promomixCalendarConstraints,
} from "../../../../../utils/constants";

type brandsObjType = {
  id: string;
  level: string;
  name: string;
  type: string;
  value: number;
};

type usedStaticConstraintsType = {
  id: Number;
  name: string;
};

type usedBrandsConstraintsType = {
  id: Number;
  brand_id: Number;
  name: string;
};

const emptyHint = [{ value: 50, label: "" }];

export function PromoMix({ errors, register, setValue, watch, brands }: any) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [presetCommonConstraint, setPresetCommonConstraint] = useState<any[]>(
    []
  );
  const [brandsRefValues, setBrandsRefValues] = useState<any>();
  const [calendarLevelList, setCalendarLevelList] = useState<any[]>([]);
  const [brandLevelList, setBrandLevelList] = useState<any[]>([]);
  const [staticConstraints, setStaticConstraints] = useState(
    promomixCalendarConstraints
  );
  const [usedStaticConstraints, setUsedStaticConstraints] = useState<
    usedStaticConstraintsType[]
  >([]);
  const [brandsConstraints, setBrandsConstraints] = useState(
    promomixBrandsConstraints
  );
  const [usedBrandsConstraints, setUsedBrandsConstraints] = useState<
    usedBrandsConstraintsType[]
  >([]);

  const referenceCalendar = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.selectedRefCalData
  );

  const { constraintsCommons } = useAppSelector((state) => {
    return {
      constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
    };
  });

  useEffect(() => {
    setCalendarLevelList([]);
    setBrandLevelList([]);
    setUsedStaticConstraints([]);
    setUsedBrandsConstraints([]);
    setStaticConstraints(promomixCalendarConstraints);
    setBrandsConstraints(promomixBrandsConstraints);

    const calendarLevelCnstr = constraintsCommons
      .filter(
        (x) =>
          x.name.includes("per_product") ||
          x.name.includes("per_subbrand") ||
          x.name.includes("per_visibility_level")
      )
      .filter((x) => x.PERCENT_MIN !== undefined);

    if (calendarLevelCnstr.length >= 1) {
      setCalendarLevelList([]);
      setUsedStaticConstraints([]);
      setStaticConstraints(promomixCalendarConstraints);

      calendarLevelCnstr.forEach((x) => {
        addConstraint(
          "calendar",
          promomixCalendarConstraints[
            promomixCalendarConstraints.findIndex(
              (constant) => constant.presetKey === x.name
            )
          ]
        );
      });
      setPresetCommonConstraint(calendarLevelCnstr);
    }
  }, [constraintsCommons]);

  useEffect(() => {
    if (referenceCalendar.data) {
      let promo_per_product = referenceCalendar.data?.promos_per_product;
      let promo_per_brand =
        referenceCalendar.data?.promos_per_brand[
          Object.keys(referenceCalendar.data.promos_per_brand)[0]
        ] | 0;
      let promo_per_viz = referenceCalendar.data?.promos_per_viz;

      staticConstraints[0].marks[0].label =
        "Ref " + formatNumber(promo_per_product);
      staticConstraints[0].marks[0].rawValue = promo_per_product;
      staticConstraints[1].marks[0].label =
        "Ref " + formatNumber(promo_per_brand);
      staticConstraints[1].marks[0].rawValue = promo_per_brand;
      staticConstraints[2].marks[0].label =
        "Ref " + formatNumber(promo_per_viz);
      staticConstraints[2].marks[0].rawValue = promo_per_viz;
      staticConstraints[0].refVal = promo_per_product;
      staticConstraints[1].refVal = promo_per_brand;
      staticConstraints[2].refVal = promo_per_viz;

      let tempBrandReferences: {
        promos_per_brand: any;
      } = { promos_per_brand: [] };

      if (Object.keys(referenceCalendar.data.promos_per_brand).length > 0) {
        Object.keys(referenceCalendar.data.promos_per_brand).forEach((key) => {
          tempBrandReferences.promos_per_brand.push({
            name: key,
            value: referenceCalendar.data?.promos_per_brand[key],
          });
        });
      }

      setBrandsRefValues(tempBrandReferences);
      setStaticConstraints(staticConstraints);
    }
  }, [referenceCalendar.data]);

  // useEffect(() => {
  //   let temp: any = [];
  //   brands.forEach((brand) => {
  //     temp.push({
  //       id: brand.id,
  //       level: "brand",
  //       name: brand.internal_code,
  //       type: "Min",
  //       value: 50,
  //     });
  //   });
  //   setBrandsObj(temp);
  // }, [brands, setBrandsObj]);

  function generateFormBody() {
    dispatch({
      type: CalendarCreationFormActionTypes.CLEAR_PROMO_MIX_CONSTRAINTS,
    });

    const requestBody: {
      calendars: any[];
      brands: any[];
    } = {
      calendars: [],
      brands: [],
    };

    if (calendarLevelList.length > 0) {
      calendarLevelList.forEach((calendar) => {
        calendar.constraint.forEach((name, index) => {
          requestBody.calendars.push({
            name: name,
            value: calendar.value[index],
            percentages: calendar.percentages
              ? calendar.percentages[index]
              : 100,
          });
        });
      });
    }

    if (brandLevelList.length > 0) {
      brandLevelList.forEach((brand) => {
        brand.constraint.forEach((name, index) => {
          requestBody.brands.push({
            name: `brands.brand-${brand.brandId}.${name}`,
            value: brand.value[index],
            percentages: brand.percentages[index],
          });
        });
      });
    }

    dispatch({
      type: CalendarCreationFormActionTypes.SEND_PROMO_MIX_CONSTRAINTS,
      payload: requestBody,
    });

    return requestBody;
  }

  function addConstraint(type, selectedConstraint?) {
    const id = Math.floor(Math.random() * 10032142);

    if (type === "calendar") {
      const availableConstraints = staticConstraints.filter(
        (constraint) => constraint.isActive === false
      );
      if (
        availableConstraints.length > 0 &&
        calendarLevelList.length < staticConstraints.length
      ) {
        if (selectedConstraint) {
          setCalendarLevelList((state) => [
            ...state,
            {
              id,
              value: [0],
              constraint: selectedConstraint.constraints_names,
              presetKey: selectedConstraint.presetKey,
            },
          ]);
        } else {
          setCalendarLevelList((state) => [
            ...state,
            { id, value: [0], constraint: [] },
          ]);
        }
      }
    }

    if (type === "brand") {
      if (brandLevelList.length < brands.length * brandsConstraints.length) {
        setBrandLevelList((state) => [
          ...state,
          { id, value: [0], constraint: [], brandId: 0 },
        ]);
      }
    }
  }

  function deleteFromList(type, object) {
    if (type === "calendar") {
      calendarLevelList.splice(
        calendarLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedStaticConstraints.splice(
        usedStaticConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setCalendarLevelList([...calendarLevelList]);
      setUsedStaticConstraints([...usedStaticConstraints]);
    }
    if (type === "brand") {
      brandLevelList.splice(
        brandLevelList.findIndex((x) => x.id === object.id),
        1
      );
      usedBrandsConstraints.splice(
        usedBrandsConstraints.findIndex((x) => x.id === object.id),
        1
      );
      setBrandLevelList([...brandLevelList]);
      setUsedBrandsConstraints([...usedBrandsConstraints]);
    }
    generateFormBody();
  }

  return (
    <Grid container direction="column" gap={2}>
      <Accordion
        expanded={true}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: "100%", padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Calendar level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {calendarLevelList &&
            calendarLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                presetValue={
                  presetCommonConstraint[
                    presetCommonConstraint.findIndex(
                      (x) => x.name === constraint.presetKey
                    )
                  ] || null
                }
                type="calendar"
                constraints={staticConstraints}
                objectToSave={constraint}
                setValue={setValue}
                usedConstraints={usedStaticConstraints}
                UpdateUsedConstraints={setUsedStaticConstraints}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("calendar", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.goodChange,
            }}
            onClick={() => addConstraint("calendar")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={true}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Brand level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {brandLevelList &&
            brandLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                type="brand"
                constraints={brandsConstraints}
                brands={brands}
                brandsRefValues={brandsRefValues}
                setValue={setValue}
                objectToSave={constraint}
                usedConstraints={usedBrandsConstraints}
                UpdateUsedConstraints={setUsedBrandsConstraints}
                saveAll={generateFormBody}
                onDelete={() => deleteFromList("brand", constraint)}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.goodChange,
            }}
            onClick={() => addConstraint("brand")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
