import {
  CalendarData,
  ProductKPI,
  PromoEvent,
} from "../../types";

export enum AnalysisActionTypes {
  FETCH_CALENDAR = "FETCH_CALENDAR",
  FETCH_CALENDAR_PENDING = "FETCH_CALENDAR_PENDING",
  FETCH_CALENDAR_FULFILLED = "FETCH_CALENDAR_FULFILLED",
  FETCH_CALENDAR_REJECTED = "FETCH_CALENDAR_REJECTED",

  APPLY_FILTERS = "APPLY_FILTERS",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  SELECT_PROMO_EVENT = "SELECT_PROMO_EVENT",
  CLEAR_PROMO_EVENT_SELECTION = "CLEAR_PROMO_EVENT_SELECTION",
}

/************************* Action Interfaces ******************************/

export interface FetchCalendarActionType {
  type: AnalysisActionTypes.FETCH_CALENDAR;
  id: number;
}
export interface FetchCalendarPendingActionType {
  type: AnalysisActionTypes.FETCH_CALENDAR_PENDING;
}
export interface FetchCalendarFulfilledActionType {
  type: AnalysisActionTypes.FETCH_CALENDAR_FULFILLED;
  data: CalendarData;
}
export interface FetchCalendarRejectedActionType {
  type: AnalysisActionTypes.FETCH_CALENDAR_REJECTED;
}

export interface ApplyFiltersActionType {
  type: AnalysisActionTypes.APPLY_FILTERS;
  promoEvents: PromoEvent[];
  productKPIS: ProductKPI[];
}

export interface ClearFiltersActionType {
  type: AnalysisActionTypes.CLEAR_FILTERS;
}
export interface SelectPromoEventActionType {
  type: AnalysisActionTypes.SELECT_PROMO_EVENT;
  promoEvent: PromoEvent;
}
export interface ClearPromoEventSelectionActionType {
  type: AnalysisActionTypes.CLEAR_PROMO_EVENT_SELECTION;
}

/************************* Action Constructors ******************************/

export const FetchCalendarAction = (id: number): FetchCalendarActionType => ({
  type: AnalysisActionTypes.FETCH_CALENDAR,
  id,
});
export const FetchCalendarPendingAction =
  (): FetchCalendarPendingActionType => ({
    type: AnalysisActionTypes.FETCH_CALENDAR_PENDING,
  });
export const FetchCalendarFulfilledAction = (
  data: CalendarData
): FetchCalendarFulfilledActionType => ({
  type: AnalysisActionTypes.FETCH_CALENDAR_FULFILLED,
  data,
});
export const FetchCalendarRejectedAction =
  (): FetchCalendarRejectedActionType => ({
    type: AnalysisActionTypes.FETCH_CALENDAR_REJECTED,
  });

export const ApplyFiltersAction = (
  promoEvents: PromoEvent[],
  productKPIS: ProductKPI[]
): ApplyFiltersActionType => ({
  type: AnalysisActionTypes.APPLY_FILTERS,
  promoEvents,
  productKPIS,
});

export const ClearFiltersAction = (): ClearFiltersActionType => ({
  type: AnalysisActionTypes.CLEAR_FILTERS,
});

export const SelectPromoEventAction = (
  promoEvent: PromoEvent
): SelectPromoEventActionType => ({
  type: AnalysisActionTypes.SELECT_PROMO_EVENT,
  promoEvent,
});
export const ClearPromoEventSelectionAction =
  (): ClearPromoEventSelectionActionType => ({
    type: AnalysisActionTypes.CLEAR_PROMO_EVENT_SELECTION,
  });

/************************* Type Composition ******************************/

export type AnalysisAction =
  | FetchCalendarActionType
  | FetchCalendarPendingActionType
  | FetchCalendarFulfilledActionType
  | FetchCalendarRejectedActionType
  | ApplyFiltersActionType
  | ClearFiltersActionType
  | SelectPromoEventActionType
  | ClearPromoEventSelectionActionType;
