import { green, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// @ts-ignore
import palette from "./palette.scss";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: palette.light,
      main: palette.main,
      background: palette.background,
      background_2: palette.background_2,
      text: palette.text,
      contrastText: palette.contrastText,
      goodChange: palette.goodChange,
      badChange: palette.badChange,
      accent: palette.accent,
      dark: palette.dark,
      contrast: palette.contrast,
    },
    secondary: {
      main: "#161616",
      contrastText: "#FFFFFF",
      light: "#E0E0E0",
      dark: palette.darkgrey,
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[400],
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      laptop_lg: 1200,
    },
  },
  typography: {
    allVariants: {
      color: "#2A2C31",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "3.75rem", //60px
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "2.813rem",
      color: "#161616",
    },
    h1: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "2rem", //32px
      fontWeight: 700,
      lineHeight: "2.5rem",
    },
    h2: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "1.5rem", // 24px
      fontWeight: 600,
      lineHeight: "2rem",
    },
    h3: {
      fontFamily: "Open Sans",
      fontSize: "1.25rem", // 20px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.75rem",
    },
    h4: {
      fontFamily: "Open Sans",
      fontSize: "1.125rem", // 18px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
    h5: {
      fontFamily: "Open Sans",
      fontSize: "1rem", // 16px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
    h6: {
      fontFamily: "Open Sans",
      fontSize: "0.875rem", // 14px
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.25rem",
    },
    h7: {
      fontFamily: "Open Sans",
      fontSize: "0.75rem", // 12px
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1rem",
    },
    h8: {
      fontFamily: "Open Sans",
      fontSize: "0.75rem", // 12px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1rem",
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: "1.125rem", // 18px
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "2rem",
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: "1rem", // 16px
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    body3: {
      fontFamily: "Open Sans",
      fontSize: ".875rem", // 14px
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.25rem",
    },
    button: {
      fontFamily: "Open Sans",
      fontSize: "1rem", // 16px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.375rem",
      color: "#FFFFFF",
      textTransform: "none",
    },
    value1: {
      fontFamily: "Open Sans",
      fontSize: "2.5rem", // 40px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "3.375rem",
      color: "text",
    },
    value2: {
      fontFamily: "Open Sans",
      fontSize: "1.75rem", // 40px
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "2.375rem",
      color: "text",
    },
  },
});

export const chartTheme = {
  waterfallChart: {
    baseline: "#CCD2D0",
    margin: "#8D8D8D",
    uplift: "#29BA74",
    discount: "#F71735",
    cannibalization: "#FF3C38",
    pullForward: "#E15A97",
  },
  scatterChart: {
    salesDilutive: "#E71C57",
    expensive: "#D4DF33",
    effective: "#5FA892",
    selfFunding: "#3B775B",
    unselected: "gray",
  },
  heatmapColors: ["#E71C57", "#FFF4BA", "#3A765A"],
};

export const KPIChartColor = {
  baseline: "rgba(204, 210, 208, 0.8)",
  maxMArgin: "rgba(197, 170, 83, 0.8)",
  inscreasedMark: "rgba(132, 220, 245, 0.8)",

  baseLineSecondary: "rgba(204, 210, 208, 0.3)",
  maxMarginSecondary: "rgba(197, 170, 83, 0.3)",
  inscreasedMarkSecondary: "rgba(132, 220, 245, 0.3)",
};

export const VolumeFlowTableTheme = {
  box: "#525252",
  boxBackground: "#ECECF1",
  background: "#FFFFFF",
  darkOrange: "#ff9800",
  darkViolet: "#6E5E82",
  lightViolet: "#A18AB8",
  darkGreen: "#4fab8d",
  lightGreen: "#74FACF",
  darkGrey: "#424242",
  lightGrey: "#bdbdbd",
};

export const KPICardTheme = {
  valuePositiveColor: "#65DA2E",
  valueNegativeColor: "#EB3A3A",
};

export default theme;
