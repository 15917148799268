import {
  eachDayOfInterval,
  eachWeekOfInterval,
  isBefore,
  max,
  min,
  parseISO,
  startOfDay,
} from "date-fns";

export const calcActivityWidth = (
  startTime: string,
  start: string,
  end: string,
  prevActivityEnd?: string
) => {
  const parsedStartDate = parseISO(start);
  const parsedEndDate = parseISO(end);
  const timelineStart = startOfDay(parseISO(startTime));

  const minutesBetweenStartTimeAndEndTime = eachDayOfInterval({
    start: parsedStartDate,
    end: parsedEndDate,
  });

  const width = minutesBetweenStartTimeAndEndTime.length * 1;
  let startPoint = 0;

  if (prevActivityEnd) {
    const parsedPreviousEndDate = parseISO(prevActivityEnd);

    const minutesBetweenStartTimeAndPreviousEndTime = eachDayOfInterval({
      start: parsedPreviousEndDate,
      end: parsedStartDate,
    });

    startPoint = minutesBetweenStartTimeAndPreviousEndTime.length;
  } else {
    const minutesBetweenTimelineStartAndStartTime = eachDayOfInterval({
      start: min([timelineStart, parsedStartDate]),
      end: max([timelineStart, parsedStartDate]),
    });

    const isStartTimeBeforeTimelineStart = isBefore(
      parsedStartDate,
      timelineStart
    );

    startPoint = isStartTimeBeforeTimelineStart
      ? (minutesBetweenTimelineStartAndStartTime.length - 1) * 1.065 * -1
      : (minutesBetweenTimelineStartAndStartTime.length - 1) * 1.065;
  }

  return { width, startPoint };
};
