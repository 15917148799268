import React, { useEffect, useRef, useState } from "react";

import CalendarComparisonKpiTable from "../components/kpiTable/CalendarComparisonKpiTable";
import { Grid } from "@mui/material";
import CalendarComparisonCard from "../components/comparisonCard/CalendarComparisonCard";
import CalendarComparisonHeader from "../components/calendarComparisonHeader/CalendarComparisonHeader";
import CalendarComparsionTimeline, {
  ITimeline,
} from "../components/calenderComparsionTimeline/CalendarComparsionTimeline";
import { defaultCalendar } from "../components/calenderComparsionTimeline/mock";
import { KPIChartColor } from "../../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  fetchAllCalendars,
  fetchComparisonData,
} from "../../../utils/redux/calendar/calendarOptimizationActions";
import { useEffectOnce } from "../../../../utils/hooks/useEffectOnce";
import { ChartDataArrayType, KPITableType } from "../../../utils/types";
import { mapChartData } from "../../../utils/mapper";
import { ICalendarComparsionRowData } from "../components/calenderComparsionTimeline/interfaces";
import {
  HashRouter,
  Route,
  Link,
  useLocation,
  Routes,
  //Redirect
} from "react-router-dom";
import { setChanged } from "../../../utils/redux/calendarViewEdit/calendarViewEditAction";

const defaultTimelines = [
  { name: "Baseline", color: KPIChartColor.baseline },
  {
    name: "Max Margin - constant market share",
    color: KPIChartColor.maxMArgin,
  },
  {
    name: "Icreased Market Share",
    color: KPIChartColor.inscreasedMark,
  },
];

type calendarComparisonProps = {
  ids?: string[];
};

const CalendarComparison = () => {
  const dispatch = useAppDispatch();
  const [kpiTable, setKpiTable] = useState<KPITableType[]>([]);
  const [timelineData, setTimelineData] = useState<any>();
  const [dailiesData, setDailiesData] = useState<any>(defaultCalendar);
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartDataArrayType>({
    cpg_retail_sales_value: [],
    customer_revenue: [],
    cpg_net_revenue: [],
    gross_profit_cpg: [],
    gross_profit_customer: [],
    volumes_cpg: [],
    volumes_customer: [],
  });
  const [refCalValue, setRefCalValue] = useState<any>("");
  const isRunned = useRef(false);

  const calendars = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.calendarsData.data
  );

  const [timelines, setTimelines] = useState<ITimeline[]>();

  const pageData = useAppSelector(
    (state) =>
      state.reducer.calendarOptimizationReducer.calendarComparisonData.data
  );

  useEffectOnce(() => {
    if(isRunned.current) return;
    if (pageData && pageData.calendar_data.length > 1) {
      setRefCalValue(pageData?.calendar_data[0].id);
      isRunned.current = true;
    }
  }, [pageData]);

  useEffect(() => {
    if (location.state != null && Array.isArray(location.state.ids)) {
      setSelectedIDs(location.state.ids);
    }
  }, [location]);

  useEffect(() => {
      dispatch(fetchComparisonData(selectedIDs));
  }, [selectedIDs]);

  useEffect(() => {
    const column: any[] = [];

    if (pageData && pageData.calendar_data.length > 1) {
      let pageDataCopy = JSON.parse(JSON.stringify(pageData));
      //resort pageDataCopy to put refCalValue at the top
      const refCal = pageDataCopy.calendar_data.find(
        (cal) => cal.id === refCalValue
      );
      if (refCal) {
        const name_refCal = refCal.name;
        const index = pageDataCopy.calendar_data.findIndex(
          (cal) => cal.name === name_refCal
        );
        if (index > -1) {
          pageDataCopy.calendar_data.splice(index, 1);
          pageDataCopy.calendar_data.unshift(refCal);
        }
        const refKpi = pageDataCopy.kpis.find(
          (kpi) => kpi.name === name_refCal
        );
        const name_refKpi = refKpi.name;
        const indexKpi = pageDataCopy.kpis.findIndex(
          (kpi) => kpi.name === name_refKpi
        );
        if (indexKpi > -1) {
          pageDataCopy.kpis.splice(indexKpi, 1);
          pageDataCopy.kpis.unshift(refKpi);
        }
        const refChart = pageDataCopy.charts.find(
          (chart) => chart.name === name_refCal
        );
        const name_refChart = refChart.name;
        const indexChart = pageDataCopy.charts.findIndex(
          (chart) => chart.name === name_refChart
        );
        if (indexChart > -1) {
          pageDataCopy.charts.splice(indexChart, 1);
          pageDataCopy.charts.unshift(refChart);
        }
        const refTimeline = pageDataCopy.timelines.find(
          (timeline) => timeline === name_refCal
        );
        const name_refTimeline = refTimeline;
        const indexTimeline = pageDataCopy.timelines.findIndex(
          (timeline) => timeline === name_refTimeline
        );
        if (indexTimeline > -1) {
          pageDataCopy.timelines.splice(indexTimeline, 1);
          pageDataCopy.timelines.unshift(refTimeline);
        }
      }

      for (const [key, value] of Object.entries(chartData)) {
        chartData[key].length = 0;
      }

      pageDataCopy.kpis.forEach((kpi, index) => {
        const row: KPITableType = {
          title: kpi.name,
          backgroundColor: KPIChartColor[Object.keys(KPIChartColor)[index]],
          data: {
            gross_margin: kpi.gross_margin || "-",
            cpg_vsop: kpi.cpg_vsop || "-",
            cpg_arp_per_unit: kpi.cpg_arp_per_unit || "-",
            rsv_share: kpi.rsv_share || "-",
            avg_time_on_promo_per_brandline:
              kpi.avg_time_on_promo_per_brandline || "-",
            avg_time_on_catalog_per_brandline:
              kpi.avg_time_on_catalog_per_brandline || "-",
            avg_time_on_seasonal_per_brandline:
              kpi.avg_time_on_seasonal_per_brandline || "-",
            avg_time_as_secondary_per_brandline:
              kpi.avg_time_as_secondary_per_brandline || "-",
            promo_budget: kpi.promo_budget || "-",
            total_profit_pool: kpi.total_profit_pool || "-",
            customer_vsop: kpi.customer_vsop || "-",
            customer_arp_per_unit: kpi.customer_arp_per_unit || "-",
          },
        };
        column.push(row);
      });

      const charts = mapChartData(pageDataCopy.charts);

      const timelines = pageDataCopy.timelines.map((timeline, i) => {
        return {
          name: timeline,
          color: Object.values(KPIChartColor)[Math.round(i % 3)],
        };
      });

      setTimelineData(timelines);
      setChartData(charts);
      setKpiTable(column);
    }
  }, [dispatch, calendars, pageData, refCalValue]);

  //CODE-SMELL move calcluations to be
  const reduceDailies = (dailies) => {
    const sumTotalNetSales = dailies.reduce((x, y) => {
      return x + y.total_net_net_sales_value;
    }, 0);
    const netRevenue = Number(Number(sumTotalNetSales).toFixed(2));

    const sumVolume = dailies.reduce((x, y) => {
      return x + y.total_volume;
    }, 0);
    const volume = Number(Number(sumVolume).toFixed(2));

    const sumGrossProfit = dailies.reduce((x, y) => {
      return x + y.total_gross_profit;
    }, 0);
    const margin = Number(Number(sumGrossProfit).toFixed(2));

    const sumRetailSales = dailies.reduce((x, y) => {
      return x + y.total_retail_sales_value;
    }, 0);
    const arp = Number(Number(sumRetailSales / sumVolume).toFixed(2));

    return {
      netRevenue,
      volume,
      margin,
      arp,
    };
  };

  useEffect(() => {
    if (calendars) {
      if (pageData && pageData.calendar_data[0]) {
        let pageDataCopy = JSON.parse(JSON.stringify(pageData));
        //resort pageDataCopy to put refCalValue at the top
        const refCal = pageDataCopy.calendar_data.find(
          (cal) => cal.id === refCalValue
        );
        if (refCal) {
          const name_refCal = refCal.name;
          const index = pageDataCopy.calendar_data.findIndex(
            (cal) => cal.name === name_refCal
          );
          if (index > -1) {
            pageDataCopy.calendar_data.splice(index, 1);
            pageDataCopy.calendar_data.unshift(refCal);
          }
          const refKpi = pageDataCopy.kpis.find(
            (kpi) => kpi.name === name_refCal
          );
          const name_refKpi = refKpi.name;
          const indexKpi = pageDataCopy.kpis.findIndex(
            (kpi) => kpi.name === name_refKpi
          );
          if (indexKpi > -1) {
            pageDataCopy.kpis.splice(indexKpi, 1);
            pageDataCopy.kpis.unshift(refKpi);
          }
          const refChart = pageDataCopy.charts.find(
            (chart) => chart.name === name_refCal
          );
          const name_refChart = refChart.name;
          const indexChart = pageDataCopy.charts.findIndex(
            (chart) => chart.name === name_refChart
          );
          if (indexChart > -1) {
            pageDataCopy.charts.splice(indexChart, 1);
            pageDataCopy.charts.unshift(refChart);
          }
          const refTimeline = pageDataCopy.timelines.find(
            (timeline) => timeline === name_refCal
          );
          const name_refTimeline = refTimeline;
          const indexTimeline = pageDataCopy.timelines.findIndex(
            (timeline) => timeline === name_refTimeline
          );
          if (indexTimeline > -1) {
            pageDataCopy.timelines.splice(indexTimeline, 1);
            pageDataCopy.timelines.unshift(refTimeline);
          }
        }
        const formatedTimelines = pageDataCopy.calendar_data.map(
          (calendar, i) => {
            return {
              name: calendar.name,
              color: Object.values(KPIChartColor)[Math.round(i % 3)],
            };
          }
        );
        setTimelines(formatedTimelines);

        if (pageDataCopy.calendar_data.every(obj => obj.hasOwnProperty('dailies'))) {
          const firstCalendar = pageDataCopy.calendar_data[0];

          if (Array.isArray(firstCalendar.events)) {
            // separate different retailers
            const uniqueRetailers = [
              new Set(
                firstCalendar.events.map(
                  (item) => item.internal_sales_entity_code
                )
              ),
            ];
            const retailers = Array.from(uniqueRetailers[0]);

            //   unique subbrands
            const uniqueProducts = [
              new Set(
                firstCalendar.events.map((item) => item.internal_product_code)
              ),
            ];
            const products = Array.from(uniqueProducts[0]);

            var data: any = [];

            products.map((subbrand, i) => {
              data.push(subbrand);
            });

            const kpis: any = [];
            products.map((subbrand, i) => {
              let productKpis: any = [];
              pageDataCopy.calendar_data.map((calendar) => {
                let dailies: any = calendar!.events!.filter((daily) => {
                  return daily.internal_product_code == subbrand;
                });

                productKpis.push(reduceDailies(dailies));
              });
              kpis.push(productKpis);
            });

            let types = [
              "baseline",
              "increasedMarketShare",
              "constantMarginMarketShare",
            ];

            const data1 = data.map((retailer, i) => {
              return {
                subbrand: retailer,
                timeline: {
                  data: {
                    data: [
                      [
                        {
                          data: pageDataCopy.calendar_data.map(
                            (calendar, i) => {
                              const dailies = calendar!.events!.filter(
                                (daily) => {
                                  return (
                                    daily.internal_product_code == retailer
                                  );
                                }
                              );
                              return [
                                {
                                  type: types[Math.round(i % 3)],
                                  events: dailies.map((daily) => [
                                    {
                                      start: new Date(daily.date_start),
                                      end: new Date(daily.date_end),
                                      value: daily.promo_desc,
                                    },
                                  ]),
                                },
                              ];
                            }
                          ),
                        },
                      ],
                    ],
                  },
                },
                kpis: kpis[i],
              };
            });

            data1.unshift({
              subbrand: pageDataCopy.calendar_data[0].retailer,
              timeline: {
                data: {
                  data: [
                    [
                      {
                        data: pageDataCopy.calendar_data.map((calendar, i) => [
                          {
                            type: types[Math.round(i % 3)],
                            events: [[]],
                          },
                        ]),
                      },
                    ],
                  ],
                },
              },
              kpis: pageDataCopy.calendar_data.map((calendar) =>
                reduceDailies(calendar.events)
              ),
            });

            setDailiesData(data1);
          }
        }
      }
      // if (!pageData) {
      //   const identifiers = calendars.calendar_data.map(
      //     (calendar) => calendar.id
      //   );
      //   dispatch(fetchComparisonData(identifiers));
      //   return;
      // }
    }
  }, [dispatch, calendars, pageData, refCalValue]);

  useEffect(() => {
    dispatch(setChanged(false));
  }, []);

  return (
    <Grid container rowSpacing={2}>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <div>
          {calendars && (
            <CalendarComparisonHeader
              calendars={calendars.calendar_data}
              comparedCalendars={pageData?.calendar_data}
              setSelectedIDs={setSelectedIDs}
              refCalValue={refCalValue}
              setRefCalValue={setRefCalValue}
            />
          )}
        </div>
      </Grid>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <CalendarComparisonKpiTable title={"KPIs"} dataArray={kpiTable} />
      </Grid>

      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>

      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <CalendarComparisonCard
          cardTitle="CPG Retail sales value & Customer Revenue"
          firstChartTitle={"CPG"}
          firstChartData={chartData.cpg_retail_sales_value}
          secondChartTitle={"Customer"}
          secondChartData={chartData.customer_revenue}
        />
      </Grid>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <CalendarComparisonCard
          cardTitle="CPG net revenue"
          firstChartTitle={""}
          firstChartData={chartData.cpg_net_revenue}
        />
      </Grid>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <CalendarComparisonCard
          cardTitle="Gross profit"
          firstChartTitle={"CPG"}
          firstChartData={chartData.gross_profit_cpg}
          secondChartTitle={"Customer"}
          secondChartData={chartData.gross_profit_customer}
        />
      </Grid>
      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <CalendarComparisonCard
          cardTitle="Volumes"
          firstChartTitle={"CPG"}
          firstChartData={chartData.volumes_cpg}
          secondChartTitle={"Customer"}
          secondChartData={chartData.volumes_customer}
        />
      </Grid>

      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}></Grid>

      <Grid item mobile={12} tablet={12} laptop={12} laptop_lg={12}>
        <Grid className="bg-white rounded rounded-lg p-2">
          <CalendarComparsionTimeline
            data={dailiesData}
            timelines={timelineData}
            calendatData={pageData?.calendar_data}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalendarComparison;
