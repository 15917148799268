import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
} from "@mui/material";
import { calendarHEaderStyle } from "./CalendarHeaderStyle";
import Multiselector from "../../../../../components/Multiselector/Multiselector";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { fetchAggregationData } from "../../../../utils/redux/calendar/calendarOptimizationActions";
import { useAppDispatch } from "../../../../../utils/hooks";

type CalendarOptionType = {
  selectedAsComparison: boolean;
  selectedAsReference: boolean;
  value: string;
  label: string;
};

const CalendarComparisonHeader = ({
  calendars,
  comparedCalendars,
  setSelectedIDs,
}) => {
  const dispatch = useAppDispatch();

  const [calendarOptions, setCalendarOptions] =
    useState<CalendarOptionType[]>(calendars);
  const [selectedComparedCalendar, SetSelectedComparedCalendar] =
    React.useState<string[]>([]);
  const classes = calendarHEaderStyle();

  const handleFilter = () => {
    dispatch(fetchAggregationData(selectedComparedCalendar));
  };

  const handleChangeComparedCalendars = (event) => {
    const {
      target: { value },
    } = event;
    SetSelectedComparedCalendar(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setSelectedIDs(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeRefCalendar = (event) => {
    const {
      target: { value },
    } = event;
    //setRefCalValue(value);
  };

  useEffect(() => {
    const list_Options: string[] = [];
    if (comparedCalendars && comparedCalendars.length > 1) {
      for (let index = 0; index < comparedCalendars.length; index++) {
        const element = comparedCalendars[index];
        list_Options.push(element.id);
      }
    }
    //only when list_options is different from selectedComparedCalendar then update the selectedComparedCalendar
    if (
      list_Options.length > 0 &&
      list_Options.length !== selectedComparedCalendar.length
    ) {
      SetSelectedComparedCalendar(list_Options);
    }
  }, [comparedCalendars]);

  //if selectedComparedCalendar changes, reset the filters

  return (
    <div>
      <div>
        <Grid container>
          <Typography variant="h2" fontSize={20} align="left" fontWeight={600}>
            Aggregation of
            {comparedCalendars &&
              comparedCalendars.length > 0 &&
              comparedCalendars.map((item: any, index: number) => {
                return (
                  <span key={index}>
                    {index === comparedCalendars.length - 1 ? " & " : ", "}“
                    {item.name}”
                  </span>
                );
              })}{" "}
            calendars
          </Typography>
        </Grid>
      </div>

      <div className="flex gap-5 mt-2">
        <Grid>
          <Typography fontWeight={"bold"} align="left" fontSize={10}>
            Aggregate calendars
          </Typography>

          <FormControl>
            <Select
              value={selectedComparedCalendar}
              className={classes.select}
              displayEmpty={true}
              multiple={true}
              sx={{ height: 32, bgcolor: "white", paddingY: "4px" }}
              onChange={handleChangeComparedCalendars}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.length + " selected"}
            >
              <MenuItem disabled={true}>
                <Typography fontSize={13}>Aggregate calendars</Typography>
              </MenuItem>
              {calendars &&
                calendars.length &&
                calendars.filter(item => item.is_complete).map((item, index) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={item.id}
                      disabled={
                        selectedComparedCalendar.length >= 3 &&
                        !selectedComparedCalendar.includes(item.id)
                      }
                    >
                      <Checkbox
                        checked={selectedComparedCalendar.indexOf(item.id) > -1}
                      />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </div>
    </div>
  );
};

export default CalendarComparisonHeader;
