import axios from "axios";
import { SimulationInputs } from "../../../../utils/types";
import { EventSimulation } from "../../types";
import EventSimulationMockData from "../../../../utils/data/simulation_mock_Data/EventSimulationData.json";

export enum SimulationActionTypes {
  POST_PREDUCTIVE_SIMULATION_PENDING = "POST_PREDUCTIVE_SIMULATION_PENDING",
  POST_PREDUCTIVE_SIMULATION_FULFILLED = "POST_PREDUCTIVE_SIMULATION_FULFILLED",
  POST_PREDUCTIVE_SIMULATION_REJECTED = "POST_PREDUCTIVE_SIMULATION_REJECTED",
  FETCH_ALL_REF_CALENDARS_PENDING = "FETCH_ALL_REF_CALENDARS_PENDING",
  FETCH_ALL_REF_CALENDARS_FULFILLED = "FETCH_ALL_REF_CALENDARS_FULFILLED",
  FETCH_ALL_REF_CALENDARS_REJECTED = "FETCH_ALL_REF_CALENDARS_REJECTED",
  REF_CALENDARS_RESET = "REF_CALENDARS_RESET",
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function postEventSimulationAction(data: SimulationInputs | any) {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_PENDING,
      });

      const resp = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/event-simulation/simulation`,
        data
      );
      data.id = resp.data;
      let response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/event-simulation/simulation/output`,
        data
      );
      let retries = 0;
      while (response.status === 202) {
        if (retries < 2) await delay(1000 * 20);
        else await delay(2000);
        response = await axios.post(
          `${process.env.REACT_APP_PROMO_API_URL}/event-simulation/simulation/output`,
          data
        );
        retries++;
      }
      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_FULFILLED,
        data: response.data as EventSimulation,
        id: data.id,
      });
    } catch (e: any) {
      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_REJECTED,
        data: { errorMsg: e.message },
      });
    }
  };
}

export function postEventSimulationActionMock(data: SimulationInputs | any) {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_PENDING,
      });

      //  const response = await axios.post(
      //    `${process.env.REACT_APP_PROMO_API_URL}/event-simulation/simulation`,
      //     data
      //  );

      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_FULFILLED,
        data: EventSimulationMockData,
      });
    } catch (e) {
      dispatch({
        type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_REJECTED,
        data: {},
      });
    }
  };
}

export function resetSimulation() {
  return async function (dispatch: any, getState: any) {
    dispatch({
      type: SimulationActionTypes.POST_PREDUCTIVE_SIMULATION_PENDING,
    });
  };
}

export const fetchAllRefCalendars = (
  quarter: string,
  retailer: string,
  market: string
) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: SimulationActionTypes.FETCH_ALL_REF_CALENDARS_PENDING,
        payload: {},
      });

      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar?quarter=${quarter}&retailer=${retailer}&market=${market}`
      );

      dispatch({
        type: SimulationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: SimulationActionTypes.FETCH_ALL_REF_CALENDARS_REJECTED,
        payload: {},
      });
    }
  };
};
