import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { waterfallChartStyles } from "./styles";
import { formatNumber, getChartData2 } from "../../utils/mapper";
import Widget from "../widget/Widget";
import { CHART_TYPE } from "../../utils/enum";
import theme, { chartTheme } from "../../../utils/theme";
import { PromoEvent, Event } from "../../utils/types";

type WaterfallChartProps = {
  className?: string;
  title?: string;
  promoEvents?: PromoEvent[];
  chartEvent: Event;
  displayDropdown?: boolean;
  chartType?: CHART_TYPE;
};

const WaterfallChart = (props: WaterfallChartProps) => {
  const classes = waterfallChartStyles();
  const { promoEvents, chartEvent } = props;
  const [chartType, setChartType] = useState(CHART_TYPE.SALES_WATERFALL_CPG);
  const [chartData, setChartData] = useState(
    getChartData2(chartEvent, CHART_TYPE.SALES_WATERFALL_CPG)
  );

  const waterfallChartData = [
    {
      name: "Baseline",
      baseline: chartData?.baseline?.toFixed(0),
      baselineLabel: formatNumber(chartData?.baseline),
      transparent: 0,
    },
    {
      name:
        chartType === CHART_TYPE.PROFIT_WATERFALL_RETAILER
          ? "Retailer gross profit"
          : chartType === CHART_TYPE.SALES_WATERFALL_RETAILER
          ? "Retailer sales value"
          : "Uplift",
      uplift: (-1 * chartData?.uplift).toFixed(0),
      upliftLabel: formatNumber(chartData?.uplift),
      transparent: (chartData?.uplift + chartData?.baseline).toFixed(0),
    },
    {
      name: "Promo investment",
      discount: chartData?.discount?.toFixed(0),
      discountLabel: formatNumber(chartData?.discount),
      transparent: (chartData?.uplift + chartData?.baseline).toFixed(0),
    },
    {
      name: "Cannibalization + pull-forward",
      cannib: (chartData?.cannibalization + chartData?.pull_forward)?.toFixed(
        0
      ),
      cannibLabel: formatNumber(
        chartData?.cannibalization + chartData?.pull_forward
      ),
      transparent: (
        chartData?.uplift +
        chartData?.baseline +
        chartData?.discount +
        chartData?.cannibalization
      ).toFixed(0),
    },
    {
      name: "Margin",
      margin: (
        chartData?.uplift +
        chartData?.discount +
        chartData?.cannibalization +
        chartData?.pull_forward
      ).toFixed(0),
      marginLabel: formatNumber(
        chartData?.uplift +
          chartData.discount +
          chartData?.cannibalization +
          chartData?.pull_forward
      ),
      baseline: chartData?.baseline?.toFixed(0),
      baselineLabel: formatNumber(chartData?.baseline),
    },
  ];
  useEffect(() => {
    switch (chartType) {
      case CHART_TYPE.PROFIT_WATERFALL_CPG:
        setChartData(
          getChartData2(chartEvent, CHART_TYPE.PROFIT_WATERFALL_CPG)
        );
        break;
      case CHART_TYPE.PROFIT_WATERFALL_RETAILER:
        setChartData(
          getChartData2(chartEvent, CHART_TYPE.PROFIT_WATERFALL_RETAILER)
        );
        break;
      case CHART_TYPE.SALES_WATERFALL_CPG:
        setChartData(getChartData2(chartEvent, CHART_TYPE.SALES_WATERFALL_CPG));
        break;
      case CHART_TYPE.SALES_WATERFALL_RETAILER:
      default:
        setChartData(
          getChartData2(chartEvent, CHART_TYPE.SALES_WATERFALL_RETAILER)
        );
        break;
    }
  }, [promoEvents, chartType]);

  const onChangeChartType = (chartType: CHART_TYPE) => {
    setChartType(chartType);
  };

  const tickFormatter = (tickItem: any) => {
    return "$" + formatNumber(tickItem);
  };

  function getWaterfallMarginColor(data): string {
    if (
      data.uplift + data.discount + data.cannibalization + data.pull_forward >=
      0
    ) {
      return chartTheme.waterfallChart.uplift;
    }
    return chartTheme.waterfallChart.discount;
  }

  return (
    <Widget
      className={props.className}
      title={props.title || ""}
      actionComponent={
        <div className={classes.selectContainer}>
          <Select
            sx={{
              fontSize: "13px",
              "&::hover": {
                borderBottom: "none",
              },
            }}
            native
            size="small"
            value={chartType}
            onChange={(e: SelectChangeEvent<any>) =>
              onChangeChartType(e.target.value as CHART_TYPE)
            }
          >
            <option value={CHART_TYPE.PROFIT_WATERFALL_CPG}>
              Profit Waterfall GEPP
            </option>
            <option value={CHART_TYPE.PROFIT_WATERFALL_RETAILER}>
              Profit Waterfall Retailer
            </option>
            <option value={CHART_TYPE.SALES_WATERFALL_CPG}>
              Sales Waterfall GEPP
            </option>
            <option value={CHART_TYPE.SALES_WATERFALL_RETAILER}>
              Sales Waterfall Retailer
            </option>
          </Select>
        </div>
      }
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={400}
          height={360}
          data={waterfallChartData}
          margin={{
            top: 10,
            right: 0,
            left: 20,
            bottom: 0,
          }}
        >
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey="name"
            tick={{ fontSize: "10px" }}
            minTickGap={-200}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={tickFormatter}
          />
          <Bar dataKey="transparent" stackId="a" fill="transparent" />
          <Bar
            dataKey="baseline"
            stackId="a"
            fill={chartTheme.waterfallChart.baseline}
          >
            <LabelList className={classes.label} dataKey="baselineLabel" />
          </Bar>
          <Bar
            dataKey="discount"
            stackId="a"
            fill={chartTheme.waterfallChart.discount}
          >
            <LabelList className={classes.label} dataKey="discountLabel" />
          </Bar>
          <Bar
            dataKey="margin"
            stackId="a"
            fill={getWaterfallMarginColor(chartData)}
          >
            <LabelList className={classes.label} dataKey="marginLabel" />
          </Bar>
          <Bar
            dataKey="uplift"
            stackId="a"
            fill={chartTheme.waterfallChart.uplift}
          >
            <LabelList className={classes.label} dataKey="upliftLabel" />
          </Bar>
          <Bar
            dataKey="cannib"
            stackId="a"
            fill={chartTheme.waterfallChart.cannibalization}
          >
            <LabelList className={classes.label} dataKey="cannibLabel" />
          </Bar>
          {/* <Bar
            dataKey="pullForward"
            stackId="a"
            fill={chartTheme.waterfallChart.pullForward}
          >
            <LabelList className={classes.label} dataKey="pullForwardLabel" />
          </Bar> */}
        </BarChart>
      </ResponsiveContainer>
    </Widget>
  );
};

export default WaterfallChart;
