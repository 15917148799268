/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../utils/hooks";
import {
  formatNumber,
  mapCalendarReferenceData,
} from "../../../../../utils/mapper";
import { ConstraintsCardSelect } from "../../../../../components/constraintsCardSelect/ConstraintsCardSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/styles";
import { CalendarCreationFormActionTypes } from "../../../../../utils/redux/calendarCreationForm/calendarCreationFormActions";
import {
  financialsBrandsConstraints,
  financialsCalendarConstraints,
  financialsProductsConstraints,
} from "../../../../../utils/constants";

type usedStaticConstraintsType = {
  id: Number;
  name: string;
};

type usedBrandsConstraintsType = {
  id: Number;
  brand_id: Number;
  name: string;
};

type usedProductsConstraintsType = {
  id: Number;
  brand_id: Number;
  product_id: Number;
  name: string;
};

export function Financials({
  setValue,
  brands,
  products,
  getConstraintsValues,
  watch,
}: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [presetCommonConstraint, setPresetCommonConstraint] = useState<any[]>(
    []
  );
  const [brandsRefValues, setBrandsRefValues] = useState<any>();
  const [productsRefValues, setProductsRefValues] = useState<any>();
  const [calendarLevelList, setCalendarLevelList] = useState<any[]>([]);
  const [brandLevelList, setBrandLevelList] = useState<any[]>([]);
  const [productLevelList, setProductLevelList] = useState<any[]>([]);
  const [hints, setHints] = useState<any[]>([]);

  const [staticConstraints, setStaticConstraints] = useState(
    financialsCalendarConstraints
  );
  const [usedStaticConstraints, setUsedStaticConstraints] = useState<
    usedStaticConstraintsType[]
  >([]);
  const [brandsConstraints, setBrandsConstraints] = useState(
    financialsBrandsConstraints
  );
  const [usedBrandsConstraints, setUsedBrandsConstraints] = useState<
    usedBrandsConstraintsType[]
  >([]);
  const [productsConstraints, setProductsConstraints] = useState(
    financialsProductsConstraints
  );
  const [usedProductsConstraints, setUsedProductsConstraints] = useState<
    usedProductsConstraintsType[]
  >([]);

  const referenceCalendar = useAppSelector(
    (state) => state.reducer.calendarOptimizationReducer.selectedRefCalData
  );

  // const { constraintsCommons, constraintsBrands, constraintsProducts } =
  //   useAppSelector((state) => {
  //     return {
  //       constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
  //       constraintsBrands: state.reducer.ConstraintsManagementReducer.brands,
  //       constraintsProducts:
  //         state.reducer.ConstraintsManagementReducer.products,
  //     };
  //   });

  // useEffect(() => {
  //   const calendarLevelCnstr = constraintsCommons
  //     .filter(
  //       (x) =>
  //         x.name.includes("promo_spend") ||
  //         x.name.includes("market_share") ||
  //         x.name.includes("floor_volume") ||
  //         x.name.includes("floor_profit")
  //     )
  //     .filter((x) => x.PERCENT_MIN !== undefined);

  //   setCalendarLevelList([]);
  //   setUsedStaticConstraints([]);
  //   setStaticConstraints(financialsCalendarConstraints);

  //   if (calendarLevelCnstr.length >= 1) {
  //     calendarLevelCnstr.forEach((x) => {
  //       if (x.PERCENT_MIN !== undefined) {
  //         addConstraint(
  //           "calendar",
  //           financialsCalendarConstraints[
  //             financialsCalendarConstraints.findIndex(
  //               (constant) => constant.presetKey === x.name
  //             )
  //           ]
  //         );
  //       }
  //     });
  //     setPresetCommonConstraint(calendarLevelCnstr);
  //   }
  // }, [constraintsCommons]);

  function addConstraint(type, selectedConstraint?) {
    const id = Math.floor(Math.random() * 10032142);

    if (type === "calendar") {
      const availableConstraints = staticConstraints.filter(
        (constraint) => constraint.isActive === false
      );
      if (
        availableConstraints.length > 0 &&
        calendarLevelList.length < staticConstraints.length
      ) {
        if (selectedConstraint) {
          setCalendarLevelList((state) => [
            ...state,
            {
              id: id,
              value: [0],
              constraint: selectedConstraint.constraints_names,
              presetKey: selectedConstraint.presetKey,
            },
          ]);
        } else {
          setCalendarLevelList((state) => [
            ...state,
            { id: id, value: [0], constraint: [] },
          ]);
        }
      }
    }

    if (type === "brand") {
      if (brandLevelList.length < brands.length * brandsConstraints.length) {
        setBrandLevelList((state) => [
          ...state,
          { id: id, value: [0], constraint: [], brandId: 0 },
        ]);
      }
    }

    if (type === "product") {
      setProductLevelList((state) => [
        ...state,
        { id: id, value: [0], constraint: [], brandId: 0, productId: 0 },
      ]);
    }
  }

  return (
    <Grid container direction="column" gap={1}>
      <Accordion
        expanded={true}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ width: "100%", padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Calendar level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {calendarLevelList &&
            calendarLevelList.map((constraint) => {
              return (
                <ConstraintsCardSelect
                  key={constraint.id}
                  key_id={constraint.id}
                  presetValue={
                    presetCommonConstraint[
                      presetCommonConstraint.findIndex(
                        (x) => x.name === constraint.presetKey
                      )
                    ] || null
                  }
                  type="calendar"
                  constraints={staticConstraints}
                  setValue={setValue}
                  objectToSave={constraint}
                  usedConstraints={usedStaticConstraints}
                  UpdateUsedConstraints={setUsedStaticConstraints}
                />
              );
            })}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.goodChange,
            }}
            onClick={() => addConstraint("calendar")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={true}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Brand level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {brandLevelList &&
            brandLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                type="brand"
                constraints={brandsConstraints}
                brands={brands}
                brandsRefValues={brandsRefValues}
                setValue={setValue}
                objectToSave={constraint}
                usedConstraints={usedBrandsConstraints}
                UpdateUsedConstraints={setUsedBrandsConstraints}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.goodChange,
            }}
            onClick={() => addConstraint("brand")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={true}
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.primary.background_2,
          padding: `0 ${theme["spacing"](3)} ${theme["spacing"](2)} ${theme[
            "spacing"
          ](3)}`,
          margin: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ padding: 0, margin: 0 }}
        >
          <Typography variant="h3">Product level</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: 0,
            margin: 0,
          }}
        >
          {productLevelList &&
            productLevelList.map((constraint) => (
              <ConstraintsCardSelect
                key={constraint.id}
                key_id={constraint.id}
                type="product"
                constraints={productsConstraints}
                brands={brands}
                productsRefValues={productsRefValues}
                setValue={setValue}
                objectToSave={constraint}
                usedConstraints={usedProductsConstraints}
                UpdateUsedConstraints={setUsedProductsConstraints}
              />
            ))}
          <Typography
            style={{
              cursor: "pointer",
              fontSize: "0.9rem",
              fontWeight: 600,
              color: theme["palette"].primary.goodChange,
            }}
            onClick={() => addConstraint("product")}
          >
            + Add another
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
