import { Grid, Typography } from "@mui/material";
import { addDays, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { ICalendarComparsionRowData } from "./interfaces";
import { Kpis } from "./kpis/Kpis";
import { defaultCalendar } from "./mock";
import PlotArea from "./plotArea/PlotArea";
import SubBrandMenu from "./subbrandMenu/SubbrandMenu";
import Timeline from "./timeline/Timeline";

export interface ITimeline {
  name: string;
  color: string;
}

type CalendarComparsionTimelineProps = {
  timelines: ITimeline[];
  data: any;
  calendatData: any;
};

const CalendarComparsionTimeline: React.FC<CalendarComparsionTimelineProps> = ({
  timelines,
  data,
  calendatData,
}) => {
  const [kpis, setKpis] = useState<any>([]);

  useEffect(() => {
    const arr: any = [];
    data.map((product) => {
      arr.push(product.kpis);
    });
    setKpis(arr.reduce((a, b) => a.concat(b), []));
  }, [data]);

  const [calendarDateRange, setCalendarDateRange] = useState<any>({
    start: addDays(parseISO(new Date().toISOString()), 0),
    end: addDays(new Date(), 365),
  });

  useEffect(() => {
    if (
      calendatData &&
      calendatData.length > 0 &&
      calendatData[0].events &&
      calendatData[0].events.length > 0
    ) {
      let startdate_min = oldestDate(calendatData[0].events);
      let enddate_max = newestDate(calendatData[0].events);
      for (let i = 0; i < calendatData.length; i++) {
        if (!calendatData[i].events || calendatData[i].events.length === 0)
          continue;
        let startdate_cur = oldestDate(calendatData[i].events);
        let enddate_cur = newestDate(calendatData[i].events);
        startdate_min =
          startdate_cur < startdate_min ? startdate_cur : startdate_min;
        enddate_max = enddate_cur > enddate_max ? enddate_cur : enddate_max;
      }
      setCalendarDateRange({
        start: addDays(startdate_min, 0),
        end: addDays(enddate_max, 0),
      });
    }
  }, [data]);

  const oldestDate = function (data) {
    const date_old = data.reduce((acc, curr) => {
      if (
        curr &&
        curr.date_start &&
        curr.date_end &&
        curr.date_start !== "" &&
        curr.date_end !== ""
      ) {
        const date = new Date(curr.date_start);
        if (date < acc) {
          return date;
        }
      }
      return acc;
    }, new Date());
    return date_old;
  };

  const newestDate = function (data) {
    const date_new = data.reduce((acc, curr) => {
      if (
        curr &&
        curr.date_start &&
        curr.date_end &&
        curr.date_start !== "" &&
        curr.date_end !== ""
      ) {
        const date = new Date(curr.date_end);
        if (date > acc) {
          return date;
        }
      }
      return acc;
    }, new Date());
    return date_new;
  };

  return (
    <Grid>
      <Grid
        container
        direction="row"
        padding={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h2" fontSize={20} fontWeight={"normal"} flex={1}>
          Calendar comparison
        </Typography>
        <Grid container item direction="row" justifyContent={"end"} flex={1}>
          {timelines &&
            timelines.map((timeline) => {
              return (
                <div className="border-r-[1px] border-r-background-60 last:border-r-0 pr-2 pl-2 last:pr-0">
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className=""
                  >
                    <div
                      className="h-4 w-4 bg-black mr-2"
                      style={{ background: timeline.color }}
                    ></div>
                    <span className="text-sm text-stone-600">
                      {timeline.name}
                    </span>
                  </Grid>
                </div>
              );
            })}
        </Grid>
      </Grid>
      <div className="flex min-h-fit">
        <SubBrandMenu
          timelines={timelines ? timelines.length : 0}
          data={data.map((calendar) => ({
            name: calendar.subbrand,
          }))}
        />
        <div className="overflow-x-auto overflow-y-hidden mt-4 relative">
          <div className="pt-6 flex flex-col w-fit h-full">
            <Timeline daily={data} timelineRange={calendarDateRange} />
            <PlotArea
              timelines={timelines ? timelines.length : 0}
              data={data}
              timelineRange={calendarDateRange}
            />
          </div>
        </div>
        <Kpis timelines={timelines ? timelines.length : 0} kpis={kpis} />
      </div>
    </Grid>
  );
};

export default CalendarComparsionTimeline;
