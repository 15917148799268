import { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";
import { StyledSlider } from "../inputSlider";
import { styled } from "@mui/styles";
import { formatValue } from "react-currency-input-field";
import CurrencyInput from "react-currency-input-field";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../Promo/utils/mapper";

type Constraint = {
  name: string | "";
};

type Mark = {
  label: string;
  value: number;
  rawValue: number;
};

type PropsType = {
  constraint?: Constraint | { name: "" };
  marks: Mark[];
  getValues: (values: number) => any;
  initialValues?: number[];
  currency?: boolean;
  refDecimal: number;
};

const Label = styled("label")({
  fontWeight: "600 !important",
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "14px",
});

const CustomCurrencyInput = styled(CurrencyInput)({
  // background: "blue",
  paddingLeft: "3px",
  height: "20px",
  border: "1px solid black",
  borderRadius: "4px",
  width: "120px",
  minHeight: "40px",
});

export function ConstraintsCardInteger({
  constraint,
  marks,
  getValues,
  initialValues = [],
  currency = false,
  refDecimal,
}: PropsType) {
  const [value, setValue] = useState<number>(initialValues[0]);

  useEffect(() => {
    getValues(value);
  }, []);

  useEffect(() => {
    if (initialValues.length >= 1) {
      setValue(initialValues[0]);
      getValues(initialValues[0]);
    }
  }, []);

  function handleOnCommit(value) {
    getValues(value);
  }

  return (
    <InputCard>
      <Typography variant="h4">{""}</Typography>
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        flexWrap={"nowrap"}
        sx={{ marginTop: "-10px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }} gap={3}>
          {marks[0].rawValue ? (
            <Box display={"grid"}>
              <Label style={{ justifySelf: "center" }}>Ref</Label>
              <Typography variant="body3">
                {currency ? "$" : null}
                {formatValue({
                  value: marks[0].rawValue.toFixed(refDecimal || 0),
                  groupSeparator: ",",
                  decimalSeparator: ".",
                })}
              </Typography>
            </Box>
          ) : null}
          <Box display={"grid"}>
            <Label style={{ justifySelf: "baseline", paddingLeft: 3 }}>
              {marks[0].rawValue && marks[0].rawValue > 0 ? (
                <label style={{ color: theme["palette"].success.main }}>
                  {((value * 100) / marks[0].rawValue).toFixed(0)}%
                </label>
              ) : (
                <Grid height={15}></Grid>
              )}
            </Label>
            {currency ? (
              <CustomCurrencyInput
                name="promo_min_per_slot"
                placeholder="Enter Value"
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                defaultValue={value || 0}
                onValueChange={(value: any, name) => {
                  let temp = Number.parseFloat(value);
                  setValue(temp);
                  if (value !== null && value !== undefined) {
                    handleOnCommit(temp);
                  }
                }}
              />
            ) : (
              <TextField
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: { fontSize: 14, paddingLeft: 3 },
                }}
                defaultValue={initialValues[0]}
                value={value}
                name="integer_input"
                size="small"
                sx={{ width: "8rem" }}
                onChange={(e: any) => {
                  let temp = Math.ceil(e.target.value);
                  setValue(temp);
                  if (value !== null && value !== undefined) {
                    handleOnCommit(temp);
                  }
                }}
              />
            )}
          </Box>
          {/* <Box display={"grid"}>
            <Label style={{justifySelf: "center"}}>
                Ref
            </Label>
            <Typography>
              {formatNumber(marks[0].rawValue)}
            </Typography>
          </Box> */}
        </Box>
      </Grid>
    </InputCard>
  );
}
