import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const calendarKpiTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    kpiTable: {
      width: "100%",
      height: "80%",
      padding: theme.spacing(2, 2),
      // alignItems: "right",
    },

    root: {
      display: "inline-block",
      width: "100%",
      justifyContent: "space-between",
      padding: theme.spacing(0, 4, 3, 3),
      backgroundColor: "white",
      borderRadius: 10,
    },
    kpiDataWidgetSection: {
      width: 590,
      height: 450,
    },
    kpiDataTableWidgetSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "left",
      width: "100%",
    },
    kpiChartSection: {
      display: "flex",
      width: "47%",
    },

    rootCell: {
      backgroundColor: "rgba(141, 141, 141, 1)",
      paddingRight: 5,
      borderLeft: "0.2px solid black",
      borderTop: "0.2px solid black",
      borderRight: "0.2px solid black",
      width: 120,
      height: 30,
      minWidth: 90,
    },
    tableCell: {
      borderLeft: "0.2px solid rgba(82, 82, 82, 1)",
      borderTop: "0.2px solid rgba(82, 82, 82, 1)",
      padding: 2,
      margin: 2,

      width: 72,
      backgroundColor: "white",
    },
    tableCellLastCell: {
      borderRight: "0.2px solid rgba(82, 82, 82, 1)",
      borderBottom: "0.2px solid rgba(82, 82, 82, 1)",
    },

    tableCellFragment: {
      width: "20%",
      height: "100%",
      alignItems: "left",

      padding: "1px 1px 1px 1px",
      minWidth: 40,
    },
    tableRow: {
      padding: "0px 12px 0px 0px",
      fontSize: "10px",
      width: 122,
      height: 10,
    },
    textStyle: {
      padding: "2px 12px 2px 2px",
    },
    rootText: {
      padding: "2px 0px 0px 12px",
    },
  })
);
