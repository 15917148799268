import { DateRangePicker } from "rsuite";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import addYears from "date-fns/addYears";
import subWeeks from "date-fns/subWeeks";

import { useStyle } from "./CustomeDatePickerStyle";
import { Typography } from "@mui/material";

export type CustomDatePickerProps = {
  onChange: (value: any) => void;
  onClean?: (value: any) => void;
  disabledDate?: (date) => any;
  period?: Date[];
};

const defaultDisabledDate = (date) => {
  const oneWeekAgo = subWeeks(new Date(), 1);
  const oneYearAhead = addYears(new Date(), 1);

  return (
    isBefore(date, startOfWeek(oneWeekAgo, { weekStartsOn: 1 })) ||
    isAfter(date, endOfWeek(oneYearAhead, { weekStartsOn: 1 }))
  );
};

const rendreDiff = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const datFormat = date1.toLocaleDateString();
  const datFormat2 = date2.toLocaleDateString();
  const result =
    datFormat + " - " + datFormat2 + " " + "(" + diffDays + " days)";
  return (
    <Typography fontSize={12} align="left">
      {result}
    </Typography>
  );
};

const CustomeDatePicker = (props: CustomDatePickerProps) => {
  const classes = useStyle();
  return (
    <DateRangePicker
      onClean={props.onClean}
      format={"MM/dd/yyyy"}
      onChange={props.onChange}
      appearance="default"
      cleanable={true}
      hoverRange="week"
      shouldDisableDate={
        props.disabledDate ? props.disabledDate : defaultDisabledDate
      }
      size="xs"
      className={classes.rootComponent}
      preventOverflow
      menuStyle={{
        color: "black",
        backgroundColor: "white",
        fontSize: 10,
        zIndex: 9999999,
      }}
      defaultValue={
        props.period
          ? [props.period[0], props.period[1]]
          : [new Date(), new Date()]
      }
      renderValue={(value, format) => {
        const startDate = value[0];
        const endDate = value[1];
        return rendreDiff(startDate, endDate);
      }}
      isoWeek
    />
  );
};
export default CustomeDatePicker;
