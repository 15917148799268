import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { PnLStyles } from "./style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import theme from "../../../utils/theme";
import { PnLData } from "../../../utils/types";
import { formatNumber } from "Promo/utils/mapper";
export type PnLProps = {
  title: string;
  pnLData: PnLData[];
  tooltipToShow?: string[];
};

const PnLTable = (props: PnLProps) => {
  const classes = PnLStyles();
  const { title, pnLData, tooltipToShow } = props;

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          style={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3" mr={1}>
            {title}
          </Typography>
          <Tooltip
            title={
              <Typography
                fontSize={14}
                color={theme.palette.primary.contrastText}
                lineHeight={1.8}
              >
                Uplifts are calculated against baseline calculated by the
                Promo.AI model
              </Typography>
            }
            placement="top"
            arrow
          >
            <Grid display={"flex"} alignItems={"center"}>
              <Typography variant="body3" lineHeight={1.8}>
                (vs. baseline)
              </Typography>
              <InfoIcon
                fontSize="small"
                sx={{ color: "text.secondary", fontSize: 14, mt: 0.3, ml: 0.5 }}
              />
            </Grid>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table aria-label={title}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableEmptyHeader}></TableCell>
                  <Tooltip
                    title={
                      <Typography
                        fontSize={14}
                        color={theme.palette.primary.contrastText}
                        lineHeight={1.8}
                      >
                        Estimated volume sold in absence of any promo. Baseline
                        counts for market and products trends, seasonality and
                        festivities
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                    >
                      Base{" "}
                      <InfoIcon
                        fontSize="small"
                        sx={{ color: "text.secondary", fontSize: 14, mb: 0.4 }}
                      />
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      <Typography
                        fontSize={14}
                        color={theme.palette.primary.contrastText}
                        lineHeight={1.8}
                      >
                        Additional volume increase due to the promo event,
                        calculated as the difference between observed volume and
                        baseline
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                    >
                      Uplift{" "}
                      <InfoIcon
                        fontSize="small"
                        sx={{ color: "text.secondary", fontSize: 14, mb: 0.4 }}
                      />
                    </TableCell>
                  </Tooltip>
                  <TableCell align="center" className={classes.tableHeaderCell}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pnLData.map((row, index) => {
                  return (
                    <>
                      {index === 9 && (
                        <TableRow
                          key={"space"}
                          style={{
                            height: 24,
                          }}
                        ></TableRow>
                      )}
                      <TableRow
                        key={row.name}
                        style={{
                          background: row.subValue
                            ? theme.palette.primary.background
                            : index % 2
                            ? theme.palette.primary.contrast
                            : theme.palette.primary.background,
                        }}
                      >
                        <TableCell align="left" className={classes.tableCell}>
                          {row.subValue ? (
                            <Box sx={{ display: "flex", ml: "20px", gap: 2 }}>
                              <FiberManualRecordIcon sx={{ width: "5px" }} />
                              <Typography variant="body3">
                                {row.name}
                              </Typography>
                            </Box>
                          ) : tooltipToShow?.includes(row.name) ? (
                            <Tooltip
                              title={
                                <Typography
                                  fontSize={14}
                                  color={theme.palette.primary.contrastText}
                                  lineHeight={1.8}
                                >
                                  {row.definition}
                                </Typography>
                              }
                              placement="bottom"
                              arrow
                            >
                              <Grid
                                container
                                alignItems={"center"}
                                flexWrap={"nowrap"}
                                gap={1}
                              >
                                <Typography variant="body3">
                                  {row.name}
                                </Typography>
                                <InfoIcon
                                  fontSize="small"
                                  sx={{
                                    color: "text.secondary",
                                    fontSize: 14,
                                    mt: 0.2,
                                  }}
                                />
                              </Grid>
                            </Tooltip>
                          ) : (
                            <Typography variant="body3">{row.name}</Typography>
                          )}
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <Typography
                            variant="body3"
                            sx={{
                              color:
                                formatNumber(row.base).includes("-") == true
                                  ? "#CE0B0B"
                                  : "#161616",
                            }}
                          >
                            {formatNumber(row.base) === "0"
                              ? "-"
                              : formatNumber(row.base, 2)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <Typography
                            variant="body3"
                            sx={{
                              color:
                                formatNumber(row.incremental).includes("-") ==
                                true
                                  ? "#CE0B0B"
                                  : "#161616",
                            }}
                          >
                            {formatNumber(row.incremental) === "0"
                              ? "-"
                              : formatNumber(row.incremental, 2)}{" "}
                            {/* {row.incrementalPercentage != undefined && (
                          <Typography
                            variant="body3"
                            component={"span"}
                            sx={{
                              color: row.incrementalPercentage >= 0 ? theme.palette.success.main : theme.palette.error.main,
                            }}
                          >
                            ({row.incrementalPercentage >= 0 && "+"}
                            {formatNumber(row.incrementalPercentage)}%)
                          </Typography>
                        )} */}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <Typography
                            variant="body3"
                            sx={{
                              color:
                                formatNumber(row.total).includes("-") == true
                                  ? "#CE0B0B"
                                  : "#161616",
                            }}
                          >
                            {formatNumber(row.total) === "0"
                              ? "-"
                              : formatNumber(row.total, 2)}{" "}
                            {/* {row.incrementalPercentage != undefined && (}{" "}
                        {/* {row.totalPercentage != undefined && (
                          <Typography
                            variant="body3"
                            component={"span"}
                            sx={{
                              color: row.totalPercentage >= 0 ? theme.palette.success.main : theme.palette.error.main,
                            }}
                          >
                            ({row.totalPercentage >= 0 && "+"}
                            {formatNumber(row.totalPercentage)}%)
                          </Typography>
                        )} */}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PnLTable;
