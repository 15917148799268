import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import "./styles.scss";
import { DecisionSpace } from "./tabs/DecisionSpace";
import { Financials } from "./tabs/Financials";
import { Scope } from "./tabs/Scope";
import { PromoMix } from "./tabs/PromoMix";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";

const tabs = ["Decision Space", "Scope", "Financials", "Promo mix"];

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type ConstraintsType = {
  errors?: any;
  register?: any;
  setValue?: any;
  getValues?: any;
  watch?: any;
  control?: any;
  resetField?: any;
  constraintsData?: {
    constraintsCommons: any[];
    constraintsBrands: any[];
    constraintsProducts: any[];
  };
};

export function Constraints({
  errors,
  register,
  setValue,
  getValues,
  watch,
  control,
  resetField,
  constraintsData,
}: ConstraintsType) {
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(0);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const retailers = useAppSelector(
    (state) => state.reducer.GlobalReducer.condensedClientData
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      name === "category" && setSelectedCategory(value.category);
    });
    return () => subscription.unsubscribe();
  });

  useEffect(() => {
    retailers &&
      selectedCategory &&
      setBrands(
        retailers.categories
          .filter((v) => v.internal_code === selectedCategory)
          .map((v) => v.brands)[0]
      );
    retailers &&
      selectedCategory &&
      setProducts(
        retailers.categories
          .filter((v) => v.internal_code === selectedCategory)
          .map((v) => v.brands)[0]
          .flat()
          .map((v) => v.products)
      );
  }, [selectedCategory, retailers]);

  // CODE-SMELL Make dynamic based on quarter selected
  function generateDefaultScopes() {
    return [
      {
        values: [new Date("01/02/2023"), new Date("01/16/2023")],
        slotName: "C15",
      },
      {
        values: [new Date("01/18/2023"), new Date("02/08/2023")],
        slotName: "C16",
      },
      {
        values: [new Date("02/08/2023"), new Date("03/01/2023")],
        slotName: "C17",
      },
      {
        values: [new Date("03/01/2023"), new Date("03/22/2023")],
        slotName: "C18",
      },
      {
        values: [new Date("03/22/2023"), new Date("04/12/2023")],
        slotName: "C1",
      },
      {
        values: [new Date("04/12/2023"), new Date("05/03/2023")],
        slotName: "C2",
      },
      {
        values: [new Date("05/03/2023"), new Date("05/24/2023")],
        slotName: "C3",
      },
      {
        values: [new Date("05/24/2023"), new Date("06/14/2023")],
        slotName: "C4",
      },
      {
        values: [new Date("06/25/2023"), new Date("07/19/2023")],
        slotName: "C5",
      },
      {
        values: [new Date("07/19/2023"), new Date("08/09/2023")],
        slotName: "C6",
      },
      {
        values: [new Date("08/09/2023"), new Date("08/30/2023")],
        slotName: "C7",
      },
      {
        values: [new Date("08/30/2023"), new Date("09/19/2023")],
        slotName: "C8",
      },
      {
        values: [new Date("09/20/2023"), new Date("10/10/2023")],
        slotName: "C10",
      },
      {
        values: [new Date("10/11/2023"), new Date("10/31/2023")],
        slotName: "C11",
      },
      {
        values: [new Date("11/01/2023"), new Date("11/21/2023")],
        slotName: "C12",
      },
      {
        values: [new Date("11/22/2023"), new Date("12/12/2023")],
        slotName: "C13",
      },
      {
        values: [new Date("12/13/2023"), new Date("01/02/2024")],
        slotName: "C14",
      },
    ];
  }

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1.6rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Typography>Constraints</Typography>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            orientation="vertical"
            onChange={(e, v) => setTabValue(v)}
            aria-label="basic tabs example"
          >
            {tabs.map((v, i) => (
              <Tab key={i} label={v} {...a11yProps(i)} />
            ))}
          </Tabs>
        </Box>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 0 ? "block" : "none",
          }}
        >
          <DecisionSpace
            register={register}
            brands={[...brands]}
            setValue={setValue}
            resetField={resetField}
            watch={watch}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 1 ? "block" : "none",
          }}
        >
          <Scope
            errors={errors}
            register={register}
            setValue={setValue}
            getValues={getValues}
            control={control}
            watch={watch}
            defaultSlots={generateDefaultScopes()}
            resetField={resetField}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 2 ? "block" : "none",
          }}
        >
          <Financials
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            brands={[...brands]}
            products={[...products]}
          />
        </div>
        <div
          style={{
            padding: "0 2rem",
            width: "100%",
            display: tabValue === 3 ? "block" : "none",
          }}
        >
          <PromoMix
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            brands={[...brands]}
          />
        </div>
      </Box>
    </Grid>
  );
}
