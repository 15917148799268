import { Box, Button, Grid, Table, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/styles";
import theme from "utils/theme";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ConstraintSetRow } from "./constraintsManagement";

export const PaperWrapper = styled(Box)({
  borderRadius: "10px",
  padding: "2rem 1.9rem",
  background: theme.palette.primary.background,
});

export const StatusChip = styled(Box)({
  backgroundColor: "#c4c4c4",
  borderRadius: "8px",
  textAlign: "center",
  fontWeight: "bold",
  padding: "0 .4rem",
  fontSize: ".7rem",
});

export const ConstraintsTable = styled(Table)({
  minWidth: 700,
  border: "0px",
  boxShadow: "none",
  ".MuiTableRow-root": {
    background: "#ECECFI",
  },
});

export const StyledTableHeader = styled(TableHead)({
  backgroundColor: "#C4C4C4",
});

export const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontSize: ".8rem",
    paddingTop: ".4rem",
    paddingBottom: ".4rem",
    "&:first-child": {
      width: "50px",
      borderTopLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "0px",
    background: "#ECECF1",
    paddingTop: "1.4rem",
    paddingBottom: "1.4rem",
    "&:first-child": {
      width: "50px",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:nth-child(2)": {
      textAlign: "left",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
});

export const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
});

type TableCellActionsType = {
  data: ConstraintSetRow;
  onDelete: (id) => any;
  onEdit: (id) => any;
  onCopy: (id) => any;
  onChipClick: (id) => any;
};

export const CardWrapper = styled(Box)({
  background: "#ECECF1",
  padding: "1.4rem 1.5rem",
  borderRadius: "10px",

  display: "flex",
  justifyContent: "space-between",
});

export const CardColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: ".4rem",
});

const CustomButton = styled(Button)({
  maxWidth: "28px",
  minWidth: "1px !important",
});

export function TableCellActions({
  data,
  onDelete,
  onEdit,
  onCopy,
  onChipClick,
}: TableCellActionsType) {
  return (
    <Grid container justifyContent={"flex-end"}>
      {data.calendarsUsingIt.length > 0 && (
        <Box mr={4} onClick={(_) => onChipClick(data.id)}>
          <p
            style={{
              background: theme.palette.primary.contrastText,
              padding: ".1rem .8rem",
              border: "1px solid #c3c3c3",
              borderRadius: "5px",
              fontSize: ".7rem",
              fontWeight: "bold",
            }}
          >
            {data.calendarsUsingIt.length} calendars using the set
          </p>
        </Box>
      )}
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <CustomButton size="small">
          <DeleteOutlineIcon onClick={(_) => onDelete(data.id)} />
        </CustomButton>
        <CustomButton size="small">
          <ContentCopyIcon
            sx={{
              opacity: 0.4,
              filter: "alpha(opacity=40)",
              "&:hover": { cursor: "not-allowed" },
            }}
            // onClick={(_) => onCopy(data.id)}
          />
        </CustomButton>
        <CustomButton size="small">
          <EditOutlinedIcon onClick={(_) => onEdit(data.id)} />
        </CustomButton>
      </Box>
    </Grid>
  );
}
