import produce, { Draft } from "immer";
import { Reducer } from "redux";

//import { OptimizationAction, OptimizationActionTypes } from './optimizationActions'
import {
  addEvent,
  updateEvent,
  removeEvent,
  promoSimulation,
} from "../../mapper";
import {
  ProductKPI,
  PromoEvent,
  CalendarData,
} from "../../types";
import { PROMO_ACTION_TYPE } from "../../enum";
import CALENDRADETAILS from "../../data/calendar_id_1.json";
import {
  OptimizationAction,
  OptimizationActionTypes,
} from "./optimizationActions";

export type OptimizationState = {
  simulationPromoEvent?: PromoEvent;
  simulationProductName: string;
  simulationRetailerName: string;
  simulationPromoID: number;
  simulate: boolean;
  showDialog: boolean;
  dialogType: PROMO_ACTION_TYPE;

  currentProductKPIS: ProductKPI[];
  currentPromoEvents: PromoEvent[];
  referenceProductKPIS: ProductKPI[];
  referencePromoEvents: PromoEvent[];
  currentCalendar: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: CalendarData | null;
  };
  referenceCalendar: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: CalendarData | null;
  };
};

export const initialState: OptimizationState = {
  simulationPromoEvent: undefined,
  simulationPromoID: 0,
  simulationProductName: "",
  simulationRetailerName: "",
  simulate: false,
  showDialog: false,
  dialogType: PROMO_ACTION_TYPE.ADD,

  currentProductKPIS: [],
  currentPromoEvents: [],
  referenceProductKPIS: [],
  referencePromoEvents: [],
  currentCalendar: {
    pending: false,
    fulfilled: false,
    rejected: false,
    // @ts-ignore
    data: CALENDRADETAILS,
  },
  referenceCalendar: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
};

export const optimizationReducer: Reducer<
  OptimizationState,
  OptimizationAction
> = produce((draft: StateDraft, action: OptimizationAction) => {
  switch (action.type) {
    case OptimizationActionTypes.SHOW_DIALOG:
      draft.showDialog = true;
      draft.simulate = false;
      draft.simulationProductName = action.productName;
      draft.simulationRetailerName = action.retailerName;
      draft.simulationPromoEvent = action.promoEvent;
      draft.simulationPromoID = action.promoID || 0;
      draft.dialogType = action.dialogType;
      break;
    case OptimizationActionTypes.HIDE_DIALOG:
      draft.showDialog = false;
      draft.simulationProductName = "";
      draft.simulationRetailerName = "";
      draft.simulationPromoID = 0;
      draft.simulationPromoEvent = undefined;
      draft.dialogType = PROMO_ACTION_TYPE.ADD;
      break;
    case OptimizationActionTypes.SIMULATE_PROMO_EVENT:
      draft.simulate = true;
      draft.simulationPromoEvent = promoSimulation(
        action.data,
        draft.simulationProductName,
        draft.simulationRetailerName,
        draft.simulationPromoID
      );
      break;
    case OptimizationActionTypes.ADD_PROMO_EVENT:
      const { productKPIS: addedProductKPIS, promoEvents: addedPromoEvents } =
        addEvent(
          draft.currentProductKPIS,
          draft.currentPromoEvents,
          action.payload
        );
      draft.currentProductKPIS = [...addedProductKPIS];
      draft.currentPromoEvents = [...addedPromoEvents];
      break;
    case OptimizationActionTypes.MODIFY_PROMO_EVENT:
      const {
        productKPIS: modifiedProductKPIS,
        promoEvents: modifiedPromoEvents,
      } = updateEvent(
        draft.currentProductKPIS,
        draft.currentPromoEvents,
        action.payload
      );
      draft.currentProductKPIS = [...modifiedProductKPIS];
      draft.currentPromoEvents = [...modifiedPromoEvents];
      break;
    case OptimizationActionTypes.DELETE_PROMO_EVENT:
      const {
        productKPIS: removedProductKPIS,
        promoEvents: removedPromoEvents,
      } = removeEvent(
        draft.currentProductKPIS,
        draft.currentPromoEvents,
        action.payload
      );
      draft.currentProductKPIS = [...removedProductKPIS];
      draft.currentPromoEvents = [...removedPromoEvents];
      break;
    case OptimizationActionTypes.SET_REFERENCE_CALENDAR_DATA:
      draft.referenceProductKPIS = action.payload.product_kpis;
      draft.referencePromoEvents = action.payload.promo_events;
      break;
    case OptimizationActionTypes.FETCH_CURRENT_CALENDAR_PENDING:
      draft.currentCalendar = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      draft.currentProductKPIS = [];
      draft.currentPromoEvents = [];
      break;
    case OptimizationActionTypes.FETCH_CURRENT_CALENDAR_FULFILLED:
      draft.currentCalendar = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      draft.currentProductKPIS = action.data.product_kpis;
      draft.currentPromoEvents = action.data.promotions;
      break;
    case OptimizationActionTypes.FETCH_CURRENT_CALENDAR_REJECTED:
      draft.currentCalendar = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      draft.currentProductKPIS = [];
      draft.currentPromoEvents = [];
      break;
    case OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_PENDING:
      draft.referenceCalendar = {
        pending: true,
        fulfilled: false,
        rejected: false,
        data: null,
      };
      draft.referenceProductKPIS = [];
      draft.referencePromoEvents = [];
      break;
    case OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_FULFILLED:
      draft.referenceCalendar = {
        pending: false,
        fulfilled: true,
        rejected: false,
        data: action.data,
      };
      draft.referenceProductKPIS = action.data.product_kpis;
      draft.referencePromoEvents = action.data.promo_events;
      break;
    case OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_REJECTED:
      draft.referenceCalendar = {
        pending: false,
        fulfilled: false,
        rejected: true,
        data: null,
      };
      draft.referenceProductKPIS = [];
      draft.referencePromoEvents = [];
      break;
  }
}, initialState);

type StateDraft = Draft<OptimizationState>;
