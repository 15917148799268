import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { isVariableDeclaration } from "typescript";
import { defaultKpiRow } from "../../calenderComparsionTimeline/mock";
import { KpiRowData } from "../interfaces";
import { formatNumber } from "Promo/utils/mapper";

export const KpisTitle = ({ stickyRef, topPos }) => {
  return (
    <Grid
      ref={stickyRef}
      className={`h-16 relative bg-white z-[99] shadow-lg`}
      style={{
        top: `${topPos}px`,
        transition: "top 0.1s ease-out",
      }}
    >
      <Grid container className="w-full h-full">
        <Grid
          item
          flex={1}
          container
          alignItems="center"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Total Net Net Sales ($)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="center"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Total Volume (cases)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="center"
          justifyContent="center"
          className="text-sm border-solid border-0 border-r-[1px] border-r-background-60 text-center"
        >
          Total Gross Profit ($)
        </Grid>
        <Grid
          item
          flex={1}
          container
          alignItems="center"
          justifyContent="center"
          className="text-sm border-solid border-0 text-center"
        >
          Total RSV ($)
        </Grid>
      </Grid>
    </Grid>
  );
};

type KpiItemProps = {
  variant?: "baseline" | "maxSalesHighDisruption";
  value: any;
  iterator: any;
};

export const KpiItem: React.FC<KpiItemProps> = ({
  variant,
  value,
  iterator,
}) => {
  return (
    <Grid
      container
      className="pr-2 h-10 flex items-center justify-center"
      style={{
        background:
          variant == "baseline"
            ? "rgba(204, 210, 208, 0.3)"
            : variant == "maxSalesHighDisruption"
            ? "rgba(197, 170, 83, 0.3)"
            : "",
      }}
    >
      {formatNumber(value, 1)}
    </Grid>
  );
};

type KpiColProps = {
  children: JSX.Element | JSX.Element[];
};

export const KpiCol: React.FC<KpiColProps> = ({ children }) => {
  return (
    <Grid
      item
      flex={1}
      className="w-full border-solid border-0 border-r-[1px] border-r-background-60"
    >
      <Grid container className="flex flex-col text-center">
        {/* KPI Item */}
        {children}
      </Grid>
    </Grid>
  );
};

export const KpiRow: any = ({ data, iterator }) => {
  return (
    <Grid className="w-full" direction="row" container>
      <KpiCol>
        <KpiItem
          iterator={iterator}
          value={data.netRevenueRef}
          variant="baseline"
        />
        <KpiItem
          iterator={iterator}
          value={data.netRevenueMine}
          variant="maxSalesHighDisruption"
        />
      </KpiCol>
      <KpiCol>
        <KpiItem
          iterator={iterator}
          value={data.volumeUnitRef}
          variant="baseline"
        />
        <KpiItem
          iterator={iterator}
          value={data.volumeMine}
          variant="maxSalesHighDisruption"
        />
      </KpiCol>
      <KpiCol>
        <KpiItem
          iterator={iterator}
          value={data.marginRef}
          variant="baseline"
        />
        <KpiItem
          iterator={iterator}
          value={data.marginMine}
          variant="maxSalesHighDisruption"
        />
      </KpiCol>
      <KpiCol>
        <KpiItem iterator={iterator} value={data.rsvRef} variant="baseline" />
        <KpiItem
          iterator={iterator}
          value={data.rsvMine}
          variant="maxSalesHighDisruption"
        />
      </KpiCol>
    </Grid>
  );
};

type KpisProps = {
  calendar: any;
  referenceCalendar: any;
  timelineData: any;
  ownProducts: boolean;
  stickyRef: any;
  topPos: number;
};

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1.75rem",
  },
  twoTimelines: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3.5rem",
  },
  threeTimelines: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3.5rem",
  },
}));

export const Kpis: React.FC<KpisProps> = ({
  calendar,
  referenceCalendar,
  timelineData,
  ownProducts,
  stickyRef,
  topPos,
}) => {
  const [kpis, setData] = useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
    if (
      timelineData != undefined &&
      timelineData != null &&
      Object.keys(calendar).length &&
      Object.keys(calendar).length &&
      calendar.events.length > 0
    ) {
      if (ownProducts && Object.keys(timelineData.products).length) {
        let data: any = [];
        for (const [key, value] of Object.entries(timelineData.products)) {
          data.push(value.kpis);
        }
        let out: any = [];
        data.forEach((item) => {
          out.push({
            netRevenueRef: item?.net_revRef | 0,
            volumeUnitRef: item?.volumeRef | 0,
            marginRef: item?.profitRef | 0,
            rsvRef: item?.rsvRef | 0,
            netRevenueMine: item?.net_rev | 0,
            volumeMine: item?.volume | 0,
            marginMine: item?.profit | 0,
            rsvMine: item?.rsv | 0,
          });
        });
        setData(out);
      }
      if (!ownProducts && Object.keys(timelineData.competitorProducts).length) {
        let data: any = [];
        for (const [key, value] of Object.entries(
          timelineData.competitorProducts
        )) {
          data.push(value.kpis);
        }
        let out: any = [];
        data.forEach((item) => {
          out.push({
            netRevenueRef: item?.net_revRef | 0,
            volumeUnitRef: item?.volumeRef | 0,
            marginRef: item?.profitRef | 0,
            rsvRef: item?.rsvRef | 0,
            netRevenueMine: item?.net_rev | 0,
            volumeMine: item?.volume | 0,
            marginMine: item?.profit | 0,
            rsvMine: item?.rsv | 0,
          });
        });
        setData(out);
      }
    }
  }, [calendar, referenceCalendar]);

  return (
    <Grid className="w-[75rem] max-w-[25%]  bg-white border-solid border-l-[1px] border-l-background-50 mt-16 pt-4 overflow-y-hidden">
      <KpisTitle stickyRef={stickyRef} topPos={topPos} />
      <Grid className="">
        <Grid container className="w-full h-full relative">
          <Grid
            container
            className="w-full h-full absolute  top-0 left-0 z-9999"
          >
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid
              item
              flex={1}
              className="h-full border-solid border-r-[1px] border-r-background-50"
            ></Grid>
            <Grid item flex={1} className="h-full "></Grid>
          </Grid>
          {/* KPI Class */}
          <Grid container className="flex flex-col w-full h-full">
            {kpis.map((kpi, i) => {
              return (
                <Grid
                  sx={{
                    width: "100%",
                  }}
                  className={classes.twoTimelines}
                >
                  <KpiRow data={kpi} iterator={Number(i)} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
