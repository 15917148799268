export enum CALENDAR_STATUS {
  PROCESSED = "Processed",
  PROCESSING = "Processing",
  COMPLETE = "COMPLETE",
}

export enum PROMO_SIMULATION_FORM_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

export enum CHART_TYPE {
  PROFIT_WATERFALL_CPG = "profit_waterfall_cpg",
  PROFIT_WATERFALL_RETAILER = "profit_waterfall_retailer",
  SALES_WATERFALL_CPG = "sales_waterfall_cpg",
  SALES_WATERFALL_RETAILER = "sales_waterfall_retailer",
}

export enum GROUP_TYPE {
  RETAILER = "RETAILER",
  PRODUCT = "PRODUCT",
}

export enum CALENDAR_TYPE {
  CURRENT = "CURRENT",
  REFERENCE = "REFERENCE",
}

export enum HEATMAP_KPI {
  ROI = "ROI",
  TOTAL_INCREMENTAL_SALES = "Total Incremental Sales",
  TOTAL_INCREMENTAL_MARGIN = "Total Incremental Margin",
}
export enum PROMO_ACTION_TYPE {
  ADD = "ADD",
  MODIFY = "MODIFY",
}

export enum PROMO_TIMELINE_TYPE {
  NORMAL = "NORMAL",
  COMPARISON = "COMPARISON",
}
