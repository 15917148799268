import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { CalendarCreation } from "./calendarCreation/CalendarCreation";
import CalendarManagement from "./calendarManagement/CalendarManagement";
import CalendarComparison from "./calendarComparison/CalendarComparison";
import { CalendarViewEdit } from "./calendarViewEdit/CalendarViewEdit";
import { Route, Link, useLocation, Routes } from "react-router-dom";
import { useStyles } from "./CalendarRouterStyle";
import theme from "../../../utils/theme";
import { ConstraintsManagement } from "./constraintsManagement/constraintsManagement";
import { EditConstraintsManagement } from "./editConstraintsManagement/editConstraintsManagement";
import CalendarAggregation from "./calendarAggregation/CalendarAggregation";

type CalendarRouterProps = {
  selectedMarket: string
};

const CalendarRouter = (props: CalendarRouterProps) => {
  const { selectedMarket } = props;

  const classes = useStyles();
  const location = useLocation();
  const currentLocation = location.pathname === "/promo/recommendation" ? "/promo/recommendation/management" : location.pathname;

  return (
    <div style={{ width: "100%" }}>
      <Tabs
        value={currentLocation}
        className={classes.tollTab}
        TabIndicatorProps={{
          style: { background: theme.palette.primary.light },
        }}
      >
        <Tab className={classes.tabStyle} label="Calendar Management" value="/recommendation" to="/recommendation" component={Link} />
        <Tab className={classes.tabStyle} label="Calendar View & Edit" value="/recommendation/create" to="/recommendation/create" component={Link} />
        {/*<Tab className={classes.tabStyle} label="Calendar Comparison" value="/recommendation/comparison" to="/recommendation/comparison" component={Link} />*/}
      </Tabs>
      <div className={classes.root}>
        <Routes>
          <Route path="/" element={<CalendarManagement selectedMarket={selectedMarket}/>} />
          <Route path="/management" element={<CalendarManagement selectedMarket={selectedMarket}/>} />
          <Route path="/create" element={<CalendarCreation selectedMarket={selectedMarket}/>} />
          <Route path="/constraints-sets" element={<ConstraintsManagement />} />
          <Route path="/constraints-sets/:id/edit" element={<EditConstraintsManagement />} />
          <Route path="/comparison" element={<CalendarComparison />} />
          <Route path="/edit/:id" element={<CalendarViewEdit selectedMarket={selectedMarket} />} />
          <Route path="/aggregation" element={<CalendarAggregation />} />
        </Routes>
      </div>
    </div>
  );
};

export default CalendarRouter;
