import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../utils/store";
import { GlobalActionTypes } from "../../utils/redux/Global/GlobalActions";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  OutlinedInput,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useStyle, DrawerHeader, AppBar, Drawer } from "./FrameStyle";
import GEPPLogo from "../../utils/img/gepp_logo.png";
import styled from "styled-components";
import "./style.scss";
import theme from "utils/theme";

const StyledHeading = styled.h1`
  font-size: 25px;

  background: ${theme.palette.primary.light};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
`;

type DashboardLinks = {
  name: string;
  path: string;
  external?: boolean;
  params?: number;
};
type Children = {
  title?: string;
  links?: DashboardLinks[];
};
type DrawerItem = {
  label: string;
  link?: any;
  icon?: any;
  hasChildren: boolean;
  children?: Children[];
};
type FrameProps = {
  title: string;
  drawer: boolean;
  drawerItems: DrawerItem[];
  navItems: any;
  marketSelectOptions: string[];
  market: string;
  handleMarketChange: any;
};

const Frame = (props: FrameProps) => {
  const {
    title,
    drawer = false,
    navItems,
    drawerItems = [],
    market,
    handleMarketChange,
    marketSelectOptions,
  } = props;
  const classes = useStyle();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [indexList, setIndexList] = React.useState<number[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.reducer.GlobalReducer.drawerOpen
  );
  const [navOpen, setNavOpen] = React.useState(false);

  function nav(link: DashboardLinks) {
    setIndexList([]);
    dispatch({ type: GlobalActionTypes.CLOSEDRAWER, payload: {} });
    if (!link.external && link.params != null && link.params != undefined) {
      if (!link.external)
        navigate(link.path, {
          state: {
            tabNumber: link.params,
          },
        });
    } else if (!link.external) navigate(link.path);
    else window.location.href = link.path;
  }

  const handleDrawerOpen = () => {
    dispatch({ type: GlobalActionTypes.OPENDRAWER, payload: {} });
  };

  const handleDrawerClose = () => {
    setIndexList([]);
    dispatch({ type: GlobalActionTypes.CLOSEDRAWER, payload: {} });
  };
  const handleItemClickindex = (index: number) => {
    if (indexList.includes(index)) {
      setIndexList(indexList.filter((i) => i !== index));
    } else {
      setIndexList([...indexList, index]);
    }
  };

  function drawerComponent(drawer: boolean) {
    const rootUrl = ["/"];
    if (drawer)
      return (
        <Drawer
          variant="permanent"
          style={{ backgroundColor: "red" }}
          className={classes.drawerStyling}
          open={open}
          PaperProps={{
            elevation: 8,
            sx: {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}
        >
          <DrawerHeader>
            {open && (
              <Grid width={110} mx={"auto"}>
                <img src={GEPPLogo} alt="GEPP" />
              </Grid>
            )}
            <IconButton
              onClick={handleDrawerClose}
              className={classes.iconButton}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon sx={{ color: theme.palette.primary.text }} />
              ) : (
                <ChevronLeftIcon sx={{ color: theme.palette.primary.text }} />
              )}
            </IconButton>
          </DrawerHeader>
          <div className={classes.container}>
            {drawerItems.map((item: DrawerItem, index) => {
              //probably can be done other way!
              const isActiveDrawer =
                item.link.path.length === 1
                  ? location.pathname === item.link.path
                  : location.pathname.includes(item.link.path);
              return (
                <TableCell
                  align={"center"}
                  sx={{
                    border: "none",
                    backgroundColor: isActiveDrawer
                      ? theme.palette.primary.light
                      : "",
                    height: "auto",
                    borderRadius: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      alignContent: "center",
                    }}
                  >
                    <div onClick={() => nav(item.link)}>
                      {item.icon && (
                        <item.icon
                          style={{
                            fontSize: 30,
                            color: isActiveDrawer
                              ? theme.palette.primary.contrastText
                              : theme.palette.primary.text,
                          }}
                        />
                      )}
                    </div>
                    <Button
                      color="secondary"
                      sx={{
                        minWidth: 260,
                        justifyContent: "space-between",
                        height: 45,
                      }}
                      onClick={() => {
                        item.hasChildren
                          ? handleItemClickindex(index)
                          : nav(item.link);
                      }}
                    >
                      <div className={classes.itemContainer}>
                        <Typography
                          align={"left"}
                          px={1}
                          fontSize={16}
                          fontWeight={600}
                          className={classes.textButton}
                          sx={{
                            opacity: open ? 1 : 0,

                            color: isActiveDrawer
                              ? theme.palette.primary.contrastText
                              : theme.palette.primary.text,
                          }}
                        >
                          {item.label}
                        </Typography>

                        <div>
                          {item.hasChildren &&
                            (indexList.includes(index) ? (
                              <ArrowDropUpIcon
                                sx={{
                                  color: isActiveDrawer
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.primary.text,
                                }}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                sx={{
                                  color: isActiveDrawer
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.primary.text,
                                }}
                              />
                            ))}
                        </div>
                      </div>
                    </Button>
                  </div>
                  <Collapse
                    in={indexList.includes(index)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ marginLeft: 7 }}>
                      {item.hasChildren &&
                        item.children &&
                        item.children.map((subItem: Children, subItemIndex) => {
                          const currentIndex = (subItemIndex + index + 1) * -1;
                          return (
                            <div>
                              {subItem.title != undefined && (
                                <Button
                                  color="secondary"
                                  sx={{
                                    minWidth: 235,
                                    justifyContent: "space-between",
                                  }}
                                  onClick={() =>
                                    handleItemClickindex(currentIndex)
                                  }
                                >
                                  <div className={classes.itemContainer}>
                                    <Typography
                                      alignItems={"left"}
                                      // px={1}
                                      fontSize={14}
                                      fontWeight={600}
                                      sx={{
                                        opacity: open ? 1 : 0,
                                        color: theme.palette.primary.text,
                                      }}
                                    >
                                      {subItem.title}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{ display: "flex", float: "right" }}
                                  >
                                    {indexList.includes(currentIndex) ? (
                                      <ArrowDropUpIcon
                                        sx={{
                                          color: theme.palette.primary.text,
                                        }}
                                      />
                                    ) : (
                                      <ArrowDropDownIcon
                                        sx={{
                                          color: theme.palette.primary.text,
                                        }}
                                      />
                                    )}
                                  </div>
                                </Button>
                              )}

                              <Collapse
                                in={
                                  indexList.includes(currentIndex) ||
                                  subItem.title === undefined
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                {subItem.links &&
                                  subItem.links.map((link, linkIndex) => {
                                    return (
                                      <div key={linkIndex}>
                                        <Button
                                          sx={{
                                            width: "100%",
                                            justifyContent: "space-between",
                                          }}
                                          onClick={() => nav(link)}
                                        >
                                          <Typography
                                            fontSize={12}
                                            sx={{
                                              opacity: open ? 1 : 0,
                                              color: theme.palette.primary.text,
                                            }}
                                          >
                                            {link.name}
                                          </Typography>
                                        </Button>
                                      </div>
                                    );
                                  })}
                              </Collapse>
                            </div>
                          );
                        })}
                    </Box>
                  </Collapse>
                </TableCell>
              );
            })}
          </div>
        </Drawer>
      );
    return null;
  }

  function drawerIcon(drawer: boolean) {
    if (drawer)
      return (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 2,
            paddingLeft: "15px",
            color: theme.palette.primary.text,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
      );
    return null;
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: "space-between", height: 70 }}>
          {drawerIcon(drawer)}
          <Grid container alignItems={"center"} gap={4} direction={"row"}>
            {!open && (
              <Grid width={110} my={0.5}>
                <img src={GEPPLogo} alt="GEPP" />
              </Grid>
            )}
            <div
              className="titleIcon"
              onClick={() => setNavOpen(true)}
              style={{
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StyledHeading>{title}</StyledHeading>
                <ArrowDropDownIcon
                  sx={{ marginLeft: 1, color: theme.palette.primary.text }}
                />
              </div>
            </div>
          </Grid>
          {!location.pathname.includes("/diagnostics") ? (
            <Grid
              container
              mobile={7}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography color={"primary.text"}>Market</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="global-select-2"
                value={market}
                className={classes.marketSelect}
                size="small"
                onChange={(event) => handleMarketChange(event.target.value)}
                sx={{
                  marginLeft: 1,
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.light,
                  },
                }}
              >
                {marketSelectOptions?.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option === "market_code-MX"
                        ? "NATIONAL"
                        : option.split("-")[1]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          ) : null}
        </Toolbar>
      </AppBar>
      {drawerComponent(drawer)}
      <Box
        sx={{
          background: `${theme.palette.primary.background}`,
          boxShadow: "4px 3px 2px rgba(0, 0, 0, 0.1)",
        }}
        hidden={!navOpen}
        onMouseLeave={() => setNavOpen(false)}
        className={
          drawer
            ? (open ? "navDropdownOpen" : "navDropdownClosed") + " navDropdown"
            : "navDropdown"
        }
      >
        <List sx={{ backgroundColor: `${theme.palette.primary.background}` }}>
          {navItems.map((data) => {
            return (
              <ListItem
                disablePadding
                sx={{
                  color: `${theme.palette.primary.text}`,
                  backgroundColor: `${theme.palette.primary.background}`,
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
                onClick={() => nav(data.link)}
                key={data.text}
              >
                {data.text}
              </ListItem>
            );
          })}
        </List>
        <Divider
          sx={{
            background: `${theme.palette.primary.text}`,
            borderColor: `${theme.palette.primary.text}`,
          }}
        />
        <Typography
          variant="body2"
          color="primary.text"
          mt={1}
          sx={{ cursor: "pointer" }}
          onClick={() => nav({ path: "/", external: false, name: "" })}
        >
          Go to Suite Hub
        </Typography>
      </Box>
    </>
  );
};
export default Frame;
