import { makeStyles, withStyles, createStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 4),
      borderRadius: "4px",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.primary.background_2,
    },
    kpiDataWidgetSection: {
      width: "100%",
      height: 400,
    },
    formContainer: {
      padding: theme.spacing(1, 1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    formContainerInputs2: {
      display: "flex",
      padding: theme.spacing(1, 0, 0, 0),

      lineHeight: 1.1,
      gap: 14,
    },
    formContainerInputs: {
      display: "flex",
      padding: theme.spacing(1, 1),
      justifyContent: "space-between",
      gap: "14px",
    },
    select: {
      width: 72,
      height: 32,
    },
    displaySelect: {
      width: 145,
      height: 32,
    },
    primaryButton: {
      gap: "14px",
      height: 32,
      float: "right",
      display: "flex",
      justifyContent: "space-between",
    },
    leftButtons: {
      padding: theme.spacing(1, 1),
      justifyContent: "right",
      // gap: "14px",
    },
    saveDeletePromo: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "right",
      gap: "14px",
    },
    savePromoButton: {
      backgroundColor: "black",
    },
  })
);
