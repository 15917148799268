import React from "react";

import { landingCards } from "../../utils/constants";

import PromoCard from "../../../components/PromoCard/promoCard";
import { Grid, Typography } from "@mui/material";
import { useStyle } from "./LandingPageStyle";
import { useAppSelector } from "utils/hooks";

const LandingPage = () => {
  const classes = useStyle();

  const user = useAppSelector((state) => state.reducer.GlobalReducer.user);

  return (
    <div className={classes.root}>
      <Grid
        mobile={12}
        laptop={12}
        laptop_lg={12}
        tablet={12}
        sx={{ marginBottom: 2 }}
      >
        <Typography fontWeight={600} fontSize={20}>
          Hi {user.firstName}, welcome to the Promo.AI tool!
        </Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        justifyContent={"center"}
        sx={{
          flexWrap: "nowrap",
          display: "flex",
          // overflowY: "auto",
          minWidth: "1280px",
        }}
      >
        {landingCards.map((card: any, index) => (
          <Grid key={index} ml={3} mt={4} flex={1} maxWidth={480}>
            <PromoCard
              key={index}
              title={card.title}
              sub={card.sub}
              icon={
                <card.icon
                  style={{
                    fill: "secondary.main",
                    minHeight: "120px",
                    minWidth: "120px",
                    margin: "0px auto",
                  }}
                />
              }
              link={card.nav}
              buttons={card.buttons}
              notifications={card.notifications}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LandingPage;
