import axios from 'axios'
import { ClientData, CondensedClientData } from "../../types";

export enum GlobalActionTypes {
  OPENDRAWER = "OPENDRAWER",
  CLOSEDRAWER = "CLOSEDRAWER",
  FETCH_RETAIL_DATA = "FETCH_RETAIL_DATA",
  FETCH_RETAIL_DATA_SUCCESS = "FETCH_RETAIL_DATA_SUCCESS",
  FETCH_USER_INFO = "FETCH_USER_INFO",
  FETCH_USER_INFO_SUCESS = "FETCH_USER_INFO_SUCESS",
  FETCH_USER_INFO_REJECTED = "FETCH_USER_INFO_REJECTED",
  FETCH_USER_CALLBACK = "FETCH_USER_CALLBACK",
  FETCH_USER_CALLBACK_SUCESS = "FETCH_USER_CALLBACK_SUCESS",
  FETCH_USER = "FETCH_USER",
  FETCH_USER_SUCESS = "FETCH_USER_SUCESS",
  INVALIDATE_USER = "INVALIDATE_USER"

}

export function getCurrentUser() {
    return async function (dispatch: any, getState: any) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_PROMO_API_URL}/user`);
          const user = response.data
          dispatch({
            type: GlobalActionTypes.FETCH_USER_SUCESS,
            payload: user,
          });
        } catch (e) {}
    };
}

export function handleUserCallback(code: string, state: string, navigate: any){
    return async function (dispatch: any, getState: any) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_PROMO_API_URL}/user/auth/callback?code=${code}&state=${state}`);
          const user = response.data
          dispatch({
            type: GlobalActionTypes.FETCH_USER_CALLBACK_SUCESS,
            payload: user,
          });
          navigate('/')
        } catch (e) {}
    };
}

// CODE-SMELL Need a better plan for this long term
export function getClientData(currentMarket: string) {
  return async function (dispatch: any, getState: any) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/client?market=${currentMarket}`
      );
      
      const clientData = response.data.clientData as ClientData
      const condensedClientData = response.data.condensedClientData as CondensedClientData

      dispatch({
        type: GlobalActionTypes.FETCH_RETAIL_DATA_SUCCESS,
        payload: {
          clientData,
          condensedClientData,
        },
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}
