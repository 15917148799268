import { promoKPIStyles } from "./styles";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Tooltip,
  Grid,
} from "@mui/material";
import { typeKPIs } from "../../utils/types";
import { formatNumber } from "../../utils/mapper";
import theme from "../../../utils/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export enum PROMO_KPI_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

type PromoKPIProps = {
  title: string;
  KPIs: typeKPIs[];
};

const PromoKPI = (props: PromoKPIProps) => {
  const classes = promoKPIStyles();
  const { KPIs, title } = props;

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary
          style={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justifyContent={"space-between"}>
            <Grid container mobile>
              <Typography variant="h3" mr={1}>
                {title}
              </Typography>
              <Tooltip
                title={
                  <Typography
                    fontSize={14}
                    color={theme.palette.primary.contrastText}
                    lineHeight={1.8}
                  >
                    Incrementals are calculated against selected reference
                    calendar
                  </Typography>
                }
                placement="top"
                arrow
              >
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography variant="body3" lineHeight={1.9}>
                    (vs. reference calendar)
                  </Typography>
                  <InfoIcon
                    fontSize="small"
                    sx={{
                      color: "text.secondary",
                      fontSize: 14,
                      mt: 0.3,
                      ml: 0.5,
                    }}
                  />
                </Grid>
              </Tooltip>
            </Grid>
            <Box component={"div"} display={"flex"} alignItems={"center"}>
              <InfoIcon fontSize="small" sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={14}
                fontWeight={500}
                fontStyle={"italic"}
                color={"text.secondary"}
              >
                Numbers include pull forward and cannibalization results
              </Typography>
            </Box>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {KPIs.map((kpi, index) => {
              return (
                <Tooltip
                  title={
                    <Typography
                      fontSize={14}
                      color={theme.palette.primary.contrastText}
                      lineHeight={1.8}
                      whiteSpace={"pre-line"}
                    >
                      {kpi.definition}
                    </Typography>
                  }
                  placement="top"
                  arrow
                >
                  <Card key={index} variant="outlined" className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                        variant="h6"
                      >
                        {kpi.name}
                      </Typography>
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <Typography
                          variant="h1"
                          component="div"
                          sx={{
                            color:
                              kpi.value >= 0
                                ? "#161616"
                                : theme.palette.error.main,
                          }}
                        >
                          {kpi.value < 0 && "-"}
                          {kpi.symbol === "$" ? kpi.symbol : ""}
                          {formatNumber(Math.abs(kpi.value), 2, kpi.symbol)}
                          {kpi.symbol === "$" ? "" : kpi.symbol}
                        </Typography>

                        {kpi.updatePercentage != null &&
                          Math.abs(kpi.updatePercentage) > 0 && (
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                variant="h8"
                                component="div"
                                sx={{
                                  color:
                                    kpi.updatePercentage >= 0
                                      ? theme.palette.success.main
                                      : theme.palette.error.main,
                                }}
                              >
                                {formatNumber(kpi.updatePercentage)}%
                              </Typography>
                              {kpi.fromValue != null && kpi.fromValue != 0 && (
                                <Typography
                                  variant="h8"
                                  sx={{ fontWeight: 400 }}
                                >
                                  from {kpi.symbol === "$" && kpi.symbol}
                                  {formatNumber(kpi.fromValue, 2)}
                                  {kpi.symbol === "%" && kpi.symbol}
                                </Typography>
                              )}
                            </Box>
                          )}
                      </Box>
                    </CardContent>
                  </Card>
                </Tooltip>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PromoKPI;
