import { Grid, Input, MenuItem, Select, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const maximizationGoals = [
  "max net net sales",
  "max gross profit",
  "max volume",
];

export function ObjectivesForm({ errors, register }: any) {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Typography variant="h3" mb={1}>
        Objectives
      </Typography>

      <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Maximization goals*</label>
        </Grid>
        <Grid>
          <Select
            {...register("maximizationGoals")}
            defaultValue="max net net sales"
            className="input input_large select_input"
            size="small"
          >
            {maximizationGoals.map((value, i) => {
              const withoutFirstWord = value.split(" ").slice(1).join(" ");
              return (
                <MenuItem key={i} value={value}>
                  <Typography align="left" fontSize={14}>
                    {withoutFirstWord.charAt(0).toLocaleUpperCase() +
                      withoutFirstWord.slice(1)}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid>
          <div>{errors?.maximizationGoals?.message}</div>
        </Grid>
      </Grid>

      {/* <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Net sales (%) of last financial year</label>
          <div>
            <small>Last year: 32.124k</small>
          </div>
        </Grid>
        <Grid>
          <Input
            className="input input_large"
            {...register("netSales")}
            defaultValue="95"
            size="small"
          />
        </Grid>
        <Grid>
          <div>{errors?.netSales?.message}</div>
        </Grid>
      </Grid> */}

      {/* <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Gross margin (%)</label>
        </Grid>
        <Grid>
          <Input
            className="input input_large"
            {...register("grossMargin")}
            defaultValue="0"
            size="small"
          />
        </Grid>
        <Grid>
          <div>{errors?.grossMargin?.message}</div>
        </Grid>
      </Grid> */}

      {/* <Grid container direction="column">
        <Grid sx={{ fontWeight: "bold" }}>
          <label>Market share (%)</label>
        </Grid>
        <Grid>
          <Input
            className="input input_large"
            {...register("marketShare")}
            defaultValue="0"
            size="small"
          />
        </Grid>
        <Grid>
          <div>{errors?.marketShare?.message}</div>
        </Grid>
      </Grid> */}
    </Grid>
  );
}
