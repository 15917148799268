import { AnyAction } from "@reduxjs/toolkit";
import { addDays } from "date-fns";
import { memo, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../../utils/hooks";
import { ITimelineEventData } from "../../../calenderComparsionTimeline/interfaces";
import { calcActivityWidth } from "../../../calenderComparsionTimeline/utils/activityWidth";
import Tooltip from "@mui/material/Tooltip";

const DAY_WIDTH = 36;

interface IProps {
  factual: AnyAction;
  variant?: "baseline" | "maxSalesHighDisruption";
  startTime: string;
}

function Activities({ factual, variant, startTime }: IProps) {
  const reducedActivity = calcActivityWidth(
    startTime,
    new Date(factual.date_start).toISOString(),
    new Date(factual.date_end).toISOString()
  );

  return (
    <div
      className={`flex items-center absolute border-solid border border-gray-400 px-1 rounded-md bg-gray-60 h-8`}
      style={{
        background: "#E6E6E6",
        marginLeft: (Math.abs(reducedActivity.startPoint) / 7) * DAY_WIDTH,
      }}
    >
      <div
        className="h-5 bg-timeTags-t1 z-10 rounded-md flex items-center px-1 justify-between"
        style={{
          width: (reducedActivity.width / 7) * DAY_WIDTH, // 50px/7 per day
          background:
            variant == "baseline"
              ? "#CCD2D0"
              : variant == "maxSalesHighDisruption"
              ? "#C5AA53"
              : "",
        }}
      >
        <Tooltip
          title={
            <div>
              {factual.promo_desc}
              <br />
              {new Date(factual.date_start).toLocaleDateString() +
                "-" +
                new Date(factual.date_end).toLocaleDateString()}
            </div>
          }
        >
          <span className="text-sm text-black">
            {factual.promo_desc.length < ((reducedActivity.width / 7) * 20) / 8
              ? factual.promo_desc
              : `${factual.promo_desc.slice(
                  0,
                  ((reducedActivity.width / 7) * 20) / 8
                )}...`}
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default memo(Activities);
