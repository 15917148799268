import { Box, MenuItem, Select, Typography } from "@mui/material";
import { InputCard } from "../../pages/calendar/calendarCreation/constraints/ContraintsStyle";
import { ConstraintsCardRange } from "../constraintsCardRange/ConstraintsCardRange";

import TrashIcon from "../../img/trash_icon.svg";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  applyReferenceFormula,
  formatNumber,
} from "../../../Promo/utils/mapper";
import { ConstraintsCardSlider } from "../constraintsCardSlider/ConstraintsCardSlider";
import { ConstraintsCardRangeInteger } from "../constraintsCardRange/ConstraintsCardRangeInteger";
import { ConstraintsCardInteger } from "../constraintsCardSlider/ConstraintsCardInteger";

const BoxWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: "0.8rem",
});

const emptyHint = [{ value: 50, label: "", rawValue: 0 }];

const SelectCustom = ({ children, ...props }) => (
  <Select
    {...props}
    sx={{
      maxWidth: "340px",
      // minWidth: "140px",
      // width: "auto",
    }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 400,
        },
      },
    }}
  >
    {children}
  </Select>
);

type ConstraintsCardSelectType = {
  key_id?: Number;
  type: "calendar" | "brand" | "product";
  setValue: any;
  constraints: any[];
  objectToSave: any;
  getConstraints?: any[];
  presetValue?: any;
  brands?: any[];
  getBrands?: any[];
  products?: any[];
  getProducts?: any[];
  brandsRefValues?: any;
  productsRefValues?: any;
  onDelete?: any;
  saveAll?: any;
  usedConstraints?: any;
  UpdateUsedConstraints?: any;
};

const value = 50;

export function ConstraintsCardSelect({
  key_id,
  type,
  constraints,
  objectToSave,
  brands,
  presetValue,
  brandsRefValues,
  productsRefValues,
  onDelete = () => null,
  saveAll = () => null,
  usedConstraints,
  UpdateUsedConstraints,
}: ConstraintsCardSelectType) {
  const [selectConstraint, setSelectConstraint] = useState("");
  const [selectBrand, setSelectBrand] = useState("");
  const [selectProduct, setSelectProduct] = useState("");
  const [selectedConstraints, setSelectedConstraint] = useState<any>();
  const [marks, setMarks] = useState<any>();
  const [products, setProducts] = useState<any[]>();
  const [formField, setFormField] = useState<any>(null);
  const CONSTRAINTS_SLIDER_AS_INTEGER = [
    "No. weeks on promo",
    "Min Cooldown Weeks",
    "Consecutive Weeks on Promo",
    "Promo spend",
    "Promo spend per case",
    "Minimum profit",
    "Minimum Sales",
    "Minimum RSV",
  ];

  const uniqueInternalCodes = new Set<string>();

  useEffect(() => {
    if (brands && selectBrand) {
      const products: any = [];
      const filteredBrands = brands.filter(
        (x) => x.internal_code === selectBrand
      );
      filteredBrands.forEach((brand) => {
        products.push(...brand.products);
      });
      setProducts(products);
      if (presetValue && presetValue.level === "PRODUCT") {
        presetValue && setSelectProduct(presetValue.internal_code);
      }
    }
  }, [selectBrand]);

  useEffect(() => {
    if (type === "brand" && selectBrand && selectConstraint) {
      const mark: any[] = [];
      let brand = brands?.filter(
        (brand) => brand.internal_code === selectBrand
      )[0];
      const temporaryConstraint =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      if (brandsRefValues) {
        Object.keys(brandsRefValues).forEach((key) => {
          if (key === temporaryConstraint.refLabel) {
            const referenceValue = brandsRefValues[key].filter(
              (x) => x.name === brand.internal_code
            );

            if (referenceValue.length > 0) {
              mark.push({
                value,
                label: "Ref " + formatNumber(referenceValue[0].value),
                rawValue: referenceValue[0].value,
              });
            }
          }
        });
      }

      // If brand name is not found in the reference object
      // A default marker is set
      if (mark.length < 1) {
        mark.push({ value, label: "Ref 10*", rawValue: null });
      }

      const constraint =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      const constraints_names = constraint.constraints_names.map(
        (x) => `brands.brand-${brand.internal_code}.${x}`
      );

      setMarks(mark);
      setFormField({
        names: constraints_names,
        rawValue: constraint.marks.rawValue,
      });
    }

    if (
      type === "product" &&
      selectBrand &&
      selectProduct &&
      selectConstraint &&
      products
    ) {
      const mark: any[] = [];
      const product = products?.filter(
        (x) => x.internal_product_code === selectProduct
      )[0];
      const temporaryConstraint =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];
      if (productsRefValues) {
        Object.keys(productsRefValues).forEach((key) => {
          if (key === temporaryConstraint.refLabel) {
            const referenceValue = productsRefValues[key].filter(
              (x) => x.name === product.internal_product_code
            );

            if (referenceValue.length > 0) {
              mark.push({
                value,
                label: "Ref " + formatNumber(referenceValue[0].value),
                rawValue: referenceValue[0].value,
              });
            }
          }
        });
      }

      if (mark.length < 1) {
        mark.push({ value, label: "Ref 10*", rawValue: null });
      }
      const constraint =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      const constraints_names = constraint.constraints_names.map(
        (x) => `products.product-${product.internal_product_code}.${x}`
      );
      setMarks(mark);
      setFormField({
        names: constraints_names,
        rawValue: constraint.marks.rawValue,
      });
    }

    if (type === "calendar" && selectConstraint) {
      const constraint =
        constraints[constraints?.findIndex((x) => x.name === selectConstraint)];

      setSelectedConstraint(constraint);
      // if (CONSTRAINTS_SLIDER_AS_INTEGER.includes(constraint.name)) {
      //   constraint.marks[0].value = constraint.marks[0].rawValue;
      // }
      setMarks(constraint.marks);
      setFormField({
        names: constraint.constraints_names,
        rawValue: constraint.marks.rawValue,
      });
    }
  }, [selectConstraint, selectBrand, selectProduct]);

  useEffect(() => {
    if (presetValue) {
      const object =
        constraints[
          constraints.findIndex((x) => x.presetKey === presetValue.name)
        ];

      if (presetValue.level === "BRAND") {
        setSelectBrand(presetValue.internal_code);
      }
      if (presetValue.level === "PRODUCT") {
        setSelectBrand(presetValue.brand);
      }
      setSelectConstraint(object.name);

      const constraints_deepcopy = JSON.parse(JSON.stringify(constraints));
      const constraint = constraints_deepcopy.filter(
        (x) => x.presetKey === presetValue.name
      )[0];
      // update usedConstraints with id and name of the constraint and check if id already exists and update the name
      const usedConstraints_deepcopy = JSON.parse(
        JSON.stringify(usedConstraints)
      );
      const usedStaticConstraint = usedConstraints_deepcopy.filter(
        (x) => x.id === key_id
      )[0];
      if (!usedStaticConstraint) {
        UpdateUsedConstraints((state) => [
          ...state,
          {
            id: key_id,
            name: constraint.name,
            brand_id:
              presetValue.level === "BRAND"
                ? presetValue.internal_code
                : presetValue.level === "PRODUCT"
                ? presetValue.brand
                : "",
            product_id:
              presetValue.level === "PRODUCT" ? presetValue.internal_code : "",
          },
        ]);
      }
    }
  }, [presetValue]);

  function handleSliderInputValue(
    value: number,
    refValue: number,
    percentage: number
  ) {
    if (selectConstraint) {
      const activeCostraints =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      objectToSave.value = [applyReferenceFormula("MAX", percentage, refValue)];
      objectToSave.percentages = [percentage];
      objectToSave.constraint = activeCostraints.constraints_names;

      if (selectBrand) {
        objectToSave.brandId = brands?.filter(
          (brand) => brand.internal_code === selectBrand
        )[0].internal_code;

        if (selectProduct) {
          objectToSave.productId = products?.filter(
            (x) => x.internal_product_code === selectProduct
          )[0].internal_product_code;
        }
      }
    }

    saveAll();
  }
  function handleIntegerInputValue(value: number) {
    if (selectConstraint) {
      const activeCostraints =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      objectToSave.value = [value];
      objectToSave.percentages = [null];
      objectToSave.constraint = activeCostraints.constraints_names;

      if (selectBrand) {
        objectToSave.brandId = brands?.filter(
          (brand) => brand.internal_code === selectBrand
        )[0].internal_code;

        if (selectProduct) {
          objectToSave.productId = products?.filter(
            (x) => x.internal_product_code === selectProduct
          )[0].internal_product_code;
        }
      }
    }

    saveAll();
  }

  function handleRangeInputValue(values: number[], refValue: number) {
    if (selectConstraint) {
      const activeConstraints =
        constraints[constraints.findIndex((x) => x.name === selectConstraint)];

      if (CONSTRAINTS_SLIDER_AS_INTEGER.includes(activeConstraints.name)) {
        objectToSave.value = [values[0], values[1]];
        objectToSave.percentages = [values[0], values[1]];
      } else {
        objectToSave.value = [
          applyReferenceFormula("MIN", values[0], refValue),
          applyReferenceFormula("MAX", values[1], refValue),
        ];
        objectToSave.percentages = [values[0], values[1]];
      }
      objectToSave.constraint = activeConstraints.constraints_names;

      if (selectBrand) {
        objectToSave.brandId = brands?.filter(
          (brand) => brand.internal_code === selectBrand
        )[0].internal_code;

        if (selectProduct) {
          objectToSave.productId = products?.filter(
            (x) => x.internal_product_code === selectProduct
          )[0].internal_product_code;
        }
      }
    }

    saveAll();
  }

  function handleConstraintChange(name) {
    setSelectConstraint(name);

    const constraints_deepcopy = JSON.parse(JSON.stringify(constraints));
    const constraint = constraints_deepcopy.filter((x) => x.name === name)[0];

    // update usedConstraints with id and name of the constraint and check if id already exists and update the name
    const usedConstraints_deepcopy = JSON.parse(
      JSON.stringify(usedConstraints)
    );

    const usedStaticConstraint = usedConstraints_deepcopy.filter(
      (x) => x.id === key_id
    )[0];

    if (usedStaticConstraint) {
      usedStaticConstraint.name = constraint.name;
    } else {
      usedConstraints_deepcopy.push({
        id: key_id,
        name: constraint.name,
      });
    }

    UpdateUsedConstraints(usedConstraints_deepcopy);
  }

  return (
    <InputCard
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "nowrap",
        gap: ".5rem",
      }}
    >
      <BoxWrapper>
        {type === "calendar" && constraints && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body3" sx={{ fontWeight: "600" }}>
              Constraint
            </Typography>
            <SelectCustom
              size="small"
              defaultValue=""
              value={selectConstraint}
              onChange={(e) => handleConstraintChange(e.target.value)}
            >
              {constraints
                .filter(
                  (x) =>
                    x.isActive === false &&
                    !usedConstraints.some(
                      (y) => y.name === x.name && y.id !== key_id
                    )
                )
                .map((option) => (
                  <MenuItem value={option.name}>
                    <Typography variant="body3">{option.name}</Typography>
                  </MenuItem>
                ))}
            </SelectCustom>
          </Box>
        )}
        {type === "brand" && (
          <>
            {brands && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body3" sx={{ fontWeight: "600" }}>
                  Brand
                </Typography>
                <SelectCustom
                  size="small"
                  value={selectBrand}
                  onChange={(e) => {
                    setSelectBrand(e.target.value);
                    setSelectConstraint("");
                  }}
                >
                  {brands
                    .filter((b) => {
                      if (
                        constraints.filter(
                          (x) =>
                            x.isActive === false &&
                            !usedConstraints.some(
                              (y) =>
                                y.name === x.name &&
                                y.brand_id === b.internal_product_code &&
                                y.id !== key_id
                            )
                        ).length > 0
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .filter((obj) => {
                      if (uniqueInternalCodes.has(obj.internal_code)) {
                        return false; // Filter out if already encountered
                      }
                      uniqueInternalCodes.add(obj.internal_code);
                      return true; // Keep if not yet encountered
                    })
                    .map((option) => {
                      return (
                        <MenuItem value={option.internal_code}>
                          <Typography variant="body3">
                            {option.internal_code}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </SelectCustom>
              </Box>
            )}
            {constraints && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body3" sx={{ fontWeight: "600" }}>
                  Constraint
                </Typography>
                <SelectCustom
                  size="small"
                  defaultValue=""
                  disabled={!selectBrand}
                  value={selectConstraint}
                  onChange={(e) => {
                    setSelectConstraint(e.target.value);
                    const constraints_deepcopy = JSON.parse(
                      JSON.stringify(constraints)
                    );
                    const constraint = constraints_deepcopy.filter(
                      (x) => x.name === e.target.value
                    )[0];
                    // update usedConstraints with id and name of the constraint and check if id already exists and update the name
                    const usedConstraints_deepcopy = JSON.parse(
                      JSON.stringify(usedConstraints)
                    );
                    const usedStaticConstraint =
                      usedConstraints_deepcopy.filter(
                        (x) => x.id === key_id
                      )[0];
                    if (usedStaticConstraint) {
                      usedStaticConstraint.name = constraint.name;
                      usedStaticConstraint.brand_id = selectBrand;
                    } else {
                      usedConstraints_deepcopy.push({
                        id: key_id,
                        name: constraint.name,
                        brand_id: selectBrand,
                      });
                    }
                    UpdateUsedConstraints(usedConstraints_deepcopy);
                  }}
                >
                  {constraints
                    .filter(
                      (x) =>
                        x.isActive === false &&
                        !usedConstraints.some(
                          (y) =>
                            y.name === x.name &&
                            y.brand_id === selectBrand &&
                            y.id !== key_id
                        )
                    )
                    .map((option) => (
                      <MenuItem value={option.name}>
                        <Typography variant="body3">{option.name}</Typography>
                      </MenuItem>
                    ))}
                </SelectCustom>
              </Box>
            )}
          </>
        )}
        {type === "product" && (
          <>
            {brands && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body3" sx={{ fontWeight: "600" }}>
                  Brand
                </Typography>
                <SelectCustom
                  size="small"
                  value={selectBrand}
                  onChange={(e) => {
                    setSelectBrand(e.target.value);
                    setSelectConstraint("");
                  }}
                >
                  {brands
                    .filter((b) => {
                      if (
                        b.products.filter((p) => {
                          if (
                            constraints.filter(
                              (x) =>
                                x.isActive === false &&
                                !usedConstraints.some(
                                  (y) =>
                                    y.name === x.name &&
                                    y.product_id === p.internal_product_code &&
                                    y.brand_id === b.internal_code &&
                                    y.id !== key_id
                                )
                            ).length > 0
                          ) {
                            return true;
                          }
                          return false;
                        }).length > 0
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .filter((obj) => {
                      if (uniqueInternalCodes.has(obj.internal_code)) {
                        return false; // Filter out if already encountered
                      }
                      uniqueInternalCodes.add(obj.internal_code);
                      return true; // Keep if not yet encountered
                    })
                    .map((option) => {
                      return (
                        <MenuItem value={option.internal_code}>
                          <Typography variant="body3">
                            {option.internal_code}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </SelectCustom>
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body3" sx={{ fontWeight: "600" }}>
                Product
              </Typography>
              <SelectCustom
                size="small"
                value={selectProduct}
                disabled={!selectBrand}
                onChange={(e) => {
                  setSelectProduct(e.target.value);
                  setSelectConstraint("");
                }}
              >
                {products &&
                  products
                    .filter((p) => {
                      if (
                        constraints.filter(
                          (x) =>
                            x.isActive === false &&
                            !usedConstraints.some(
                              (y) =>
                                y.name === x.name &&
                                y.product_id === p.internal_product_code &&
                                y.id !== key_id
                            )
                        ).length > 0
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .map((option) => (
                      <MenuItem value={option.internal_product_code}>
                        <Typography variant="body3">
                          {option.internal_product_code}
                        </Typography>
                      </MenuItem>
                    ))}
              </SelectCustom>
            </Box>
            {constraints && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body3" sx={{ fontWeight: "600" }}>
                  Constraint
                </Typography>
                <SelectCustom
                  size="small"
                  defaultValue=""
                  disabled={!selectProduct}
                  value={selectConstraint}
                  onChange={(e) => {
                    setSelectConstraint(e.target.value);
                    const constraints_deepcopy = JSON.parse(
                      JSON.stringify(constraints)
                    );
                    const constraint = constraints_deepcopy.filter(
                      (x) => x.name === e.target.value
                    )[0];
                    // update usedConstraints with id and name of the constraint and check if id already exists and update the name
                    const usedConstraints_deepcopy = JSON.parse(
                      JSON.stringify(usedConstraints)
                    );
                    const usedStaticConstraint =
                      usedConstraints_deepcopy.filter(
                        (x) => x.id === key_id
                      )[0];
                    if (usedStaticConstraint) {
                      usedStaticConstraint.name = constraint.name;
                      usedStaticConstraint.brand_id = selectBrand;
                      usedStaticConstraint.product_id = selectProduct;
                    } else {
                      usedConstraints_deepcopy.push({
                        id: key_id,
                        name: constraint.name,
                        brand_id: selectBrand,
                        product_id: selectProduct,
                      });
                    }
                    UpdateUsedConstraints(usedConstraints_deepcopy);
                  }}
                >
                  {constraints
                    .filter(
                      (x) =>
                        x.isActive === false &&
                        !usedConstraints.some(
                          (y) =>
                            y.name === x.name &&
                            y.brand_id === selectBrand &&
                            y.product_id === selectProduct &&
                            y.id !== key_id
                        )
                    )
                    .map((option) => (
                      <MenuItem value={option.name}>
                        <Typography variant="body3">{option.name}</Typography>
                      </MenuItem>
                    ))}
                </SelectCustom>
              </Box>
            )}
          </>
        )}
        {selectConstraint && marks && (
          <Box sx={{ marginBottom: "-15px" }}>
            {[1].map(() => {
              const constraint =
                constraints[
                  constraints?.findIndex((x) => x.name === selectConstraint)
                ];
              if (presetValue) {
                if (constraint.constraints_names.length === 1) {
                  if (CONSTRAINTS_SLIDER_AS_INTEGER.includes(constraint.name)) {
                    return (
                      <ConstraintsCardInteger
                        constraint={{ name: "" }}
                        marks={marks || emptyHint}
                        getValues={handleIntegerInputValue}
                        initialValues={[Number(presetValue?.MIN) || 0]}
                        currency={constraint?.currency ? true : false}
                        refDecimal={
                          selectConstraint === "Promo spend per case" ? 1 : 0
                        }
                      />
                    );
                  }
                  return (
                    <ConstraintsCardSlider
                      constraint={{ name: "" }}
                      marks={marks || emptyHint}
                      getValue={handleSliderInputValue}
                      initialValue={Number(presetValue?.MIN) || 100}
                    />
                  );
                }
                if (CONSTRAINTS_SLIDER_AS_INTEGER.includes(constraint.name)) {
                  return (
                    <ConstraintsCardRangeInteger
                      constraint={{ name: "" }}
                      marks={marks || emptyHint}
                      getValues={handleRangeInputValue}
                      initialValues={[
                        Number(presetValue?.MIN) || 0,
                        Number(presetValue?.MAX) || 10,
                      ]}
                      currency={constraint?.currency ? true : false}
                      refDecimal={
                        selectConstraint === "Promo spend per case" ? 1 : 0
                      }
                    />
                  );
                }
                return (
                  <ConstraintsCardRange
                    constraint={{ name: "" }}
                    marks={marks || emptyHint}
                    getValues={handleRangeInputValue}
                    initialValues={[
                      Number(presetValue?.MIN) || 100,
                      Number(presetValue?.MAX) || 1000,
                    ]}
                  />
                );
              }
              if (constraint.constraints_names.length === 1) {
                if (CONSTRAINTS_SLIDER_AS_INTEGER.includes(constraint.name)) {
                  return (
                    <ConstraintsCardInteger
                      constraint={{ name: "" }}
                      marks={marks || emptyHint}
                      getValues={handleIntegerInputValue}
                      initialValues={[Number(presetValue?.MIN) || 0]}
                      currency={constraint?.currency ? true : false}
                      refDecimal={
                        selectConstraint === "Promo spend per case" ? 1 : 0
                      }
                    />
                  );
                }
                return (
                  <ConstraintsCardSlider
                    constraint={{ name: "" }}
                    marks={marks || emptyHint}
                    getValue={handleSliderInputValue}
                  />
                );
              } else if (
                CONSTRAINTS_SLIDER_AS_INTEGER.includes(constraint.name)
              ) {
                return (
                  <ConstraintsCardRangeInteger
                    constraint={{ name: "" }}
                    marks={marks || emptyHint}
                    getValues={handleRangeInputValue}
                    initialValues={[
                      Number(presetValue?.MIN) || 0,
                      Number(presetValue?.MAX) || 10,
                    ]}
                    currency={constraint?.currency ? true : false}
                    refDecimal={
                      selectConstraint === "Promo spend per case" ? 1 : 0
                    }
                  />
                );
              }
              return (
                <ConstraintsCardRange
                  constraint={{ name: "" }}
                  marks={marks || emptyHint}
                  getValues={handleRangeInputValue}
                />
              );
            })}
          </Box>
        )}
      </BoxWrapper>
      <Box sx={{ marginLeft: "auto", margin: "0 1rem" }}>
        <img
          src={TrashIcon}
          alt="Delete constraint"
          onClick={() => onDelete()}
        />
      </Box>
    </InputCard>
  );
}
