import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { makeStyles } from "@mui/styles";

type SubBrand = {
  name: string;
};

type SubBrandMenuProps = {
  timelines: number;
  data: SubBrand[];
};

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    width: "100%",
    wordBreak: "break-all",
    height: "5rem",
    marginBottom: "3.5rem",
  },
  twoTimelines: {
    width: "100%",
    height: "5rem",
    wordBreak: "break-all",
    marginBottom: "3.5rem",
  },
  threeTimelines: {
    width: "100%",
    wordBreak: "break-all",
    height: "5rem",
    marginBottom: "6rem",
  },
}));

const SubBrandMenu: React.FC<SubBrandMenuProps> = ({ data, timelines }) => {
  const classes = useStyles();

  return (
    <div className="min-w-[10rem] px-4 py-5 bg-white">
      <FormGroup>
        <div className="flex flex-col mt-24 ml-2 gap-8">
          <div className="w-full">
            {data.map((subbrand) => (
              <div
                className={
                  timelines == 3
                    ? classes.threeTimelines
                    : timelines == 2
                    ? classes.twoTimelines
                    : timelines == 1
                    ? classes.oneTimeline
                    : ""
                }
              >
                <Typography variant="body1">{subbrand.name}</Typography>
              </div>

              // <FormControlLabel
              //   key={subbrand.name}
              //   label={subbrand.name}
              //   control={<></>}
              //   sx={}
              // />
            ))}
          </div>
        </div>
      </FormGroup>
    </div>
  );
};

export default SubBrandMenu;
