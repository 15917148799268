import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type WidgetProps = {
  className?: string;
  contentClassName?: string;
  title: string;
  actionComponent?: React.ReactElement;
  children: any;
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    background: "rgb(255, 255, 255)",

    borderRadius: "4px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 4),
    width: "100%",
    height: "100%",
  },
  actionContainer: {},
}));

const Widget = (props: WidgetProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(props.className, classes.root)}>
      <div className={classes.header}>
        <span className={classes.title}>{props.title}</span>
        <div className={classes.actionContainer}>{props.actionComponent}</div>
      </div>
      <div className={clsx(props.contentClassName, classes.content)}>
        {props.children}
      </div>
    </div>
  );
};

export default Widget;
