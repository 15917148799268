import axios from "axios";
import _ from "lodash";

export enum CalendarViewEditActionTypes {
  CALENDAR_UPDATE = "CALENDAR_UPDATE",
  UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT",
  DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT",
  ADD_CALENDAR_EVENT = "ADD_CALENDAR_EVENT",
  EDIT_CALENDAR_REQUEST = "EDIT_CALENDAR_REQUEST",
  EDIT_CALENDAR_REQUEST_SUCCESS = "EDIT_CALENDAR_REQUEST_SUCCESS",
  EDIT_CALENDAR_REQUEST_FAILURE = "EDIT_CALENDAR_REQUEST_FAILURE",
  EDIT_CALENDAR_NAME_REQUEST = "EDIT_CALENDAR_NAME_REQUEST",
  EDIT_CALENDAR_NAME_REQUEST_SUCCESS = "EDIT_CALENDAR_NAME_REQUEST_SUCCESS",
  EDIT_CALENDAR_NAME_REQUEST_FAILURE = "EDIT_CALENDAR_NAME_REQUEST_FAILURE",
  FETCH_REFERENCE_CALENDARS = "FETCH_REFERENCE_CALENDARS",
  FETCH_REFERENCE_CALENDAR_BY_ID = "FETCH_REFERENCE_CALENDAR_BY_ID",
  CALENDAR_PREVIEW = "CALENDAR_PREVIEW",
  DELETE_EVENT = "DELETE_EVENT",
  FETCH_CLIENT = "FETCH_CLIENT",
  SAVE_CALENDAR = "SAVE_CALENDAR",
  SIMULATE = "SIMULATE",
  RESET_SIMULATION = "RESET_SIMULATION",
  CHANGE = "CHANGE",
  // API call use this types
  FETCH_CALENDAR_BY_ID = "FETCH_CALENDAR_BY_ID",
  SIMULATE_CALENDAR = "SIMULATE_CALENDAR",
  FETCH_CALENDAR_BY_ID_REQUEST = "FETCH_CALENDAR_BY_ID_REQUEST",
  FETCH_CALENDAR_BY_ID_REJECTED = "FETCH_CALENDAR_BY_ID_REJECTED",
  PREVIEW_CALENDAR_REQUEST = "PREVIEW_CALENDAR_REQUEST",
  PREVIEW_CALENDAR_REJECTED = "PREVIEW_CALENDAR_REJECTED",
  FLAT_EVENTS = "FLAT_EVENTS",
  SET_SIMULATION_EVENT = "SET_SIMULATION_EVENT",
  FILTER_CALENDAR_SUCCESS = "FILTER_CALENDAR_SUCCESS",
  FILTER_CALENDAR_RESET = "FILTER_CALENDAR_RESET",
}

export function setSimulationEvent(event: any) {
  return function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.SET_SIMULATION_EVENT,
      payload: event,
    });
  };
}

export function flatEvents() {
  return async function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.FLAT_EVENTS,
    });
  };
}

export function setChanged(val: boolean) {
  return async function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.CHANGE,
      payload: val,
    });
  };
}

export function updateCalendar(data: any) {
  return async function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.CALENDAR_UPDATE,
      payload: data,
    });
  };
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function simulateCalendar(
  calendarId: string,
  selectedMarket: string,
  currentDraftId: string,
  body: any
) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: CalendarViewEditActionTypes.PREVIEW_CALENDAR_REQUEST });
      if (currentDraftId) body["previousCalendarId"] = currentDraftId;
      body["market"] = selectedMarket;
      const resp = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview`,
        body
      );

      body.id = resp.data;
      let response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview/output`,
        body
      );
      let retries = 0;
      while (response.status === 202) {
        if (retries < 1) await delay(1000 * 20);
        else await delay(2000);
        response = await axios.post(
          `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview/output`,
          body
        );
        retries++;
      }
      dispatch({
        type: CalendarViewEditActionTypes.SIMULATE_CALENDAR,
        payload: {
          data: response.data,
          saveObject: body,
          affected_event_code: 0,
        },
      });
    } catch (e) {
      dispatch({ type: CalendarViewEditActionTypes.PREVIEW_CALENDAR_REJECTED });
    }
  };
}

export function previewCalendar() {
  return async function (dispatch: any) {
    try {
      dispatch({
        type: CalendarViewEditActionTypes.CALENDAR_PREVIEW,
      });
    } catch (e) {
      dispatch({ type: CalendarViewEditActionTypes.PREVIEW_CALENDAR_REJECTED });
    }
  };
}

export function previewDeletedEvent(calendarId: string, id: string, body: any) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: CalendarViewEditActionTypes.PREVIEW_CALENDAR_REQUEST });

      const resp = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview`,
        body
      );

      id = resp.data;
      body.id = id;
      let response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview/output`,
        body
      );
      let retries = 0;
      while (response.status === 202) {
        if (retries < 1) await delay(1000 * 20);
        else await delay(2000);
        response = await axios.post(
          `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/preview/output`,
          body
        );
        retries++;
      }

      dispatch({
        type: CalendarViewEditActionTypes.DELETE_EVENT,
        payload: { data: response.data, id, saveObject: body },
      });
    } catch (e) {
      dispatch({ type: CalendarViewEditActionTypes.PREVIEW_CALENDAR_REJECTED });
    }
  };
}

export function fetchCalendarById(calendar_id: string) {
  return async function (dispatch: any) {
    try {
      dispatch({
        type: CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID_REQUEST,
      });

      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendar_id}`
      );

      dispatch({
        type: CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID_REJECTED,
      });
      //TODO error handling plese and thank you
    }
  };
}

export function updateEvent(calendarId: string, data: any) {
  return async function (dispatch: any) {
    try {
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_REQUEST,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/edit`,
        data
      );
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_REQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_REQUEST_FAILURE,
      });
    }
  };
}

export function updateCalendarName(calendarId: string, data: any) {
  return async function (dispatch: any) {
    try {
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST,
      });
      await axios.put(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}`,
        data
      );
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}`
      );
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarViewEditActionTypes.EDIT_CALENDAR_NAME_REQUEST_FAILURE,
      });
    }
  };
}

export function removeEvent(event_id: string) {
  return async function (dispatch: any) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PROMO_API_URL}/event/${event_id}`
      );
      dispatch({
        type: CalendarViewEditActionTypes.FETCH_CALENDAR_BY_ID,
        payload: response.data,
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}

export function fetchReferenceCalendars() {
  return async function (dispatch: any) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/reference/calendar`
      );
      dispatch({
        type: CalendarViewEditActionTypes.FETCH_REFERENCE_CALENDARS,
        payload: response.data,
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}

export function fetchReferenceCalendarById(calendar_id: string) {
  return async function (dispatch: any) {
    try {
      if (calendar_id) {
        // TODO: CHANGE TO REFERENCE CALENDAR ROUTE
        const response = await axios.get(
          `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendar_id}`
        );
        dispatch({
          type: CalendarViewEditActionTypes.FETCH_REFERENCE_CALENDAR_BY_ID,
          payload: response.data,
        });
      }
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}

export function filterCalendarOutput(calendar_id: string, filterObject: any) {
  return async function (dispatch: any) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendar_id}/filter`,
        filterObject
      );
      dispatch({
        type: CalendarViewEditActionTypes.FILTER_CALENDAR_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}

export function resetCalendarFilter() {
  return async function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.FILTER_CALENDAR_RESET,
    });
  };
}

export function resetSimulation() {
  return async function (dispatch: any) {
    dispatch({
      type: CalendarViewEditActionTypes.RESET_SIMULATION,
    });
  };
}

export function simulate(event) {
  return async function (dispatch: any) {
    try {
      const volume = Number(Number(event.total_volume).toFixed(2));

      const baselineVolume = Number(Number(event.baseline_volume).toFixed(2));

      const totalPromoSpend = Number(
        Number(event.total_promo_spend).toFixed(2)
      );
      const incrementalGrossProfit = Number(
        Number(event.incremental_gross_profit).toFixed(2)
      );

      const totalGrossProfit = Number(
        Number(event.total_gross_profit).toFixed(2)
      );

      const retailSalesValue = Number(
        Number(event.total_retail_sales_value).toFixed(2)
      );

      const totalCustomerTradeProfit = Number(
        Number(event.total_customer_trade_profit).toFixed(2)
      );
      const totalCustomerRevenue = Number(
        Number(event.baseline_volume).toFixed(2)
      );
      //==
      const sum_customer_front_profit = Number(
        Number(event.total_customer_front_profit).toFixed(2)
      );

      // TODO: CHANGE TO REFERENCE CALENDAR ROUTE
      let simulation = {
        kpis: {
          volume_uplift: Number(Number(volume / baselineVolume).toFixed(2)),
          roi: isFinite(
            Number(Number(incrementalGrossProfit / totalPromoSpend).toFixed(2))
          )
            ? Number(
                Number(incrementalGrossProfit / totalPromoSpend).toFixed(2)
              )
            : 0,
          volume: Number(Number(volume).toFixed(2)),
          cpg_profit: Number(Number(totalGrossProfit).toFixed(2)),
          promo_spend: Number(Number(totalPromoSpend).toFixed(2)),
          retail_sales: Number(Number(retailSalesValue).toFixed(2)),
          customer_trade_margin: Number(
            Number(sum_customer_front_profit).toFixed(2)
          ),
          customer_trade_profit: Number(
            Number(totalCustomerTradeProfit).toFixed(2)
          ),
        },
        event,
      };
      dispatch({
        type: CalendarViewEditActionTypes.SIMULATE,
        payload: simulation,
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}
