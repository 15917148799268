import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useTheme } from "@mui/system";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import {
  ConstraintsManagementActionTypes,
  fetchConstraintSets,
} from "Promo/utils/redux/constraintsManagement/constraintsManagementAction";
import { convertConstraintsData } from "Promo/utils/mapper";
import { stringify } from "querystring";

const calenderLevelConstraints = {
  allow_multibuy: "allow_multibuy",
  allow_upr: "allow_upr",
  allow_ppr: "allow_ppr",
  allow_newprice: "allow_newprice",
};

export function ConstraintsForm({
  register,
  takeSnapshot,
  reset,
  selectedMarket,
  selectedCustomer,
}: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [constraintId, setConstraintId] = useState("0");
  const [constraintSet, setConstraintSet] = useState<any[]>([]);
  const [snapshotTook, setSnapshotTook] = useState(false);

  const calendarFormState = useAppSelector(
    (state) => state.reducer.CalendarCreationFormReducer
  );
  const { list } = useAppSelector((state) => {
    const data = state.reducer.ConstraintsManagementReducer.data;
    if (!Array.isArray(data) || data.length <= 0) {
      return { list: [] };
    }
    return {
      list: data.filter(
        (x) =>
          x.calendars.length &&
          x.calendars[0].internal_geo_code === selectedMarket &&
          x.calendars[0].retailer === selectedCustomer
      ),
    };
  });

  useEffect(() => {
    dispatch(fetchConstraintSets());
  }, []);

  useEffect(() => {
    if (constraintId.length) {
      setSnapshotTook(false);
      constraintId === "0"
        ? setConstraintSet([])
        : setConstraintSet([
            ...JSON.parse(
              JSON.stringify(
                list[list.findIndex((x) => x.id === constraintId)]
                  .calendar_constraints
              )
            ),
          ]);
    }
  }, [constraintId]);

  useEffect(() => {
    if (reset) {
      setConstraintId("0");
    }
  }, [reset]);

  useEffect(() => {
    dispatch({
      type: ConstraintsManagementActionTypes.CONSTRAINTS_WIPE,
    });

    if (constraintSet.length <= 0) {
      return;
    }
    const { constraintsCommons, constraintsBrands, constraintsProducts } =
      convertConstraintsData(constraintSet);

    dispatch({
      type: ConstraintsManagementActionTypes.CONSTRAINTS_UPDATE,
      data: { constraintsCommons, constraintsBrands, constraintsProducts },
    });
  }, [constraintSet]);

  useEffect(() => {
    if (snapshotTook) {
      return;
    }
    if (calendarFormState.dataIsSet && snapshotTook === false) {
      takeSnapshot(constraintId);
      setSnapshotTook(true);
    }
  }, [calendarFormState]);

  return (
    <Grid
      container
      direction="column"
      className="section_card"
      gap={"1rem"}
      sx={{
        background: theme.palette.primary.background,
      }}
    >
      <Typography variant="h3" mb={1}>
        Import Constraints
      </Typography>

      <Grid container sx={{ "& span": { fontWeight: 500 } }}>
        <Box>
          <label>Constraint set</label>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              maxWidth: "270px",
            }}
          >
            <Select
              name="name"
              className="input"
              placeholder="No constraint set selected"
              size="small"
              value={constraintId}
              renderValue={(selected) => (
                <Typography
                  variant="body3"
                  sx={{ fontWeight: "400 !important" }}
                >
                  {selected === "0"
                    ? "No constraint set"
                    : list.find((select) => select.id === selected)?.name}
                </Typography>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                  },
                },
              }}
              onChange={(e) => {
                setConstraintId(e.target.value);
              }}
            >
              <MenuItem value={"0"} disabled>
                <Typography align="left" fontSize={14}>
                  No constraint set
                </Typography>
              </MenuItem>

              {list &&
                list.map((constraintSet, i) => (
                  <MenuItem key={i} value={constraintSet.id}>
                    <Typography align="left" fontSize={14}>
                      {constraintSet.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>

        {/* <label
            style={{
              width: "fit-content",
              padding: ".3rem .5rem",
              background: "#74facf",
              borderRadius: "5px",
              marginTop: ".8rem",
              fontWeight: 500,
            }}
          >
            2 constraints modified in this set
          </label> */}
      </Grid>
    </Grid>
  );
}
