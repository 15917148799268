import React, { useState, useEffect } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tabs, Tab, Button, Typography } from "@mui/material";
import { diagnosticDashboardList } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DiagnosticDashboardPage = () => {
  const [dashboardList, setDashboardList] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState("6");
  const [title, setTitle] = useState("Back");
  const goBack = () => {
    navigate("/");
  };
  useEffect(() => {
    const resultList: any[] = [];
    diagnosticDashboardList.forEach((da) => {
      da.dashboards.forEach((dashboard) => {
        resultList.push({
          title: dashboard.title,
          link: dashboard.link,
          value: dashboard.value,
        });
      });
    });
    setDashboardList(resultList);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (location.state != null && location.state.tabNumber != null) {
      setValue(location.state.tabNumber);
    }
  }, [location]);

  return (
    <div>
      <TabContext value={value}>
        {dashboardList.map((tab, index) => (
          <TabPanel value={tab.value} key={tab.link} sx={{ pt: 2 }}>
            <Button
              sx={{ marginBottom: 2 }}
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={goBack}
            >
              <Typography color="primary">{tab.title}</Typography>
            </Button>
            <iframe
              title={tab.title}
              src={tab.link}
              width="100%"
              style={{ height: "calc(100vh - 170px)" }}
            />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default DiagnosticDashboardPage;
