import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { makeStyles } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";

type SubBrand = {
  name: string;
};

type IType = {
  calendar: any;
  referenceCalendar: any;
  timelineData: any;
  ownProducts: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    width: "100%",
    wordBreak: "break-all",
    height: "5rem",
    marginBottom: "3.5rem",
  },
  twoTimelines: {
    width: "100%",
    height: "5rem",
    wordBreak: "break-all",
    marginBottom: "3.5rem",
  },
  threeTimelines: {
    width: "100%",
    wordBreak: "break-all",
    height: "5rem",
    marginBottom: "6rem",
  },
}));

const SubBrandMenu: React.FC<IType> = ({
  calendar,
  referenceCalendar,
  timelineData,
  ownProducts,
}) => {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (ownProducts && Object.keys(timelineData.products).length > 0) {
      var data: any = [];
      for (const [key, value] of Object.entries(timelineData.products)) {
        data.push(key);
      }
      setData(data);
    } if (!ownProducts && Object.keys(timelineData.competitorProducts).length > 0) {
      var data: any = [];
      for (const [key, value] of Object.entries(timelineData.competitorProducts)) {
        data.push(key);
      }
      setData(data);
    }
  }, [calendar]);

  return (
    <div className="min-w-[10rem] mt-12 px-4 py-5 pt-9 bg-white">
      <FormGroup>
        <div className="flex flex-col mt-24 ml-2 gap-8">
          <div className="w-full">
            {data.map((subbrand) => (
              <div className={classes.twoTimelines}>
                <Typography variant="body1">{subbrand}</Typography>
              </div>
            ))}
          </div>
        </div>
      </FormGroup>
    </div>
  );
};

export default SubBrandMenu;
