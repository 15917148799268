import { TableCell, TableRow } from "@mui/material";
import { KPIChartColor } from "../../../../../utils/theme";
import { makeStyles, withStyles, createStyles } from "@mui/styles";
export const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 4),
      borderRadius: "4px",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      width: "100%",
    },

    tableRootCell: {
      backgroundColor: KPIChartColor.baseLineSecondary,
      padding: "12px 12px 12px 0px",
      width: "100%",
      height: "10%",
    },
    tableCell: {
      borderLeft: "0.2px solid rgba(82, 82, 82, 1)",
      borderTop: "0.2px solid rgba(82, 82, 82, 1)",

      padding: 2,
      //  margin: 2,
      width: "8%",
      height: 44,
    },
    tableRow: {
      border: "1px solid black",
    },

    tableCellBases: {
      border: "1px solid black",
      padding: "12px 12px 12px 0px",
      width: "8%",
    },
    tableCellSecondRow: {
      borderLeft: "0.2px solid rgba(82, 82, 82, 1)",
      borderTop: "0.2px solid rgba(82, 82, 82, 1)",

      width: "8%",
    },

    textContent: {
      padding: theme.spacing(0, 2, 0, 0),
    },
    tableCellTitle: {
      width: "13%",
      borderLeft: "0.2px solid rgba(82, 82, 82, 1)",
      borderTop: "0.2px solid rgba(82, 82, 82, 1)",
      height: 44,
    },
  })
);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "27%",
  },
}))(TableRow);
export const StyledSecondRowTableRow = withStyles((theme) => ({
  root: {
    height: "17%",
    padding: "0px 0px 12px 0px",
  },
}))(TableRow);
