import produce, { Draft } from "immer";
import { Reducer } from "redux";

//import { AnalysisAction, AnalysisActionTypes } from './analysisActions'
import {
  CalendarData,
  ProductKPI,
  PromoEvent,
} from "../../types";

import { AnalysisAction, AnalysisActionTypes } from "./analysisActions";

export type AnalysisState = {
  promoEvents: PromoEvent[];
  productKPIS: ProductKPI[];
  selectedPromoEvent: PromoEvent | null;
  calendar: {
    pending: boolean;
    fulfilled: boolean;
    rejected: boolean;
    data: CalendarData | null;
  };
};

export const initialState: AnalysisState = {
  promoEvents: [],
  productKPIS: [],
  selectedPromoEvent: null,
  calendar: {
    pending: false,
    fulfilled: false,
    rejected: false,
    data: null,
  },
};

export const analysisReducer: Reducer<AnalysisState, AnalysisAction> = produce(
  (draft: StateDraft, action: AnalysisAction) => {
    switch (action.type) {
      case AnalysisActionTypes.FETCH_CALENDAR_PENDING:
        draft.calendar = {
          pending: true,
          fulfilled: false,
          rejected: false,
          data: null,
        };
        break;
      case AnalysisActionTypes.FETCH_CALENDAR_FULFILLED:
        draft.calendar = {
          pending: false,
          fulfilled: true,
          rejected: false,
          data: action.data,
        };
        draft.promoEvents = action.data.promo_events;
        draft.productKPIS = action.data.product_kpis;
        break;
      case AnalysisActionTypes.FETCH_CALENDAR_REJECTED:
        draft.calendar = {
          pending: false,
          fulfilled: false,
          rejected: true,
          data: null,
        };
        break;
      case AnalysisActionTypes.APPLY_FILTERS:
        draft.promoEvents = action.promoEvents;
        draft.productKPIS = action.productKPIS;
        break;
      case AnalysisActionTypes.CLEAR_FILTERS:
        draft.promoEvents = draft.calendar.data?.promo_events || [];
        draft.productKPIS = draft.calendar.data?.product_kpis || [];
        break;
      case AnalysisActionTypes.SELECT_PROMO_EVENT:
        draft.selectedPromoEvent = action.promoEvent;
        break;
      case AnalysisActionTypes.CLEAR_PROMO_EVENT_SELECTION:
        draft.selectedPromoEvent = null;
        break;
    }
  },
  initialState
);

type StateDraft = Draft<AnalysisState>;
