import { Slider } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";

export const StyledSlider = styled(Slider)({
  width: "14rem",
  position: "relative",
  "& .MuiSlider-rail": {
    backgroundColor: "#bebebe",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "white",
    border: "1px solid #828282",
  },
  "& .MuiSlider-track": {
    backgroundColor: "#000",
  },
  "& .MuiSlider-markLabel": {
    fontSize: ".825rem",
  },
  "& .MuiSlider-mark": {
    background:
      "repeating-linear-gradient(to bottom,transparent 0 4px,black 4px 6px) 80%/2px 100% no-repeat",
    height: "27px",
    width: "1px",
  },
});

function valuetext(value: number) {
  return `${value}%`;
}

export function InputSlider({ sliderValue }: any) {
  const [value, setValue] = useState(0);

  return (
    <StyledSlider
      getAriaLabel={() => "Minimum distance"}
      valueLabelDisplay="auto"
      value={value}
      onChange={(e) => {
        setValue(e.target!["value"]);
      }}
      marks={[{ value: 50, label: "" }]}
      getAriaValueText={valuetext}
      disableSwap
    />
  );
}
