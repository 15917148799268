import CalendarViewEditHeader from "../components/calendarViewEditHeader/CalendarViewEditHeader";
import CalendarComparisonKpiTable from "../components/kpiTable/CalendarComparisonKpiTable";
import {
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import CalendarComparsionViewEdit from "../components/calendarComparsionViewEdit/calendarComparsionViewEdit";
import { KPIChartColor } from "../../../../utils/theme";
import {
  CalendarComparisonKPIS,
  KPITableType,
  ViewEditEventCalendarKPi,
} from "../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { useEffect, useState } from "react";
import {
  fetchCalendarById,
  flatEvents,
  filterCalendarOutput,
  resetCalendarFilter,
} from "../../../utils/redux/calendarViewEdit/calendarViewEditAction";
import { formatNumber } from "../../../utils/mapper";
import { useParams } from "react-router-dom";
import PnLTable from "../../../components/consumptionAndRetailerPL/PnLTable";
import TrendAnalysis from "../../../components/TrendAnalysis/TrendAnalysis";
import { getClientData } from "utils/redux/Global/GlobalActions";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ConstraintsAccordian from "Promo/components/constraintsAccordian/ConstraintsAccordian";
import KeyKpi from "Promo/components/keyKpi/KeyKpi";
import { tooltipItemsToShow } from "Promo/utils/constants";

const KPI_TABLE: KPITableType[] = [
  {
    title: "Baseline",
    backgroundColor: KPIChartColor.baseLineSecondary,
    data: {
      gross_margin: "60.871%",
      cpg_vsop: "64.54%",
      cpg_arp_per_unit: "$44.1",
      rsv_share: "15.65%",
      avg_time_on_promo_per_brandline: "25%",
      avg_time_on_catalog_per_brandline: "12.43%",
      avg_time_on_seasonal_per_brandline: "32.423%",
      avg_time_as_secondary_per_brandline: "$13.1K",
      promo_budget: "-",
      total_profit_pool: "$14.4K",
      customer_vsop: "43.5%",
      customer_arp_per_unit: "$50.6",
    },
  },
  {
    title: "Max margin -co",
    backgroundColor: KPIChartColor.maxMarginSecondary,
    data: {
      gross_margin: "60.8%",
      cpg_vsop: "64.5%",
      cpg_arp_per_unit: "$44.1",
      rsv_share: "15.6%",
      avg_time_on_promo_per_brandline: "25%",
      avg_time_on_catalog_per_brandline: "12.4%",
      avg_time_on_seasonal_per_brandline: "32.4%",
      avg_time_as_secondary_per_brandline: "$13.1K",
      promo_budget: "-",
      total_profit_pool: "$14.4K",
      customer_vsop: "43.5%",
      customer_arp_per_unit: "$50.6",
    },
  },
  {
    title: "Increased mark",
    backgroundColor: KPIChartColor.inscreasedMarkSecondary,
    data: {
      gross_margin: "60.8%",
      cpg_vsop: "64.5%",
      cpg_arp_per_unit: "$44.1",
      rsv_share: "15.6%",
      avg_time_on_promo_per_brandline: "25%",
      avg_time_on_catalog_per_brandline: "12.4%",
      avg_time_on_seasonal_per_brandline: "32.4%",
      avg_time_as_secondary_per_brandline: "$13.1K",
      promo_budget: "-",
      total_profit_pool: "$14.4K",
      customer_vsop: "43.5%",
      customer_arp_per_unit: "$50.6",
    },
  },
];

type CalendarViewEditProps = {
  selectedMarket: string;
};

export const CalendarViewEdit = (props: CalendarViewEditProps) => {
  const { selectedMarket } = props;
  /// use useParams from react router get calendar_id
  const calendar = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.calendar
  );
  const referenceCalendar = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.referenceCalendar
  );

  const { calendarDataKpi, draftCalendarKPI, pending, rejected } =
    useAppSelector((state) => state.reducer.CalendarViewEditReducer);
  const ClientData: ClientData = useAppSelector(
    (state) => state.reducer.GlobalReducer.clientData
  );
  const draftFlag = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.draft
  );
  const draftCalendar = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.draftCalendar
  );
  const filterFlag = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.filterCalendar
  );
  const filterObject = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer.filterResult
  );
  const dispatch = useAppDispatch();

  const [filterBrand, setFilterBrand] = useState(null);
  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [filterSubBrand, setFilterSubBrand] = useState(null);
  const [subbrandOptions, setSubBrandOptions] = useState<any>([]);
  const [filterProduct, setFilterProduct] = useState(null);
  const [productOptions, setProductOptions] = useState<any>([]);

  // useEffect(() => {
  //   if (calendar.microCalendar.id != undefined && calendar.microCalendar.id != null) dispatch(fetchCalendarById(calendar.microCalendar.id));
  // }, [calendar.microCalendar]);

  // useEffect(() => {
  //   setFilterSubBrand(null);
  //   if (filterBrand) {
  //     const subbrands = brandOptions.filter(
  //       (item) => item.internal_code === filterBrand
  //     )[0].sub_brands;
  //     setSubBrandOptions(subbrands);
  //   } else {
  //     setSubBrandOptions([]);
  //   }
  // }, [filterBrand]);

  useEffect(() => {
    setFilterProduct(null);
    setProductOptions([]);
    if (filterBrand) {
      const products = brandOptions.filter(
        (item) => item.internal_code === filterBrand
      )[0].products;
      setProductOptions(products);
    }
  }, [filterBrand]);

  const handleFilter = () => {
    if (filterBrand) {
      const filterObject = {
        referenceCalendar: calendar.reference_calendar_id || calendar.id,
        brand: filterBrand,
        // subbrand: filterSubBrand,
        product: filterProduct,
      };
      dispatch(
        filterCalendarOutput(
          draftFlag ? draftCalendar.id : calendar.id,
          filterObject
        )
      );
    } else {
      dispatch(resetCalendarFilter());
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id != undefined && id != null) {
      dispatch(fetchCalendarById(id));
    }
  }, [id]);

  useEffect(() => {
    if (calendar && referenceCalendar) {
      updateKpis();
    }
  }, [calendar]);

  useEffect(() => {
    if (
      calendar &&
      calendar.calendar &&
      !Array.isArray(calendar.calendar.events)
    ) {
      if (!Array.isArray(calendar.calendar.events)) {
        dispatch(flatEvents());
      }
    }
  }, [calendar]);

  useEffect(() => {
    if (ClientData && calendar) {
      const calRetailer = calendar.retailer;
      const calCategory = calendar.category;
      const retailer = ClientData.retailers.filter(
        (item) => item.internal_code === calRetailer
      )[0];
      const category = retailer?.categories.filter(
        (item) => item.internal_code === calCategory
      )[0];
      const brands = category?.brands;
      if (brands) setBrandOptions(brands);
    }
  }, [calendar, ClientData]);

  useEffect(() => {
    if (calendar) {
      let reducedKpis = reduceKpis(calendar.dailies);
      let kpis: CalendarComparisonKPIS = {
        gross_margin: `${reducedKpis[0]}%`,
        cpg_vsop: `${reducedKpis[1]}%`,
        cpg_arp_per_unit: `$${formatNumber(reducedKpis[2])}`,
        rsv_share: `${reducedKpis[3]}%`,
        avg_time_on_promo_per_brandline: `${reducedKpis[4]}%`,
        avg_time_on_catalog_per_brandline: `${reducedKpis[5]}%`,
        avg_time_on_seasonal_per_brandline: `${reducedKpis[6]}%`,
        avg_time_as_secondary_per_brandline: `${reducedKpis[7]}%`,
        promo_budget: `${reducedKpis[8]}%`,
        total_profit_pool: `$${formatNumber(reducedKpis[9])}`,
        customer_vsop: `${reducedKpis[10]}%`,
        customer_arp_per_unit: `${reducedKpis[11]}%`,
      };
      let newKpis = kpiTable[1];
      newKpis.data = kpis;
      setKpiTable([kpiTable[0], newKpis]);
    }
    if (referenceCalendar) {
      let reducedKpis = reduceKpis(referenceCalendar.dailies);
      let kpis = {
        gross_margin: `${reducedKpis[0]}%`,
        cpg_vsop: `${reducedKpis[1]}%`,
        cpg_arp_per_unit: `$${formatNumber(reducedKpis[2])}`,
        rsv_share: `${reducedKpis[3]}%`,
        avg_time_on_promo_per_brandline: `${reducedKpis[4]}%`,
        avg_time_on_catalog_per_brandline: `${reducedKpis[5]}%`,
        avg_time_on_seasonal_per_brandline: `${reducedKpis[6]}%`,
        avg_time_as_secondary_per_brandline: `${reducedKpis[7]}%`,
        promo_budget: `${reducedKpis[8]}%`,
        total_profit_pool: `$${formatNumber(reducedKpis[9])}`,
        customer_vsop: `${reducedKpis[10]}%`,
        customer_arp_per_unit: `${reducedKpis[11]}%`,
      };
      let newKpis = kpiTable[0];
      newKpis.data = kpis;
      setKpiTable([newKpis, kpiTable[1]]);
    }
  }, [calendar, referenceCalendar]);

  const [kpiTable, setKpiTable] = useState(KPI_TABLE);

  const updateKpis = () => {
    const newKpis = kpiTable[1];
    newKpis.title = calendar.name;
    const newKpis1 = kpiTable[0];
    newKpis1.title = referenceCalendar.name;
    setKpiTable([newKpis1, newKpis]);
  };

  const reduceKpis = (daily: any[]) => {
    if (Array.isArray(daily)) {
      // gross margin
      const sumTotalGrossProfit = daily.reduce((x, y) => {
        return x + y.total_gross_profit;
      }, 0);
      const sumTotalNetSales = daily.reduce((x, y) => {
        return x + y.total_net_sales_value;
      }, 0);
      const grossMargin = Number(
        (Number(sumTotalGrossProfit / sumTotalNetSales) * 100).toFixed(2)
      );

      // vsop
      const sumTotalPromotedVolume = daily.reduce((x, y) => {
        return x + y.total_promoted_volume;
      }, 0);
      const sumTotalVolume = daily.reduce((x, y) => {
        return x + y.total_volume;
      }, 0);
      const vsop = Number(
        (Number(sumTotalPromotedVolume / sumTotalVolume) * 100).toFixed(2)
      );

      // arp per unit
      const sumTotalRetailSalesValues = daily.reduce((x, y) => {
        return x + y.total_retail_sales_value;
      }, 0);
      const arpPerUnit = Number(
        Number(sumTotalRetailSalesValues / sumTotalVolume).toFixed(2)
      );

      // average time on promo per brandline %
      const sumIsPromo = daily.reduce((x, y) => {
        return x + (y.is_promo ? 1 : 0);
      }, 0);

      const avgIsPromo = Number(Number(sumIsPromo / daily.length).toFixed(2));

      // average time on catalog per brandline %
      const sumIsOnCatalog = daily.reduce((x, y) => {
        return x + (y.is_promo ? 1 : 0);
      }, 0);

      const avgIsOnCatalog = Number(
        Number(sumIsOnCatalog / daily.length).toFixed(2)
      );

      // average time on seasonal per brandline %
      const sumIsSeasonal = daily.reduce((x, y) => {
        return x + (y.is_promo ? 1 : 0);
      }, 0);

      const avgIsSeasonal = Number(
        Number(sumIsSeasonal / daily.length).toFixed(2)
      );

      // average time as secondary per brandline %
      const sumIsSecondary = daily.reduce((x, y) => {
        return x + (y.is_promo ? 1 : 0);
      }, 0);

      const avgIsSecondary = Number(
        Number(sumIsSecondary / daily.length).toFixed(2)
      );

      // total profit tool
      let sumTotalProfitPool = Number(
        Number(daily.reduce((x, y) => x + y.total_profit_pool, 0)).toFixed(2)
      );

      const data = [
        grossMargin,
        vsop,
        arpPerUnit,
        0,
        avgIsPromo,
        avgIsOnCatalog,
        avgIsSeasonal,
        avgIsSecondary,
        0,
        sumTotalProfitPool,
        vsop,
        arpPerUnit,
      ];

      return data;
    } else {
      return [];
    }
  };

  if (!calendar || !ClientData || !referenceCalendar) {
    return <div>Loading</div>;
  }

  window.onscroll = function () {
    onPageScroll();
  };

  const header = document.getElementById("myHeader");
  const sticky = header?.offsetTop;

  function onPageScroll() {
    if (sticky !== undefined && header !== null) {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }

  if (pending) return <div>Loading Calendar...</div>;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item mobile={12} className="top-container">
          <CalendarViewEditHeader
            calendar={calendar}
            selectedMarket={selectedMarket}
          />
        </Grid>

        {/* <Grid container mobile={12} className="header" id={"myHeader"}>
          <Grid mr={1} ml={2}>
            <Typography fontWeight={"bold"} align="left" fontSize={10}>
              Brand
            </Typography>

            <FormControl>
              <Select
                value={filterBrand ? filterBrand : "No Filter"}
                displayEmpty={true}
                sx={{
                  height: 32,
                  bgcolor: "white",
                  paddingY: "4px",
                  width: 160,
                }}
                onChange={(event: any) =>
                  setFilterBrand(
                    event.target.value === "No Filter"
                      ? null
                      : event.target.value
                  )
                }
              >
                <MenuItem disabled>
                  <Typography fontSize={13}> Brand name </Typography>{" "}
                </MenuItem>
                {brandOptions.map((item, index) => {
                  return (
                    <MenuItem
                      value={item.internal_code}
                      key={`${index}_${item.internal_code}`}
                    >
                      <Typography fontSize={13}>
                        {" "}
                        {item.internal_code}
                      </Typography>
                    </MenuItem>
                  );
                })}
                <MenuItem value={"No Filter"}>
                  <Typography fontSize={13}>No Filter</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid mr={1}>
            <Typography fontWeight={"bold"} align="left" fontSize={10}>
              Sub Brand
            </Typography>

            <FormControl>
              <Select
                value={filterSubBrand ? filterSubBrand : "No Filter"}
                displayEmpty={true}
                sx={{
                  height: 32,
                  bgcolor: "white",
                  paddingY: "4px",
                  width: 160,
                }}
                onChange={(event: any) =>
                  setFilterSubBrand(
                    event.target.value === "No Filter"
                      ? null
                      : event.target.value
                  )
                }
              >
                <MenuItem disabled>
                  <Typography fontSize={13}> Sub Brand name </Typography>{" "}
                </MenuItem>
                {subbrandOptions.map((item, index) => {
                  return (
                    <MenuItem
                      value={item.internal_code}
                      key={`${index}_${item.internal_code}`}
                    >
                      <Typography fontSize={13}>
                        {" "}
                        {item.internal_code}
                      </Typography>
                    </MenuItem>
                  );
                })}
                <MenuItem value={"No Filter"}>
                  <Typography fontSize={13}>No Filter</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid mr={1}>
            <Typography fontWeight={"bold"} align="left" fontSize={10}>
              Product
            </Typography>

            <FormControl>
              <Select
                value={filterProduct ? filterProduct : "No Filter"}
                displayEmpty={true}
                sx={{
                  height: 32,
                  bgcolor: "white",
                  paddingY: "4px",
                  width: 160,
                }}
                onChange={(event: any) =>
                  setFilterProduct(
                    event.target.value === "No Filter"
                      ? null
                      : event.target.value
                  )
                }
              >
                <MenuItem disabled={true}>
                  <Typography fontSize={13}>Product name </Typography>
                </MenuItem>
                {productOptions.map((item, index) => {
                  return (
                    <MenuItem
                      value={item?.internal_product_code}
                      key={`${index}_${item?.internal_product_code}`}
                    >
                      <Typography fontSize={13}>
                        {item.internal_product_code}
                      </Typography>
                    </MenuItem>
                  );
                })}
                <MenuItem value={"No Filter"}>
                  <Typography fontSize={13}>No Filter</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flex: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<FilterAltOutlinedIcon />}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Grid> */}
        {/* </Grid> */}

        <Grid container mobile={12} className="content">
          <Grid item mobile={12}>
            {calendar?.constraint_set && (
              <ConstraintsAccordian
                constraintSet={calendar.constraint_set}
                title={"Optimization parameters"}
                maximizationGoals={calendar.maximization_goals}
              />
            )}
          </Grid>
          <Grid item mobile={12}>
            {calendarDataKpi && (
              <KeyKpi
                KPIs={
                  filterFlag
                    ? filterObject.keyKpi
                    : draftFlag
                    ? draftCalendarKPI.keyKpi
                    : calendarDataKpi.keyKpi
                }
                title={"Key KPIs"}
              />
            )}
          </Grid>
          <Grid item mobile={12}>
            {calendarDataKpi &&
              calendar &&
              referenceCalendar &&
              calendarDataKpi.timeline.timelineDateRange && (
                <CalendarComparsionViewEdit
                  referenceCalendar={
                    filterFlag
                      ? filterObject.calendar?.reference_calendar
                      : referenceCalendar
                  }
                  calendar={
                    filterFlag
                      ? filterObject.calendar
                      : draftFlag
                      ? calendar //draftCalendar
                      : calendar
                  }
                  selectedMarket={selectedMarket}
                  timelineData={
                    draftFlag
                      ? draftCalendarKPI.timeline
                      : calendarDataKpi.timeline
                  }
                  clientData={ClientData}
                />
              )}
          </Grid>
          <Grid item mobile={12} mt={4}>
            <Box
              component={"div"}
              style={{ display: "flex", gap: "22px", marginBottom: "24px" }}
            >
              <Box style={{ width: "55%" }}>
                {calendarDataKpi && (
                  <PnLTable
                    title="GEPP P&L"
                    pnLData={
                      filterFlag
                        ? filterObject.consumptionPL
                        : draftFlag
                        ? draftCalendarKPI.consumptionPL
                        : calendarDataKpi.consumptionPL
                    }
                    tooltipToShow={tooltipItemsToShow}
                  />
                )}
              </Box>
              <Box style={{ width: "50%" }}>
                {calendarDataKpi && (
                  <PnLTable
                    title="Retailer P&L"
                    pnLData={
                      filterFlag
                        ? filterObject.retailPL
                        : draftFlag
                        ? draftCalendarKPI.retailPL
                        : calendarDataKpi.retailPL
                    }
                    tooltipToShow={tooltipItemsToShow}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          {/* <Grid item mobile={12}>
            {calendarDataKpi && (
              <TrendAnalysis
                title={"Product trend analysis"}
                chartData={
                  filterFlag
                    ? filterObject.productTrendAnalysis
                    : draftFlag
                    ? draftCalendarKPI.productTrendAnalysis
                    : calendarDataKpi.productTrendAnalysis
                }
              />
            )}
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};
