import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "../calendarComparisonViewEditStyle";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Controller, useForm } from "react-hook-form";
import { updateEventParams } from "../../../../../utils/redux/recommendation/eventParams/eventParamsAction";
import CustomeDatePicker from "../../../../../components/customDatePicker/CustomeDatePicker";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import CalendarKpiTable from "../kpiTable/calendarKpiTable";
import WaterfallChart from "../../../../../components/waterfallChart/WaterfallChart";
import {
  setChanged,
  previewCalendar,
  simulate,
  resetSimulation,
  simulateCalendar,
  setSimulationEvent,
  CalendarViewEditActionTypes,
} from "../../../../../utils/redux/calendarViewEdit/calendarViewEditAction";
import { EVENT_PARAMS_INITIAL_STATE } from "../../../../../utils/redux/recommendation/eventParams/eventParamsReducer";
import {
  DisplayItems,
  location,
  tacticTypes,
  singleBuyPicklist,
  multiBuyPicklist1,
  multiBuyPicklist2,
  multiBuyPicklist3,
  QUARTER_MAPPINGS,
} from "../../../../../utils/constants";
import { formatNumber, getPromotedPrice } from "Promo/utils/mapper";
import { add, endOfWeek, isAfter, isBefore, startOfWeek } from "date-fns";

type IProps = {
  calendar: any;
  selectedMarket: string;
  openProduct: any;
  addParam: boolean;
  onClose: Function;
  timelineData: any;
};

const style = {
  position: "absolute",
  top: "55%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "65vw",
  height: "55vh",
  //bgcolor: "background.paper",
  borderRadius: "0.313rem",
  // boxShadow: 24,
  justifyContent: "center",
  alignItems: "center",
};

const CreateEventDialog: React.FC<IProps> = ({
  calendar,
  selectedMarket,
  openProduct,
  addParam,
  onClose,
  timelineData,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const eventParams = useAppSelector(
    (state) => state.reducer.EventParamsReducer
  );

  const selector = useAppSelector(
    (state) => state.reducer.CalendarViewEditReducer
  );

  const [addParametersOpen, setAddParametersOpen] = useState(addParam);
  const [submited, setSubmited] = useState<boolean>(false);
  const [updated, setUpdated] = useState(false);

  const { eventSimulation }: any = useAppSelector(
    (state) => state.reducer.SimulationReducer
  );

  const [formValues, setFormValues] = React.useState({ ...eventParams });
  const handleEventParamsChange = useCallback(() => {
    setUpdated(true);
  }, [eventParams]);

  useEffect(() => {
    if (eventParams && Object.keys(eventParams).length > 0) {
      setFormValues({ ...eventParams });
    }
  }, [eventParams]);

  function closeAddParameters() {
    setAddParametersOpen(false);
    onClose();
  }
  const handleCalendarComparsionClick = (left: number) => {};

  const paramsForm = useForm<any>({
    mode: "onSubmit",
    defaultValues: {},
    // resolver: yupResolver(CreateScenarioParamsSchema)
  });

  const handleOnSubmit = (data) => {
    dispatch(updateEventParams(data));

    if (!submited) {
      setSubmited(true);
      setUpdated(true);

      // TODO: Adapt entity to scratch entity
      var event: any = {};

      event.id = Math.floor(Math.random() * -10000);
      event.date_start = eventParams.period[0];
      event.date_end = eventParams.period[1];
      event.promo_mechanism_code =
        eventParams.multiBuyPick3 == "%"
          ? "percentage_price_reduction"
          : "unit_price_reduction";
      event.must_buy_quantity = eventParams.multiple;
      event.promo_desc =
        eventParams.tacticTypes === "Punto de Precio"
          ? eventParams.singleBuyValue + eventParams.singleBuyPick
          : eventParams.multiBuyPick1 +
            " " +
            eventParams.multibuyUnit +
            ` ${eventParams.multiBuyPick3 == "%" ? "get" : "for"} ` +
            eventParams.multibuyAmount +
            eventParams.multiBuyPick3 +
            ` ${eventParams.multiBuyPick3 == "%" ? "off" : ""}`;
      event.everydayPrice = eventParams.everydayPrice;

      const body = {
        startDate: eventParams.period[0],
        endDate: eventParams.period[1],
        // depth: eventParams.depth,
        // display: eventParams.display,
        mechanic:
          eventParams.multiBuyPick3 == "%"
            ? "percentage_price_reduction"
            : "unit_price_reduction",
        multi: eventParams.multiple > 1 ? true : false,
        quantity: eventParams.multiple,
        product_code: eventParams.product,
        retailer_code: calendar.retailer,
        //========
        tacticTypes: eventParams.tacticTypes,
        singleBuyPick: eventParams.singleBuyPick,
        multiBuyPick1: eventParams.multiBuyPick1,
        multibuyAmount: eventParams.multibuyAmount,
        multiBuyPick3: eventParams.multiBuyPick3,
        multiBuyPick2: eventParams.multiBuyPick2,
        everydayPrice: eventParams.everydayPrice,
        singleBuyValue: eventParams.singleBuyValue,
        multibuyUnit: eventParams.multibuyUnit,
      };

      let saveObj = { ...selector.saveObject };
      saveObj.referenceCalendarId = calendar.reference_calendar_id;
      let removeList = [...saveObj.remove];
      let addList = [...saveObj.add];
      // if (!event.previwed && event.id) {
      //   removeList.push(event.id);
      // }
      addList.push(body);
      saveObj.remove = removeList;
      saveObj.add = addList;

      closeAddParameters();
      dispatch({
        type: CalendarViewEditActionTypes.ADD_CALENDAR_EVENT,
        payload: {
          saveObject: saveObj,
          event: event,
          product: openProduct,
        },
      });
    }
  };

  useEffect(() => {
    dispatch(resetSimulation());
    setAddParametersOpen(addParam);
    setUpdated(false);
    if (openProduct != null && openProduct != undefined) {
      dispatch(
        updateEventParams({
          period: [
            new Date(timelineData.timelineDateRange.start),
            new Date(timelineData.timelineDateRange.start),
          ],
          depth: 0,
          unit: "%",
          // multiple: false,
          // display: openEvent.display,
          customer: calendar.retailer,
          category: openProduct.category,
          product: openProduct.internal_product_code,
          brand: openProduct.brand,
          quantity: 0,
          //======to be verified, how to  calculate these values from event object
          tacticTypes: "Punto de Precio",
          singleBuyPick: "% off",
          multiBuyPick1: "Buy",
          multibuyAmount: 1,
          multiBuyPick3: "%",
          multiBuyPick2: "Save",
          everydayPrice: openProduct.retail_standard_price,
          singleBuyValue: 0,
          multibuyUnit: 0,
        })
      );
    }
  }, [addParam]);

  function calculatPromotedPrice(): string {
    const result = getPromotedPrice({ ...eventParams });

    return result;
  }

  const disableNonQuarterDates = (date) => {
    const subString = calendar.quarter.split(" ");
    subString.length > 2 && subString.shift();
    const startDate = Date.parse(
      `${QUARTER_MAPPINGS[subString[0]].start}/${subString[1]}`
    );
    const endDate = Date.parse(
      `${QUARTER_MAPPINGS[subString[0]].end}/${subString[1]}`
    );
    return (
      isBefore(date, startOfWeek(startDate)) ||
      isAfter(date, endOfWeek(endDate))
    );
  };

  const renderForm = () => {
    const subString = calendar.quarter.split(" ");
    subString.length > 2 && subString.shift();
    const startDate = new Date(
      `${QUARTER_MAPPINGS[subString[0]].start}/${subString[1]}`
    );

    return (
      <form
        onSubmit={paramsForm.handleSubmit(handleOnSubmit)}
        className={classes.formContainer}
      >
        <div>
          <div className={classes.formContainerInputs}>
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Period
              </Typography>

              <Controller
                name="period"
                control={paramsForm.control}
                render={({ field }) => (
                  <CustomeDatePicker
                    {...field}
                    disabledDate={disableNonQuarterDates}
                    period={[startDate, startDate]}
                    onChange={(event) => {
                      const tzoffset = event[0].getTimezoneOffset() * 60000;
                      const endWithoutTimezone = new Date(
                        event[1].valueOf() - tzoffset
                      )
                        .toISOString()
                        .slice(0, -1);
                      const startWithoutTimezone = new Date(
                        event[0].valueOf() - tzoffset
                      )
                        .toISOString()
                        .slice(0, -1);
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          period: [startWithoutTimezone, endWithoutTimezone],
                        })
                      );
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Promo Type
              </Typography>
              <Select
                {...paramsForm.register("tacticTypes")}
                value={eventParams.tacticTypes}
                className={classes.displaySelect}
                sx={{ height: 32, bgcolor: "white" }}
                onChange={(event: any) => {
                  setSubmited(false);
                  dispatch(
                    updateEventParams({
                      ...eventParams,
                      tacticTypes: event.target.value,
                    })
                  );
                  setFormValues({
                    ...formValues,
                    tacticTypes: event.target.value,
                  });
                }}
              >
                {tacticTypes.map((item, index) => {
                  return (
                    <MenuItem
                      selected={item == eventParams.tacticTypes ? true : false}
                      value={item}
                      key={index}
                    >
                      <Typography fontSize={13}>{item}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {eventParams.tacticTypes === "Punto de Precio" ? (
              <>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <TextField
                    {...paramsForm.register("singleBuyValue")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.singleBuyValue}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          singleBuyValue: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        singleBuyValue: event.target.value,
                      });
                    }}
                    style={{ width: "48px" }}
                  />
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("singleBuyPick")}
                    value={eventParams.singleBuyPick}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          singleBuyPick: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        singleBuyPick: event.target.value,
                      });
                    }}
                  >
                    {singleBuyPicklist.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.singleBuyPick ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>

                  <Select
                    {...paramsForm.register("multiBuyPick1")}
                    value={eventParams.multiBuyPick1}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick1: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick1: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist1.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick1 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    #{" "}
                    {formValues.multiBuyPick1 === multiBuyPicklist1[1]
                      ? "$"
                      : "units"}
                  </Typography>
                  <TextField
                    {...paramsForm.register("multibuyUnit")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.multibuyUnit}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multibuyUnit: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multibuyUnit: event.target.value,
                      });
                    }}
                    style={{ width: "48px" }}
                  />
                </FormControl>

                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("multiBuyPick2")}
                    value={eventParams.multiBuyPick2}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick2: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick2: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist2.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick2 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    #
                  </Typography>
                  <TextField
                    {...paramsForm.register("multibuyAmount")}
                    sx={{ minWidth: 65 }}
                    InputProps={{
                      inputProps: { min: 0, step: "any" },
                      style: { fontSize: 12, background: "white", height: 32 },
                    }}
                    type="number"
                    size="small"
                    value={eventParams.multibuyAmount}
                    onChange={(event: any) => {
                      setSubmited(false);

                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multibuyAmount: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multibuyAmount: event.target.value,
                      });
                    }}
                    style={{ width: "80px" }}
                  />
                </FormControl>

                <FormControl>
                  <Typography fontWeight={"bold"} align="left" fontSize={10}>
                    .
                  </Typography>
                  <Select
                    {...paramsForm.register("multiBuyPick3")}
                    value={eventParams.multiBuyPick3}
                    className={classes.displaySelect}
                    sx={{ height: 32, bgcolor: "white", width: "68px" }}
                    onChange={(event: any) => {
                      setSubmited(false);
                      dispatch(
                        updateEventParams({
                          ...eventParams,
                          multiBuyPick3: event.target.value,
                        })
                      );
                      setFormValues({
                        ...formValues,
                        multiBuyPick3: event.target.value,
                      });
                    }}
                  >
                    {multiBuyPicklist3.map((item, index) => {
                      return (
                        <MenuItem
                          selected={
                            item == eventParams.multiBuyPick3 ? true : false
                          }
                          value={item}
                          key={index}
                        >
                          <Typography fontSize={13}>{item}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
          <div className={classes.formContainerInputs2}>
            {/* <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Display
              </Typography>
              <Select
                {...paramsForm.register("display")}
                value={eventParams.display}
                className={classes.displaySelect}
                sx={{ height: 32, bgcolor: "white" }}
                onChange={(event: any) => {
                  setSubmited(false);

                  dispatch(updateEventParams({ ...eventParams, display: event.target.value }));
                }}
              >
                {displaySelectItem.map((item, index) => {
                  return (
                    <MenuItem selected={item.value == eventParams.display ? true : false} value={item.value} key={index}>
                      <Typography fontSize={13}>{item.label}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Retail Standard Price
              </Typography>
              <TextField
                {...paramsForm.register("everydayPrice")}
                sx={{ minWidth: 87 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                }}
                type="number"
                size="small"
                value={Number(eventParams.everydayPrice).toFixed(2)}
                style={{ width: "68px" }}
                disabled
              />
            </FormControl>
            <FormControl>
              <Typography fontWeight={"bold"} align="left" fontSize={10}>
                Promoted Price
              </Typography>
              <TextField
                sx={{ minWidth: 87 }}
                InputProps={{
                  style: { fontSize: 12, background: "white", height: 32 },
                }}
                type="number"
                size="small"
                value={parseFloat(calculatPromotedPrice()).toFixed(2)}
                style={{ width: "68px" }}
                disabled
              />
            </FormControl>
            <div className={`flex gap-2 justify-items-end items-end`}>
              <Button
                // disabled={!updated}
                type="submit"
                className={classes.primaryButton}
                variant="outlined"
                color="secondary"
                sx={
                  {
                    // display: submited && !openEvent.id ? "none" : "auto",
                  }
                }
              >
                <PlayArrowOutlinedIcon />
                <Typography
                  color={"auto"}
                  fontWeight={"bold"}
                  align="center"
                  fontSize={14}
                >
                  Apply
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const previewEvent = () => {
    if (addParametersOpen) {
      setAddParametersOpen(false);
      dispatch(previewCalendar());
    }
  };

  return (
    <Modal
      //  hideBackdrop={true}
      open={addParametersOpen}
      onClose={closeAddParameters}
      disableEnforceFocus={true}
    >
      <Grid container sx={{ ...style }} direction="column" rowSpacing={6}>
        <div className={classes.root}>
          <div className="flex w-full justify-between">
            <Typography variant="h2">Event parameters and KPIs</Typography>
            <CloseIcon
              onClick={closeAddParameters}
              sx={{ color: "black", cursor: "pointer" }}
            />
          </div>

          <div style={{ width: "100%" }}>{renderForm()}</div>
          {!selector.previewPending ? (
            <div>
              <div>
                {selector.simulation && (
                  <CalendarKpiTable kpis={selector.simulation.kpis} />
                )}
              </div>
              <br />
              <div>
                <div style={{ height: "100%" }}>
                  {selector.simulation && (
                    <WaterfallChart
                      className={classes.kpiDataWidgetSection}
                      chartEvent={selector.simulation.event}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-items-center items-center">
              <Stack width={"100%"} alignItems="center">
                <CircularProgress />
              </Stack>
            </div>
          )}
          <br />
          <div className={classes.saveDeletePromo}>
            <div>
              {updated && (
                <Button
                  type="submit"
                  className={classes.savePromoButton}
                  variant="outlined"
                  disabled={!selector.changed}
                  color="secondary"
                  sx={{
                    bgcolor: "black",
                    display: `${
                      selector.changed && !selector.previewPending
                        ? "auto"
                        : "none"
                    }`,
                  }}
                  onClick={() => {
                    previewEvent();
                  }}
                >
                  <SaveIcon sx={{ color: "white" }} />
                  <Typography
                    fontWeight={"bold"}
                    align="center"
                    color={"white"}
                    px={1}
                  >
                    Apply
                  </Typography>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </Modal>
  );
};

export default CreateEventDialog;
