import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const waterfallChartStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    selectContainer: {
      display: "flex",
      alignItems: "center",
    },
    selectLabel: {
      fontSize: "12px",
      fontWeight: 600,
      marginRight: theme.spacing(2),
    },
    selectRoot: {
      fontSize: "13px",
      padding: "0 0 5px",
      fontWeight: 600,
      color: theme.palette.primary.main,
      "&::hover": {
        borderBottom: "none",
      },
    },
    chartContainer: {
      width: "100%",
      height: "100%",
    },
    label: {
      fontSize: 14,
      fontWeight: 900,
      fill:'#000000',
    },
  })
);
