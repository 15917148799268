import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme["spacing"](2),
  },
  "& .MuiDialogActions-root": {
    padding: theme["spacing"](1),
  },
}));

export interface DialogTitleProps {
  id: string;
  open: boolean;
  dialogueFlag: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

export function LoadingDialog({ open, onClose, dialogueFlag }: DialogTitleProps) {
  return (
    <div>
      <BootstrapDialog
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
        }}
      >
        <Grid container direction={"column"} gap={3} sx={{ padding: "1.5rem" }}>
          <Box>
            <Typography variant="h3">Please wait...</Typography>
          </Box>
          <Box>
            {(dialogueFlag) ? "While we fetch you reference calendar" : "While we are creating your reference calendar from last year"}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                border: "1px solid #000",
                borderRadius: "5px",
                padding: ".2rem .5rem",
              }}
              onClick={() => onClose()}
            >
              <CloseIcon sx={{ marginRight: ".2rem" }} />
              Cancel
            </Button>
          </Box>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
