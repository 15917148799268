import React, { useState, useEffect } from "react";
import map from "lodash/map";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
} from "@mui/material";

import { kpiSimulationTableStyles } from "./eventSimulationPageStyles";

import { formatNumber } from "Promo/utils/mapper";
import { KeyTerms } from "Promo/utils/constants";

import { Simulation_data_details } from "../../utils/types";

type KPISimulationTableProps = {
  simulationTable: Simulation_data_details[];
  tacticValue: string;
  startDate: string;
  endDate: string;
  promoDepth: string;
  duration: string;
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const AlternativePeriodsTable = React.memo(
  ({
    simulationTable,
    tacticValue,
    startDate,
    endDate,
    promoDepth,
    duration,
  }: KPISimulationTableProps) => {
    const classes = kpiSimulationTableStyles();
    const [barGraphData, setBarGraphData] = useState<any>({});
    const renderBarChart = (
      depthsData: any,
      kpi: string,
      rec: Boolean = false,
      tooltipPosition
    ) => {
      const keys = Object.entries(depthsData).map((row) => row[0]);
      // other step to confirm that keys are populated in sorted order
      keys.sort();
      let maxKey,
        maxValue = -Infinity;
      for (const [key, value] of Object.entries(depthsData)) {
        if (Number(value) > maxValue) {
          maxValue = Number(value);
          maxKey = key;
        }
      }

      const CustomTooltip: React.FC<CustomTooltipProps> = ({
        active,
        payload,
      }) => {
        if (active && payload && payload.length) {
          return (
            <div className={classes.customToolTip}>
              <p className="label">{`${
                KeyTerms[payload[0].dataKey]
              } : ${formatNumber(payload[0].value)}`}</p>
              <p className="start-date">{`Start Date : ${payload[0].payload.startDate}`}</p>
              <p className="end-date">{`End Date : ${payload[0].payload.endDate}`}</p>
              <p className="promo-depth">{`Promo Depth : ${payload[0].payload.promoDepth}`}</p>
              <p className="promo-duration">{`Promo Duration (Days) : ${payload[0].payload.duration}`}</p>
            </div>
          );
        }

        return null;
      };
      const data = keys.map((row, index) => {
        // need to check if row is date object!
        const formatDate = new Date(row).toLocaleDateString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "2-digit",
          timeZone: "UTC",
        });
        return {
          name: `${formatDate}`,
          [kpi]: depthsData[row],
          recommended: row === maxKey ? true : false,
          isNegative: depthsData[row].toString().includes("-"),
          startDate: new Date(startDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "2-digit",
            timeZone: "UTC",
          }),
          endDate: new Date(endDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "2-digit",
            timeZone: "UTC",
          }),
          promoDepth: promoDepth,
          duration: duration,
        };
      });
      return (
        <ResponsiveContainer width="99%" height={500}>
          <BarChart
            width={600}
            height={600}
            data={data}
            layout="vertical"
            margin={{ top: 2, right: 55, left: 5, bottom: 7 }}
          >
            <XAxis type="number" padding={{ left: 40 }} hide />
            <YAxis type="category" dataKey="name" width={70} />
            <Tooltip
              content={<CustomTooltip />}
              position={{
                y: -225,
              }}
            />
            <Bar
              dataKey={kpi}
              barSize={13}
              onMouseOver={(data) => {
                setBarGraphData(data);
              }}
            >
              {data.map((entry, index) => {
                return (
                  <>
                    <Cell
                      cursor="pointer"
                      fill={entry.recommended ? "#3EA589" : "#CCD2D0"}
                      key={`cell-${index}`}
                    />
                    {index === 0 && (
                      <LabelList
                        dataKey={kpi}
                        position={entry.isNegative ? "left" : "right"}
                        style={{ fill: "#525252", fontSize: ".9rem" }}
                        formatter={(kpiValue) => {
                          return "$" + formatNumber(kpiValue);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    };

    return (
      <div className={classes.root}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "26px",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h3">Alternative Periods</Typography>
            <Typography variant="subtitle1">
              Sensitivity analysis of simulation periods ({tacticValue})
            </Typography>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                GEPP Incremental net net sales
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                GEPP Incremental gross profit
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Incremental retailer sales value
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Incremental retailer gross profit
              </TableCell>
            </TableRow>
          </TableHead>

          {simulationTable.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  {renderBarChart(
                    row.incremental_net_net_sales,
                    "incremental_net_net_sales",
                    index === 0,
                    0
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {renderBarChart(
                    row.incremental_gross_profit,
                    "incremental_gross_profit",
                    index === 0,
                    0
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {renderBarChart(
                    row.incremental_retail_sales_value,
                    "incremental_retail_sales_value",
                    index === 0,
                    0
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {renderBarChart(
                    row.incremental_customer_trade_profit,
                    "incremental_customer_trade_profit",
                    index === 0,
                    -130
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </div>
    );
  }
);

export default AlternativePeriodsTable;
