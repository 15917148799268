import axios from "axios";

export enum CalendarComparsionViewEditActionTypes {
  SELECT_REFERENCE_CALENDAR = "SELECT_REFERENCE_CALENDAR",
}

export function selectReferenceCalendar(data: any) {
  return async function (dispatch: any) {
    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_PROMO_API_URL}/calendar/${data}`
      // );
      dispatch({
        type: CalendarComparsionViewEditActionTypes.SELECT_REFERENCE_CALENDAR,
        payload: data,
      });
    } catch (e) {
      //TODO error handling plese and thank you
    }
  };
}