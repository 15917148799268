import { addDays } from "date-fns";
import { ICalendarComparsionRowData } from "../../../pages/calendar/components/calendarComparsionViewEdit/interfaces";
import { getDefaultCalendar } from "../../../pages/calendar/components/calendarComparsionViewEdit/mock";
import { defaultCalendar } from "../../../pages/calendar/components/calenderComparsionTimeline/mock";
import { KPIChartColor } from "../../../../utils/theme";
import { SimulationActionTypes } from "./recommendationAction";

type RecommendationStateType = {
  data: ICalendarComparsionRowData[];
}

const INITIAL_STATE : RecommendationStateType = {
  data: [
    {
      subbrand: "Retailer 0",
      timeline: {
        data: [
          // Element
          {
            // Row
            data: [
              {
                type: "baseline",
                events: [
                  {
                    start: addDays(new Date(), 7 * 5),
                    end: addDays(new Date(), 7 * 5 + 7 * 2),
                    value: "100$",
                  },
                  {
                    start: addDays(new Date(), 7 * 15),
                    end: addDays(new Date(), 7 * 15 + 7 * 2),
                    value: "150$",
                  },
                  {
                    start: addDays(new Date(), 7 * 25),
                    end: addDays(new Date(), 7 * 25 + 7 * 5),
                    value: "110$",
                  },
                ],
              },
              {
                type: "maxSalesHighDisruption",
                events: [
                  {
                    start: addDays(new Date(), 7 * 5),
                    end: addDays(new Date(), 7 * 5 + 7 * 2),
                    value: "100$",
                  },
                  {
                    start: addDays(new Date(), 7 * 15),
                    end: addDays(new Date(), 7 * 15 + 7 * 2),
                    value: "150$",
                  },
                  {
                    start: addDays(new Date(), 7 * 25),
                    end: addDays(new Date(), 7 * 25 + 7 * 5),
                    value: "110$",
                  },
                ],
              },
            ],
          },
        ],
      },
      kpis: {
        netRevenueBaseline: 620000,
        volumeUnitBaseline: 620000,
        marginBaseline: 620000,
        arpBaseline: 0.32,
        netRevenueConstantMarginMarketShare: 620000,
        volumeUnitConstantMarginMarketShare: 620000,
        marginConstantMarginMarketShare: 620000,
        arpConstantMarginMarketShare: 0.4,
      },
    },
    {
      subbrand: "Subbrand 1",
      timeline: {
        data: [
          // Element
          {
            // Row
            data: [
              {
                type: "baseline",
                events: [
                  {
                    start: addDays(new Date(), 7 * 5),
                    end: addDays(new Date(), 7 * 5 + 7 * 2),
                    value: "100$",
                  },
                  {
                    start: addDays(new Date(), 7 * 15),
                    end: addDays(new Date(), 7 * 15 + 7 * 2),
                    value: "150$",
                  },
                  {
                    start: addDays(new Date(), 7 * 25),
                    end: addDays(new Date(), 7 * 25 + 7 * 5),
                    value: "110$",
                  },
                ],
              },
              {
                type: "maxSalesHighDisruption",
                events: [
                  {
                    start: addDays(new Date(), 7 * 5),
                    end: addDays(new Date(), 7 * 5 + 7 * 2),
                    value: "100$",
                  },
                  {
                    start: addDays(new Date(), 7 * 15),
                    end: addDays(new Date(), 7 * 15 + 7 * 2),
                    value: "150$",
                  },
                  {
                    start: addDays(new Date(), 7 * 25),
                    end: addDays(new Date(), 7 * 25 + 7 * 5),
                    value: "110$",
                  },
                ],
              },
            ],
          },
        ],
      },
      kpis: {
        netRevenueBaseline: 620000,
        volumeUnitBaseline: 620000,
        marginBaseline: 620000,
        arpBaseline: 0.32,
        netRevenueConstantMarginMarketShare: 620000,
        volumeUnitConstantMarginMarketShare: 620000,
        marginConstantMarginMarketShare: 620000,
        arpConstantMarginMarketShare: 0.4,
      },
    },
  ],
};

function RecommendationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SimulationActionTypes.TIMELINE_UPDATE:
      return { ...state };
    default:
      return { ...state };
  }
  return state;
}

export default RecommendationReducer;
