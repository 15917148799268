import axios from "axios";

export enum ConstraintsManagementActionTypes {
  CONSTRAINT_SETS_FETCH = "CONSTRAINT_SETS_FETCH",

  CONSTRAINTS_UPDATE = "CONSTRAINTS_UPDATE",
  CONSTRAINTS_WIPE = "CONSTRAINTS_WIPE",

  COMMON_CONSTRAINTS_UPDATE = "COMMON_CONSTRAINTS_UPDATE",
  COMMON_CONSTRAINTS_WIPE = "COMMON_CONSTRAINTS_WIPE",

  BRAND_CONSTRAINTS_UPDATE = "BRAND_CONSTRAINTS_UPDATE",
  BRAND_CONSTRAINTS_WIPE = "BRAND_CONSTRAINTS_WIPE",

  PRODUCT_CONSTRAINTS_UPDATE = "PRODUCT_CONSTRAINTS_UPDATE",
  PRODUCT_CONSTRAINTS_WIPE = "PRODUCT_CONSTRAINTS_WIPE",

  EDIT_FORM_UPDATE = "EDIT_FORM_UPDATE",
  EDIT_FORM_WIPE = "EDIT_FORM_WIPE",

  CONSTRAINT_SET_FETCH_PENDING = "CONSTRAINT_SET_FETCH_PENDING",
  CONSTRAINT_SET_FETCH_FULFILLED = "CONSTRAINT_SET_FETCH_FULFILLED",
  CONSTRAINT_SET_FETCH_REJECTED = "CONSTRAINT_SET_FETCH_REJECTED",

  CONSTRAINT_SET_DELETE_PENDING = "CONSTRAINT_SET_DELETE_PENDING",
  CONSTRAINT_SET_DELETE_FULFILLED = "CONSTRAINT_SET_DELETE_FULFILLED",
  CONSTRAINT_SET_DELETE_REJECTED = "CONSTRAINT_SET_DELETE_REJECTED",

  CONSTRAINT_SET_EDIT_PENDING = "CONSTRAINT_SET_EDIT_PENDING",
  CONSTRAINT_SET_EDIT_FULFILLED = "CONSTRAINT_SET_EDIT_FULFILLED",
  CONSTRAINT_SET_EDIT_REJECTED = "CONSTRAINT_SET_EDIT_REJECTED",

  CONSTRAINT_SET_COPY_PENDING = "CONSTRAINT_SET_COPY_PENDING",
  CONSTRAINT_SET_COPY_FULFILLED = "CONSTRAINT_SET_COPY_FULFILLED",
  CONSTRAINT_SET_COPY_REJECTED = "CONSTRAINT_SET_COPY_REJECTED",

  CONSTRAINT_SET_CREATE_PENDING = "CONSTRAINT_SET_CREATE_PENDING",
  CONSTRAINT_SET_CREATE_FULFILLED = "CONSTRAINT_SET_CREATE_FULFILLED",
  CONSTRAINT_SET_CREATE_REJECTED = "CONSTRAINT_SET_CREATE_REJECTED",

  CONSTRAINT_SET_EDIT = "CONSTRAINT_SET_EDIT",
  CONSTRAINT_SET_CREATE = "CONSTRAINT_SET_CREATE",
}

export const fetchConstraintSets =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_PENDING,
        data: {},
      });

      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/constraint_sets`
      );

      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_FETCH_REJECTED,
        data: {},
      });
    }
  };

export const deleteConstraintSet =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_DELETE_PENDING,
        data: {},
      });

      const response = await axios.delete(
        `${process.env.REACT_APP_PROMO_API_URL}/constraint_sets/${id}`
      );

      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_DELETE_FULFILLED,
        data: id,
      });
    } catch (e) {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_DELETE_REJECTED,
        data: {},
      });
    }
  };

export const copyConstraintSet =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_COPY_PENDING,
        data: {},
      });
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_COPY_FULFILLED,
        data: id,
      });
    } catch (e) {
      dispatch({
        type: ConstraintsManagementActionTypes.CONSTRAINT_SET_COPY_REJECTED,
        data: {},
      });
    }
  };
