import {
  Calendar,
  FetchAllCalendarsReturnType,
  FetchCalendarFiltersType,
  FetchPaginatedCalendarsType,
  GeneratedReferenceCalendar,
  NewCalendarFormValues,
  ReferenceCalendar,
  SetSelectedRefCalendarType,
} from "../../types";
import axios from "axios";

export enum CalendarOptimizationActionTypes {
  CREATE_CALENDAR_REQUEST_PENDING = "CREATE_CALENDAR_REQUEST_PENDING",
  CREATE_CALENDAR_REQUEST_FULFILLED = "CREATE_CALENDAR_REQUEST_FULFILLED",
  CREATE_CALENDAR_REQUEST_REJECTED = "CREATE_CALENDAR_REQUEST_REJECTED",
  CREATE_CALENDAR_REQUEST_RESET = "CREATE_CALENDAR_REQUEST_RESET",

  FETCH_ALL_CALENDARS = "FETCH_ALL_CALENDARS",
  FETCH_ALL_CALENDARS_PENDING = "FETCH_ALL_CALENDARS_PENDING",
  FETCH_ALL_CALENDARS_FULFILLED = "FETCH_ALL_CALENDARS_FULFILLED",
  FETCH_ALL_CALENDARS_REJECTED = "FETCH_ALL_CALENDARS_REJECTED",
  CLEAR_ALL_CALENDARS = "CLEAR_ALL_CALENDARS",

  FETCH_CALENDAR = "FETCH_CALENDAR",
  FETCH_CALENDAR_PENDING = "FETCH_CALENDAR_PENDING",
  FETCH_CALENDAR_FULFILLED = "FETCH_CALENDAR_FULFILLED",
  FETCH_CALENDAR_REJECTED = "FETCH_CALENDAR_REJECTED",

  FETCH_ALL_REF_CALENDARS = "FETCH_ALL_REF_CALENDARS",
  FETCH_ALL_REF_CALENDARS_PENDING = "FETCH_ALL_REF_CALENDARS_PENDING",
  FETCH_ALL_REF_CALENDARS_FULFILLED = "FETCH_ALL_REF_CALENDARS_FULFILLED",
  FETCH_ALL_REF_CALENDARS_REJECTED = "FETCH_ALL_REF_CALENDARS_REJECTED",

  FETCH_REF_CALENDAR = "FETCH_REF_CALENDAR",
  FETCH_REF_CALENDAR_PENDING = "FETCH_REF_CALENDAR_PENDING",
  FETCH_REF_CALENDAR_FULFILLED = "FETCH_REF_CALENDAR_FULFILLED",
  FETCH_REF_CALENDAR_REJECTED = "FETCH_REF_CALENDAR_REJECTED",

  FETCH_GENERATED_REF_CALENDAR = "FETCH_GENERATED_REF_CALENDAR",
  FETCH_GENERATED_REF_CALENDAR_PENDING = "FETCH_GENERATED_REF_CALENDAR_PENDING",
  FETCH_GENERATED_REF_CALENDAR_FULFILLED = "FETCH_GENERATED_REF_CALENDAR_FULFILLED",
  FETCH_GENERATED_REF_CALENDAR_REJECTED = "FETCH_GENERATED_REF_CALENDAR_REJECTED",
  FETCH_GENERATED_REF_CALENDAR_CLEAR = "FETCH_GENERATED_REF_CALENDAR_CLEAR",

  FETCH_COMPARISON_PAGE = "FETCH_COMPARISON_PAGE",
  FETCH_COMPARISON_PAGE_PENDING = "FETCH_COMPARISON_PAGE_PENDING",
  FETCH_COMPARISON_PAGE_FULFILLED = "FETCH_COMPARISON_PAGE_FULFILLED",
  FETCH_COMPARISON_PAGE_REJECTED = "FETCH_COMPARISON_PAGE_REJECTED",

  FETCH_AGGREGATION_PAGE = "FETCH_AGGREGATION_PAGE",
  FETCH_AGGREGATION_PAGE_PENDING = "FETCH_AGGREGATION_PAGE_PENDING",
  FETCH_AGGREGATION_PAGE_FULFILLED = "FETCH_AGGREGATION_PAGE_FULFILLED",
  FETCH_AGGREGATION_PAGE_REJECTED = "FETCH_AGGREGATION_PAGE_REJECTED",

  ADD_NEW_CALENDAR = "ADD_NEW_CALENDAR",
  ADD_NEW_CALENDAR_PENDING = "ADD_NEW_CALENDAR_PENDING",
  ADD_NEW_CALENDAR_FULFILLED = "ADD_NEW_CALENDAR_FULFILLED",
  ADD_NEW_CALENDAR_REJECTED = "ADD_NEW_CALENDAR_REJECTED",

  DELETE_CALENDARS = "DELETE_CALENDARS",
  DELETE_CALENDAR_PENDING = "DELETE_CALENDAR_PENDING",
  DELETE_CALENDAR_FULFILLED = "DELETE_CALENDAR_FULFILLED",
  DELETE_CALENDAR_REJECTED = "DELETE_CALENDAR_REJECTED",

  FETCH_ALL_CALENDAR_FILTERS = "FETCH_ALL_CALENDAR_FILTERS",
  FETCH_ALL_CALENDAR_FILTERS_PENDING = "FETCH_ALL_CALENDAR_FILTERS_PENDING",
  FETCH_ALL_CALENDAR_FILTERS_FULFILLED = "FETCH_ALL_CALENDAR_FILTERS_FULFILLED",
  FETCH_ALL_CALENDAR_FILTERS_REJECTED = "FETCH_ALL_CALENDAR_FILTERS_REJECTED",

  UPLOAD_CALENDAR = "UPLOAD_CALENDAR",
  UPLOAD_CALENDAR_PENDING = "UPLOAD_CALENDAR_PENDING",
  UPLOAD_CALENDAR_FULFILLED = "UPLOAD_CALENDAR_FULFILLED",
  UPLOAD_CALENDAR_REJECTED = "UPLOAD_CALENDAR_REJECTED",

  SET_SELECTED_REF_CALENDAR = "SET_SELECTED_REF_CALENDAR",

  // This should be dumped, this is just to make believe
  UPDATE_NEW_CALENDAR = "UPDATE_NEW_CALENDAR",
}

/************************* Action Interfaces ******************************/

export interface SetSelectedRefCalendarActionType {
  type: CalendarOptimizationActionTypes.SET_SELECTED_REF_CALENDAR;
  data: SetSelectedRefCalendarType;
}

export interface CreateCalendarResetActionType {
  type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_RESET;
}
export interface CreateCalendarPendingActionType {
  type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_PENDING;
}

export interface CreateCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_FULFILLED;
}

export interface CreateCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_REJECTED;
}

export interface FetchAllCalendarsActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS;
}
export interface FetchAllCalendarsPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_PENDING;
}
export interface FetchAllCalendarsFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_FULFILLED;
  data: FetchPaginatedCalendarsType;
}
export interface FetchAllCalendarsRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_REJECTED;
}

export interface FetchAllCalendarFiltersActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS;
}
export interface FetchAllCalendarFiltersPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_PENDING;
}
export interface FetchAllCalendarFiltersFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_FULFILLED;
  data: FetchCalendarFiltersType;
}
export interface FetchAllCalendarFiltersRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_REJECTED;
}

export interface FetchAggregationChartsFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_FULFILLED;
  data: any;
}

export interface FetchAllRefCalendarsActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS;
}
export interface FetchAllRefCalendarsPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_PENDING;
}
export interface FetchAllRefCalendarsFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED;
  data: ReferenceCalendar[];
}
export interface FetchAllRefCalendarsRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_REJECTED;
}

export interface FetchRefCalendarActionType {
  type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR;
}
export interface FetchRefCalendarPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_PENDING;
}
export interface FetchRefCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_FULFILLED;
  data: any;
}
export interface FetchRefCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_REJECTED;
}

export interface FetchGeneratedRefCalActionType {
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR;
}
export interface FetchGeneratedRefCalPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_PENDING;
}
export interface FetchGeneratedRefCalFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_FULFILLED;
  data: any;
}
export interface FetchGeneratedRefCalRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_REJECTED;
}
export interface FetchGeneratedRefCalClearActionType {
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_CLEAR;
}

export interface FetchCalendarActionType {
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR;
}
export interface FetchCalendarPendingActionType {
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR_PENDING;
}
export interface FetchCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR_FULFILLED;
  data: Calendar;
}
export interface FetchCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR_REJECTED;
}

export interface FetchComparisonChartsFulfilledActionType {
  type: CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_FULFILLED;
  data: any;
}

export interface AddNewCalendarActionType {
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR;
  data: NewCalendarFormValues;
}
export interface AddNewCalendarPendingActionType {
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_PENDING;
}
export interface AddNewCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_FULFILLED;
  data: Calendar;
}
export interface AddNewCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_REJECTED;
}

export interface UpdateNewCalendarActionType {
  type: CalendarOptimizationActionTypes.UPDATE_NEW_CALENDAR;
  id: string;
}

export interface UploadCalendarActionType {
  type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR;
}
export interface UploadCalendarPendingActionType {
  type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_PENDING;
}
export interface UploadCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_FULFILLED;
  data: any;
}
export interface UploadCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_REJECTED;
  data: any;
}

/************************* Action Constructors ******************************/

export const FetchAggregationChartsFulfilledAction = (
  data: any
): FetchAggregationChartsFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_FULFILLED,
  data,
});

export const SetSelectedRefCalendarAction = (
  data: SetSelectedRefCalendarType
): SetSelectedRefCalendarActionType => ({
  type: CalendarOptimizationActionTypes.SET_SELECTED_REF_CALENDAR,
  data,
});

export const CreateCalendarRequestResetAction =
  (): CreateCalendarResetActionType => ({
    type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_RESET,
  });
export const CreateCalendarRequestFulfilledAction =
  (): CreateCalendarFulfilledActionType => ({
    type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_FULFILLED,
  });

export const CreateCalendarRequestPendingAction =
  (): CreateCalendarPendingActionType => ({
    type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_PENDING,
  });

export const CreateCalendarRequestRejectedAction =
  (): CreateCalendarRejectedActionType => ({
    type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_REJECTED,
  });

export const FetchAllCalendarsAction = (): FetchAllCalendarsActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS,
});
export const FetchAllCalendarsPendingAction =
  (): FetchAllCalendarsPendingActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_PENDING,
  });
export const FetchAllCalendarsFulfilledAction = (
  data: FetchPaginatedCalendarsType
): FetchAllCalendarsFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_FULFILLED,
  data,
});
export const FetchAllCalendarsRejectedAction =
  (): FetchAllCalendarsRejectedActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_REJECTED,
  });

export const FetchAllCalendarFilterAction =
  (): FetchAllCalendarFiltersActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS,
  });
export const FetchAllCalendarFilterPendingAction =
  (): FetchAllCalendarFiltersPendingActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_PENDING,
  });
export const FetchAllCalendarFilterFulfilledAction = (
  data: FetchCalendarFiltersType
): FetchAllCalendarFiltersFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_FULFILLED,
  data,
});
export const FetchAllCalendarFilterRejectedAction =
  (): FetchAllCalendarFiltersRejectedActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_REJECTED,
  });

export const FetchAllRefCalendarsAction =
  (): FetchAllRefCalendarsActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS,
  });
export const FetchAllRefCalendarsPendingAction =
  (): FetchAllRefCalendarsPendingActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_PENDING,
  });
export const FetchAllRefCalendarsFulfilledAction = (
  data: ReferenceCalendar[]
): FetchAllRefCalendarsFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED,
  data,
});
export const FetchAllRefCalendarsRejectedAction =
  (): FetchAllRefCalendarsRejectedActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_REJECTED,
  });

export const FetchGeneratedRefCalendarAction =
  (): FetchGeneratedRefCalActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR,
  });
export const FetchGeneratedRefCalendarPendingAction =
  (): FetchGeneratedRefCalPendingActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_PENDING,
  });
export const FetchGeneratedRefCalendarFulfilledAction = (
  data: any
): FetchGeneratedRefCalFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_FULFILLED,
  data,
});
export const FetchGeneratedRefCalendarRejectedAction =
  (): FetchGeneratedRefCalRejectedActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_REJECTED,
  });
export const FetchGeneratedRefCalendarClearAction =
  (): FetchGeneratedRefCalClearActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_CLEAR,
  });

export const FetchComparisonChartsFulfilledAction = (
  data: any
): FetchComparisonChartsFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_FULFILLED,
  data,
});

export const FetchCalendarAction = (): FetchCalendarActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR,
});
export const FetchCalendarPendingAction =
  (): FetchCalendarPendingActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_CALENDAR_PENDING,
  });
export const FetchCalendarFulfilledAction = (
  data: Calendar
): FetchCalendarFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.FETCH_CALENDAR_FULFILLED,
  data,
});
export const FetchCalendarRejectedAction =
  (): FetchCalendarRejectedActionType => ({
    type: CalendarOptimizationActionTypes.FETCH_CALENDAR_REJECTED,
  });

export const AddNewCalendarAction = (
  data: NewCalendarFormValues
): AddNewCalendarActionType => ({
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR,
  data,
});
export const AddNewCalendarPendingAction =
  (): AddNewCalendarPendingActionType => ({
    type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_PENDING,
  });
export const AddNewCalendarFulfilledAction = (
  data: Calendar
): AddNewCalendarFulfilledActionType => ({
  type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_FULFILLED,
  data,
});
export const AddNewCalendarRejectedAction =
  (): AddNewCalendarRejectedActionType => ({
    type: CalendarOptimizationActionTypes.ADD_NEW_CALENDAR_REJECTED,
  });

export const UpdateNewCalendarAction = (
  id: string
): UpdateNewCalendarActionType => ({
  type: CalendarOptimizationActionTypes.UPDATE_NEW_CALENDAR,
  id,
});
/************************* Action Deletion ******************************/

export interface DeleteCalendarsActionType {
  type: CalendarOptimizationActionTypes.DELETE_CALENDARS;
}
export interface DeleteCalendarsPendingActionType {
  type: CalendarOptimizationActionTypes.DELETE_CALENDAR_PENDING;
}
export interface DeleteCalendarFulfilledActionType {
  type: CalendarOptimizationActionTypes.DELETE_CALENDAR_FULFILLED;
  data: any;
}
export interface DeleteCalendarRejectedActionType {
  type: CalendarOptimizationActionTypes.DELETE_CALENDAR_REJECTED;
  data: any;
}

/************************* Type Composition ******************************/

export type CalendarOptimizationAction =
  | SetSelectedRefCalendarActionType
  | CreateCalendarRejectedActionType
  | CreateCalendarFulfilledActionType
  | CreateCalendarPendingActionType
  | CreateCalendarResetActionType
  | FetchAggregationChartsFulfilledActionType
  | FetchAllRefCalendarsActionType
  | FetchAllRefCalendarsPendingActionType
  | FetchAllRefCalendarsFulfilledActionType
  | FetchAllRefCalendarsRejectedActionType
  | FetchGeneratedRefCalActionType
  | FetchGeneratedRefCalPendingActionType
  | FetchGeneratedRefCalFulfilledActionType
  | FetchGeneratedRefCalRejectedActionType
  | FetchGeneratedRefCalClearActionType
  | FetchAllCalendarsActionType
  | FetchAllCalendarsPendingActionType
  | FetchAllCalendarsFulfilledActionType
  | FetchAllCalendarsRejectedActionType
  | FetchAllCalendarFiltersActionType
  | FetchAllCalendarFiltersPendingActionType
  | FetchAllCalendarFiltersFulfilledActionType
  | FetchAllCalendarFiltersRejectedActionType
  | FetchComparisonChartsFulfilledActionType
  | FetchRefCalendarFulfilledActionType
  | FetchRefCalendarPendingActionType
  | FetchRefCalendarRejectedActionType
  | FetchCalendarActionType
  | FetchCalendarPendingActionType
  | FetchCalendarFulfilledActionType
  | FetchCalendarRejectedActionType
  | AddNewCalendarActionType
  | AddNewCalendarPendingActionType
  | AddNewCalendarFulfilledActionType
  | AddNewCalendarRejectedActionType
  | UpdateNewCalendarActionType
  | UploadCalendarActionType
  | UploadCalendarPendingActionType
  | UploadCalendarFulfilledActionType
  | UploadCalendarRejectedActionType
  | CreateCalendarFulfilledActionType
  | DeleteCalendarsActionType
  | DeleteCalendarsPendingActionType
  | DeleteCalendarFulfilledActionType
  | DeleteCalendarRejectedActionType
  | any;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const params = (query?: any) => {
  let params = "";
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      const val: any = value;
      if (val.length) {
        params += `${key}=[${encodeURIComponent(val)}]&`;
      }
    }
  }

  return {
    params: {},
    paramsSerializer: () => {
      return params;
    },
  };
};

export const fetchAllCalendars = (
  market: string,
  page: number,
  size: number,
  query?: any,
  sortBy: string = "created",
  ascending: boolean = false
) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_PENDING,
        data: {},
      });
      let requestUrl = `${process.env.REACT_APP_PROMO_API_URL}/calendar/?market=${market}&page=${page}&size=${size}&sortBy=${sortBy}&ascending=${ascending}`;
      const response = await axios.get(requestUrl, params(query));

      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDARS_REJECTED,
        data: {},
      });
    }
  };
};

export const fetchAllCalendarFilters = (market: string) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_PENDING,
        data: {},
      });
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/filters?market=${market}`
      );
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_CALENDAR_FILTERS_REJECTED,
        data: {},
      });
    }
  };
};

export const fetchCalendar = () => (id: string) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_CALENDAR_PENDING,
        data: {},
      });
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${id}`
      );
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_CALENDAR_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_CALENDAR_REJECTED,
        data: {},
      });
    }
  };
};

export const fetchComparisonData =
  (identifiers: string[], filters?: { brand: any; product: any }) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_PENDING,
        data: {},
      });
      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/comparison`,
        { ids: identifiers, filters: filters }
      );
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_FULFILLED,
        data: response.data,
      });
    } catch (e: any) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_COMPARISON_PAGE_REJECTED,
        data: {},
      });
    }
  };

export const createCalendar = (calendar: any) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_PENDING,
        payload: {},
      });

      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar`,
        calendar
      );

      dispatch({
        type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_FULFILLED,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.CREATE_CALENDAR_REQUEST_REJECTED,
        payload: {},
      });
    }
  };
};

export const deleteCalendarAction = (calendarId: any) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.DELETE_CALENDAR_PENDING,
        payload: {},
      });

      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/${calendarId}/delete`
      );

      dispatch({
        type: CalendarOptimizationActionTypes.DELETE_CALENDAR_FULFILLED,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.DELETE_CALENDAR_REJECTED,
        payload: {},
      });
    }
  };
};

export const fetchAllRefCalendars = (
  quarter: string,
  retailer: string,
  market: string
) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_PENDING,
        payload: {},
      });

      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar?quarter=${quarter}&retailer=${retailer}&market=${market}`
      );

      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_ALL_REF_CALENDARS_REJECTED,
        payload: {},
      });
    }
  };
};

export const fetchRefCalendar = (calendar_id) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_PENDING,
        payload: {},
      });

      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/reference/calendar/${calendar_id}`
      );

      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_REF_CALENDAR_REJECTED,
        payload: {},
      });
    }
  };
};

export const fetchGeneratedRefCal = (quarter, retailer, market) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_PENDING,
        payload: {},
      });

      const resp = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/reference/calendar`,
        {
          quarter,
          retailer,
          market,
        }
      );
      const calendarId = resp.data;

      let response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/reference/calendar/${calendarId}`
      );
      let retries = 0;
      while (response.status === 202) {
        if (retries < 1) await delay(1000 * 20);
        else await delay(2000);
        response = await axios.get(
          `${process.env.REACT_APP_PROMO_API_URL}/reference/calendar/${calendarId}`
        );
        retries++;
      }

      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_GENERATED_REF_CALENDAR_REJECTED,
        payload: {},
      });
    }
  };
};

export const fetchAggregationData =
  (identifiers: string[]) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_PENDING,
        data: {},
      });
      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/aggregation`,
        { ids: identifiers }
      );
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_FULFILLED,
        data: response.data,
      });
    } catch (e: any) {
      dispatch({
        type: CalendarOptimizationActionTypes.FETCH_AGGREGATION_PAGE_REJECTED,
        data: {},
      });
    }
  };

export const uploadCalendarAction = (formData: any) => {
  return async function (dispatch: any, getState: any) {
    try {
      dispatch({
        type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_PENDING,
        payload: {},
      });

      const response = await axios.post(
        `${process.env.REACT_APP_PROMO_API_URL}/calendar/upload`,
        formData
      );

      dispatch({
        type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_FULFILLED,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR_REJECTED,
        payload: {},
      });
    }
  };
};
