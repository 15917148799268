import { ReactComponent as PricingIcon } from "./icons/pricing_icon.svg";
import { ReactComponent as PromoIcon } from "./icons/promo_icon.svg";
import { ReactComponent as AssortmentIcon } from "./icons/assortment_icon.svg";
import { ReactComponent as TradeIcon } from "./icons/trade_icon.svg";

export interface LinkClass {
  url: string;
  external: boolean;
}

export interface NavCardClass {
  title: string;
  sub: string;
  link: LinkClass;
  key: number;
}

export const DropDownItems = [
  {
    text: "Promo",
    link: {
      path: "/",
      external: false,
    },
  },
];

export const PROMO_MECHANICS_TYPE_TO_UNIT: Record<string, string> = {
  percentage: "%",
  euros: "$",
};
