//import { PromoSimulationFormValues } from "shared/components/promoSimulationForm/PromoSimulationForm"
import {
  CalendarData,
  PromoEvent,
  PromoSimulationFormValues,
} from "../../types";
import { PROMO_ACTION_TYPE } from "../../enum";
import axios from "axios";
import { fetchCalendarApi } from "./optimizationApis";
export enum OptimizationActionTypes {
  FETCH_CURRENT_CALENDAR = "FETCH_CURRENT_CALENDAR",
  FETCH_CURRENT_CALENDAR_PENDING = "FETCH_CURRENT_CALENDAR_PENDING",
  FETCH_CURRENT_CALENDAR_FULFILLED = "FETCH_CURRENT_CALENDAR_FULFILLED",
  FETCH_CURRENT_CALENDAR_REJECTED = "FETCH_CURRENT_CALENDAR_REJECTED",

  FETCH_REFERENCE_CALENDAR = "FETCH_REFERENCE_CALENDAR",
  FETCH_REFERENCE_CALENDAR_PENDING = "FETCH_REFERENCE_CALENDAR_PENDING",
  FETCH_REFERENCE_CALENDAR_FULFILLED = "FETCH_REFERENCE_CALENDAR_FULFILLED",
  FETCH_REFERENCE_CALENDAR_REJECTED = "FETCH_REFERENCE_CALENDAR_REJECTED",

  SHOW_DIALOG = "SHOW_DIALOG",
  HIDE_DIALOG = "HIDE_DIALOG",
  SIMULATE_PROMO_EVENT = "SIMULATE_PROMO_EVENT",

  ADD_PROMO_EVENT = "ADD_PROMO_EVENT",
  MODIFY_PROMO_EVENT = "MODIFY_PROMO_EVENT",
  DELETE_PROMO_EVENT = "DELETE_PROMO_EVENT",
  SET_REFERENCE_CALENDAR_DATA = "SET_REFERENCE_CALENDAR_DATA",
}

/************************* Action Interfaces ******************************/

export interface ShowDialogActionType {
  type: OptimizationActionTypes.SHOW_DIALOG;
  dialogType: PROMO_ACTION_TYPE;
  promoEvent?: PromoEvent;
  promoID?: number;
  productName: string;
  retailerName: string;
}
export interface HideDialogActionType {
  type: OptimizationActionTypes.HIDE_DIALOG;
}
export interface SimulatePromoEventActionType {
  type: OptimizationActionTypes.SIMULATE_PROMO_EVENT;
  data: PromoSimulationFormValues;
}

export interface AddPromoEventActionType {
  type: OptimizationActionTypes.ADD_PROMO_EVENT;
  payload: any;
}

export interface ModifyPromoEventActionType {
  type: OptimizationActionTypes.MODIFY_PROMO_EVENT;
  payload: any;
}

export interface DeletePromoEventActionType {
  type: OptimizationActionTypes.DELETE_PROMO_EVENT;
  payload: any;
}

export interface SetReferenceCalendarDataActionType {
  type: OptimizationActionTypes.SET_REFERENCE_CALENDAR_DATA;
  payload: any;
}

export interface FetchCurrentCalendarActionType {
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR;
  id: string;
}
export interface FetchCurrentCalendarPendingActionType {
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_PENDING;
}
export interface FetchCurrentCalendarFulfilledActionType {
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_FULFILLED;
  data: CalendarData;
}
export interface FetchCurrentCalendarRejectedActionType {
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_REJECTED;
}

export interface FetchReferenceCalendarActionType {
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR;
  id: string;
}
export interface FetchReferenceCalendarPendingActionType {
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_PENDING;
}
export interface FetchReferenceCalendarFulfilledActionType {
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_FULFILLED;
  data: CalendarData;
}
export interface FetchReferenceCalendarRejectedActionType {
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_REJECTED;
}

/************************* Action Constructors ******************************/

export const ShowDialogAction = (
  dialogType: PROMO_ACTION_TYPE,
  productName: string,
  retailerName: string,
  promoID?: number,
  promoEvent?: PromoEvent
): ShowDialogActionType => ({
  type: OptimizationActionTypes.SHOW_DIALOG,
  dialogType,
  promoID,
  productName,
  retailerName,
  promoEvent,
});
export const HideDialogAction = (): HideDialogActionType => ({
  type: OptimizationActionTypes.HIDE_DIALOG,
});
export const SimulatePromoEventAction = (
  data: PromoSimulationFormValues
): SimulatePromoEventActionType => ({
  type: OptimizationActionTypes.SIMULATE_PROMO_EVENT,
  data,
});

export const AddPromoEventAction = (payload: any): AddPromoEventActionType => ({
  type: OptimizationActionTypes.ADD_PROMO_EVENT,
  payload,
});

export const ModifyPromoEventAction = (
  payload: any
): ModifyPromoEventActionType => ({
  type: OptimizationActionTypes.MODIFY_PROMO_EVENT,
  payload,
});
export const DeletePromoEventAction = (
  payload: any
): DeletePromoEventActionType => ({
  type: OptimizationActionTypes.DELETE_PROMO_EVENT,
  payload,
});
export const SetReferenceCalendarDataAction = (
  payload: any
): SetReferenceCalendarDataActionType => ({
  type: OptimizationActionTypes.SET_REFERENCE_CALENDAR_DATA,
  payload,
});

export const FetchCurrentCalendarAction = (
  id: string
): FetchCurrentCalendarActionType => ({
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR,
  id,
});
export const FetchCurrentCalendarPendingAction =
  (): FetchCurrentCalendarPendingActionType => ({
    type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_PENDING,
  });
export const FetchCurrentCalendarFulfilledAction = (
  data: CalendarData
): FetchCurrentCalendarFulfilledActionType => ({
  type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_FULFILLED,
  data,
});
export const FetchCurrentCalendarRejectedAction =
  (): FetchCurrentCalendarRejectedActionType => ({
    type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_REJECTED,
  });

export const FetchReferenceCalendarAction = (
  id: string
): FetchReferenceCalendarActionType => ({
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR,
  id,
});
export const FetchReferenceCalendarPendingAction =
  (): FetchReferenceCalendarPendingActionType => ({
    type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_PENDING,
  });
export const FetchReferenceCalendarFulfilledAction = (
  data: CalendarData
): FetchReferenceCalendarFulfilledActionType => ({
  type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_FULFILLED,
  data,
});
export const FetchReferenceCalendarRejectedAction =
  (): FetchReferenceCalendarRejectedActionType => ({
    type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_REJECTED,
  });

/************************* Type Composition ******************************/

export type OptimizationAction =
  | ShowDialogActionType
  | HideDialogActionType
  | SimulatePromoEventActionType
  | AddPromoEventActionType
  | ModifyPromoEventActionType
  | DeletePromoEventActionType
  | SetReferenceCalendarDataActionType
  | FetchCurrentCalendarActionType
  | FetchCurrentCalendarPendingActionType
  | FetchCurrentCalendarFulfilledActionType
  | FetchCurrentCalendarRejectedActionType
  | FetchReferenceCalendarActionType
  | FetchReferenceCalendarPendingActionType
  | FetchReferenceCalendarFulfilledActionType
  | FetchReferenceCalendarRejectedActionType;

//******************************************************** */

export const fetchCurrentCalendarAction =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_PENDING,
        data: {},
      });
      const response = await axios.get(
        `${process.env.REACT_APP_PROMO_API_URL}/calender/${id}`
      );
      dispatch({
        type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_FULFILLED,
        data: response.data,
      });
    } catch (e) {
      dispatch({
        type: OptimizationActionTypes.FETCH_CURRENT_CALENDAR_REJECTED,
        data: {},
      });
    }
  };

export const fetchReferenceCalendarAction =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_PENDING,
        data: {},
      });
      //   const response = await axios.get(`${config.API_URL}/calender/${id}`);
      const result = fetchCalendarApi(id);
      dispatch({
        type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_FULFILLED,
        data: result,
      });
    } catch (e) {
      dispatch({
        type: OptimizationActionTypes.FETCH_REFERENCE_CALENDAR_REJECTED,
        data: {},
      });
    }
  };
