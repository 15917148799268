import { ICalendarComparsionRowData } from "../interfaces";
import Activities from "./activities/activities";
import addIcon from "../img/Shape.svg";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ITimelineEventData } from "../../calenderComparsionTimeline/interfaces";
import { makeStyles } from "@mui/styles";
import { addDays } from "date-fns";

interface IProps {
  calendar: any;
  referenceCalendar: any;
  onAddActivity: Function;
  onItemClick: (values: ITimelineEventData) => void;
  timelineRange: any;
  timelineData: any;
  ownProducts: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  oneTimeline: {
    display: "flex",
    flexDirection: "column",
    gap: "1.75rem",
  },
  twoTimelines: {
    display: "flex",
    flexDirection: "column",
    gap: "3.5rem",
  },
  threeTimelines: {
    display: "flex",
    flexDirection: "column",
    gap: "3.5rem",
  },
}));

function PlotArea({
  calendar,
  referenceCalendar,
  onAddActivity,
  onItemClick,
  timelineRange,
  timelineData,
  ownProducts,
}: IProps) {
  const classes = useStyles();

  const [data, setData] = useState<any>({ data: [], referenceData: [] });
  const [startTime, setStartTime] = useState(new Date().toISOString());

  useEffect(() => {
    if (timelineData != undefined && timelineData != null) {
      if (ownProducts && Object.keys(timelineData.products).length > 0) {
        let myData: any = [];
        let refData: any = [];
        for (const [key, value] of Object.entries(timelineData.products)) {
          myData.push(value.events);
          refData.push(value.refEvents);
        }
        setData({ data: myData, referenceData: refData });
        setStartTime(addDays(timelineRange.start, 0).toISOString());
      }
      if (
        !ownProducts &&
        Object.keys(timelineData.competitorProducts).length > 0
      ) {
        let myData: any = [];
        let refData: any = [];
        for (const [key, value] of Object.entries(
          timelineData.competitorProducts
        )) {
          myData.push(value.events);
          refData.push(value.refEvents);
        }
        setData({ data: myData, referenceData: refData });
        setStartTime(addDays(timelineRange.start, 0).toISOString());
      }
    }
  }, [calendar, referenceCalendar, timelineData]);

  const getRelativeCoordinates = (e) => {
    // TODO: Fix, it is not working properly
    var posx = 0;
    var posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  };

  const handleOnClickAddAcvity = (
    productIndex: number,
    timeline: boolean = false,
    e
  ) => {
    e.preventDefault();
    onAddActivity(Object.keys(timelineData.products)[productIndex], 0);
  };
  return (
    <div className="h-full bg-white">
      <div className="flex flex-col gap-y-7 pb-4">
        <div className={classes.twoTimelines}>
          {data?.data.map((timelineRow, i) => (
            <>
              <div>
                <div
                  style={{
                    background: "rgba(204, 210, 208, 0.3)",
                  }}
                  className={`relative flex h-10 px-[10px]`}
                >
                  <hr className="h-[1px] w-full border-dashed border-0 border-b border-b-stone-300 absolute top-0 bottom-0 my-auto" />
                  <div className="h-10 flex pt-1 px-7 relative">
                    {data.referenceData[i].map((activity, index) => (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => onItemClick(activity)}
                      >
                        <Activities
                          key={index}
                          variant={"baseline"}
                          factual={activity}
                          startTime={startTime}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    background: "rgba(197, 170, 83, 0.3)",
                  }}
                  className={`relative flex h-10 px-[10px]`}
                >
                  <img
                    src={addIcon}
                    onClick={(e) => {
                      handleOnClickAddAcvity(i, false, e);
                    }}
                    className="hover:cursor-pointer absolute left-[7px] justify-self-center self-center z-[98]"
                  />
                  <hr className="h-[1px] w-full border-dashed border-0 border-b border-b-stone-300 absolute top-0 bottom-0 my-auto" />
                  <div className="h-10 flex pt-1 px-7 relative">
                    {timelineRow.map((activity, index) => (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => onItemClick(activity)}
                      >
                        <Activities
                          key={index}
                          variant={"maxSalesHighDisruption"}
                          factual={activity}
                          startTime={startTime}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlotArea;
