import React, { useState, useEffect, ReactElement } from "react";
import map from "lodash/map";
import { KeyTerms } from "Promo/utils/constants";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Paper,
  Grid,
  Tab,
  FormControl,
  Select,
  MenuItem,
  Switch,
  TextField,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Tooltip as MuiTooltip,
} from "@mui/material";

import { kpiSimulationTableStyles } from "./eventSimulationPageStyles";

import {
  Simulation_data_details,
  VisibilityDefinition,
} from "../../utils/types";

import {
  DisplayItems,
  location,
  tacticTypeAll,
} from "../../components/promoSimulationForm/SelectItemMenues";
import { formatNumber } from "Promo/utils/mapper";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import theme from "utils/theme";

type KPISimulationTableProps = {
  simulationTable: Simulation_data_details[];
  startDate: string;
  endDate: string;
  duration: string;
  visibility: string;
  depth: number;
  visibilityDefinition: VisibilityDefinition;
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const KPISimulationTable = React.memo(
  ({
    simulationTable,
    startDate,
    endDate,
    duration,
    visibility,
    depth,
    visibilityDefinition,
  }: KPISimulationTableProps) => {
    const classes = kpiSimulationTableStyles();
    const renderBarChart = (
      depthsData: any,
      kpi: string,
      rec: Boolean = false,
      tooltipPosition
    ) => {
      let minValue = Number.POSITIVE_INFINITY;
      let maxValue = Number.NEGATIVE_INFINITY;
      let keys: string[] = [];
      if (!rec) {
        keys = ["shelf", "low", "medium", "high"];
      } else {
        keys = Object.entries(depthsData).map((row) => {
          maxValue =
            depthsData[row[0]] > maxValue ? depthsData[row[0]] : maxValue;

          minValue =
            depthsData[row[0]] < minValue ? depthsData[row[0]] : minValue;
          return row[0];
        });
        keys.sort((a, b) => {
          if (Number(a) > Number(b)) return 1;
          else return -1;
        });
      }

      const data = keys.map((row, index) => {
        return {
          name: `${row}`,
          [kpi]: depthsData[row],
          recommended: maxValue === depthsData[row],
          isNegative: minValue < 0,
          startDate: new Date(startDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "2-digit",
            timeZone: "UTC",
          }),
          endDate: new Date(endDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "2-digit",
            timeZone: "UTC",
          }),
          duration: duration,
        };
      });

      const CustomTooltip: React.FC<CustomTooltipProps> = ({
        active,
        payload,
      }) => {
        if (active && payload && payload.length) {
          return (
            <div className={classes.customToolTip}>
              <p className="label">{`${
                KeyTerms[payload[0].dataKey]
              } : ${formatNumber(payload[0].value)}`}</p>
              <p className="start-date">{`Start Date : ${payload[0].payload.startDate}`}</p>
              <p className="end-date">{`End Date : ${payload[0].payload.endDate}`}</p>
              <p className="promo-depth">{`Promo Depth : ${payload[0].payload.name}`}</p>
              <p className="promo-duration">{`Promo Duration (Days) : ${payload[0].payload.duration}`}</p>
            </div>
          );
        }
        return null;
      };

      return (
        <ResponsiveContainer width="99%" height={250}>
          <BarChart
            width={600}
            height={300}
            data={data}
            layout="vertical"
            margin={{ top: 2, right: 60, left: 1, bottom: 7 }}
          >
            <XAxis type="number" padding={{ left: 80 }} hide />
            <YAxis
              type="category"
              dataKey="name"
              style={{ fontSize: !rec ? "13px" : "unset" }}
              allowDecimals
            />
            <Tooltip
              content={<CustomTooltip />}
              position={{
                y: ["shelf", "low", "medium", "high"].includes(
                  barGraphData.name
                )
                  ? 250
                  : -225,
              }}
            />
            <Bar
              dataKey={kpi}
              barSize={13}
              onMouseOver={(data) => {
                setBarGraphData(data);
              }}
            >
              {data.map((entry, index) => {
                return (
                  <>
                    <Cell
                      cursor="pointer"
                      fill={entry.recommended ? "#3EA589" : "#CCD2D0"}
                      key={`cell-${index}`}
                    />

                    {index === 0 && (
                      <LabelList
                        dataKey={kpi}
                        position={entry.isNegative ? "left" : "right"}
                        style={{ fill: "#525252" }}
                        formatter={(kpiValue) => {
                          return "$" + formatNumber(kpiValue);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    };

    const [tacticType, setTacticType] = React.useState<string[]>([]);
    const [displayType, setDisplayType] = React.useState<string[]>([]);
    const [locationType, setLocationType] = React.useState<string[]>([]);
    const [simulationData, setSimulationData] = React.useState<any[]>([]);
    const [barGraphData, setBarGraphData] = useState<any>({});

    const handleTacticTypeChange = (
      event: SelectChangeEvent<typeof tacticType>
    ) => {
      const {
        target: { value },
      } = event;
      setTacticType(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };
    const handleDisplayChange = (
      event: SelectChangeEvent<typeof tacticType>
    ) => {
      const {
        target: { value },
      } = event;
      setDisplayType(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };
    const handleLocationChange = (
      event: SelectChangeEvent<typeof tacticType>
    ) => {
      const {
        target: { value },
      } = event;
      setLocationType(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    useEffect(() => {
      const resultList: any[] = [];
      const visibilityChartData: any = {
        tactic: "Visibility variations",
        display: "medium",
        xaxis_unit: "",
        location: "dummy_location",
        incremental_net_net_sales: {
          shelf: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
        incremental_gross_profit: {
          shelf: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
        incremental_retail_sales_value: {
          shelf: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
        incremental_customer_trade_profit: {
          shelf: 0,
          high: 0,
          medium: 0,
          low: 0,
        },
      };
      simulationTable.forEach((row) => {
        if (row.display === visibility) {
          resultList.push({ ...row, tactic: row.tactic + " variations" });
        }
        const depthStr = Object.keys(row.incremental_net_net_sales).filter(
          (key) => Number(key) === depth
        )[0];
        visibilityChartData.incremental_net_net_sales[row.display] =
          row.incremental_net_net_sales[depthStr];
        visibilityChartData.incremental_gross_profit[row.display] =
          row.incremental_gross_profit[depthStr];
        visibilityChartData.incremental_retail_sales_value[row.display] =
          row.incremental_retail_sales_value[depthStr];
        visibilityChartData.incremental_customer_trade_profit[row.display] =
          row.incremental_customer_trade_profit[depthStr];
      });
      resultList.push(visibilityChartData);
      setSimulationData(resultList);
    }, []);

    const VisibilityDefinitionTooltip = () => {
      const elements: ReactElement[] = [];
      for (const [key, value] of Object.entries(visibilityDefinition)) {
        elements.push(
          <Grid display={"flex"} key={key}>
            <Typography
              fontSize={14}
              color={theme.palette.primary.contrastText}
              lineHeight={1.8}
              fontWeight={500}
            >
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </Typography>
            <Typography
              fontSize={14}
              color={theme.palette.primary.contrastText}
              lineHeight={1.8}
            >
              : {value}
            </Typography>
          </Grid>
        );
      }
      return <>{elements}</>;
    };

    return (
      <div className={classes.root}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "26px",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h3">Alternative Promo Types</Typography>
            <Typography variant="subtitle1">
              Sensitivity analysis of simulation promo types (other simulation
              inputs remain constant)
            </Typography>
          </Box>
          <Box>
            <Box component={"div"} display={"flex"} alignItems={"center"}>
              <InfoIcon fontSize="small" sx={{ color: "text.secondary" }} />
              <Typography
                fontSize={14}
                fontWeight={500}
                fontStyle={"italic"}
                color={"text.secondary"}
              >
                Numbers include pull forward and cannibalization results
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          {/*<Box className={classes.formContainer}>*/}
          {/*<Box>*/}
          {/*<Typography fontWeight={"bold"} align="left" fontSize={13}>*/}
          {/*  Tactic*/}
          {/*</Typography>*/}

          {/*<FormControl>*/}
          {/*  <Select*/}
          {/*    className={classes.specialSelectItem}*/}
          {/*    sx={{ width: "250px" }}*/}
          {/*    multiple*/}
          {/*    renderValue={(selected) => (*/}
          {/*      <Box*/}
          {/*        sx={{ display: "flex", justifyContent: "space-between" }}*/}
          {/*      >*/}
          {/*        <Box sx={{ width: "170px", overflow: "hidden" }}>*/}
          {/*          {selected[0]}*/}
          {/*        </Box>*/}
          {/*        {selected.length >= 2 && (*/}
          {/*          <Box> +{selected.length - 1}</Box>*/}
          {/*        )}*/}
          {/*      </Box>*/}
          {/*    )}*/}
          {/*    value={tacticType}*/}
          {/*    onChange={handleTacticTypeChange}*/}
          {/*  >*/}
          {/*    {tacticTypeAll.map((item, index) => {*/}
          {/*      return (*/}
          {/*        <MenuItem key={index} value={item}>*/}
          {/*          <Checkbox checked={tacticType.indexOf(item) > -1} />*/}
          {/*          <Typography align="left" fontSize={13}>*/}
          {/*            {item}*/}
          {/*          </Typography>*/}
          {/*        </MenuItem>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*</Box>*/}
          {/* <Box>
              <Typography fontWeight={"bold"} align="left" fontSize={13}>
                Display
              </Typography>

              <FormControl>
                <Select
                  className={classes.specialSelectItem}
                  sx={{ width: "250px" }}
                  multiple
                  renderValue={(selected) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ width: "170px", overflow: "hidden" }}>
                        {selected[0]}
                      </Box>
                      {selected.length >= 2 && (
                        <Box> +{selected.length - 1}</Box>
                      )}
                    </Box>
                  )}
                  value={displayType}
                  onChange={handleDisplayChange}
                >
                  {DisplayItems.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        <Checkbox checked={displayType.indexOf(item) > -1} />
                        <Typography align="left" fontSize={13}>
                          {item}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box> */}
          {/*</Box>*/}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell}>
                Promo type
              </TableCell>
              {/* <TableCell className={classes.tableHeaderCell}>
                Display{" "}
              </TableCell> */}

              <TableCell className={classes.tableHeaderCell}>
                GEPP Incremental net net sales
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                GEPP Incremental gross profit
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Incremental retailer sales value
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>
                Incremental retailer gross profit
              </TableCell>
            </TableRow>
          </TableHead>

          {simulationData.length &&
            simulationData
              .filter((row: any) => {
                let allowRow: boolean = true;
                if (tacticType.length >= 1 && !tacticType.includes(row.tactic))
                  allowRow = false;
                if (
                  displayType.length >= 1 &&
                  !displayType.includes(row.display)
                )
                  allowRow = false;
                if (
                  locationType.length >= 1 &&
                  !locationType.includes(row.location)
                )
                  allowRow = false;

                return allowRow;
              })
              .map((row: any, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      className={classes.tableCellMechnaincDisplay}
                      style={{ verticalAlign: "top" }}
                    >
                      {row.tactic === "Visibility variations" ? (
                        <MuiTooltip
                          title={
                            visibilityDefinition && (
                              <VisibilityDefinitionTooltip />
                            )
                          }
                          componentsProps={{
                            tooltip: {
                              style: {
                                maxWidth: 500,
                              },
                            },
                          }}
                          placement="bottom"
                          arrow
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography align="left"> {row.tactic}</Typography>
                            <InfoIcon
                              fontSize="small"
                              sx={{
                                color: "text.secondary",
                                fontSize: 16,
                                justifySelf: "center",
                              }}
                            />
                          </Box>
                        </MuiTooltip>
                      ) : (
                        <Typography align="left"> {row.tactic}</Typography>
                      )}
                    </TableCell>
                    {/* <TableCell
                    className={classes.tableCellMechnaincDisplay}
                    style={{ verticalAlign: "top" }}
                  >
                    <Typography align="left"> {row.display}</Typography>
                  </TableCell> */}

                    <TableCell className={classes.tableCell}>
                      {renderBarChart(
                        row.incremental_net_net_sales,
                        "incremental_net_net_sales",
                        index === 0,
                        0
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {renderBarChart(
                        row.incremental_gross_profit,
                        "incremental_gross_profit",
                        index === 0,
                        0
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {renderBarChart(
                        row.incremental_retail_sales_value,
                        "incremental_retail_sales_value",
                        index === 0,
                        0
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {renderBarChart(
                        row.incremental_customer_trade_profit,
                        "incremental_customer_trade_profit",
                        index === 0,
                        -140
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
        </Table>
      </div>
    );
  }
);

export default KPISimulationTable;
