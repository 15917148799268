export enum CalendarCreationFormActionTypes {
  SEND_FINANCIALS_CONSTRAINTS = "SEND_FINANCIALS_CONSTRAINTS",
  CLEAR_FINANCIALS_CONSTRAINTS = "CLEAR_FINANCIALS_CONSTRAINTS",

  SEND_PROMO_MIX_CONSTRAINTS = "SEND_PROMO_MIX_CONSTRAINTS",
  CLEAR_PROMO_MIX_CONSTRAINTS = "CLEAR_PROMO_MIX_CONSTRAINTS",

  SEND_PROMOTION_CONSTRAINTS = "SEND_PROMOTION_CONSTRAINTS",
  CLEAR_PROMOTION_CONSTRAINTS = "CLEAR_PROMOTION_CONSTRAINTS"
}
