import { combineReducers } from "redux";
import GlobalReducer from "./Global/GlobalReducer";
// import EventSimulationReducer from "./Promo/eventSimulation";
import EventSimulationReducer from "../../Promo/utils/redux/simulation/eventSimulationReducer";
import commonReducer from "../../Promo/utils/redux/commonReducer";
import { analysisReducer } from "../../Promo/utils/redux/analysis/analysisReducer";
import { optimizationReducer } from "../../Promo/utils/redux/optimization/optimizationReducer";
import { calendarOptimizationReducer } from "../../Promo/utils/redux/calendar/calendarOptimizationReducer";
import SimulationReducer from "../../Promo/utils/redux/simulation/eventSimulationReducer";
import RecommendationReducer from "../../Promo/utils/redux/recommendation/recommendationReducer";
import EventParamsReducer from "../../Promo/utils/redux/recommendation/eventParams/eventParamsReducer";
import CalendarViewEditReducer from "../../Promo/utils/redux/calendarViewEdit/calendarViewEditReducer";
import CalendarComparsionViewEditReducer from "../../Promo/pages/calendar/components/calendarComparsionViewEdit/redux/calendarComparsionViewEditReducer";
import CalendarCreationReducer from "../../Promo/utils/redux/calendarCreation/calendarCreationReducer";
import CalendarCreationFormReducer from "../../Promo/utils/redux/calendarCreationForm/calendarCreationFormReducer";
import ConstraintsManagementReducer from "../../Promo/utils/redux/constraintsManagement/constraintsManagementReducer";

const rootReducer = combineReducers({
  GlobalReducer,
  EventSimulationReducer,
  commonReducer,
  analysisReducer,
  optimizationReducer,
  calendarOptimizationReducer,
  SimulationReducer,
  RecommendationReducer,
  EventParamsReducer,
  CalendarViewEditReducer,
  CalendarComparsionViewEditReducer,
  CalendarCreationReducer,
  CalendarCreationFormReducer,
  ConstraintsManagementReducer,
});

export default rootReducer;
