import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import Frame from "../components/Frame/frame";

import LandingPage from "./pages/LandingPage/landingPage";
import DiagnosticPage from "./pages/Diagnostics/diagnostics";
import SimulationPage from "./pages/Simulation/simulation";

import { useTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CalendarRouter from "./pages/calendar/CalendarRouter";
import PromoPlanPage from "./pages/PromoPlan/PromoPlanPage";
import { PROMO_DRAWER_ITEM } from "./utils/constants";
import { DropDownItems } from "../utils/constants";
import DiagnosticDashboardPage from "./pages/Diagnostics/DiagnosticDashboardPage";
import "../App.scss";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import {
  getClientData,
  getCurrentUser,
} from "../utils/redux/Global/GlobalActions";
import { ClientData } from "../utils/types";
import { supportedMarketValues } from "./utils/constants";
import AuthCallbackPage from "./pages/Auth/authCallbackPage";
import AxiosSetup from "utils/axios";

const MARKET_DEFAULT = "market_code-MX";

const PromoPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const open: boolean = useSelector(
    (state: RootState) => state.reducer.GlobalReducer.drawerOpen
  );
  const [market, setMarket] = useState(() => {
    const initialValue = JSON.parse(String(localStorage.getItem("market")));
    return initialValue || MARKET_DEFAULT;
  });
  const [setup, setSetup] = useState(true);
  const sortedMarketValues = supportedMarketValues.slice().sort();

  const user = useAppSelector((state) => state.reducer.GlobalReducer.user);
  const ClientData: ClientData | null = useAppSelector(
    (state) => state.reducer.GlobalReducer.clientData
  );
  useEffect(() => {
    if (user) {
      localStorage.setItem("market", JSON.stringify(market));
      dispatch(getClientData(market));
    }
  }, [market, user]);

  useEffect(() => {
    if (setup) {
      AxiosSetup(window);
      setSetup(false);
    }

    if (!user) {
      if (!String(window.location.pathname).includes("/user/auth/callback"))
        dispatch(getCurrentUser());
    }

    if (!ClientData && user) {
      if (!market) {
        setMarket(MARKET_DEFAULT);
        dispatch(getClientData(MARKET_DEFAULT));
      } else dispatch(getClientData(market));
    }
  }, []);

  if (!user)
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Frame
          title={"Promo AI"}
          drawer={true}
          navItems={DropDownItems}
          drawerItems={PROMO_DRAWER_ITEM}
          marketSelectOptions={sortedMarketValues}
          market={market}
          handleMarketChange={setMarket}
        />
        <Routes>
          <Route path="/user/auth/callback" element={<AuthCallbackPage />} />
        </Routes>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Frame
        title={"Promo AI"}
        drawer={true}
        navItems={DropDownItems}
        drawerItems={PROMO_DRAWER_ITEM}
        marketSelectOptions={sortedMarketValues}
        market={market}
        handleMarketChange={setMarket}
      />
      <div
        className={
          (open ? "innerFrameContinerOpen" : "innerFrameContainerClosed") +
          " innerFrameContainer"
        }
        style={{
          background: `${theme.palette.primary.background_2}`,
          position: "relative",
        }}
      >
        {ClientData && ClientData?.retailers.length > 0 ? (
          <Routes>
            <Route path="" element={<LandingPage />} />
            {/* <Route path="/diagnostics" element={<DiagnosticPage />} /> */}
            <Route path="/diagnostics" element={<DiagnosticDashboardPage />} />
            <Route
              path="/diagnostics/dashboard"
              element={<DiagnosticDashboardPage />}
            />

            <Route
              path="/simulation"
              element={<SimulationPage key={market} selectedMarket={market} />}
            />
            <Route
              path="/recommendation/*"
              element={<CalendarRouter selectedMarket={market} />}
            />
            <Route path="/user/auth/callback" element={<AuthCallbackPage />} />
            {/* <Route path="/recommendation/:id" element={<PromoPlanPage />} /> */}
          </Routes>
        ) : (
          <div></div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default PromoPage;
