import { PromoEvent } from "../pages/Simulation/types";
import { PROMO_MECHANICS_TYPE_TO_UNIT } from "../../utils/constants";
import moment from "moment";
import map from "lodash/map";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import find from "lodash/find";
import {
  PromoSimulationFormValues,
  ProductKPI,
  TimelineGroup,
  ProductData,
  TimelineItem,
  RetailerData,
  Event,
  Calendar,
  RowType,
  CalendarCardType,
  ChartDataArrayType,
} from "./types";
import { CALENDAR_TYPE, GROUP_TYPE, HEATMAP_KPI, CHART_TYPE } from "./enum";
import reduce from "lodash/reduce";
import { tacticTypes, multiBuyPicklist2, multiBuyPicklist3 } from "./constants";
import { ConstraintSetRow } from "Promo/pages/calendar/constraintsManagement/constraintsManagement";
import * as XLSX from "xlsx";

// match periods that are not part of a decimal number
const periodRegex = /(?<!\d)\.(?!\d)/;

// Format Number is used to format the numbers
export const formatNumber = (
  number?: number,
  decimalPlaces?: number,
  symbol: string = "",
  billionCheck: boolean = true
): string => {
  if (number === undefined) return "N/A";
  if (number === 0) {
    return "0";
  }
  const isNegative = number < 0;
  if (isNegative) {
    number = number * -1;
  }

  if (number < 10 && number > 0) {
    return (
      (isNegative ? "-" : "") +
      number.toFixed(decimalPlaces ? decimalPlaces : 2)
    );
  }

  const format = (number: number, decimalPlaces: number): string => {
    if (billionCheck && number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    } else if (number >= 1 && number < 1000) {
      if (symbol === "%") return number.toFixed(1);
      return number.toFixed(0);
    } else {
      return number.toFixed(decimalPlaces);
    }
  };
  return isNegative
    ? "-" + format(number, decimalPlaces || 0)
    : format(number, decimalPlaces || 0);
};

/**
 * It takes a number and returns a string with the number formatted to one decimal place, with a unit
 * suffix of k, M, B, or nothing
 * @param number - The number to be formatted.
 * @returns A string with the number formatted to one decimal place.
 */
export const formatNumbertoOne = (number) => {
  const units = ["", "k", "M", "B"];
  const decimalPlaces = 2;

  const absNumber = Math.abs(number);

  if (absNumber >= 1000000000) {
    return `${(number / 1000000000).toFixed(decimalPlaces)}${units[3]}`;
  } else if (absNumber >= 1000000) {
    return `${(number / 1000000).toFixed(decimalPlaces)}${units[2]}`;
  } else if (absNumber < 1000000 && absNumber >= 1000) {
    return `${(number / 1000).toFixed(decimalPlaces)}${units[1]}`;
  } else if (absNumber >= 0.01) {
    return `${number.toFixed(decimalPlaces)}${units[0]}`;
  } else if (absNumber < 0.01) {
    return 0;
  } else {
    return "";
  }
};

// Mapper to get KPI's from a promo event
export const promoKpiMapper = (promoEvent: PromoEvent) => {
  return {
    volumeUplift: (
      promoEvent.product_kpi.volume / promoEvent.product_kpi.baseline_volume
    ).toFixed(1),
    roi: (
      (promoEvent.product_kpi.profit_waterfall_cpg.total -
        promoEvent.product_kpi.profit_waterfall_cpg.baseline) /
      promoEvent.promo_spend
    ).toFixed(2),
    internalView: {
      current: {
        volume: formatNumber(promoEvent.product_kpi.volume),
        profitTotal: formatNumber(
          promoEvent.product_kpi.profit_waterfall_cpg.total
        ),
        promoSpend: formatNumber(promoEvent.promo_spend),
      },
    },
    customerView: {
      current: {
        retailSales: formatNumber(
          promoEvent.product_kpi.sales_waterfall_retailer.total
        ),
        customerMarginRate: `${formatNumber(
          (promoEvent.product_kpi.profit_waterfall_retailer.total /
            promoEvent.product_kpi.sales_waterfall_retailer.total) *
            100
        )}%`,
        customerCashMargin: formatNumber(
          promoEvent.product_kpi.profit_waterfall_retailer.total
        ),
      },
    },
  };
};

export const promoSimulation = (
  promoSimulationFormValues?: PromoSimulationFormValues,
  productName?: string,
  retailerName?: string,
  promoID?: number
): PromoEvent => {
  const unit = promoSimulationFormValues?.unit || "percentage";
  let depth = promoSimulationFormValues?.depth || 100;
  if (unit === "percentage") {
    depth = depth / 100;
  }

  let promoEvent = {
    id: promoID || 0,
    product_name: productName || "",
    start_date: promoSimulationFormValues?.startDate || "",
    end_date: promoSimulationFormValues?.endDate || "",
    retailer_name: retailerName || "",
    promo_spend: -30,
    price_mechanics: {
      value: depth,
      type: unit,
      unit: PROMO_MECHANICS_TYPE_TO_UNIT[unit],
      multiple: promoSimulationFormValues?.multiple || 1,
    },
    execution_mechanics: {
      execution_flags: "none",
    },
    product_kpi: {
      product: productName || "",
      retailer: retailerName || "",
      volume: 400,
      baseline_volume: 150,
      profit_waterfall_cpg: {
        baseline: 60,
        uplift: 10,
        discount: -10,
        execution_cost: 0,
        cannibalization: -3,
        pull_forward: -1,
        total: 54,
      },
      profit_waterfall_retailer: {
        baseline: 80,
        uplift: 50,
        discount: 0,
        execution_cost: 0,
        cannibalization: -6,
        pull_forward: -3,
        total: 121,
      },
      sales_waterfall_cpg: {
        baseline: 100,
        uplift: 60,
        discount: -20,
        execution_cost: 0,
        cannibalization: -5,
        pull_forward: -2,
        total: 133,
      },
      sales_waterfall_retailer: {
        baseline: 120,
        uplift: 100,
        discount: -30,
        execution_cost: 0,
        cannibalization: -10,
        pull_forward: -6,
        total: 174,
      },
    },
  };
  if (
    promoSimulationFormValues?.startDate &&
    promoSimulationFormValues?.endDate &&
    promoSimulationFormValues?.depth
  ) {
    let duration = Math.ceil(
      moment(promoSimulationFormValues.endDate).diff(
        moment(promoSimulationFormValues.startDate),
        "days"
      ) / 7
    );

    if (
      promoSimulationFormValues.depth > 0 &&
      promoSimulationFormValues.depth <= 20
    ) {
      promoEvent.promo_spend =
        (promoEvent.product_kpi.profit_waterfall_cpg.total -
          promoEvent.product_kpi.profit_waterfall_cpg.baseline) /
        (0.45 - (duration - 1) * 0.1);
      promoEvent.product_kpi.profit_waterfall_cpg.uplift = 40 * duration;
      promoEvent.product_kpi.profit_waterfall_retailer.uplift = 40 * duration;
      promoEvent.product_kpi.sales_waterfall_cpg.uplift = 40 * duration;
      promoEvent.product_kpi.sales_waterfall_retailer.uplift = 40 * duration;
    } else if (
      promoSimulationFormValues.depth > 20 &&
      promoSimulationFormValues.depth <= 35
    ) {
      promoEvent.promo_spend =
        (promoEvent.product_kpi.profit_waterfall_cpg.total -
          promoEvent.product_kpi.profit_waterfall_cpg.baseline) /
        (0.6 - (duration - 1) * 0.1);
      promoEvent.product_kpi.profit_waterfall_cpg.uplift = 35 * duration;
      promoEvent.product_kpi.profit_waterfall_retailer.uplift = 35 * duration;
      promoEvent.product_kpi.sales_waterfall_cpg.uplift = 35 * duration;
      promoEvent.product_kpi.sales_waterfall_retailer.uplift = 35 * duration;
    } else {
      promoEvent.promo_spend =
        (promoEvent.product_kpi.profit_waterfall_cpg.total -
          promoEvent.product_kpi.profit_waterfall_cpg.baseline) /
        (0.2 - (duration - 1) * 0.1);
      promoEvent.product_kpi.profit_waterfall_cpg.uplift = 30 * duration;
      promoEvent.product_kpi.profit_waterfall_retailer.uplift = 30 * duration;
      promoEvent.product_kpi.sales_waterfall_cpg.uplift = 30 * duration;
      promoEvent.product_kpi.sales_waterfall_retailer.uplift = 30 * duration;
    }
  }
  return promoEvent;
};

// Get dashboard KPI's for calendar page
export const getDashboardKpi = (
  currentProductKPIS: ProductKPI[],
  currentPromoEvents: PromoEvent[],
  referenceProductKPIS: ProductKPI[],
  referencePromoEvents: PromoEvent[]
) => {
  return {
    volumeUplift: (
      reduce(
        currentProductKPIS,
        (total, product) => total + product.volume,
        0
      ) /
      reduce(
        currentProductKPIS,
        (total, product) => total + product.baseline_volume,
        0
      )
    ).toFixed(1),
    roi: (
      (reduce(
        currentProductKPIS,
        (total, product) => total + product.profit_waterfall_cpg.total,
        0
      ) -
        reduce(
          currentProductKPIS,
          (total, product) => total + product.profit_waterfall_cpg.baseline,
          0
        )) /
      reduce(currentPromoEvents, (total, promo) => total + promo.promo_spend, 0)
    ).toFixed(2),
    internalView: {
      reference: {
        volume: formatNumber(
          reduce(
            referenceProductKPIS,
            (total, product) => total + product.volume,
            0
          )
        ),
        profitTotal: formatNumber(
          reduce(
            referenceProductKPIS,
            (total, product) => total + product.profit_waterfall_cpg.total,
            0
          )
        ),
        promoSpend: formatNumber(
          reduce(
            referencePromoEvents,
            (total, promo) => total + promo.promo_spend,
            0
          )
        ),
      },
      current: {
        volume: formatNumber(
          reduce(
            currentProductKPIS,
            (total, product) => total + product.volume,
            0
          )
        ),
        profitTotal: formatNumber(
          reduce(
            currentProductKPIS,
            (total, product) => total + product.profit_waterfall_cpg.total,
            0
          )
        ),
        promoSpend: formatNumber(
          reduce(
            currentPromoEvents,
            (total, promo) => total + promo.promo_spend,
            0
          )
        ),
      },
    },
    customerView: {
      reference: {
        retailSales: formatNumber(
          reduce(
            referenceProductKPIS,
            (total, product) => total + product.sales_waterfall_retailer.total,
            0
          )
        ),
        customerMarginRate: `${formatNumber(
          (reduce(
            referenceProductKPIS,
            (total, product) => total + product.profit_waterfall_retailer.total,
            0
          ) /
            reduce(
              referenceProductKPIS,
              (total, product) =>
                total + product.sales_waterfall_retailer.total,
              0
            )) *
            100
        )}%`,
        customerCashMargin: formatNumber(
          reduce(
            referenceProductKPIS,
            (total, product) => total + product.profit_waterfall_retailer.total,
            0
          )
        ),
      },
      current: {
        retailSales: formatNumber(
          reduce(
            currentProductKPIS,
            (total, product) => total + product.sales_waterfall_retailer.total,
            0
          )
        ),
        customerMarginRate: `${formatNumber(
          (reduce(
            currentProductKPIS,
            (total, product) => total + product.profit_waterfall_retailer.total,
            0
          ) /
            reduce(
              currentProductKPIS,
              (total, product) =>
                total + product.sales_waterfall_retailer.total,
              0
            )) *
            100
        )}%`,
        customerCashMargin: formatNumber(
          reduce(
            currentProductKPIS,
            (total, product) => total + product.profit_waterfall_retailer.total,
            0
          )
        ),
      },
    },
  };
};

// Mapper to map product to a timeline group (please refer TimelineGroup type to understand the type)
const productToGroupMapper = (
  productKPI: ProductKPI,
  calendarType: CALENDAR_TYPE,
  product?: ProductData
): TimelineGroup => {
  return {
    id: `${calendarType}_PRODUCT_${productKPI.retailer}_${productKPI.product}`,
    title: calendarType === CALENDAR_TYPE.CURRENT ? product?.name || "" : "",
    type: GROUP_TYPE.PRODUCT,
    calendarType,
    netRevenue: productKPI.sales_waterfall_retailer.total,
    volume: productKPI.volume,
    margin: productKPI.profit_waterfall_cpg.total,
    avp: productKPI.sales_waterfall_retailer.total / productKPI.volume,
    product: productKPI,
  };
};

// Mapper to map a promo to a timeline item (please refer TimelineItem to understand type)
const promoToItemMapper = (
  promo: PromoEvent,
  calendarType: CALENDAR_TYPE,
  currentYear: number
): TimelineItem => {
  return {
    id: `${calendarType}_PROMO_${promo.retailer_name}_${promo.product_name}_${promo.id}`,
    group: `${calendarType}_PRODUCT_${promo.retailer_name}_${promo.product_name}`,
    title: `${promo.id}`, //getMechanics(promo),
    start_time:
      calendarType === CALENDAR_TYPE.CURRENT
        ? moment(promo.start_date).toDate()
        : moment(promo.start_date).set("year", currentYear).toDate(),
    end_time:
      calendarType === CALENDAR_TYPE.CURRENT
        ? moment(promo.end_date).toDate()
        : moment(promo.end_date).set("year", currentYear).toDate(),
    itemProps: {
      style: {
        background: "",
        //      calendarType === CALENDAR_TYPE.CURRENT
        //       ? theme.palette.primary.main
        //        : theme.palette.secondary.main,
        border: "none",
        borderRadius: "40px",
      },
    },
    promo,
  };
};

// Mapper to map current and reference calendar to timeline groups and items)
export const timelineMapper = (
  currentProductKPIS: ProductKPI[],
  currentPromoEvents: PromoEvent[],
  currentYear: number,
  products: ProductData[],
  retailers: RetailerData[],
  referenceProductKPIS?: ProductKPI[],
  referencePromoEvents?: PromoEvent[]
) => {
  let groups: TimelineGroup[] = [];
  let items: TimelineItem[] = [];

  map(retailers, (retailer) => {
    const retailerGroups: TimelineGroup[] = [];
    map(retailer.products, (productName) => {
      const product = products.find((data) => data.name === productName);
      const currentProductKPI = currentProductKPIS.find(
        (data) =>
          data.product === productName &&
          data.retailer === retailer.retailer_name
      );
      const referenceProductKPI = referenceProductKPIS?.find(
        (data) =>
          data.product === productName &&
          data.retailer === retailer.retailer_name
      );
      if (currentProductKPI) {
        retailerGroups.push(
          productToGroupMapper(
            currentProductKPI,
            CALENDAR_TYPE.CURRENT,
            product
          )
        );
        items = items.concat(
          map(
            filter(
              currentPromoEvents,
              (promo) =>
                promo.retailer_name === retailer.retailer_name &&
                promo.product_name === productName
            ),
            (promo) =>
              promoToItemMapper(promo, CALENDAR_TYPE.CURRENT, currentYear)
          )
        );
      }

      if (referenceProductKPIS && referencePromoEvents && referenceProductKPI) {
        retailerGroups.push(
          productToGroupMapper(
            referenceProductKPI,
            CALENDAR_TYPE.REFERENCE,
            product
          )
        );
        items = items.concat(
          map(
            filter(
              referencePromoEvents,
              (promo) =>
                promo.retailer_name === retailer.retailer_name &&
                promo.product_name === productName
            ),
            (promo) =>
              promoToItemMapper(promo, CALENDAR_TYPE.REFERENCE, currentYear)
          )
        );
      }
    });

    groups.push(
      retailerToGroupMapper(
        retailer.retailer_id,
        retailerGroups,
        CALENDAR_TYPE.CURRENT,
        retailer
      )
    );
    if (referenceProductKPIS && referencePromoEvents) {
      groups.push(
        retailerToGroupMapper(
          retailer.retailer_id,
          retailerGroups,
          CALENDAR_TYPE.REFERENCE,
          retailer
        )
      );
    }
    groups = groups.concat(retailerGroups);
  });

  return {
    groups,
    items,
  };
};

// Mapper to map retailer to a timeline group (please refer TimelineGroup type to understand the type)
const retailerToGroupMapper = (
  retailerID: string,
  groups: TimelineGroup[],
  calendarType: CALENDAR_TYPE,
  retailer: RetailerData
): TimelineGroup => {
  const salesWaterfallRetailerTotal = reduce(
    groups,
    (total, retailerGroup) => {
      if (retailerGroup.product) {
        return total + retailerGroup.product.sales_waterfall_retailer.total;
      }
      return total + 0;
    },
    0
  );
  const volumeTotal = reduce(
    groups,
    (total, retailerGroup) => {
      if (retailerGroup.product) {
        return total + retailerGroup.product.volume;
      }
      return total + 0;
    },
    0
  );

  return {
    id: `${calendarType}_RETAILER_${retailerID}`,
    title: calendarType === CALENDAR_TYPE.CURRENT ? retailer.retailer_name : "",
    type: GROUP_TYPE.RETAILER,
    calendarType,
    netRevenue: reduce(
      filter(groups, (group) => group.calendarType === calendarType),
      (total, group) => total + group.netRevenue,
      0
    ),
    volume: reduce(
      filter(groups, (group) => group.calendarType === calendarType),
      (total, group) => total + group.volume,
      0
    ),
    margin: reduce(
      filter(groups, (group) => group.calendarType === calendarType),
      (total, group) => total + group.margin,
      0
    ),
    avp: salesWaterfallRetailerTotal / volumeTotal,
  };
};

export const kpiPromoSpend = (promoEvents: PromoEvent[]) => {
  return reduce(
    promoEvents,
    (total, promoEvent) => total + promoEvent.promo_spend,
    0
  );
};

export const kpiROI = (
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[]
) => {
  const promoSpend = kpiPromoSpend(promoEvents);
  return promoSpend
    ? (reduce(
        productKPIS,
        (total, productKPI) => total + productKPI.profit_waterfall_cpg.total,
        0
      ) -
        reduce(
          productKPIS,
          (total, productKPI) =>
            total + productKPI.profit_waterfall_cpg.baseline,
          0
        )) /
        promoSpend
    : 0;
};

export const kpiTotalIncrementalSales = (productKPIS: ProductKPI[]) => {
  return (
    reduce(
      productKPIS,
      (total, productKPI) => total + productKPI.sales_waterfall_cpg.total,
      0
    ) -
    reduce(
      productKPIS,
      (total, productKPI) => total + productKPI.sales_waterfall_cpg.baseline,
      0
    )
  );
};

export const kpiTotalIncrementalMargin = (productKPIS: ProductKPI[]) => {
  return (
    reduce(
      productKPIS,
      (total, productKPI) => total + productKPI.profit_waterfall_cpg.total,
      0
    ) -
    reduce(
      productKPIS,
      (total, productKPI) => total + productKPI.profit_waterfall_cpg.baseline,
      0
    )
  );
};

export const getProductKPISAndPromoEventsPerBrandAndRetailer = (
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[],
  retailersData: RetailerData[],
  productsData: ProductData[]
) => {
  const brands = uniq(map(productsData, (product) => product.product_brand));
  return map(retailersData, (retailer) => {
    return {
      retailer: retailer.retailer_name,
      brands: map(brands, (brand) => {
        const products = map(
          filter(productsData, (product) => product.product_brand === brand),
          (product) => product.name
        );
        const brandKPIS = filter(
          productKPIS,
          (currentProductKPI) =>
            currentProductKPI.retailer === retailer.retailer_name &&
            products.includes(currentProductKPI.product)
        );
        const brandPromoEvents = filter(
          promoEvents,
          (currentPromoEvent) =>
            currentPromoEvent.retailer_name === retailer.retailer_name &&
            products.includes(currentPromoEvent.product_name)
        );
        const promoSpend = kpiPromoSpend(brandPromoEvents);
        const roi = kpiROI(brandKPIS, brandPromoEvents);
        const totalIncrementalSales = kpiTotalIncrementalSales(brandKPIS);
        const totalIncrementalMargin = kpiTotalIncrementalMargin(brandKPIS);
        return {
          brand,
          brandKPIS,
          brandPromoEvents,
          promoSpend,
          roi,
          totalIncrementalSales,
          totalIncrementalMargin,
        };
      }),
    };
  });
};

export const getHeatmapData = (
  kpi: HEATMAP_KPI,
  retailersData: RetailerData[],
  productsData: ProductData[],
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[]
) => {
  const brands = uniq(map(productsData, (product) => product.product_brand));
  const brandAndRetailersData = getProductKPISAndPromoEventsPerBrandAndRetailer(
    productKPIS,
    promoEvents,
    retailersData,
    productsData
  );

  const maxPromoSpend = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.promoSpend),
        (total, promoSpend) =>
          Math.abs(promoSpend) > total ? Math.abs(promoSpend) : total,
        0
      )
    ),
    (total, promoSpend) =>
      Math.abs(promoSpend) > total ? Math.abs(promoSpend) : total,
    0
  );
  const minROI = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.roi),
        (total, roi) => (roi < total ? roi : total),
        Number.MAX_SAFE_INTEGER
      )
    ),
    (total, roi) => (roi < total ? roi : total),
    Number.MAX_SAFE_INTEGER
  );
  const maxROI = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.roi),
        (total, roi) => (roi > total ? roi : total),
        minROI
      )
    ),
    (total, roi) => (roi > total ? roi : total),
    minROI
  );
  const minTotalIncrementalSales = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.totalIncrementalSales),
        (total, totalIncrementalSales) =>
          totalIncrementalSales < total ? totalIncrementalSales : total,
        Number.MAX_SAFE_INTEGER
      )
    ),
    (total, totalIncrementalSales) =>
      totalIncrementalSales < total ? totalIncrementalSales : total,
    Number.MAX_SAFE_INTEGER
  );
  const maxTotalIncrementalSales = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.totalIncrementalSales),
        (total, totalIncrementalSales) =>
          totalIncrementalSales > total ? totalIncrementalSales : total,
        minTotalIncrementalSales
      )
    ),
    (total, totalIncrementalSales) =>
      totalIncrementalSales > total ? totalIncrementalSales : total,
    minTotalIncrementalSales
  );
  const minTotalIncrementalMargin = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.totalIncrementalMargin),
        (total, totalIncrementalMargin) =>
          totalIncrementalMargin < total ? totalIncrementalMargin : total,
        Number.MAX_SAFE_INTEGER
      )
    ),
    (total, totalIncrementalMargin) =>
      totalIncrementalMargin < total ? totalIncrementalMargin : total,
    Number.MAX_SAFE_INTEGER
  );
  const maxTotalIncrementalMargin = reduce(
    brandAndRetailersData.map((retailer) =>
      reduce(
        retailer.brands.map((brand) => brand.totalIncrementalMargin),
        (total, totalIncrementalMargin) =>
          totalIncrementalMargin > total ? totalIncrementalMargin : total,
        minTotalIncrementalMargin
      )
    ),
    (total, totalIncrementalMargin) =>
      totalIncrementalMargin > total ? totalIncrementalMargin : total,
    minTotalIncrementalMargin
  );
  let maxColor;
  let minColor;
  switch (kpi) {
    case HEATMAP_KPI.ROI:
      maxColor = formatNumber(maxROI, 2);
      minColor = formatNumber(minROI, 2);
      break;
    case HEATMAP_KPI.TOTAL_INCREMENTAL_MARGIN:
      maxColor = formatNumber(maxTotalIncrementalMargin);
      minColor = formatNumber(minTotalIncrementalMargin);
      break;
    case HEATMAP_KPI.TOTAL_INCREMENTAL_SALES:
    default:
      maxColor = formatNumber(maxTotalIncrementalSales);
      minColor = formatNumber(minTotalIncrementalSales);
      break;
  }

  return {
    chartData: brandAndRetailersData.map((retailer) => {
      const brandsData: Record<string, number | string> = {};
      map(retailer.brands, (brand) => {
        switch (kpi) {
          case HEATMAP_KPI.ROI:
            brandsData[`${brand.brand}`] = brand.roi;
            brandsData[`${brand.brand}-label`] = formatNumber(brand.roi, 2);
            brandsData[`${brand.brand}-spend`] = formatNumber(brand.promoSpend);
            brandsData[`${brand.brand}-size`] =
              Math.abs(brand.promoSpend) / maxPromoSpend;
            brandsData[`${brand.brand}-color`] = Math.round(
              ((brand.roi - minROI) * 100) / (maxROI - minROI)
            );
            break;
          case HEATMAP_KPI.TOTAL_INCREMENTAL_MARGIN:
            brandsData[`${brand.brand}`] = brand.totalIncrementalMargin;
            brandsData[`${brand.brand}-label`] = formatNumber(
              brand.totalIncrementalMargin
            );
            brandsData[`${brand.brand}-spend`] = formatNumber(brand.promoSpend);
            brandsData[`${brand.brand}-size`] =
              Math.abs(brand.promoSpend) / maxPromoSpend;
            brandsData[`${brand.brand}-color`] = Math.round(
              ((brand.totalIncrementalMargin - minTotalIncrementalMargin) *
                100) /
                (maxTotalIncrementalMargin - minTotalIncrementalMargin)
            );
            break;
          case HEATMAP_KPI.TOTAL_INCREMENTAL_SALES:
          default:
            brandsData[`${brand.brand}`] = brand.totalIncrementalSales;
            brandsData[`${brand.brand}-label`] = formatNumber(
              brand.totalIncrementalSales
            );
            brandsData[`${brand.brand}-spend`] = formatNumber(brand.promoSpend);
            brandsData[`${brand.brand}-size`] =
              Math.abs(brand.promoSpend) / maxPromoSpend;
            brandsData[`${brand.brand}-color`] = Math.round(
              ((brand.totalIncrementalSales - minTotalIncrementalSales) * 100) /
                (maxTotalIncrementalSales - minTotalIncrementalSales)
            );
        }
      });
      return {
        retailer: retailer.retailer,
        ...brandsData,
      };
    }),
    maxSize: maxPromoSpend,
    maxColor,
    minColor,
    numberOfColumns: brands.length,
    numberOfRows: brandAndRetailersData.length,
  };
};

// Add event
export const addEvent = (
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[],
  promoEvent: PromoEvent
) => {
  return {
    productKPIS: [
      ...map(productKPIS, (product) => {
        if (
          product.product === promoEvent.product_name &&
          product.retailer === promoEvent.retailer_name
        ) {
          return {
            ...product,
            profit_waterfall_cpg: {
              baseline:
                product.profit_waterfall_cpg.baseline +
                promoEvent.product_kpi.profit_waterfall_cpg.baseline,
              uplift:
                product.profit_waterfall_cpg.uplift +
                promoEvent.product_kpi.profit_waterfall_cpg.uplift,
              discount:
                product.profit_waterfall_cpg.discount +
                promoEvent.product_kpi.profit_waterfall_cpg.discount,
              execution_cost:
                product.profit_waterfall_cpg.execution_cost +
                promoEvent.product_kpi.profit_waterfall_cpg.execution_cost,
              cannibalization:
                product.profit_waterfall_cpg.cannibalization +
                promoEvent.product_kpi.profit_waterfall_cpg.cannibalization,
              pull_forward:
                product.profit_waterfall_cpg.pull_forward +
                promoEvent.product_kpi.profit_waterfall_cpg.pull_forward,
              total:
                product.profit_waterfall_cpg.total +
                promoEvent.product_kpi.profit_waterfall_cpg.total,
            },
            profit_waterfall_retailer: {
              baseline:
                product.profit_waterfall_retailer.baseline +
                promoEvent.product_kpi.profit_waterfall_retailer.baseline,
              uplift:
                product.profit_waterfall_retailer.uplift +
                promoEvent.product_kpi.profit_waterfall_retailer.uplift,
              discount:
                product.profit_waterfall_retailer.discount +
                promoEvent.product_kpi.profit_waterfall_retailer.discount,
              execution_cost:
                product.profit_waterfall_retailer.execution_cost +
                promoEvent.product_kpi.profit_waterfall_retailer.execution_cost,
              cannibalization:
                product.profit_waterfall_retailer.cannibalization +
                promoEvent.product_kpi.profit_waterfall_retailer
                  .cannibalization,
              pull_forward:
                product.profit_waterfall_retailer.pull_forward +
                promoEvent.product_kpi.profit_waterfall_retailer.pull_forward,
              total:
                product.profit_waterfall_retailer.total +
                promoEvent.product_kpi.profit_waterfall_retailer.total,
            },
            sales_waterfall_cpg: {
              baseline:
                product.sales_waterfall_cpg.baseline +
                promoEvent.product_kpi.sales_waterfall_cpg.baseline,
              uplift:
                product.sales_waterfall_cpg.uplift +
                promoEvent.product_kpi.sales_waterfall_cpg.uplift,
              discount:
                product.sales_waterfall_cpg.discount +
                promoEvent.product_kpi.sales_waterfall_cpg.discount,
              execution_cost:
                product.sales_waterfall_cpg.execution_cost +
                promoEvent.product_kpi.sales_waterfall_cpg.execution_cost,
              cannibalization:
                product.sales_waterfall_cpg.cannibalization +
                promoEvent.product_kpi.sales_waterfall_cpg.cannibalization,
              pull_forward:
                product.sales_waterfall_cpg.pull_forward +
                promoEvent.product_kpi.sales_waterfall_cpg.pull_forward,
              total:
                product.sales_waterfall_cpg.total +
                promoEvent.product_kpi.sales_waterfall_cpg.total,
            },
            sales_waterfall_retailer: {
              baseline:
                product.sales_waterfall_retailer.baseline +
                promoEvent.product_kpi.sales_waterfall_retailer.baseline,
              uplift:
                product.sales_waterfall_retailer.uplift +
                promoEvent.product_kpi.sales_waterfall_retailer.uplift,
              discount:
                product.sales_waterfall_retailer.discount +
                promoEvent.product_kpi.sales_waterfall_retailer.discount,
              execution_cost:
                product.sales_waterfall_retailer.execution_cost +
                promoEvent.product_kpi.sales_waterfall_retailer.execution_cost,
              cannibalization:
                product.sales_waterfall_retailer.cannibalization +
                promoEvent.product_kpi.sales_waterfall_retailer.cannibalization,
              pull_forward:
                product.sales_waterfall_retailer.pull_forward +
                promoEvent.product_kpi.sales_waterfall_retailer.pull_forward,
              total:
                product.sales_waterfall_retailer.total +
                promoEvent.product_kpi.sales_waterfall_retailer.total,
            },
          };
        }
        return product;
      }),
    ],
    promoEvents: [
      ...promoEvents,
      {
        ...promoEvent,
        promo_id: promoEvents.length,
      },
    ],
  };
};

export const updateEvent = (
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[],
  promoEvent: PromoEvent
) => {
  const previousPromoEvent = find(
    promoEvents,
    (promo) => promo.id === promoEvent.id
  );
  return {
    productKPIS: [
      ...map(productKPIS, (product) => {
        if (
          product.product === promoEvent.product_name &&
          product.retailer === promoEvent.retailer_name
        ) {
          return {
            ...product,
            profit_waterfall_cpg: {
              baseline:
                product.profit_waterfall_cpg.baseline -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .baseline || 0) +
                promoEvent.product_kpi.profit_waterfall_cpg.baseline,
              uplift:
                product.profit_waterfall_cpg.uplift -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg.uplift ||
                  0) +
                promoEvent.product_kpi.profit_waterfall_cpg.uplift,
              discount:
                product.profit_waterfall_cpg.discount -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .discount || 0) +
                promoEvent.product_kpi.profit_waterfall_cpg.discount,
              execution_cost:
                product.profit_waterfall_cpg.execution_cost -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .execution_cost || 0) +
                promoEvent.product_kpi.profit_waterfall_cpg.execution_cost,
              cannibalization:
                product.profit_waterfall_cpg.cannibalization -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .cannibalization || 0) +
                promoEvent.product_kpi.profit_waterfall_cpg.cannibalization,
              pull_forward:
                product.profit_waterfall_cpg.pull_forward -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .pull_forward || 0) +
                promoEvent.product_kpi.profit_waterfall_cpg.pull_forward,
              total:
                product.profit_waterfall_cpg.total -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg.total ||
                  0) +
                promoEvent.product_kpi.profit_waterfall_cpg.total,
            },
            profit_waterfall_retailer: {
              baseline:
                product.profit_waterfall_retailer.baseline -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .baseline || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.baseline,
              uplift:
                product.profit_waterfall_retailer.uplift -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .uplift || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.uplift,
              discount:
                product.profit_waterfall_retailer.discount -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .discount || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.discount,
              execution_cost:
                product.profit_waterfall_retailer.execution_cost -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .execution_cost || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.execution_cost,
              cannibalization:
                product.profit_waterfall_retailer.cannibalization -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .cannibalization || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer
                  .cannibalization,
              pull_forward:
                product.profit_waterfall_retailer.pull_forward -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .pull_forward || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.pull_forward,
              total:
                product.profit_waterfall_retailer.total -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .total || 0) +
                promoEvent.product_kpi.profit_waterfall_retailer.total,
            },
            sales_waterfall_cpg: {
              baseline:
                product.sales_waterfall_cpg.baseline -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.baseline ||
                  0) +
                promoEvent.product_kpi.sales_waterfall_cpg.baseline,
              uplift:
                product.sales_waterfall_cpg.uplift -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.uplift ||
                  0) +
                promoEvent.product_kpi.sales_waterfall_cpg.uplift,
              discount:
                product.sales_waterfall_cpg.discount -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.discount ||
                  0) +
                promoEvent.product_kpi.sales_waterfall_cpg.discount,
              execution_cost:
                product.sales_waterfall_cpg.execution_cost -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .execution_cost || 0) +
                promoEvent.product_kpi.sales_waterfall_cpg.execution_cost,
              cannibalization:
                product.sales_waterfall_cpg.cannibalization -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .cannibalization || 0) +
                promoEvent.product_kpi.sales_waterfall_cpg.cannibalization,
              pull_forward:
                product.sales_waterfall_cpg.pull_forward -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .pull_forward || 0) +
                promoEvent.product_kpi.sales_waterfall_cpg.pull_forward,
              total:
                product.sales_waterfall_cpg.total -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.total ||
                  0) +
                promoEvent.product_kpi.sales_waterfall_cpg.total,
            },
            sales_waterfall_retailer: {
              baseline:
                product.sales_waterfall_retailer.baseline -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .baseline || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.baseline,
              uplift:
                product.sales_waterfall_retailer.uplift -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .uplift || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.uplift,
              discount:
                product.sales_waterfall_retailer.discount -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .discount || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.discount,
              execution_cost:
                product.sales_waterfall_retailer.execution_cost -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .execution_cost || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.execution_cost,
              cannibalization:
                product.sales_waterfall_retailer.cannibalization -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .cannibalization || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.cannibalization,
              pull_forward:
                product.sales_waterfall_retailer.pull_forward -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .pull_forward || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.pull_forward,
              total:
                product.sales_waterfall_retailer.total -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .total || 0) +
                promoEvent.product_kpi.sales_waterfall_retailer.total,
            },
          };
        }
        return product;
      }),
    ],
    promoEvents: [
      ...filter(promoEvents, (promo) => promo.id !== promoEvent.id),
      promoEvent,
    ],
  };
};
export const removeEvent = (
  productKPIS: ProductKPI[],
  promoEvents: PromoEvent[],
  promoEvent: PromoEvent
) => {
  const previousPromoEvent = find(
    promoEvents,
    (promo) => promo.id === promoEvent.id
  );
  return {
    productKPIS: [
      ...map(productKPIS, (product) => {
        if (
          product.product === promoEvent.product_name &&
          product.retailer === promoEvent.retailer_name
        ) {
          return {
            ...product,
            profit_waterfall_cpg: {
              baseline:
                product.profit_waterfall_cpg.baseline -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .baseline || 0),
              uplift:
                product.profit_waterfall_cpg.uplift -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg.uplift ||
                  0),
              discount:
                product.profit_waterfall_cpg.discount -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .discount || 0),
              execution_cost:
                product.profit_waterfall_cpg.execution_cost -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .execution_cost || 0),
              cannibalization:
                product.profit_waterfall_cpg.cannibalization -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .cannibalization || 0),
              pull_forward:
                product.profit_waterfall_cpg.pull_forward -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg
                  .pull_forward || 0),
              total:
                product.profit_waterfall_cpg.total -
                (previousPromoEvent?.product_kpi.profit_waterfall_cpg.total ||
                  0),
            },
            profit_waterfall_retailer: {
              baseline:
                product.profit_waterfall_retailer.baseline -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .baseline || 0),
              uplift:
                product.profit_waterfall_retailer.uplift -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .uplift || 0),
              discount:
                product.profit_waterfall_retailer.discount -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .discount || 0),
              execution_cost:
                product.profit_waterfall_retailer.execution_cost -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .execution_cost || 0),
              cannibalization:
                product.profit_waterfall_retailer.cannibalization -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .cannibalization || 0),
              pull_forward:
                product.profit_waterfall_retailer.pull_forward -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .pull_forward || 0),
              total:
                product.profit_waterfall_retailer.total -
                (previousPromoEvent?.product_kpi.profit_waterfall_retailer
                  .total || 0),
            },
            sales_waterfall_cpg: {
              baseline:
                product.sales_waterfall_cpg.baseline -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.baseline ||
                  0),
              uplift:
                product.sales_waterfall_cpg.uplift -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.uplift ||
                  0),
              discount:
                product.sales_waterfall_cpg.discount -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.discount ||
                  0),
              execution_cost:
                product.sales_waterfall_cpg.execution_cost -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .execution_cost || 0),
              cannibalization:
                product.sales_waterfall_cpg.cannibalization -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .cannibalization || 0),
              pull_forward:
                product.sales_waterfall_cpg.pull_forward -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg
                  .pull_forward || 0),
              total:
                product.sales_waterfall_cpg.total -
                (previousPromoEvent?.product_kpi.sales_waterfall_cpg.total ||
                  0),
            },
            sales_waterfall_retailer: {
              baseline:
                product.sales_waterfall_retailer.baseline -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .baseline || 0),
              uplift:
                product.sales_waterfall_retailer.uplift -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .uplift || 0),
              discount:
                product.sales_waterfall_retailer.discount -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .discount || 0),
              execution_cost:
                product.sales_waterfall_retailer.execution_cost -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .execution_cost || 0),
              cannibalization:
                product.sales_waterfall_retailer.cannibalization -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .cannibalization || 0),
              pull_forward:
                product.sales_waterfall_retailer.pull_forward -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .pull_forward || 0),
              total:
                product.sales_waterfall_retailer.total -
                (previousPromoEvent?.product_kpi.sales_waterfall_retailer
                  .total || 0),
            },
          };
        }
        return product;
      }),
    ],
    promoEvents: [
      ...filter(promoEvents, (promo) => promo.id !== promoEvent.id),
    ],
  };
};

// get PromoEvent from item id of timeline item
export const getPromoEventFromItemId = (
  items: TimelineItem[],
  itemId: string
): PromoEvent | undefined | null => {
  return find(items, (item) => item.id === itemId)?.promo;
};

export const getChartData2 = (promoEvents: Event, chartType: CHART_TYPE) => {
  switch (chartType) {
    case CHART_TYPE.PROFIT_WATERFALL_CPG: {
      return {
        baseline: promoEvents.baseline_gross_profit,
        uplift: promoEvents.uplift_gross_profit + promoEvents.total_promo_spend,
        discount: -1 * promoEvents.total_promo_spend,
        cannibalization: -1 * promoEvents.gross_profit_cannib,
        pull_forward: -1 * promoEvents.gross_profit_pull_forward,
        margin: promoEvents.incremental_gross_profit,
      };
    }
    case CHART_TYPE.SALES_WATERFALL_CPG: {
      return {
        baseline: promoEvents.baseline_net_net_sales_value,
        uplift: promoEvents.uplift_net_sales_value,
        discount: -1 * promoEvents.total_promo_spend,
        cannibalization: -1 * promoEvents.net_net_sales_value_cannib,
        pull_forward: -1 * promoEvents.net_net_sales_value_pull_forward,
        margin: promoEvents.incremental_net_net_sales_value,
      };
    }
    case CHART_TYPE.SALES_WATERFALL_RETAILER: {
      //
      return {
        baseline: promoEvents.baseline_retail_sales_value,
        uplift: promoEvents.uplift_retail_sales_value,
        discount: 0,
        cannibalization: -promoEvents.retail_sales_value_cannib,
        pull_forward: -promoEvents.retail_sales_value_pull_forward,
        margin: promoEvents.incremental_retail_sales_value,
      };
    }
    case CHART_TYPE.PROFIT_WATERFALL_RETAILER: {
      return {
        baseline: promoEvents.baseline_customer_trade_profit,
        uplift:
          promoEvents.uplift_customer_trade_profit +
          promoEvents.customer_promo_contribution,
        discount: -1 * promoEvents.customer_promo_contribution,

        cannibalization: -1 * promoEvents.customer_trade_profit_cannib,
        pull_forward: -1 * promoEvents.customer_trade_profit_pull_forward,
        margin: promoEvents.incremental_customer_trade_profit,
      };
    }
  }
};

export const comparator = <T>(
  data: T[],
  keyToSort: keyof T,
  direction: "asc" | "des" = "asc"
) => {
  if (data === null || data === undefined || data.length < 2) return data;
  const compare = (objectA: T, objectB: T) => {
    const valueA = objectA[keyToSort];
    const valueB = objectB[keyToSort];
    if (valueA === valueB) return 0;
    if (valueA > valueB) return direction === "asc" ? 1 : -1;
    else return direction === "asc" ? -1 : 1;
  };

  return data.slice().sort(compare);
};

export const mapChartData = (charts: any[]) => {
  const chartData: ChartDataArrayType = {
    cpg_retail_sales_value: [],
    customer_revenue: [],
    cpg_net_revenue: [],
    gross_profit_cpg: [],
    gross_profit_customer: [],
    volumes_cpg: [],
    volumes_customer: [],
  };
  const keys = Object.keys(chartData);
  charts.forEach((chart, index) => {
    keys.forEach((key) => {
      chartData[key].push({
        name: chart["name"],
        total: +chart["total_" + key],
        inscreased_mark_val:
          index != 0 && index % 2 === 0 ? +chart["promo_" + key] : undefined,
        inscreased_mark_val2:
          index != 0 && index % 2 === 0
            ? +chart["non_promo_" + key]
            : undefined,
        max_margin_val:
          index != 0 && index % 2 !== 0 ? +chart["promo_" + key] : undefined,
        max_margin_val2:
          index != 0 && index % 2 !== 0
            ? +chart["non_promo_" + key]
            : undefined,
        baseline_val: index === 0 ? +chart["promo_" + key] : undefined,
        baseline_val2: index === 0 ? +chart["non_promo_" + key] : undefined,
        differenceRefCalend: +chart["percentage_" + key],
        up: "",
        gap: 10,
        marginLabel: [+chart["total_" + key]],
      });
    });
  });

  return chartData;
};

function formatReferenceValue(value) {
  return "Ref " + formatNumber(value);
}

export function mapCalendarReferenceData(referenceCalendar) {
  return [
    // TO BE UPDATED, arrays returned should map to the individual products not just pick one
    [
      {
        value: 50,
        label: formatReferenceValue(
          referenceCalendar.arp[Object.keys(referenceCalendar.arp)[0]]
        ),
      },
    ],
    [
      {
        value: 50,
        label: formatReferenceValue(
          referenceCalendar.floor_retail_sales[
            Object.keys(referenceCalendar.floor_retail_sales)[0]
          ]
        ),
      },
    ],
    [
      {
        value: 50,
        label: formatReferenceValue(
          referenceCalendar.margin_rate[
            Object.keys(referenceCalendar.margin_rate)[0]
          ]
        ),
      },
    ],
    [{ value: 50, label: formatReferenceValue(referenceCalendar.profit) }],
    [
      {
        value: 50,
        label: formatReferenceValue(referenceCalendar.promo_spend),
      },
    ],
    [
      {
        value: 50,
        label: formatReferenceValue(referenceCalendar.promos_per_product),
      },
    ],
    [
      {
        value: 50,
        label: formatReferenceValue(
          referenceCalendar.promos_per_brand[
            Object.keys(referenceCalendar.promos_per_brand)[0]
          ]
        ),
      },
    ],
    [
      {
        value: 50,
        label: formatReferenceValue(referenceCalendar.promos_per_viz_type),
      },
    ],
  ];
}

export function applyReferenceFormula(type, percentage, reference) {
  return type === "MAX"
    ? (1 + percentage / 100) * reference
    : (1 - percentage / 100) * reference;
}

export function mapToRequestBody(constraintsForm, formData) {
  const requestToBeSent = {};
  const brands = {};
  const products = {};

  const pages = Object.keys(constraintsForm);
  pages.forEach((page) => {
    if (constraintsForm[page].calendars) {
      constraintsForm[page].calendars.forEach((calendar) => {
        requestToBeSent[calendar.name] = calendar.value;
        requestToBeSent[calendar.name + "Percent"] = calendar.percentages;
      });
    }

    if (constraintsForm[page].brands) {
      constraintsForm[page].brands.forEach((brand) => {
        const brandId = brand.name.split(periodRegex)[1];
        const constraintName = brand.name.split(periodRegex)[2];

        if (!brands[brandId]) {
          brands[brandId] = {};
        }
        brands[brandId][constraintName] = brand.value;
        brands[brandId][constraintName + "Percent"] = brand.percentages;
      });
    }

    if (constraintsForm[page].products) {
      constraintsForm[page].products.forEach((product) => {
        const productId = product.name.split(periodRegex)[1];
        const constraintName = product.name.split(periodRegex)[2];

        if (!products[productId]) {
          products[productId] = {};
        }
        products[productId][constraintName] = product.value;
      });
    }
  });

  if (formData.brands) {
    Object.keys(formData.brands).forEach((key) => {
      if (brands[key]) {
        Object.assign(brands[key], formData.brands[key]);
      } else {
        brands[key] = { ...formData.brands[key] };
      }
    });
  }
  if (formData.products) {
    Object.keys(formData.products).forEach((key) => {
      if (products[key]) {
        Object.assign(products[key], formData.products[key]);
      } else {
        products[key] = { ...formData.products[key] };
      }
    });
  }

  if (Object.keys(brands).length) {
    Object.keys(brands).forEach((key) => {
      const brandId = key.split("-")[1];
      brands[key].name = brandId;
    });
    requestToBeSent["brands"] = { ...brands };
  }

  if (Object.keys(products).length) {
    Object.keys(products).forEach((key) => {
      const productId = key.substring(key.indexOf("-") + 1);
      products[key].name = productId;
    });
    requestToBeSent["products"] = { ...products };
  }

  return requestToBeSent;
}

export function compareObjects(obj1, obj2) {
  if (obj1 == null || obj2 == null) return false;
  // Check if the objects have the same number of properties
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // Iterate over the properties of the first object
  for (let prop in obj1) {
    // Check if the second object has the same property
    if (!obj2.hasOwnProperty(prop)) {
      return false;
    }

    // Compare the values of the properties
    if (obj1[prop] !== obj2[prop]) {
      return false;
    }
  }

  // The objects are considered equal if all the properties match
  return true;
}

export function mapCalendarLevelForm(formData) {
  const formDataCopy = { ...formData };
  return {
    allowMultibuy: formDataCopy.allowMultibuy,
    allowUpr: formDataCopy.allowUpr,
    allowPpr: formDataCopy.allowPpr,
    allowNewPrice: formDataCopy.allowNewPrice,
    priceEstablishment: formDataCopy.priceEstablishment,
    promo_min_per_slot: formDataCopy.promo_min_per_slot,
    promo_max_per_slot: formDataCopy.promo_max_per_slot,
  };
}

export function mapCalendarLevelConstraints(constraintsForm, formData) {
  const requestToBeSent = {};
  const brands = {};
  const products = {};

  const pages = Object.keys(constraintsForm);
  pages.forEach((page) => {
    if (constraintsForm[page].calendars) {
      constraintsForm[page].calendars.forEach((calendar) => {
        requestToBeSent[calendar.name] = calendar.value;
        requestToBeSent[calendar.name + "Percent"] = calendar.percentages;
      });
    }

    if (constraintsForm[page].brands) {
      constraintsForm[page].brands.forEach((brand) => {
        let brandId = brand.name.split(periodRegex)[1];
        let constraintName = brand.name.split(periodRegex)[2];

        if (!brands[brandId]) {
          brands[brandId] = {};
        }
        brands[brandId][constraintName] = brand.value;
        brands[brandId][constraintName + "Percent"] = brand.percentages;
      });
    }

    if (constraintsForm[page].products) {
      constraintsForm[page].products.forEach((product) => {
        let productId = product.name.split(periodRegex)[1];
        let constraintName = product.name.split(periodRegex)[2];
        if (!products[productId]) {
          products[productId] = {};
        }
        products[productId][constraintName] = product.value;
      });
    }
  });

  if (formData.brands) {
    Object.keys(formData.brands).forEach((key) => {
      if (brands[key]) {
        Object.assign(brands[key], formData.brands[key]);
      } else {
        brands[key] = { ...formData.brands[key] };
      }
    });
  }
  if (formData.products) {
    Object.keys(formData.products).forEach((key) => {
      if (products[key]) {
        Object.assign(products[key], formData.products[key]);
      } else {
        products[key] = { ...formData.products[key] };
      }
    });
  }

  return requestToBeSent;
}

export function mapToConstraintSetRow(data) {
  const list: ConstraintSetRow[] = [];

  data.forEach((constraint) => {
    const item: ConstraintSetRow = {
      author: "-",
      calendarsUsingIt: constraint.calendars,
      category: "-",
      customer: "-",
      id: constraint.id,
      isTemplate: constraint.is_template,
      isFavorite: constraint.is_favorite,
      lastUpdated: constraint.modifiedAt,
      name: constraint.name,
      quarter: "-",
    };
    list.push(item);
  });

  return list;
}

export function convertConstraintsData(constraintSet) {
  const constraintsCommons: any[] = [];
  const constraintsBrands: any[] = [];
  const constraintsProducts: any[] = [];

  constraintSet.forEach((constraint) => {
    if (constraint.level === "CALENDAR") {
      if (
        constraintsCommons.findIndex((x) => x.name === constraint.name) === -1
      ) {
        constraintsCommons.push({
          constraint_set_id: constraint.constraint_set_id,
          id: constraint.id,
          level: constraint.level,
          name: constraint.name,
        });
      }

      const ref =
        constraintsCommons[
          constraintsCommons.findIndex((x) => x.name === constraint.name)
        ];

      ref[constraint.type] = constraint.value;
    }

    if (constraint.level === "BRAND") {
      if (
        constraintsBrands.findIndex(
          (x) =>
            x.name === constraint.name &&
            x.internal_code === constraint.related_internal_code
        ) === -1
      ) {
        constraintsBrands.push({
          constraint_set_id: constraint.constraint_set_id,
          id: constraint.id,
          internal_code: constraint.related_internal_code,
          level: constraint.level,
          name: constraint.name,
        });
      }
      const ref =
        constraintsBrands[
          constraintsBrands.findIndex(
            (x) =>
              x.name === constraint.name &&
              x.internal_code === constraint.related_internal_code
          )
        ];
      ref[constraint.type] = constraint.value;
    }

    if (constraint.level === "PRODUCT") {
      if (constraint.type === "EQUAL") {
        constraintsProducts.push({
          constraint_set_id: constraint.constraint_set_id,
          id: constraint.id,
          internal_code: constraint.related_internal_code,
          level: constraint.level,
          name: constraint.name,
        });
        const ref = constraintsProducts[constraintsProducts.length - 1];
        ref[constraint.type] = constraint.value;
      } else {
        if (
          constraintsProducts.findIndex(
            (x) =>
              x.name === constraint.name &&
              x.internal_code === constraint.related_internal_code
          ) === -1
        ) {
          constraintsProducts.push({
            constraint_set_id: constraint.constraint_set_id,
            id: constraint.id,
            internal_code: constraint.related_internal_code,
            level: constraint.level,
            name: constraint.name,
          });
        }
        const ref =
          constraintsProducts[
            constraintsProducts.findIndex(
              (x) =>
                x.name === constraint.name &&
                x.internal_code === constraint.related_internal_code
            )
          ];
        ref[constraint.type] = constraint.value;
      }
    }
  });

  return {
    constraintsCommons,
    constraintsBrands,
    constraintsProducts,
  };
}

// Function to split a string into chunks
const splitIntoChunks = (str, size) => {
  let chunks: any = [];
  for (let i = 0; i < str.length; i += size) {
    chunks.push(str.substring(i, i + size));
  }
  return chunks;
};

// Convert json array to csv, header order is based on alphabet order
export const convertToExcel = (objArray: any) => {
  // Sort headers alphabetically
  const headers = Object.keys(objArray[0]).sort();

  // Initialize an array to hold the processed data
  let processedData: any = [];

  objArray.forEach((item) => {
    let newItem: any = {};
    let extraRows: any = [];

    headers.forEach((header) => {
      let value = item[header];

      // Convert object to a formatted string if it's an object
      if (typeof value === "object" && value !== null) {
        value = JSON.stringify(value, null, 2);
      }

      // Check and handle long text values
      const MAX_EXCEL_CELL_LENGTH = 32767;
      if (value && value.length > MAX_EXCEL_CELL_LENGTH) {
        // Split the value into chunks
        const chunks = splitIntoChunks(value, MAX_EXCEL_CELL_LENGTH);
        newItem[header] = chunks[0]; // First chunk in the main row

        // Additional chunks as new rows
        chunks.slice(1).forEach((chunk, index) => {
          if (!extraRows[index]) extraRows[index] = {};
          extraRows[index][header] = chunk;
        });
      } else {
        newItem[header] = value ?? "";
      }
    });

    // Add the new item and any extra rows to the processed data
    processedData.push(newItem);
    processedData = processedData.concat(extraRows);
  });

  // Create worksheet and workbook using processed data
  const worksheet = XLSX.utils.json_to_sheet(processedData, {
    header: headers,
    skipHeader: false,
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  return workbook;
};

// String to ArrayBuffer (required by SheetJS)
const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

// Download Excel file
export const downloadExcel = (workbook, filename) => {
  // Write the workbook to a binary string
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

  // Create a download link and trigger the download
  const downloadLink = document.createElement("a");
  downloadLink.download = filename + ".xlsx";
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export function getPromotedPrice(values: any): string {
  const everydayPrice: number = values.everydayPrice;
  if (values.tacticTypes === tacticTypes[0]) {
    if (values.singleBuyPick === "% off") {
      return `${everydayPrice * (1 - values.singleBuyValue / 100)}`;
    } else if (values.singleBuyPick === "$ Off") {
      return `${everydayPrice - values.singleBuyValue}`;
    } else {
      return `${values.singleBuyValue}`;
    }
  } else if (values.tacticTypes === tacticTypes[1]) {
    const Y: number = Number.parseInt(values.multibuyAmount + ""); // need this, otherwise will consider X and Y as string a+b == "ab"
    const X: number = Number.parseInt(values.multibuyUnit + "");

    if (X !== null && Y !== null) {
      if ("Buy" === values.multiBuyPick1) {
        // buy
        if ("Get" === values.multiBuyPick2) {
          if (
            values.multiBuyPick3 === "Units" ||
            values.multiBuyPick3 === "Free"
          ) {
            //GET, unit,
            const result = (everydayPrice * Y) / (X + Y);
            return `${result}`;
          }
        } else if (values.multiBuyPick2 === "Save") {
          // save
          return values.multiBuyPick3 === multiBuyPicklist3[1]
            ? `${(everydayPrice * X - Y) / X}`
            : `${everydayPrice * (1 - Y / 100)}`;
        } else if (values.multiBuyPick2 === multiBuyPicklist2[2]) {
          return values.multiBuyPick3 === multiBuyPicklist3[0]
            ? `${Y / X}`
            : `${(everydayPrice * Y) / X}`;
        }
      } else {
        // Spend
        if (multiBuyPicklist2[0] === values.multiBuyPick2) {
          return `${X / Y}`;
        } else if (multiBuyPicklist2[1] === values.multiBuyPick2) {
          return values.multiBuyPick3 === multiBuyPicklist3[0]
            ? `${everydayPrice * (1 - Y / X)}`
            : `${everydayPrice * (1 - Y / 100)}`;
        }
      }
    }
  }
  return everydayPrice + "";
}
