import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList,
  Popper,
  Stack,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CalendarOptimizationActionTypes,
  fetchAllCalendars,
  fetchAllCalendarFilters,
  uploadCalendarAction,
} from "../../../utils/redux/calendar/calendarOptimizationActions";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW";
import { useStyles } from "./CalendarManagementStyle";
import AllCalendar from "../../../components/allCalendars/AllCalendar";
import { useNavigate, generatePath } from "react-router-dom";
import StadiumIcon from "@mui/icons-material/Stadium";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";

import { RootState } from "../../../../utils/store";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

type ManagamentProps = {
  selectedMarket: string;
};

const PAGE_SIZE = 8;

// branch feature/celndar-management-business-logic
const createCalendarMenu = [
  {
    title: "Generate optimized calendar",
    navigationTabNumber: 1,
    Icon: <StadiumIcon />,
  },
  // { title: "Use last year promotion plan", navigationTabNumber: 0, Icon: null },
  // { title: "Import an external calendar", navigationTabNumber: 0, Icon: null },
];
const CalendarManagement = (props: ManagamentProps) => {
  const { selectedMarket } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [listCheckedCard, setListCheckedCard] = useState<string[]>([]);
  const [filters, setFilters] = useState<any>(null);
  const [sort, setSort] = useState<string>("created");
  const [ascending, setAscending] = useState<boolean | null>(false);
  const calendarRequest = useAppSelector(
    (state: RootState) =>
      state.reducer.calendarOptimizationReducer.calendarsData
  );
  const calendars = useAppSelector(
    (state: RootState) =>
      state.reducer.calendarOptimizationReducer.calendarsData.data
  );
  const allCalendars = useAppSelector(
    (state: RootState) =>
      state.reducer.calendarOptimizationReducer.AllCalendarData.data
  );
  const calendarFilters = useAppSelector(
    (state: RootState) =>
      state.reducer.calendarOptimizationReducer.calendarFilters
  );
  const uploadCalendar = useAppSelector(
    (state: RootState) =>
      state.reducer.calendarOptimizationReducer.uploadCalendar
  );
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (!calendarRequest.pending) {
      dispatch({
        type: CalendarOptimizationActionTypes.CLEAR_ALL_CALENDARS,
        data: {},
      });
    }
  }, []);

  useEffect(() => {
    if (!calendarRequest.pending) {
      dispatch({
        type: CalendarOptimizationActionTypes.CLEAR_ALL_CALENDARS,
        data: {},
      });
    }
    if (selectedMarket) {
      dispatch(fetchAllCalendarFilters(selectedMarket));
      setPage(1);
    }
  }, [selectedMarket]);

  // useEffect(() => {
  //   if(!calendars && !calendarRequest.pending) {
  //     dispatch(fetchAllCalendars(selectedMarket));
  //   }
  // }, [calendars]);

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      dispatch(uploadCalendarAction(formData));
    }
  }, [selectedFile]);

  useEffect(() => {
    if (uploadCalendar.rejected || uploadCalendar.fulfilled) {
      setOpenAlert(true);
    }
    if (uploadCalendar.fulfilled) {
      if (sort && ascending !== null) {
        dispatch(
          fetchAllCalendars(
            selectedMarket,
            page,
            PAGE_SIZE,
            filters,
            sort,
            ascending
          )
        );
      } else {
        dispatch(fetchAllCalendars(selectedMarket, page, PAGE_SIZE, filters));
      }
    }
  }, [uploadCalendar]);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (sort && ascending !== null) {
      dispatch(
        fetchAllCalendars(
          selectedMarket,
          page,
          PAGE_SIZE,
          filters,
          sort,
          ascending
        )
      );
    } else {
      dispatch(fetchAllCalendars(selectedMarket, page, PAGE_SIZE, filters));
    }
  }, [page, selectedMarket, filters, sort, ascending]);

  useEffect(() => {
    setPage(1);
  }, [filters, ascending, sort]);

  useEffect(() => {
    if (isDeleted) {
      if (selectedMarket) {
        dispatch(fetchAllCalendarFilters(selectedMarket));
        setPage(1);
        if (sort && ascending !== null) {
          dispatch(
            fetchAllCalendars(
              selectedMarket,
              page,
              PAGE_SIZE,
              filters,
              sort,
              ascending
            )
          );
        } else {
          dispatch(fetchAllCalendars(selectedMarket, page, PAGE_SIZE, filters));
        }
      }
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const checkedCard = (calendarId: string) => {
    if (listCheckedCard.includes(calendarId)) {
      setListCheckedCard(listCheckedCard.filter((cal) => cal != calendarId));
    } else {
      setListCheckedCard([...listCheckedCard, calendarId]);
    }
  };

  const handleCompareCalendar = () => {
    navigate("/recommendation/comparison", {
      state: {
        ids: listCheckedCard,
      },
    });
  };

  const handleAggregateCalendar = () => {
    navigate("/recommendation/aggregation", {
      state: {
        ids: listCheckedCard,
      },
    });
  };

  const handleNavigationToViewedit = (calendar: any) => {
    //const path = generatePath("/promo/recommendation/edit", { calendar['id'] } );
    navigate(`/recommendation/edit/${calendar.id}`, {
      state: {
        id: calendar.id,
      },
    });
  };

  const handleUploadCalendar = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: CalendarOptimizationActionTypes.UPLOAD_CALENDAR });
    const file = event.target.files?.[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
        file.type === "application/vnd.ms-excel" || // .xls
        file.name.endsWith(".xlsx") ||
        file.name.endsWith(".xls")
      ) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
        setOpenAlert(true);
      }
      event.target.value = ""; // Reset the file input
    }
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <div>
          <Typography fontWeight={"bold"}>Calendar Management</Typography>
        </div>
        <div className={classes.buttonsStyling}>
          {/* <div>
            <Button variant="outlined" color="secondary" startIcon={<ContentCopyOutlinedIcon />} sx={{ marginRight: 2 }}
                    onClick={handleAggregateCalendar} disabled={![2, 3, 4].includes(listCheckedCard.length)}
            >
              Aggregate calendars
            </Button>
          </div> */}
          {/* <div>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FilterBAndWIcon />}
              sx={{ marginRight: 2 }}
              onClick={handleCompareCalendar}
              disabled={listCheckedCard.length < 2}
            >
              Compare calendars
            </Button>
          </div> */}
          <div>
            <Button
              component="label"
              variant="outlined"
              color="secondary"
              startIcon={<FileUploadOutlined />}
              sx={{ marginRight: 2 }}
            >
              Upload calendar
              <input
                type="file"
                onChange={handleUploadCalendar}
                accept=".xlsx, .xls"
                hidden
              />
            </Button>
          </div>

          <div>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<NoteAddOutlinedIcon />}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(ev) => {
                  navigate("/recommendation/create");
                }}
              >
                Create calendar
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                //      disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <div className={classes.dropDownCreateCalendarMenu}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          {createCalendarMenu.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={(ev) => {
                                  navigate("/recommendation/create");
                                }}
                                style={{
                                  backgroundColor: "white",
                                  marginTop: 10,
                                  borderRadius: 4,
                                }}
                              >
                                <Typography fontSize={16}>
                                  {item.title} {item.Icon}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </div>
      </div>
      {calendars && calendarFilters.data ? (
        <Grid container direction="column">
          <AllCalendar
            handleCardClick={(e, calendar) => {
              handleNavigationToViewedit(calendar);
            }}
            calendarCardDetails={calendars.calendar_data}
            calendarTimelineData={calendars.calendar_management_timeline}
            checkedCard={(calendarId) => checkedCard(calendarId)}
            listChecked={listCheckedCard}
            count={
              allCalendars
                ? Math.ceil(allCalendars.total / PAGE_SIZE)
                : undefined
            }
            page={page}
            handleChange={handleChange}
            calendarFilters={calendarFilters.data}
            setFilters={setFilters}
            setSortBy={setSort}
            sortBy={sort}
            setAscending={setAscending}
            ascending={ascending}
            setIsDeleted={setIsDeleted}
          />
        </Grid>
      ) : (
        <div>'Loading'</div>
      )}

      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 999999,
          backgroundColor: "neutral.main",
        }}
        open={
          uploadCalendar.pending ||
          calendarRequest.pending ||
          calendarFilters.pending
        }
        onClick={() => {
          return;
        }}
      >
        <CircularProgress sx={{ color: "primary.main" }} />
      </Backdrop>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={6000}
      >
        {uploadCalendar.rejected ? (
          <Alert severity="error">{`File upload failed! Please try again later`}</Alert>
        ) : uploadCalendar.fulfilled ? (
          <Alert severity="success">{`File upload successful!`}</Alert>
        ) : (
          <Alert severity="error">{`Incompatible file extension provided. Supported extensions are .xlsx & .xls`}</Alert>
        )}
      </Snackbar>
    </div>
  );
};

export default CalendarManagement;
