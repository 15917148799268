import { ReactComponent as DiagnosticsIcon } from "../../utils/icons/diagnostics_icon.svg";
import { ReactComponent as RecommendationIcon } from "../../utils/icons/recommendation_icon.svg";
import { ReactComponent as SimulationIcon } from "../../utils/icons/simulation_icon.svg";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BoltIcon from "@mui/icons-material/Bolt";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";

import BASE_IMAGE from "../img/diagnostics/base_image.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PROMO_EXCUTIVE_VIEW from "../img/diagnostics/PromoExecutiveView.png";
import PROMO_BUSINESS_OBJECTIVES from "../img/diagnostics/PromoBusinessObjectives.png";

import PROMO_INTENSITY_ACROSS_BRAND_CATEGORY from "../img/diagnostics/PromoIntensityAcrossBrandsAndCategories.png";
import PROMO_INTENSITY_ACROSS_CUSTOMER from "../img/diagnostics/PromoIntensityAcrossCustomers.png";
import PROMO_MECHANICS_DYNAMICS from "../img/diagnostics/PromoMechanicsDynamics.png";
import PROMO_ACTIVITY_ACROSS_CUSTOMER_BEHAVIORS from "../img/diagnostics/PromoActivityAcrossConsumerBehaviors.png";
import PROMO_PERFORMANCE from "../img/diagnostics/PROMO_PERFORMANCE.png";
import PROMO_PERFORMANCE_DRIVERS from "../img/diagnostics/PROMO_DRIVER.png";
import PROMO_PERFORMANCE_BY_PRODUCT from "../img/diagnostics/PEAPromoProductLevelPerformance.png";
import PROMO_PERFORMANCE_BY_EVENT from "../img/diagnostics/PEAPromoEventLevelPerformance.png";
import DETAILED_KPIS_SUMMARY from "../img/diagnostics/Detailed_KPIs_summary.png";

export const PROMO_DRAWER_ITEM = [
  {
    icon: HomeOutlinedIcon,

    label: "Home",
    link: {
      path: "/",
      external: false,
    },
    hasChildren: false,
  },
  {
    icon: LightbulbOutlinedIcon,
    label: "Diagnostics",
    link: {
      path: "/diagnostics",
      external: false,
    },
    hasChildren: false,
    children: [
      {
        title: "Intensity",
        links: [
          {
            name: "Relative promo intensity",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo customer",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo customer overview",
            path: "/diagnostics",
            external: false,
          },
        ],
      },
      {
        title: "Depth",
        links: [
          {
            name: "Promo customer overview",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo mapping",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo performance matrix",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo mechanics mapping",
            path: "/diagnostics",
            external: false,
          },
        ],
      },
      {
        title: "Spend",
        links: [
          {
            name: "Promo spend mapping",
            path: "/diagnostics",
            external: false,
          },
          {
            name: "Promo customer needs",
            path: "/diagnostics",
            external: false,
          },
        ],
      },
    ],
  },
  {
    icon: BoltIcon,
    label: "Predictive Simulation",
    link: {
      path: "/simulation",
      external: false,
    },
    hasChildren: false,
  },
  {
    icon: ModelTrainingIcon,
    label: "Prescriptive recommendation",
    link: {
      path: "/recommendation",
      external: true,
    },
    hasChildren: true,
    children: [
      {
        links: [
          {
            name: "Calendar management",
            path: "/recommendation",
            external: false,
            params: 0,
          },
          {
            name: "Calendar View & Edit ",
            path: "/recommendation",
            external: false,
            params: 1,
          },
          {
            name: "Calendar Comparison ",
            path: "/recommendation",
            external: false,
            params: 2,
          },
          {
            name: "Constraints Management ",
            path: "/recommendation/constraints-sets",
            external: false,
            params: 2,
          },
        ],
      },
    ],
  },
];

export const landingCards = [
  {
    icon: QueryStatsIcon,
    title: "Diagnostics",
    sub: "Track promo performance and strategy realization at brand & customer level",
    nav: {
      url: "/diagnostics",

      external: false,
    },
    buttons: [
      {
        icon: ArrowForwardIcon,
        name: "Go to diagnostics",
        link: {
          url: "diagnostics",
          external: false,
        },
      },
    ],
    notifications: [],
  },
  {
    icon: BoltIcon,
    title: "Predictive Simulation",
    sub: "Predict outcomes of promo events and calendars and their business implications",
    nav: {
      url: "simulation",
      external: true,
    },
    buttons: [
      {
        icon: ArrowForwardIcon,
        name: "Simulate event",
        link: {
          url: "simulation",
          external: false,
        },
      },
      // {
      //   icon: ArrowForwardIcon,
      //   name: "Simulate calendar",
      //   link: {
      //     url: "simulation",
      //     external: false,
      //   },
      // },
    ],
    notifications: [],
  },
  {
    icon: ModelTrainingIcon,
    title: "Prescriptive Recommendation",
    sub: "Optimize promo calendars at event & customer level considering various parameters & business constraints",
    nav: {
      url: "recommendation",
      external: false,
    },
    buttons: [
      {
        icon: NoteAddOutlinedIcon,
        name: "Create calendar",
        link: {
          url: "recommendation/create",
          external: false,
          params: 1,
        },
      },
      {
        icon: ArrowForwardIcon,
        name: "Go to calendar list",
        link: {
          url: "recommendation",
          external: false,
          params: 0,
        },
      },
    ],
    notifications: [],
  },
];

//=============Diagnostic==dashboard==========//
// Promo business objectives https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoBusinessObjectives
export const diagnosticDashboardList = [
  {
    label: "Executive view",
    dashboards: [
      {
        title: "Promo executive view",
        description: "What is the overall promo performance & strategy",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_Performance_V2/PromoExecutiveView",
        value: "14",
        isChangable: false,
        isPositionned: true,
        img: PROMO_EXCUTIVE_VIEW,
      },
    ],
  },
  {
    label: "Promo Strategy",
    dashboards: [
      {
        title: "Promo business objectives",
        description:
          "How does my promo activity drive my business objectives? ",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoBusinessObjectives",
        value: "1",
        isChangable: false,
        isPositionned: true,
        img: PROMO_BUSINESS_OBJECTIVES,
      },
      {
        title: "Promo intensity across brands & categories",
        description:
          "How does my promo intensity deaverge in pressure and depth across the portfolio? Vs competiton?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoIntensityAcrossBrandsAndCategories",
        value: "2",
        isChangable: false,
        isPositionned: true,
        img: PROMO_INTENSITY_ACROSS_BRAND_CATEGORY,
      },
      {
        title: "Promo activity across customers",
        description:
          "How is the promo activity across customers for my company and for the competition?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoIntensityAcrossCustomers",
        value: "3",
        isChangable: true,
        isPositionned: true,
        img: PROMO_INTENSITY_ACROSS_CUSTOMER,
      },
      {
        title: "Promo mechanics dynamics",
        description:
          "How do my promo mechanics & depth vary for my company and competition across our portoflio? ",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoMechanicsDynamics",
        value: "4",
        isChangable: true,
        isPositionned: true,
        img: PROMO_MECHANICS_DYNAMICS,
      },
      {
        title: "Promo activity across consumer segments",
        description:
          "What are the promo dynamics preferred by each consumer segment?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoDashboardsV3/PromoActivityAcrossConsumerBehaviors",
        value: "5",
        isChangable: true,
        isPositionned: true,
        img: PROMO_ACTIVITY_ACROSS_CUSTOMER_BEHAVIORS,
      },
    ],
  },

  {
    label: "Promo Performance (Post-event analysis)",
    dashboards: [
      {
        title: "Diagnostics",
        description:
          "How is my promo strategy performing? Which brands, categories and customers are driving the most value?",
        link: "https://app.powerbi.com/reportEmbed?reportId=ded0721d-ac6b-4162-8521-acf92810f679&autoAuth=true&ctid=480d282e-43bd-4cfc-8011-117f28fe97fc",
        value: "6",
        isChangable: false,
        isPositionned: true,
        img: PROMO_PERFORMANCE,
      },
      {
        title: "Promo performance drivers",
        description:
          "Which features (depth mechanic, visivility) are driving the best promotions?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_PromoPerformanceMatrixV2/PromoPerformanceDrivers",
        value: "8",
        isChangable: false,
        isPositionned: true,
        img: PROMO_PERFORMANCE_DRIVERS,
      },
      {
        title: "Promo performance by product",
        description:
          "By product, what drives higher/lower performing promotions?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_Performance_V2/PEAPromoProductLevelPerformance",
        value: "7",
        isChangable: false,
        isPositionned: true,
        img: PROMO_PERFORMANCE_BY_PRODUCT,
      },

      {
        title: "Promo performance by event",
        description:
          "What has been the precise performance of one given past promo event?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_Performance_V2/PEAPromoEventLevelPerformance",
        value: "9",
        isChangable: false,
        isPositionned: true,
        img: PROMO_PERFORMANCE_BY_EVENT,
      },
    ],
  },
  {
    label: "Detailed view",
    dashboards: [
      {
        title: "Detailed KPIs summary",
        description:
          "How to deaverage the overall promo KPIs at global & local, category, brand and customer level?",
        link: "https://tableauha.bcg.com/t/nextgen-nrm/views/NRM_Promo_Performance_V2/OverallPromoPerformance",
        value: "10",
        isChangable: false,
        isPositionned: true,
        img: DETAILED_KPIS_SUMMARY,
      },
    ],
  },
];

export const financialsCalendarConstraints = [
  {
    constraints_names: ["promoSpendMin", "promoSpendMax"],
    name: "Promo spend",
    presetKey: "promo_spend",
    refLabel: "promo_spend",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["promoSpendPerUnitMin", "promoSpendPerUnitMax"],
    name: "Promo spend per case",
    presetKey: "promo_spend_per_unit",
    refLabel: "promo_spend_per_unit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorProfitMin"],
    name: "Minimum profit",
    presetKey: "floor_profit",
    refLabel: "floor_profit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Minimum Sales",
    constraints_names: ["floorSalesMin"],
    presetKey: "floor_sales",
    refLabel: "floor_sales",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorRSVMin"],
    name: "Minimum RSV",
    presetKey: "floor_rsv",
    refLabel: "floor_rsv",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const financialsBrandsConstraints = [
  {
    constraints_names: ["promoSpendMin", "promoSpendMax"],
    brand: "",
    name: "Promo spend",
    presetKey: "promo_spend",
    refLabel: "promo_spend",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["promoSpendPerUnitMin", "promoSpendPerUnitMax"],
    brand: "",
    name: "Promo spend per case",
    presetKey: "promo_spend_per_unit",
    refLabel: "promo_spend_per_unit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorProfitMin"],
    brand: "",
    name: "Minimum profit",
    presetKey: "floor_profit",
    refLabel: "floor_profit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Minimum Sales",
    brand: "",
    constraints_names: ["floorSalesMin"],
    presetKey: "floor_sales",
    refLabel: "floor_sales",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorRSVMin"],
    brand: "",
    name: "Minimum RSV",
    presetKey: "floor_rsv",
    refLabel: "floor_rsv",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const financialsProductsConstraints = [
  {
    constraints_names: ["promoSpendMin", "promoSpendMax"],
    name: "Promo spend",
    brand: "",
    product: "",
    presetKey: "promo_spend",
    refLabel: "promo_spend",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["promoSpendPerUnitMin", "promoSpendPerUnitMax"],
    name: "Promo spend per case",
    brand: "",
    product: "",
    presetKey: "promo_spend_per_unit",
    refLabel: "promo_spend_per_unit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorProfitMin"],
    name: "Minimum profit",
    brand: "",
    product: "",
    presetKey: "floor_profit",
    refLabel: "floor_profit",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Minimum Sales",
    brand: "",
    product: "",
    constraints_names: ["floorSalesMin"],
    presetKey: "floor_sales",
    refLabel: "floor_sales",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    constraints_names: ["floorRSVMin"],
    brand: "",
    product: "",
    name: "Minimum RSV",
    presetKey: "floor_rsv",
    refLabel: "floor_rsv",
    refVal: 10,
    isActive: false,
    currency: true,
    marks: [
      {
        value: [50],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const promomixCalendarConstraints = [
  {
    name: "No. weeks on promo",
    constraints_names: ["numPromosMin", "numPromosMax"],
    brand: "",
    level: "calendar",
    presetKey: "num_promos",
    refLabel: "num_promos",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [5],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Consecutive Weeks on Promo",
    brand: "",
    constraints_names: ["minWeeksPromo", "maxWeeksPromo"],
    level: "calendar",
    presetKey: "consecutive_promo_weeks",
    refLabel: "consecutive_promo_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Min Cooldown Weeks",
    brand: "",
    constraints_names: ["minWeeksCooldown"],
    level: "calendar",
    presetKey: "cooldown_weeks",
    refLabel: "cooldown_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const promomixBrandsConstraints = [
  {
    name: "No. weeks on promo",
    brand: "",
    constraints_names: ["numPromosMin", "numPromosMax"],
    level: "brand",
    presetKey: "num_promos",
    refLabel: "num_promos",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Consecutive Weeks on Promo",
    brand: "",
    constraints_names: ["minWeeksPromo", "maxWeeksPromo"],
    level: "brand",
    presetKey: "consecutive_promo_weeks",
    refLabel: "consecutive_promo_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Min Cooldown Weeks",
    brand: "",
    constraints_names: ["minWeeksCooldown"],
    level: "brand",
    presetKey: "cooldown_weeks",
    refLabel: "cooldown_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const promomixProductsConstraints = [
  {
    name: "No. weeks on promo",
    brand: "",
    product: "",
    constraints_names: ["numPromosMin", "numPromosMax"],
    level: "product",
    presetKey: "num_promos",
    refLabel: "num_promos",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Consecutive Weeks on Promo",
    brand: "",
    product: "",
    constraints_names: ["minWeeksPromo", "maxWeeksPromo"],
    level: "product",
    presetKey: "consecutive_promo_weeks",
    refLabel: "consecutive_promo_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
  {
    name: "Min Cooldown Weeks",
    brand: "",
    product: "",
    constraints_names: ["minWeeksCooldown"],
    level: "product",
    presetKey: "cooldown_weeks",
    refLabel: "cooldown_weeks",
    refVal: 1,
    isActive: false,
    marks: [
      {
        value: [0],
        label: "Ref 10*",
        rawValue: 0,
      },
    ],
  },
];

export const supportedMarketValues = [
  "sub_national_code-OCCIDENTE",
  "sub_national_code-SUR",
  "sub_national_code-CENTRO",
  "sub_national_code-METRO",
  "sub_national_code-NORTE",
  "sub_national_code-PACIFICO",
  "market_code-MX",
];

export const tacticTypes = ["Punto de Precio", "Multiahorro"];
export const multiBuyPicklist1 = ["Buy"];
export const multiBuyPicklist2 = ["Save"];
export const multiBuyPicklist3 = ["%", "$"];
export const singleBuyPicklist = ["% off"];

export const tacticTypeAll = [
  "Single-buy - $ off",
  "Single-buy - % off",
  "Single-buy - $ price",
  "Multi-buy",
];
export const location = [
  "Store Entrance",
  "Front Aisle",
  "Back of Aisle",
  "In-aisle - with Category",
  "In-aisle - Secondary Category",
  "In-aisle - Out of Category",
  "In-aisle - Seasonal",
  "Pharmacy",
  "Pharmacy – Behind the Register",
  "Check Out/Register",
  "Front of Store (behind Check Out)",
  "Convenience only",
  "Multiple locations",
];
export const DisplayItems = [
  "Gondola/End Cap",
  "Free Standing",
  "Wing/Ladder Rack",
  "Dump Bin",
  "Counter Unit",
  "Check Out/Front End",
  "Shelf Only",
  "Shelf Unit (Promo/Hot Spot)",
  "Pallet",
  "Other",
];

export const KeyTerms = {
  incremental_net_net_sales: "Incremental Net Net Sales",
  incremental_gross_profit: "Incremental Gross Profit",
  incremental_retail_sales_value: "Incremental Retailer Sales Value",
  incremental_customer_trade_profit: "Incremental Retailer Gross Profit",
};

export const Months = {
  January: 1,
  February: 1,
  March: 1,
  April: 2,
  May: 2,
  June: 2,
  July: 3,
  August: 3,
  September: 3,
  October: 4,
  November: 4,
  December: 4,
};

export const QUARTER_MAPPINGS = {
  Q1: {
    start: "01/01",
    end: "03/31",
  },
  Q2: {
    start: "04/01",
    end: "06/30",
  },
  Q3: {
    start: "07/01",
    end: "09/30",
  },
  Q4: {
    start: "10/01",
    end: "12/31",
  },
  Year: {
    start: "01/01",
    end: "12/31",
  },
  January: {
    start: "01/01",
    end: "01/31",
  },
  February: {
    start: "02/01",
    end: "02/29",
  },
  March: {
    start: "03/01",
    end: "03/31",
  },
  April: {
    start: "04/01",
    end: "04/30",
  },
  May: {
    start: "05/01",
    end: "05/31",
  },
  June: {
    start: "06/01",
    end: "06/30",
  },
  July: {
    start: "07/01",
    end: "07/31",
  },
  August: {
    start: "08/01",
    end: "08/31",
  },
  September: {
    start: "09/01",
    end: "09/30",
  },
  October: {
    start: "10/01",
    end: "10/31",
  },
  November: {
    start: "11/01",
    end: "11/30",
  },
  December: {
    start: "12/01",
    end: "12/31",
  },
};

export const tooltipItemsToShow = [
  "Promo spend ($)",
  "COGS ($)",
  "Cannibalization + pull-forward effect on net net sales ($)",
];

export const constraintsNameMap = {
  promo_spend: "Promo spend",
  promo_spend_per_unit: "Promo spend per case",
  floor_profit: "Minimum profit",
  floor_sales: "Minimum sales",
  floor_rsv: "Minimum RSV",
  num_promos: "No. weeks on promo",
  cooldown_weeks: "Min Cooldown Weeks",
  consecutive_promo_weeks: "Consecutive Weeks on Promo",
};
