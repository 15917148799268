import { CalendarComparsionViewEditActionTypes } from "./calendarComparsionViewEditActions";

const INITIAL_STATE = {
  referenceCalendar: {},
}

function CalendarComparsionViewEditReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CalendarComparsionViewEditActionTypes.SELECT_REFERENCE_CALENDAR:
      return { ...state, referenceCalendar: {...action.payload} };
    default:
      return { ...state };
  }
  return state;
}

export default CalendarComparsionViewEditReducer;
