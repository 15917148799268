import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4, 5),
  },
  head: {
    minWidth: "125px",
  },
  tableContainer: {
    width: "95.4vw",
    height: "100%",
    paddingBottom: "1rem",
  },
  tableRow: {
    height: 40,
  },
  cell: {
    minWidth: "100px",
  },
  tollTab: {
    backgroundColor: theme.palette.primary.background,
  },
  tabStyle: {
    "&.MuiButtonBase-root.MuiTab-root": {
      color: theme.palette.primary.text,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.primary.light,
      },
    },
    "&.Mui-selected": {
      color: "red",
    },
  },
}));
