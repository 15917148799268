import React from "react";
import clsx from "clsx";
import { Typography, Box, Grid } from "@mui/material";

import { makeStyles, createStyles } from "@mui/styles";
import KpiChart from "./KpiChart";

import { useStyles } from "./ChartKpiStyle";
import { KPIChartColor } from "../../../../../utils/theme";
export enum DATA_WIDGET_SIZE {
  SMALL = "SMALL",
  BIG = "BIG",
}

export type DataWidgetStyleProps = {
  size: DATA_WIDGET_SIZE;
};

type DataWidgetProps = {
  className?: string;
  cardTitle: string;
  firstChartTitle: string;
  firstChartData: any[];
  secondChartTitle?: string;
  secondChartData?: any[];
};

const CalendarComparisonCard = (props: DataWidgetProps) => {
  const {
    className,
    cardTitle,
    firstChartTitle,
    firstChartData,
    secondChartTitle,
    secondChartData,
  } = props;
  const classes = useStyles({});

  const renderLegend = () => {
    return (
      <div style={{ display: "flex" }}>
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item>
            <Box
              sx={{
                width: 23,
                height: 20,
                border: 1,
              }}
            />
          </Grid>
          <Grid item>
            <Typography fontSize={12}>Promo</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          spacing={1}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item laptop={6} laptop_lg={4}>
            <Box
              sx={{
                width: 23,
                height: 20,
                border: 1,
                backgroundColor: KPIChartColor.baseline,
              }}
            />
          </Grid>
          <Grid item laptop={6} laptop_lg={5}>
            <Typography fontSize={12} sx={{ minWidth: 80 }}>
              Non-promo
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2" fontSize={20} fontWeight={"500"} align="left">
          {cardTitle}
        </Typography>
        <div style={{ float: "right", paddingRight: 25 }}>{renderLegend()}</div>
      </div>
      <div className={classes.dataContainer}>
        <Grid container>
          <Grid item mobile={12} tablet={6} laptop={6} laptop_lg={6}>
            <KpiChart title={firstChartTitle} data={firstChartData} />
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={6} laptop_lg={6}>
            {secondChartData && (
              <KpiChart
                title={secondChartTitle ? secondChartTitle : ""}
                data={secondChartData}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CalendarComparisonCard;
