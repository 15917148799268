import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const calendarHEaderStyle = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(2, 4),
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-arround",
      gap: "12px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "4px",
    },

    select: {
      width: 160,
    },
    primaryButton: {
      gap: "14px",
      height: 32,
      float: "right",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
