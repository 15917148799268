import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const promoIncrementalityStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '10px',
    },
    kpiDataWidgetSection: {
      width: "100%",
      height: 450,
    },
    selectContainer: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column"
      },
      selectLabel: {
        fontSize: "12px",
        fontWeight: 600,
        marginRight: theme.spacing(2),
      },
  })
);
