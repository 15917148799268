import { Grid, TableBody, TableContainer } from "@mui/material";
import {
  ConstraintsTable,
  StyledTableCell,
  StyledTableHeader,
  TableCellActions,
} from "Promo/pages/calendar/constraintsManagement/constraintsManagementStyles";
import { StyledTableRow } from "Promo/pages/calendar/components/kpiTable/KpiTableStyle";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { ConstraintSetRow } from "Promo/pages/calendar/constraintsManagement/constraintsManagement";

type ConstraintsSetsTableType = {
  rows: ConstraintSetRow[];
  onDelete?: (id, name) => any;
  onEdit?: (id) => any;
  onCopy?: (id) => any;
  onRowClick?: (id) => any;
  onChipClick?: (id) => any;
};

export function ConstraintsSetsTable({
  rows,
  onDelete = (id, name) => id,
  onEdit = (id) => id,
  onCopy = (id) => id,
  onRowClick = (id) => id,
  onChipClick = (id) => id,
}: ConstraintsSetsTableType) {
  return (
    <TableContainer>
      <ConstraintsTable
        aria-label="customized table"
        sx={{ borderSpacing: "0px 15px", borderCollapse: "separate" }}
      >
        <StyledTableHeader>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Set name</StyledTableCell>
            <StyledTableCell align="right">Last update</StyledTableCell>
            <StyledTableCell align="right">Author</StyledTableCell>
            {/* <StyledTableCell align="right">Quarter</StyledTableCell> */}
            {/* <StyledTableCell align="right">Customer</StyledTableCell> */}
            {/* <StyledTableCell align="right">Category</StyledTableCell> */}
            <StyledTableCell align="right"></StyledTableCell>
          </StyledTableRow>
        </StyledTableHeader>
        <TableBody>
          {rows.map((row) => {
            return (
              <StyledTableRow
                key={row.name}
                onClick={(_) => onRowClick(row.id)}
              >
                <StyledTableCell>
                  {row.isFavorite ? (
                    <StarIcon />
                  ) : (
                    <StarBorderIcon
                      sx={{
                        opacity: 0.4,
                        filter: "alpha(opacity=40)",
                        "&:hover": { cursor: "not-allowed" },
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.lastUpdated ? row.lastUpdated.toLocaleDateString() : "-"}
                </StyledTableCell>
                <StyledTableCell align="right">{row.author}</StyledTableCell>
                {/* <StyledTableCell align="right">{row.quarter}</StyledTableCell> */}
                {/* <StyledTableCell align="right">{row.customer}</StyledTableCell> */}
                {/* <StyledTableCell align="right">{row.category}</StyledTableCell> */}
                <StyledTableCell align="right">
                  <TableCellActions
                    data={row}
                    onChipClick={(id) => onChipClick(row.id)}
                    onDelete={(id) => onDelete(row.id, row.name)}
                    onCopy={(id) => onCopy(row.id)}
                    onEdit={(id) => onEdit(row.id)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </ConstraintsTable>
    </TableContainer>
  );
}
