import { createStyles, makeStyles } from "@mui/styles";
import {
  Button,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const calendarHeaderStyle = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(2, 0),
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-arround",
      gap: "12px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "4px",
    },
    leftButtons: {
      // position: "relative",
      gap: "12px",
      float: "right",
      display: "flex",
      justifyContent: "space-between",
    },
    leftIcon: {
      float: "left",
      display: "flex",
      justifyContent: "space-between",
      width: "6%",
    },
    headerTitles: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
    },
    draft: {
      // backgroundColor: "rgba(168, 168, 168, 1)",
      //  border: "1px solid",
      borderRadius: 6,
      width: "54%",
      height: 23,
      ///alignItems: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    select: {
      width: 160,
    },
  })
);

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "26px",
  lineHeight: "27px",
  color: theme.palette.primary.text,
  padding: "0",
}));

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    fontFamily: "Open Sans",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "600",
    color: theme.palette.primary.text,
    lineHeight: "14px",
    padding: "20px 0px 0px 1px",
  })
);

export const StyledDialogButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "4px",
  whiteSpace: "nowrap",
  border: "1px solid",
  boxShadow: "0px 3px 2px rgba(0, 0, 0, 0.1)",
  borderColor: theme.palette.secondary.main,
  height: "32px",
  width: "auto",
  "&:hover": {
    opacity: "0.8",
  },
}));

export const StyledDialogButtonText = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "19px",
  maxHeight: "32px",
  whiteSpace: "nowrap",
  cursor: "pointer",
}));
