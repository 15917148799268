import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "utils/hooks";
import * as s from "../ContraintsStyle";
import "../styles.scss";

const multibuyValues = [1, 2, 3];
const uprValues = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0];
const pprValues = [10, 15, 20, 25, 30];
const newPriceValues = [1, 2, 3, 4, 5];

export function DecisionSpace({
  register,
  brands,
  watch,
  setValue,
  resetField,
}: any) {
  const [allowMultibuy, setAllowMultibuy] = useState(false);
  const [allowUPR, setAllowUPR] = useState(false);
  const [allowPPR, setAllowPPR] = useState(false);
  const [allowNewPrice, setAllowNewPrice] = useState(false);
  const [selectedCard, setSelectedCard] = useState("none");

  const { constraintsCommons, constraintsBrands, constraintsProducts } =
    useAppSelector((state) => {
      return {
        constraintsCommons: state.reducer.ConstraintsManagementReducer.commons,
        constraintsBrands: state.reducer.ConstraintsManagementReducer.brands,
        constraintsProducts:
          state.reducer.ConstraintsManagementReducer.products,
      };
    });

  useEffect(() => {
    setAllowMultibuy(false);
    setAllowUPR(false);
    setAllowPPR(false);
    setAllowNewPrice(false);
    setValue("allowMultibuy", false);
    setValue("allowUpr", false);
    setValue("allowPpr", false);
    setValue("allowNewPrice", false);
    resetField("brands");
    resetField("products");
    setSelectedCard("none");

    if (constraintsCommons.length >= 1) {
      constraintsCommons.forEach((x) => {
        if (x.name === "allow_multibuy") {
          setAllowMultibuy(!!x.BINARY);
          setValue("multibuy", x.EQUAL || 0);
        }
        if (x.name === "upr") {
          setAllowUPR(!!x.BINARY);
          setValue("upr", x.EQUAL || 0);
        }
        if (x.name === "ppr") {
          setAllowPPR(!!x.BINARY);
          setValue("ppr", x.EQUAL || 0);
        }
        if (x.name === "newPrice") {
          setAllowNewPrice(!!x.BINARY);
          setValue("newPrice", x.EQUAL || 0);
        }
      });
    }
  }, [constraintsCommons]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: "100%",
        "& .price-input": {
          width: "4rem",
          height: "2.5rem",
          padding: 0,
        },
      }}
    >
      <Typography variant="h4">Add calendar constraints</Typography>

      <Grid container direction="column" mt={3} gap={3} sx={{ width: "100%" }}>
        <s.Row>
          {s.SwitchWithLabelWithState(
            "Allow multibuy",
            register("allowMultibuy", {
              onChange: (event) => setAllowMultibuy(!allowMultibuy),
            }),
            allowMultibuy,
            "small"
          )}

          {allowMultibuy && (
            <>
              <FormControl
                sx={{
                  maxWidth: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
                size="small"
              >
                <s.Label>Number of items</s.Label>

                <Select
                  sx={{ background: "white", marginBottom: "1rem" }}
                  {...register("multibuy")}
                  defaultValue={0}
                >
                  <MenuItem value={0} defaultChecked disabled>
                    {"0"}
                  </MenuItem>
                  {multibuyValues.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <s.RedirectLabel>Show brand / product constraint</s.RedirectLabel>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand: any, i) => (
                        <Box
                          onClick={() => {
                            selectedCard === "multibuy" + i
                              ? setSelectedCard("none")
                              : setSelectedCard("multibuy" + i);
                          }}
                          className={`brandcard ${
                            selectedCard === "multibuy" + i
                              ? "active-brandcard"
                              : ""
                          }`}
                        >
                          {s.SwitchWithLabel(
                            brand.internal_code,
                            register(
                              `brands.brand-${brand.internal_code}.multibuy`
                            )
                          )}
                          <FormControl
                            sx={{
                              maxWidth: "fit-content",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                            size="small"
                          >
                            <s.Label># of items</s.Label>
                            <Select
                              sx={{ background: "white" }}
                              defaultValue={1}
                              {...register(
                                `brands.brand-${brand.internal_code}.multibuyQuantity`
                              )}
                            >
                              <MenuItem value={1}>1</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand, i) => {
                        return (
                          <Box
                            className={`submenu ${
                              selectedCard === "multibuy" + i
                                ? "active-productcard"
                                : ""
                            }`}
                            sx={{}}
                          >
                            {brand.products.map((product) => (
                              <Box
                                py={1}
                                px={3}
                                sx={{
                                  background: "#F4F4F4",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "2rem",
                                }}
                              >
                                {s.SwitchWithLabel(
                                  product.sku_name,
                                  register(
                                    `products.product-${product.internal_product_code}.multibuy`
                                  )
                                )}
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexWrap: "nowrap",
                                    whiteSpace: "nowrap",
                                  }}
                                  size="small"
                                >
                                  <s.Label># of items</s.Label>
                                  <Select
                                    defaultValue={1}
                                    sx={{ background: "white" }}
                                    {...register(
                                      `products.product-${product.internal_product_code}.multibuyQuantity`
                                    )}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            ))}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </s.Row>

        <s.Row>
          {s.SwitchWithLabelWithState(
            "Allow unit price reduction",
            register("allowUpr", {
              onChange: (event) => setAllowUPR(!allowUPR),
            }),
            allowUPR,
            "small"
          )}
          {allowUPR && (
            <>
              <FormControl
                sx={{
                  maxWidth: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
                size="small"
              >
                <s.Label>Promo depth ($)</s.Label>
                <Select
                  sx={{ background: "white", marginBottom: "1rem" }}
                  {...register("upr")}
                  defaultValue={0}
                >
                  <MenuItem value={0} defaultChecked disabled>
                    {"0"}
                  </MenuItem>
                  {uprValues.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <s.RedirectLabel>Show brand / product constraint</s.RedirectLabel>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand: any, i) => (
                        <Box
                          onClick={() => {
                            selectedCard === "upr" + i
                              ? setSelectedCard("none")
                              : setSelectedCard("upr" + i);
                          }}
                          className={`brandcard ${
                            selectedCard === "upr" + i ? "active-brandcard" : ""
                          }`}
                        >
                          {s.SwitchWithLabel(
                            brand.internal_code,
                            register(`brands.brand-${brand.internal_code}.upr`)
                          )}
                          <FormControl
                            sx={{
                              maxWidth: "fit-content",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                            size="small"
                          >
                            <s.Label># of items</s.Label>
                            <Select
                              defaultValue={1}
                              sx={{ background: "white" }}
                              {...register(
                                `brands.brand-${brand.internal_code}.uprQuantity`
                              )}
                            >
                              <MenuItem value={1}>1</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand, i) => {
                        return (
                          <Box
                            className={`submenu ${
                              selectedCard === "upr" + i
                                ? "active-productcard"
                                : ""
                            }`}
                            sx={{}}
                          >
                            {brand.products.map((product) => (
                              <Box
                                py={1}
                                px={3}
                                sx={{
                                  background: "#F4F4F4",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "2rem",
                                }}
                              >
                                {s.SwitchWithLabel(
                                  product.sku_name,
                                  register(
                                    `products.product-${product.internal_product_code}.upr`
                                  )
                                )}
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexWrap: "nowrap",
                                    whiteSpace: "nowrap",
                                  }}
                                  size="small"
                                >
                                  <s.Label># of items</s.Label>
                                  <Select
                                    defaultValue={1}
                                    sx={{ background: "white" }}
                                    {...register(
                                      `products.product-${product.internal_product_code}.uprQuantity`
                                    )}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            ))}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </s.Row>

        <s.Row>
          {s.SwitchWithLabelWithState(
            "Allow percentage price reduction",
            register("allowPpr", {
              onChange: (event) => setAllowPPR(!allowPPR),
            }),
            allowPPR,
            "small"
          )}
          {allowPPR && (
            <>
              <FormControl
                sx={{
                  maxWidth: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
                size="small"
              >
                <s.Label>Promo depth (%)</s.Label>
                <Select
                  sx={{ background: "white", marginBottom: "1rem" }}
                  {...register("ppr")}
                  defaultValue={0}
                >
                  <MenuItem value={0} defaultChecked disabled>
                    {"0"}
                  </MenuItem>
                  {pprValues.map((value) => (
                    <MenuItem value={value}>{value}%</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <s.RedirectLabel>Show brand / product constraint</s.RedirectLabel>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand: any, i) => (
                        <Box
                          onClick={() => {
                            selectedCard === "ppr" + i
                              ? setSelectedCard("none")
                              : setSelectedCard("ppr" + i);
                          }}
                          className={`brandcard ${
                            selectedCard === "ppr" + i ? "active-brandcard" : ""
                          }`}
                        >
                          {s.SwitchWithLabel(
                            brand.internal_code,
                            register(`brands.brand-${brand.internal_code}.ppr`)
                          )}
                          <FormControl
                            sx={{
                              maxWidth: "fit-content",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                            size="small"
                          >
                            <s.Label># of items</s.Label>
                            <Select
                              sx={{ background: "white" }}
                              defaultValue={1}
                              {...register(
                                `brands.brand-${brand.internal_code}.pprQuantity`
                              )}
                            >
                              <MenuItem value={1}>1</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand, i) => {
                        return (
                          <Box
                            className={`submenu ${
                              selectedCard === "ppr" + i
                                ? "active-productcard"
                                : ""
                            }`}
                            sx={{}}
                          >
                            {brand.products.map((product) => (
                              <Box
                                py={1}
                                px={3}
                                sx={{
                                  background: "#F4F4F4",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "2rem",
                                }}
                              >
                                {s.SwitchWithLabel(
                                  product.sku_name,
                                  register(
                                    `products.product-${product.internal_product_code}.ppr`
                                  )
                                )}
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexWrap: "nowrap",
                                    whiteSpace: "nowrap",
                                  }}
                                  size="small"
                                >
                                  <s.Label># of items</s.Label>
                                  <Select
                                    defaultValue={1}
                                    sx={{ background: "white" }}
                                    {...register(
                                      `products.product-${product.internal_product_code}.pprQuantity`
                                    )}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            ))}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </s.Row>

        <s.Row>
          {s.SwitchWithLabelWithState(
            "Allow new price",
            register("allowNewPrice", {
              onChange: (event) => setAllowNewPrice(!allowNewPrice),
            }),
            allowNewPrice,
            "small"
          )}
          {allowNewPrice && (
            <>
              <FormControl
                sx={{
                  maxWidth: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
                size="small"
              >
                <s.Label>Price ($)</s.Label>

                <Select
                  sx={{ background: "white", marginBottom: "1rem" }}
                  {...register("newPrice")}
                  defaultValue={0}
                >
                  <MenuItem value={0} defaultChecked disabled>
                    {"0"}
                  </MenuItem>
                  {newPriceValues.map((value) => (
                    <MenuItem value={value}>{value}%</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <s.RedirectLabel>Show brand / product constraint</s.RedirectLabel>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand: any, i) => (
                        <Box
                          onClick={() => {
                            selectedCard === "newprice" + i
                              ? setSelectedCard("none")
                              : setSelectedCard("newprice" + i);
                          }}
                          className={`brandcard ${
                            selectedCard === "newPrice" + i
                              ? "active-brandcard"
                              : ""
                          }`}
                        >
                          {s.SwitchWithLabel(
                            brand.internal_code,
                            register(
                              `brands.brand-${brand.internal_code}.newPrice`
                            )
                          )}
                          <FormControl
                            sx={{
                              maxWidth: "fit-content",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                            }}
                            size="small"
                          >
                            <s.Label># of items</s.Label>
                            <Select
                              sx={{ background: "white" }}
                              defaultValue={1}
                              {...register(
                                `brands.brand-${brand.internal_code}.newPriceQuantity`
                              )}
                            >
                              <MenuItem value={1}>1</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ))}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {brands &&
                      brands.map((brand, i) => {
                        return (
                          <Box
                            className={`submenu ${
                              selectedCard === "newPrice" + i
                                ? "active-productcard"
                                : ""
                            }`}
                            sx={{}}
                          >
                            {brand.products.map((product) => (
                              <Box
                                py={1}
                                px={3}
                                sx={{
                                  background: "#F4F4F4",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "2rem",
                                }}
                              >
                                {s.SwitchWithLabel(
                                  product.sku_name,
                                  register(
                                    `products.product-${product.internal_product_code}.newPrice`
                                  )
                                )}
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    flexWrap: "nowrap",
                                    whiteSpace: "nowrap",
                                  }}
                                  size="small"
                                >
                                  <s.Label># of items</s.Label>
                                  <Select
                                    defaultValue={1}
                                    sx={{ background: "white" }}
                                    {...register(
                                      `products.product-${product.internal_product_code}.newPriceQuantity`
                                    )}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            ))}
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </s.Row>
      </Grid>
    </Grid>
  );
}
