import { addDays } from "date-fns";
import {
    ICalendarComparsionRowData,
    ITimelineData,
    ITimelineRowData,
    KpiRowData,
} from "./interfaces";

export const defaultKpiRow: KpiRowData = {
    netSales: 620000,
    volume: 620000,
    profit: 620000,
    arp: 0.32,
};

var memo = 0

const setOffset = (weeks : number) => {
    memo += weeks * 7
    return memo
}

export const defaultTimelineRow: ITimelineRowData = {
    type: "baseline",
    events: [
        {
            start: addDays(new Date(), 7*5),
            end: addDays(new Date(), (7*5)+(7*2)),
            value: "100€",
        },
        {
            start: addDays(new Date(), 7*15),
            end: addDays(new Date(), (7*15)+(7*2)),
            value: "150€",
        },
        {
            start: addDays(new Date(), 7*25),
            end: addDays(new Date(), (7*25)+(7*5)),
            value: "110€",
        },
    ],
};

const defaultTimeline : ITimelineData = {
    data: [
        // Element
        {
            // Row
            data: [
                defaultTimelineRow,
                {
                    ...defaultTimelineRow,
                    type: "constantMarginMarketShare",
                },
                { ...defaultTimelineRow, type: "increasedMarketShare" },
            ],
        },
    ],
}

export const defaultCalendar: ICalendarComparsionRowData[] = [
    {
        subbrand: "Retailer 0",
        timeline: defaultTimeline,
        kpis: [defaultKpiRow],
    },
    {
        subbrand: "Subbrand 1",
        timeline: defaultTimeline,
        kpis: [defaultKpiRow],
    },
];
