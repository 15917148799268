import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { constraintsTableStyle } from "./constraintsTableStyle";
import { useEffect, useState } from "react";
import { constraintsNameMap } from "Promo/utils/constants";

type ConstraintsTableProps = {
  constraintSet: any;
  calendarName?: string;
  title?: string;
  maximizationGoals?: string;
};

const ConstraintsTable = (props: ConstraintsTableProps) => {
  const { constraintSet, calendarName, title, maximizationGoals } = props;
  const [maxObjective, setMaxObjective] = useState<string>("");
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const classes = constraintsTableStyle();

  useEffect(() => {
    if (maximizationGoals) {
      const withoutFirstWord = maximizationGoals.split(" ").slice(1).join(" ");
      setMaxObjective(
        withoutFirstWord.charAt(0).toLocaleUpperCase() +
          withoutFirstWord.slice(1)
      );
    }
  }, [maximizationGoals]);

  return (
    <>
      <Typography variant="h3" sx={{ marginLeft: "9px", padding: "10px" }}>
        {calendarName && `${calendarName} - `}
        {title}
      </Typography>

      <TableContainer component={Paper} className={classes.constraintViewTable}>
        {maxObjective && (
          <Typography
            fontSize={16}
            fontWeight={500}
            mb={2}
            textAlign={"center"}
          >
            Maximization Goal - {maxObjective}
          </Typography>
        )}
        <Table sx={{ minWidth: 700 }} aria-label="Constraint View Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Level</StyledTableCell>
              <StyledTableCell align="right">Internal Code</StyledTableCell>
              <StyledTableCell align="right">Type</StyledTableCell>
              <StyledTableCell align="right">Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {constraintSet &&
              constraintSet.calendar_constraints &&
              Object.keys(constraintSet.calendar_constraints).length > 0 &&
              constraintSet.calendar_constraints.map((constraint) => (
                <StyledTableRow key={constraint.name}>
                  <StyledTableCell component="th" scope="row">
                    {constraintsNameMap[constraint.name]
                      ? constraintsNameMap[constraint.name]
                      : constraint.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {constraint.level}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {constraint.level == "CALENDAR"
                      ? "N/A"
                      : constraint.related_internal_code}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {constraint.type}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {constraint.value}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        {!(
          constraintSet &&
          constraintSet.calendar_constraints &&
          Object.keys(constraintSet.calendar_constraints).length > 0
        ) && (
          <Grid
            display={"flex"}
            justifyContent={"center"}
            pt={2}
            width={"100%"}
          >
            <Typography>No constraints have been added!</Typography>
          </Grid>
        )}
      </TableContainer>
    </>
  );
};

export default ConstraintsTable;
