import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    rootComponent: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      fontSize: -2,
      borderRadius: 4,
      marginButtom: 15,
    },
  })
);
